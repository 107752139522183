.app-wrapper{height:100vh}
.onboarding-blackout::before{content:"";position:absolute;width:100%;height:100vh;top:0;background:rgba(0,0,0,.3);z-index:2}
.onboarding-highlighting{z-index:3 !important}
@font-face {
    font-weight: 200;
    font-family: IBMPlexMono;
    src: url(/fonts/IBMPlexMono-ExtraLight.ff2e3c2b.ttf);
}

@font-face {
    font-weight: 300;
    font-family: IBMPlexMono;
    src: url(/fonts/IBMPlexMono-Light.0080c7cc.ttf);
}

@font-face {
    font-weight: 400; /* Соответствует значению normal */
    font-family: IBMPlexMono;
    src: url(/fonts/IBMPlexMono-Regular.b01b725c.ttf);
}

@font-face {
    font-weight: 500;
    font-family: IBMPlexMono;
    src: url(/fonts/IBMPlexMono-Regular.b01b725c.ttf);
}

@font-face {
    font-weight: 600;
    font-family: IBMPlexMono;
    src: url(/fonts/IBMPlexMono-Medium.2a9c8cd1.ttf);
}

@font-face {
    font-weight: bold; /* Соответствует значению bold */
    font-family: IBMPlexMono;
    src: url(/fonts/IBMPlexMono-Bold.7c138e5a.ttf);
}

@font-face {
    font-weight: 700; /* Соответствует значению bold */
    font-family: IBMPlexMono;
    src: url(/fonts/IBMPlexMono-Bold.7c138e5a.ttf);
}

@font-face {
    font-weight: 800; 
    font-family: IBMPlexMono;
    src: url(/fonts/IBMPlexMono-SemiBold.5e23ff29.ttf);
}
@font-face {
    font-weight: 200;
    font-family: Inter;
    src: url(/fonts/Inter-ExtraLight.e7f00fab.ttf);
}

@font-face {
    font-weight: 300;
    font-family: Inter;
    src: local("Inter");
    src: url(/fonts/Inter-Light.1ae2e909.ttf);
}

@font-face {
    font-weight: 400;
    font-family: Inter;
    src: url(/fonts/Inter-Regular.a1db7239.ttf);
}

@font-face {
    font-weight: 500;
    font-family: Inter;
    src: url(/fonts/Inter-Medium.adc5e936.ttf);
}

@font-face {
    font-weight: 600;
    font-family: Inter;
    src: url(/fonts/Inter-Bold.658d907c.ttf);
}

@font-face {
    font-weight: 200;
    font-family: Montserrat;
    src: url(/fonts/Montserrat-ExtraLight.c82a42c9.ttf);
}

@font-face {
    font-weight: 300;
    font-family: Montserrat;
    src: url(/fonts/Montserrat-Light.ac0230b4.ttf);
}

@font-face {
    font-weight: 400; /* Соответствует значению normal */
    font-family: Montserrat;
    src: url(/fonts/Montserrat-Regular.54c76208.ttf);
}

@font-face {
    font-weight: 500;
    font-family: Montserrat;
    src: url(/fonts/Montserrat-Regular.54c76208.ttf);
}

@font-face {
    font-weight: 600;
    font-family: Montserrat;
    src: url(/fonts/Montserrat-Medium.62e48611.ttf);
}

@font-face {
    font-weight: bold; /* Соответствует значению bold */
    font-family: Montserrat;
    src: url(/fonts/Montserrat-Bold.0066078b.ttf);
}

@font-face {
    font-weight: 700; /* Соответствует значению bold */
    font-family: Montserrat;
    src: url(/fonts/Montserrat-Bold.0066078b.ttf);
}

@font-face {
    font-weight: 800; 
    font-family: Montserrat;
    src: url(/fonts/Montserrat-SemiBold.941396b7.ttf);
}
/*!
 * Bootstrap v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */:root{--blue: #00cfe8;--indigo: #6610f2;--purple: #A94CD8;--pink: #e83e8c;--red: #ea5455;--orange: #ff9f43;--yellow: #ffc107;--green: #28c76f;--teal: #20c997;--cyan: #17a2b8;--white: #fff;--gray: #b8c2cc;--gray-dark: #1e1e1e;--primary: #A94CD8;--secondary: #82868b;--success: #28c76f;--info: #00cfe8;--warning: #ff9f43;--danger: #ea5455;--light: #f6f6f6;--dark: #161d31;--breakpoint-xs: 0;--breakpoint-sm: 576px;--breakpoint-md: 768px;--breakpoint-lg: 992px;--breakpoint-xl: 1200px;--font-family-sans-serif: "Montserrat", Helvetica, Arial, serif;--font-family-monospace: "Montserrat", Helvetica, Arial, serif}

*,*::before,*::after{box-sizing:border-box}

html{font-family:sans-serif;line-height:1.15;-webkit-text-size-adjust:100%;-webkit-tap-highlight-color:rgba(34,41,47,0)}

article,aside,figcaption,figure,footer,header,hgroup,main,nav,section{display:block}

body{margin:0;font-family:"Montserrat",Helvetica,Arial,serif;font-size:1rem;font-weight:400;line-height:1.45;color:#6e6b7b;background-color:#f6f6f6}

[dir="ltr"] body{text-align:left}

[dir="rtl"] body{text-align:right}

[tabindex="-1"]:focus:not(:focus-visible){outline:0 !important}

hr{box-sizing:content-box;height:0;overflow:visible}

h1,h2,h3,h4,h5,h6{margin-top:0;margin-bottom:.5rem}

p{margin-top:0;margin-bottom:1rem}

abbr[title],abbr[data-original-title]{text-decoration:underline;text-decoration:underline dotted;cursor:help;border-bottom:0;text-decoration-skip-ink:none}

address{margin-bottom:1rem;font-style:normal;line-height:inherit}

ol,ul,dl{margin-top:0;margin-bottom:1rem}

ol ol,ul ul,ol ul,ul ol{margin-bottom:0}

dt{font-weight:500}

dd{margin-bottom:.5rem}

[dir="ltr"] dd{margin-left:0}

[dir="rtl"] dd{margin-right:0}

blockquote{margin:0 0 1rem}

b,strong{font-weight:600}

small{font-size:80%}

sub,sup{position:relative;font-size:75%;line-height:0;vertical-align:baseline}

sub{bottom:-0.25em}

sup{top:-0.5em}

a{color:#a94cd8;text-decoration:none;background-color:transparent}

a:hover{color:#9f37d3;text-decoration:none}

a:not([href]):not([class]){color:inherit;text-decoration:none}

a:not([href]):not([class]):hover{color:inherit;text-decoration:none}

pre,code,kbd,samp{font-family:"Montserrat",Helvetica,Arial,serif;font-size:1em}

pre{margin-top:0;margin-bottom:1rem;overflow:auto;-ms-overflow-style:scrollbar}

figure{margin:0 0 1rem}

img{vertical-align:middle;border-style:none}

svg{overflow:hidden;vertical-align:middle}

table{border-collapse:collapse}

caption{padding-top:.72rem;padding-bottom:.72rem;color:#b9b9c3;caption-side:bottom}

[dir="ltr"] caption{text-align:left}

[dir="rtl"] caption{text-align:right}

th{text-align:inherit;text-align:-webkit-match-parent}

label{display:inline-block;margin-bottom:.2857rem}

button{border-radius:0}

button:focus:not(:focus-visible){outline:0}

input,button,select,optgroup,textarea{margin:0;font-family:inherit;font-size:inherit;line-height:inherit}

button,input{overflow:visible}

button,select{text-transform:none}

[role=button]{cursor:pointer}

select{word-wrap:normal}

button,[type=button],[type=reset],[type=submit]{-webkit-appearance:button}

button:not(:disabled),[type=button]:not(:disabled),[type=reset]:not(:disabled),[type=submit]:not(:disabled){cursor:pointer}

button::-moz-focus-inner,[type=button]::-moz-focus-inner,[type=reset]::-moz-focus-inner,[type=submit]::-moz-focus-inner{padding:0;border-style:none}

input[type=radio],input[type=checkbox]{box-sizing:border-box;padding:0}

textarea{overflow:auto;resize:vertical}

fieldset{min-width:0;padding:0;margin:0;border:0}

legend{display:block;width:100%;max-width:100%;padding:0;margin-bottom:.5rem;font-size:1.5rem;line-height:inherit;color:inherit;white-space:normal}

progress{vertical-align:baseline}

[type=number]::-webkit-inner-spin-button,[type=number]::-webkit-outer-spin-button{height:auto}

[type=search]{outline-offset:-2px;-webkit-appearance:none}

[type=search]::-webkit-search-decoration{-webkit-appearance:none}

::-webkit-file-upload-button{font:inherit;-webkit-appearance:button}

output{display:inline-block}

summary{display:list-item;cursor:pointer}

template{display:none}

[hidden]{display:none !important}

h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6{margin-bottom:.5rem;font-family:inherit;font-weight:500;line-height:1.2;color:#5e5873}

h1,.h1{font-size:2rem}

h2,.h2{font-size:1.714rem}

h3,.h3{font-size:1.5rem}

h4,.h4{font-size:1.286rem}

h5,.h5{font-size:1.07rem}

h6,.h6{font-size:1rem}

.lead{font-size:1.14rem;font-weight:400}

.display-1{font-size:6rem;font-weight:400;line-height:1.2}

.display-2{font-size:5.5rem;font-weight:400;line-height:1.2}

.display-3{font-size:4.5rem;font-weight:400;line-height:1.2}

.display-4{font-size:3.5rem;font-weight:400;line-height:1.2}

hr{margin-top:1rem;margin-bottom:1rem;border:0;border-top:1px solid #ebe9f1}

small,.small{font-size:0.857rem;font-weight:400}

mark,.mark{padding:.2em;background-color:#fcf8e3}

.list-unstyled{list-style:none}

[dir="ltr"] .list-unstyled{padding-left:0}

[dir="rtl"] .list-unstyled{padding-right:0}

.list-inline{list-style:none}

[dir="ltr"] .list-inline{padding-left:0}

[dir="rtl"] .list-inline{padding-right:0}

.list-inline-item{display:inline-block}

[dir="ltr"] .list-inline-item:not(:last-child){margin-right:.5rem}

[dir="rtl"] .list-inline-item:not(:last-child){margin-left:.5rem}

.initialism{font-size:90%;text-transform:uppercase}

.blockquote{margin-bottom:1rem;font-size:1.25rem}

.blockquote-footer{display:block;font-size:0.857rem;color:#b9b9c3}

.blockquote-footer::before{content:"— "}

.img-fluid{max-width:100%;height:auto}

.img-thumbnail{padding:.25rem;background-color:#f6f6f6;border:1px solid #dae1e7;border-radius:.357rem;max-width:100%;height:auto}

.figure{display:inline-block}

.figure-img{margin-bottom:.5rem;line-height:1}

.figure-caption{font-size:90%;color:#b8c2cc}

code{font-size:90%;color:#e83e8c;word-wrap:break-word}

a>code{color:inherit}

kbd{padding:.2rem .4rem;font-size:90%;color:#fff;background-color:#eee;border-radius:.25rem}

kbd kbd{padding:0;font-size:100%;font-weight:500}

pre{display:block;font-size:90%;color:#2a2e30}

pre code{font-size:inherit;color:inherit;word-break:normal}

.pre-scrollable{max-height:340px;overflow-y:scroll}

.container,.container-fluid,.container-xxl,.container-xl,.container-lg,.container-md,.container-sm{width:100%;padding-right:1rem;padding-left:1rem;margin-right:auto;margin-left:auto}

@media(min-width: 576px){
.container-sm,.container{max-width:540px}}

@media(min-width: 768px){
.container-md,.container-sm,.container{max-width:720px}}

@media(min-width: 992px){
.container-lg,.container-md,.container-sm,.container{max-width:960px}}

@media(min-width: 1200px){
.container-xl,.container-lg,.container-md,.container-sm,.container{max-width:1140px}}

.container-xl,.container-lg,.container-md,.container-sm,.container{max-width:1440px}

.row{display:flex;flex-wrap:wrap;margin-right:-1rem;margin-left:-1rem}

.no-gutters{margin-right:0;margin-left:0}

.no-gutters>.col,.no-gutters>[class*=col-]{padding-right:0;padding-left:0}

.col-xl,.col-xl-auto,.col-xl-12,.col-xl-11,.col-xl-10,.col-xl-9,.col-xl-8,.col-xl-7,.col-xl-6,.col-xl-5,.col-xl-4,.col-xl-3,.col-xl-2,.col-xl-1,.col-lg,.col-lg-auto,.col-lg-12,.col-lg-11,.col-lg-10,.col-lg-9,.col-lg-8,.col-lg-7,.col-lg-6,.col-lg-5,.col-lg-4,.col-lg-3,.col-lg-2,.col-lg-1,.col-md,.col-md-auto,.col-md-12,.col-md-11,.col-md-10,.col-md-9,.col-md-8,.col-md-7,.col-md-6,.col-md-5,.col-md-4,.col-md-3,.col-md-2,.col-md-1,.col-sm,.col-sm-auto,.col-sm-12,.col-sm-11,.col-sm-10,.col-sm-9,.col-sm-8,.col-sm-7,.col-sm-6,.col-sm-5,.col-sm-4,.col-sm-3,.col-sm-2,.col-sm-1,.col,.col-auto,.col-12,.col-11,.col-10,.col-9,.col-8,.col-7,.col-6,.col-5,.col-4,.col-3,.col-2,.col-1{position:relative;width:100%;padding-right:1rem;padding-left:1rem}

.col{flex-basis:0;flex-grow:1;max-width:100%}

.row-cols-1>*{flex:0 0 100%;max-width:100%}

.row-cols-2>*{flex:0 0 50%;max-width:50%}

.row-cols-3>*{flex:0 0 33.3333333333%;max-width:33.3333333333%}

.row-cols-4>*{flex:0 0 25%;max-width:25%}

.row-cols-5>*{flex:0 0 20%;max-width:20%}

.row-cols-6>*{flex:0 0 16.6666666667%;max-width:16.6666666667%}

.col-auto{flex:0 0 auto;width:auto;max-width:100%}

.col-1{flex:0 0 8.3333333333%;max-width:8.3333333333%}

.col-2{flex:0 0 16.6666666667%;max-width:16.6666666667%}

.col-3{flex:0 0 25%;max-width:25%}

.col-4{flex:0 0 33.3333333333%;max-width:33.3333333333%}

.col-5{flex:0 0 41.6666666667%;max-width:41.6666666667%}

.col-6{flex:0 0 50%;max-width:50%}

.col-7{flex:0 0 58.3333333333%;max-width:58.3333333333%}

.col-8{flex:0 0 66.6666666667%;max-width:66.6666666667%}

.col-9{flex:0 0 75%;max-width:75%}

.col-10{flex:0 0 83.3333333333%;max-width:83.3333333333%}

.col-11{flex:0 0 91.6666666667%;max-width:91.6666666667%}

.col-12{flex:0 0 100%;max-width:100%}

.order-first{order:-1}

.order-last{order:13}

.order-0{order:0}

.order-1{order:1}

.order-2{order:2}

.order-3{order:3}

.order-4{order:4}

.order-5{order:5}

.order-6{order:6}

.order-7{order:7}

.order-8{order:8}

.order-9{order:9}

.order-10{order:10}

.order-11{order:11}

.order-12{order:12}

[dir="ltr"] .offset-1{margin-left:8.3333333333%}

[dir="rtl"] .offset-1{margin-right:8.3333333333%}

[dir="ltr"] .offset-2{margin-left:16.6666666667%}

[dir="rtl"] .offset-2{margin-right:16.6666666667%}

[dir="ltr"] .offset-3{margin-left:25%}

[dir="rtl"] .offset-3{margin-right:25%}

[dir="ltr"] .offset-4{margin-left:33.3333333333%}

[dir="rtl"] .offset-4{margin-right:33.3333333333%}

[dir="ltr"] .offset-5{margin-left:41.6666666667%}

[dir="rtl"] .offset-5{margin-right:41.6666666667%}

[dir="ltr"] .offset-6{margin-left:50%}

[dir="rtl"] .offset-6{margin-right:50%}

[dir="ltr"] .offset-7{margin-left:58.3333333333%}

[dir="rtl"] .offset-7{margin-right:58.3333333333%}

[dir="ltr"] .offset-8{margin-left:66.6666666667%}

[dir="rtl"] .offset-8{margin-right:66.6666666667%}

[dir="ltr"] .offset-9{margin-left:75%}

[dir="rtl"] .offset-9{margin-right:75%}

[dir="ltr"] .offset-10{margin-left:83.3333333333%}

[dir="rtl"] .offset-10{margin-right:83.3333333333%}

[dir="ltr"] .offset-11{margin-left:91.6666666667%}

[dir="rtl"] .offset-11{margin-right:91.6666666667%}

@media(min-width: 576px){
.col-sm{flex-basis:0;flex-grow:1;max-width:100%}

.row-cols-sm-1>*{flex:0 0 100%;max-width:100%}

.row-cols-sm-2>*{flex:0 0 50%;max-width:50%}

.row-cols-sm-3>*{flex:0 0 33.3333333333%;max-width:33.3333333333%}

.row-cols-sm-4>*{flex:0 0 25%;max-width:25%}

.row-cols-sm-5>*{flex:0 0 20%;max-width:20%}

.row-cols-sm-6>*{flex:0 0 16.6666666667%;max-width:16.6666666667%}

.col-sm-auto{flex:0 0 auto;width:auto;max-width:100%}

.col-sm-1{flex:0 0 8.3333333333%;max-width:8.3333333333%}

.col-sm-2{flex:0 0 16.6666666667%;max-width:16.6666666667%}

.col-sm-3{flex:0 0 25%;max-width:25%}

.col-sm-4{flex:0 0 33.3333333333%;max-width:33.3333333333%}

.col-sm-5{flex:0 0 41.6666666667%;max-width:41.6666666667%}

.col-sm-6{flex:0 0 50%;max-width:50%}

.col-sm-7{flex:0 0 58.3333333333%;max-width:58.3333333333%}

.col-sm-8{flex:0 0 66.6666666667%;max-width:66.6666666667%}

.col-sm-9{flex:0 0 75%;max-width:75%}

.col-sm-10{flex:0 0 83.3333333333%;max-width:83.3333333333%}

.col-sm-11{flex:0 0 91.6666666667%;max-width:91.6666666667%}

.col-sm-12{flex:0 0 100%;max-width:100%}

.order-sm-first{order:-1}

.order-sm-last{order:13}

.order-sm-0{order:0}

.order-sm-1{order:1}

.order-sm-2{order:2}

.order-sm-3{order:3}

.order-sm-4{order:4}

.order-sm-5{order:5}

.order-sm-6{order:6}

.order-sm-7{order:7}

.order-sm-8{order:8}

.order-sm-9{order:9}

.order-sm-10{order:10}

.order-sm-11{order:11}

.order-sm-12{order:12}

[dir="ltr"] .offset-sm-0{margin-left:0}

[dir="rtl"] .offset-sm-0{margin-right:0}

[dir="ltr"] .offset-sm-1{margin-left:8.3333333333%}

[dir="rtl"] .offset-sm-1{margin-right:8.3333333333%}

[dir="ltr"] .offset-sm-2{margin-left:16.6666666667%}

[dir="rtl"] .offset-sm-2{margin-right:16.6666666667%}

[dir="ltr"] .offset-sm-3{margin-left:25%}

[dir="rtl"] .offset-sm-3{margin-right:25%}

[dir="ltr"] .offset-sm-4{margin-left:33.3333333333%}

[dir="rtl"] .offset-sm-4{margin-right:33.3333333333%}

[dir="ltr"] .offset-sm-5{margin-left:41.6666666667%}

[dir="rtl"] .offset-sm-5{margin-right:41.6666666667%}

[dir="ltr"] .offset-sm-6{margin-left:50%}

[dir="rtl"] .offset-sm-6{margin-right:50%}

[dir="ltr"] .offset-sm-7{margin-left:58.3333333333%}

[dir="rtl"] .offset-sm-7{margin-right:58.3333333333%}

[dir="ltr"] .offset-sm-8{margin-left:66.6666666667%}

[dir="rtl"] .offset-sm-8{margin-right:66.6666666667%}

[dir="ltr"] .offset-sm-9{margin-left:75%}

[dir="rtl"] .offset-sm-9{margin-right:75%}

[dir="ltr"] .offset-sm-10{margin-left:83.3333333333%}

[dir="rtl"] .offset-sm-10{margin-right:83.3333333333%}

[dir="ltr"] .offset-sm-11{margin-left:91.6666666667%}

[dir="rtl"] .offset-sm-11{margin-right:91.6666666667%}}

@media(min-width: 768px){
.col-md{flex-basis:0;flex-grow:1;max-width:100%}

.row-cols-md-1>*{flex:0 0 100%;max-width:100%}

.row-cols-md-2>*{flex:0 0 50%;max-width:50%}

.row-cols-md-3>*{flex:0 0 33.3333333333%;max-width:33.3333333333%}

.row-cols-md-4>*{flex:0 0 25%;max-width:25%}

.row-cols-md-5>*{flex:0 0 20%;max-width:20%}

.row-cols-md-6>*{flex:0 0 16.6666666667%;max-width:16.6666666667%}

.col-md-auto{flex:0 0 auto;width:auto;max-width:100%}

.col-md-1{flex:0 0 8.3333333333%;max-width:8.3333333333%}

.col-md-2{flex:0 0 16.6666666667%;max-width:16.6666666667%}

.col-md-3{flex:0 0 25%;max-width:25%}

.col-md-4{flex:0 0 33.3333333333%;max-width:33.3333333333%}

.col-md-5{flex:0 0 41.6666666667%;max-width:41.6666666667%}

.col-md-6{flex:0 0 50%;max-width:50%}

.col-md-7{flex:0 0 58.3333333333%;max-width:58.3333333333%}

.col-md-8{flex:0 0 66.6666666667%;max-width:66.6666666667%}

.col-md-9{flex:0 0 75%;max-width:75%}

.col-md-10{flex:0 0 83.3333333333%;max-width:83.3333333333%}

.col-md-11{flex:0 0 91.6666666667%;max-width:91.6666666667%}

.col-md-12{flex:0 0 100%;max-width:100%}

.order-md-first{order:-1}

.order-md-last{order:13}

.order-md-0{order:0}

.order-md-1{order:1}

.order-md-2{order:2}

.order-md-3{order:3}

.order-md-4{order:4}

.order-md-5{order:5}

.order-md-6{order:6}

.order-md-7{order:7}

.order-md-8{order:8}

.order-md-9{order:9}

.order-md-10{order:10}

.order-md-11{order:11}

.order-md-12{order:12}

[dir="ltr"] .offset-md-0{margin-left:0}

[dir="rtl"] .offset-md-0{margin-right:0}

[dir="ltr"] .offset-md-1{margin-left:8.3333333333%}

[dir="rtl"] .offset-md-1{margin-right:8.3333333333%}

[dir="ltr"] .offset-md-2{margin-left:16.6666666667%}

[dir="rtl"] .offset-md-2{margin-right:16.6666666667%}

[dir="ltr"] .offset-md-3{margin-left:25%}

[dir="rtl"] .offset-md-3{margin-right:25%}

[dir="ltr"] .offset-md-4{margin-left:33.3333333333%}

[dir="rtl"] .offset-md-4{margin-right:33.3333333333%}

[dir="ltr"] .offset-md-5{margin-left:41.6666666667%}

[dir="rtl"] .offset-md-5{margin-right:41.6666666667%}

[dir="ltr"] .offset-md-6{margin-left:50%}

[dir="rtl"] .offset-md-6{margin-right:50%}

[dir="ltr"] .offset-md-7{margin-left:58.3333333333%}

[dir="rtl"] .offset-md-7{margin-right:58.3333333333%}

[dir="ltr"] .offset-md-8{margin-left:66.6666666667%}

[dir="rtl"] .offset-md-8{margin-right:66.6666666667%}

[dir="ltr"] .offset-md-9{margin-left:75%}

[dir="rtl"] .offset-md-9{margin-right:75%}

[dir="ltr"] .offset-md-10{margin-left:83.3333333333%}

[dir="rtl"] .offset-md-10{margin-right:83.3333333333%}

[dir="ltr"] .offset-md-11{margin-left:91.6666666667%}

[dir="rtl"] .offset-md-11{margin-right:91.6666666667%}}

@media(min-width: 992px){
.col-lg{flex-basis:0;flex-grow:1;max-width:100%}

.row-cols-lg-1>*{flex:0 0 100%;max-width:100%}

.row-cols-lg-2>*{flex:0 0 50%;max-width:50%}

.row-cols-lg-3>*{flex:0 0 33.3333333333%;max-width:33.3333333333%}

.row-cols-lg-4>*{flex:0 0 25%;max-width:25%}

.row-cols-lg-5>*{flex:0 0 20%;max-width:20%}

.row-cols-lg-6>*{flex:0 0 16.6666666667%;max-width:16.6666666667%}

.col-lg-auto{flex:0 0 auto;width:auto;max-width:100%}

.col-lg-1{flex:0 0 8.3333333333%;max-width:8.3333333333%}

.col-lg-2{flex:0 0 16.6666666667%;max-width:16.6666666667%}

.col-lg-3{flex:0 0 25%;max-width:25%}

.col-lg-4{flex:0 0 33.3333333333%;max-width:33.3333333333%}

.col-lg-5{flex:0 0 41.6666666667%;max-width:41.6666666667%}

.col-lg-6{flex:0 0 50%;max-width:50%}

.col-lg-7{flex:0 0 58.3333333333%;max-width:58.3333333333%}

.col-lg-8{flex:0 0 66.6666666667%;max-width:66.6666666667%}

.col-lg-9{flex:0 0 75%;max-width:75%}

.col-lg-10{flex:0 0 83.3333333333%;max-width:83.3333333333%}

.col-lg-11{flex:0 0 91.6666666667%;max-width:91.6666666667%}

.col-lg-12{flex:0 0 100%;max-width:100%}

.order-lg-first{order:-1}

.order-lg-last{order:13}

.order-lg-0{order:0}

.order-lg-1{order:1}

.order-lg-2{order:2}

.order-lg-3{order:3}

.order-lg-4{order:4}

.order-lg-5{order:5}

.order-lg-6{order:6}

.order-lg-7{order:7}

.order-lg-8{order:8}

.order-lg-9{order:9}

.order-lg-10{order:10}

.order-lg-11{order:11}

.order-lg-12{order:12}

[dir="ltr"] .offset-lg-0{margin-left:0}

[dir="rtl"] .offset-lg-0{margin-right:0}

[dir="ltr"] .offset-lg-1{margin-left:8.3333333333%}

[dir="rtl"] .offset-lg-1{margin-right:8.3333333333%}

[dir="ltr"] .offset-lg-2{margin-left:16.6666666667%}

[dir="rtl"] .offset-lg-2{margin-right:16.6666666667%}

[dir="ltr"] .offset-lg-3{margin-left:25%}

[dir="rtl"] .offset-lg-3{margin-right:25%}

[dir="ltr"] .offset-lg-4{margin-left:33.3333333333%}

[dir="rtl"] .offset-lg-4{margin-right:33.3333333333%}

[dir="ltr"] .offset-lg-5{margin-left:41.6666666667%}

[dir="rtl"] .offset-lg-5{margin-right:41.6666666667%}

[dir="ltr"] .offset-lg-6{margin-left:50%}

[dir="rtl"] .offset-lg-6{margin-right:50%}

[dir="ltr"] .offset-lg-7{margin-left:58.3333333333%}

[dir="rtl"] .offset-lg-7{margin-right:58.3333333333%}

[dir="ltr"] .offset-lg-8{margin-left:66.6666666667%}

[dir="rtl"] .offset-lg-8{margin-right:66.6666666667%}

[dir="ltr"] .offset-lg-9{margin-left:75%}

[dir="rtl"] .offset-lg-9{margin-right:75%}

[dir="ltr"] .offset-lg-10{margin-left:83.3333333333%}

[dir="rtl"] .offset-lg-10{margin-right:83.3333333333%}

[dir="ltr"] .offset-lg-11{margin-left:91.6666666667%}

[dir="rtl"] .offset-lg-11{margin-right:91.6666666667%}}

@media(min-width: 1200px){
.col-xl{flex-basis:0;flex-grow:1;max-width:100%}

.row-cols-xl-1>*{flex:0 0 100%;max-width:100%}

.row-cols-xl-2>*{flex:0 0 50%;max-width:50%}

.row-cols-xl-3>*{flex:0 0 33.3333333333%;max-width:33.3333333333%}

.row-cols-xl-4>*{flex:0 0 25%;max-width:25%}

.row-cols-xl-5>*{flex:0 0 20%;max-width:20%}

.row-cols-xl-6>*{flex:0 0 16.6666666667%;max-width:16.6666666667%}

.col-xl-auto{flex:0 0 auto;width:auto;max-width:100%}

.col-xl-1{flex:0 0 8.3333333333%;max-width:8.3333333333%}

.col-xl-2{flex:0 0 16.6666666667%;max-width:16.6666666667%}

.col-xl-3{flex:0 0 25%;max-width:25%}

.col-xl-4{flex:0 0 33.3333333333%;max-width:33.3333333333%}

.col-xl-5{flex:0 0 41.6666666667%;max-width:41.6666666667%}

.col-xl-6{flex:0 0 50%;max-width:50%}

.col-xl-7{flex:0 0 58.3333333333%;max-width:58.3333333333%}

.col-xl-8{flex:0 0 66.6666666667%;max-width:66.6666666667%}

.col-xl-9{flex:0 0 75%;max-width:75%}

.col-xl-10{flex:0 0 83.3333333333%;max-width:83.3333333333%}

.col-xl-11{flex:0 0 91.6666666667%;max-width:91.6666666667%}

.col-xl-12{flex:0 0 100%;max-width:100%}

.order-xl-first{order:-1}

.order-xl-last{order:13}

.order-xl-0{order:0}

.order-xl-1{order:1}

.order-xl-2{order:2}

.order-xl-3{order:3}

.order-xl-4{order:4}

.order-xl-5{order:5}

.order-xl-6{order:6}

.order-xl-7{order:7}

.order-xl-8{order:8}

.order-xl-9{order:9}

.order-xl-10{order:10}

.order-xl-11{order:11}

.order-xl-12{order:12}

[dir="ltr"] .offset-xl-0{margin-left:0}

[dir="rtl"] .offset-xl-0{margin-right:0}

[dir="ltr"] .offset-xl-1{margin-left:8.3333333333%}

[dir="rtl"] .offset-xl-1{margin-right:8.3333333333%}

[dir="ltr"] .offset-xl-2{margin-left:16.6666666667%}

[dir="rtl"] .offset-xl-2{margin-right:16.6666666667%}

[dir="ltr"] .offset-xl-3{margin-left:25%}

[dir="rtl"] .offset-xl-3{margin-right:25%}

[dir="ltr"] .offset-xl-4{margin-left:33.3333333333%}

[dir="rtl"] .offset-xl-4{margin-right:33.3333333333%}

[dir="ltr"] .offset-xl-5{margin-left:41.6666666667%}

[dir="rtl"] .offset-xl-5{margin-right:41.6666666667%}

[dir="ltr"] .offset-xl-6{margin-left:50%}

[dir="rtl"] .offset-xl-6{margin-right:50%}

[dir="ltr"] .offset-xl-7{margin-left:58.3333333333%}

[dir="rtl"] .offset-xl-7{margin-right:58.3333333333%}

[dir="ltr"] .offset-xl-8{margin-left:66.6666666667%}

[dir="rtl"] .offset-xl-8{margin-right:66.6666666667%}

[dir="ltr"] .offset-xl-9{margin-left:75%}

[dir="rtl"] .offset-xl-9{margin-right:75%}

[dir="ltr"] .offset-xl-10{margin-left:83.3333333333%}

[dir="rtl"] .offset-xl-10{margin-right:83.3333333333%}

[dir="ltr"] .offset-xl-11{margin-left:91.6666666667%}

[dir="rtl"] .offset-xl-11{margin-right:91.6666666667%}}

.table{width:100%;margin-bottom:1rem;color:#6e6b7b}

.table th,.table td{padding:.72rem;vertical-align:top;border-top:1px solid #ebe9f1}

.table thead th{vertical-align:bottom;border-bottom:2px solid #ebe9f1}

.table tbody+tbody{border-top:2px solid #ebe9f1}

.table-sm th,.table-sm td{padding:.3rem}

.table-bordered{border:1px solid #ebe9f1}

.table-bordered th,.table-bordered td{border:1px solid #ebe9f1}

.table-bordered thead th,.table-bordered thead td{border-bottom-width:2px}

.table-borderless th,.table-borderless td,.table-borderless thead th,.table-borderless tbody+tbody{border:0}

.table-striped tbody tr:nth-of-type(odd){background-color:#fafafc}

.table-hover tbody tr:hover{color:#6e6b7b;background-color:#f6f6f9}

.table-primary,.table-primary>th,.table-primary>td{background-color:#e7cdf4}

.table-primary th,.table-primary td,.table-primary thead th,.table-primary tbody+tbody{border-color:#d2a2eb}

.table-hover .table-primary:hover{background-color:#ddb8ef}

.table-hover .table-primary:hover>td,.table-hover .table-primary:hover>th{background-color:#ddb8ef}

.table-secondary,.table-secondary>th,.table-secondary>td{background-color:#dcdddf}

.table-secondary th,.table-secondary td,.table-secondary thead th,.table-secondary tbody+tbody{border-color:#bec0c3}

.table-hover .table-secondary:hover{background-color:#cfd0d3}

.table-hover .table-secondary:hover>td,.table-hover .table-secondary:hover>th{background-color:#cfd0d3}

.table-success,.table-success>th,.table-success>td{background-color:#c3efd7}

.table-success th,.table-success td,.table-success thead th,.table-success tbody+tbody{border-color:#8fe2b4}

.table-hover .table-success:hover{background-color:#afeaca}

.table-hover .table-success:hover>td,.table-hover .table-success:hover>th{background-color:#afeaca}

.table-info,.table-info>th,.table-info>td{background-color:#b8f2f9}

.table-info th,.table-info td,.table-info thead th,.table-info tbody+tbody{border-color:#7ae6f3}

.table-hover .table-info:hover{background-color:#a0eef7}

.table-hover .table-info:hover>td,.table-hover .table-info:hover>th{background-color:#a0eef7}

.table-warning,.table-warning>th,.table-warning>td{background-color:#ffe4ca}

.table-warning th,.table-warning td,.table-warning thead th,.table-warning tbody+tbody{border-color:#ffcd9d}

.table-hover .table-warning:hover{background-color:#ffd7b1}

.table-hover .table-warning:hover>td,.table-hover .table-warning:hover>th{background-color:#ffd7b1}

.table-danger,.table-danger>th,.table-danger>td{background-color:#f9cfcf}

.table-danger th,.table-danger td,.table-danger thead th,.table-danger tbody+tbody{border-color:#f4a6a7}

.table-hover .table-danger:hover{background-color:#f6b8b8}

.table-hover .table-danger:hover>td,.table-hover .table-danger:hover>th{background-color:#f6b8b8}

.table-light,.table-light>th,.table-light>td{background-color:#fcfcfc}

.table-light th,.table-light td,.table-light thead th,.table-light tbody+tbody{border-color:#fafafa}

.table-hover .table-light:hover{background-color:#efefef}

.table-hover .table-light:hover>td,.table-hover .table-light:hover>th{background-color:#efefef}

.table-dark,.table-dark>th,.table-dark>td{background-color:#bec0c5}

.table-dark th,.table-dark td,.table-dark thead th,.table-dark tbody+tbody{border-color:#868994}

.table-hover .table-dark:hover{background-color:#b1b3b9}

.table-hover .table-dark:hover>td,.table-hover .table-dark:hover>th{background-color:#b1b3b9}

.table-active,.table-active>th,.table-active>td{background-color:#f6f6f9}

.table-hover .table-active:hover{background-color:#e7e7ef}

.table-hover .table-active:hover>td,.table-hover .table-active:hover>th{background-color:#e7e7ef}

.table .thead-dark th{color:#fff;background-color:#161d31;border-color:#404040}

.table .thead-light th{color:#5e5873;background-color:#f3f2f7;border-color:#ebe9f1}

.table-dark{color:#fff;background-color:#161d31}

.table-dark th,.table-dark td,.table-dark thead th{border-color:#404040}

.table-dark.table-bordered{border:0}

.table-dark.table-striped tbody tr:nth-of-type(odd){background-color:rgba(255,255,255,.05)}

.table-dark.table-hover tbody tr:hover{color:#fff;background-color:rgba(255,255,255,.075)}

@media(max-width: 575.98px){
.table-responsive-sm{display:block;width:100%;overflow-x:auto;-webkit-overflow-scrolling:touch}

.table-responsive-sm>.table-bordered{border:0}}

@media(max-width: 767.98px){
.table-responsive-md{display:block;width:100%;overflow-x:auto;-webkit-overflow-scrolling:touch}

.table-responsive-md>.table-bordered{border:0}}

@media(max-width: 991.98px){
.table-responsive-lg{display:block;width:100%;overflow-x:auto;-webkit-overflow-scrolling:touch}

.table-responsive-lg>.table-bordered{border:0}}

@media(max-width: 1199.98px){
.table-responsive-xl{display:block;width:100%;overflow-x:auto;-webkit-overflow-scrolling:touch}

.table-responsive-xl>.table-bordered{border:0}}

.table-responsive{display:block;width:100%;overflow-x:auto;-webkit-overflow-scrolling:touch}

.table-responsive>.table-bordered{border:0}

.form-control{display:block;width:100%;height:2.714rem;padding:.438rem 1rem;font-size:1rem;font-weight:400;line-height:1.45;color:#6e6b7b;background-color:#fff;background-clip:padding-box;border:1px solid #d8d6de;border-radius:.357rem;transition:border-color .15s ease-in-out,box-shadow .15s ease-in-out}

@media(prefers-reduced-motion: reduce){
.form-control{transition:none}}

.form-control::-ms-expand{background-color:transparent;border:0}

.form-control:-moz-focusring{color:transparent;text-shadow:0 0 0 #6e6b7b}

.form-control:focus{color:#6e6b7b;background-color:#fff;border-color:#a94cd8;outline:0;box-shadow:0 3px 10px 0 rgba(34,41,47,.1)}

.form-control::placeholder{color:#b9b9c3;opacity:1}

.form-control:disabled,.form-control[readonly]{background-color:#efefef;opacity:1}

input[type=date].form-control,input[type=time].form-control,input[type=datetime-local].form-control,input[type=month].form-control{appearance:none}

select.form-control:focus::-ms-value{color:#6e6b7b;background-color:#fff}

.form-control-file,.form-control-range{display:block;width:100%}

.col-form-label{padding-top:calc(0.438rem + 1px);padding-bottom:calc(0.438rem + 1px);margin-bottom:0;font-size:inherit;line-height:1.45}

.col-form-label-lg{padding-top:calc(0.75rem + 1px);padding-bottom:calc(0.75rem + 1px);font-size:1.143rem;line-height:1.25}

.col-form-label-sm{padding-top:calc(0.188rem + 1px);padding-bottom:calc(0.188rem + 1px);font-size:0.857rem;line-height:1}

.form-control-plaintext{display:block;width:100%;padding:.438rem 0;margin-bottom:0;font-size:1rem;line-height:1.45;color:#6e6b7b;background-color:transparent;border:solid transparent;border-width:1px 0}

.form-control-plaintext.form-control-sm,.form-control-plaintext.form-control-lg{padding-right:0;padding-left:0}

.form-control-sm{height:2.142rem;padding:.188rem .857rem;font-size:0.857rem;line-height:1;border-radius:.357rem}

.form-control-lg{height:3.2857rem;padding:.75rem 1.143rem;font-size:1.143rem;line-height:1.25;border-radius:.357rem}

select.form-control[size],select.form-control[multiple]{height:auto}

textarea.form-control{height:auto}

.form-group{margin-bottom:1rem}

.form-text{display:block;margin-top:.25rem}

.form-row{display:flex;flex-wrap:wrap;margin-right:-5px;margin-left:-5px}

.form-row>.col,.form-row>[class*=col-]{padding-right:5px;padding-left:5px}

.form-check{position:relative;display:block}

[dir="ltr"] .form-check{padding-left:1.25rem}

[dir="rtl"] .form-check{padding-right:1.25rem}

.form-check-input{position:absolute;margin-top:.3rem}

[dir="ltr"] .form-check-input{margin-left:-1.25rem}

[dir="rtl"] .form-check-input{margin-right:-1.25rem}

.form-check-input[disabled]~.form-check-label,.form-check-input:disabled~.form-check-label{color:#b9b9c3}

.form-check-label{margin-bottom:0}

.form-check-inline{display:inline-flex;align-items:center}

[dir="ltr"] .form-check-inline{padding-left:0;margin-right:.75rem}

[dir="rtl"] .form-check-inline{padding-right:0;margin-left:.75rem}

.form-check-inline .form-check-input{position:static;margin-top:0}

[dir="ltr"] .form-check-inline .form-check-input{margin-right:.3125rem;margin-left:0}

[dir="rtl"] .form-check-inline .form-check-input{margin-left:.3125rem;margin-right:0}

.valid-feedback{display:none;width:100%;margin-top:.25rem;font-size:0.857rem;color:#28c76f}

.valid-tooltip{position:absolute;top:100%;z-index:5;display:none;max-width:100%;padding:.4rem .775rem;margin-top:.1rem;font-size:0.857rem;line-height:1.45;color:#fff;background-color:#28c76f;border-radius:.358rem}

[dir="ltr"] .valid-tooltip{left:0}

[dir="rtl"] .valid-tooltip{right:0}

[dir="ltr"] .form-row>.col>.valid-tooltip,[dir="ltr"] .form-row>[class*=col-]>.valid-tooltip{left:5px}

[dir="rtl"] .form-row>.col>.valid-tooltip,[dir="rtl"] .form-row>[class*=col-]>.valid-tooltip{right:5px}

.was-validated :valid~.valid-feedback,.was-validated :valid~.valid-tooltip,.is-valid~.valid-feedback,.is-valid~.valid-tooltip{display:block}

.was-validated .form-control:valid,.form-control.is-valid{border-color:#28c76f;background-image:url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%278%27 height=%278%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%2328c76f%27 d=%27M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e");background-repeat:no-repeat;background-size:calc(0.725em + 0.438rem) calc(0.725em + 0.438rem)}

[dir="ltr"] .was-validated .form-control:valid,[dir="ltr"] .form-control.is-valid{padding-right:calc(1.45em + 0.876rem);background-position:right calc(0.3625em + 0.219rem) center}

[dir="rtl"] .was-validated .form-control:valid,[dir="rtl"] .form-control.is-valid{padding-left:calc(1.45em + 0.876rem);background-position:left calc(0.3625em + 0.219rem) center}

.was-validated .form-control:valid:focus,.form-control.is-valid:focus{border-color:#28c76f;box-shadow:0 0 0 .2rem rgba(40,199,111,.25)}

[dir="ltr"] .was-validated textarea.form-control:valid,[dir="ltr"] textarea.form-control.is-valid{padding-right:calc(1.45em + 0.876rem);background-position:top calc(0.3625em + 0.219rem) right calc(0.3625em + 0.219rem)}

[dir="rtl"] .was-validated textarea.form-control:valid,[dir="rtl"] textarea.form-control.is-valid{padding-left:calc(1.45em + 0.876rem);background-position:top calc(0.3625em + 0.219rem) left calc(0.3625em + 0.219rem)}

.was-validated .custom-select:valid,.custom-select.is-valid{border-color:#28c76f}

[dir="ltr"] .was-validated .custom-select:valid,[dir="ltr"] .custom-select.is-valid{padding-right:calc(0.75em + 2.657rem);background:url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%274%27 height=%275%27 viewBox=%270 0 4 5%27%3e%3cpath fill=%27%23d8d6de%27 d=%27M2 0L0 2h4zm0 5L0 3h4z%27/%3e%3c/svg%3e") right 1rem center/10px 10px no-repeat,#fff url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%278%27 height=%278%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%2328c76f%27 d=%27M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e") center right 2rem/calc(0.725em + 0.438rem) calc(0.725em + 0.438rem) no-repeat}

[dir="rtl"] .was-validated .custom-select:valid,[dir="rtl"] .custom-select.is-valid{padding-left:calc(0.75em + 2.657rem);background:url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%274%27 height=%275%27 viewBox=%270 0 4 5%27%3e%3cpath fill=%27%23d8d6de%27 d=%27M2 0L0 2h4zm0 5L0 3h4z%27/%3e%3c/svg%3e") left 1rem center/10px 10px no-repeat,#fff url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%278%27 height=%278%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%2328c76f%27 d=%27M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e") center left 2rem/calc(0.725em + 0.438rem) calc(0.725em + 0.438rem) no-repeat}

.was-validated .custom-select:valid:focus,.custom-select.is-valid:focus{border-color:#28c76f;box-shadow:0 0 0 .2rem rgba(40,199,111,.25)}

.was-validated .form-check-input:valid~.form-check-label,.form-check-input.is-valid~.form-check-label{color:#28c76f}

.was-validated .form-check-input:valid~.valid-feedback,.was-validated .form-check-input:valid~.valid-tooltip,.form-check-input.is-valid~.valid-feedback,.form-check-input.is-valid~.valid-tooltip{display:block}

.was-validated .custom-control-input:valid~.custom-control-label,.custom-control-input.is-valid~.custom-control-label{color:#28c76f}

.was-validated .custom-control-input:valid~.custom-control-label::before,.custom-control-input.is-valid~.custom-control-label::before{border-color:#28c76f}

.was-validated .custom-control-input:valid:checked~.custom-control-label::before,.custom-control-input.is-valid:checked~.custom-control-label::before{border-color:#48da89;background-color:#48da89}

.was-validated .custom-control-input:valid:focus~.custom-control-label::before,.custom-control-input.is-valid:focus~.custom-control-label::before{box-shadow:0 0 0 .2rem rgba(40,199,111,.25)}

.was-validated .custom-control-input:valid:focus:not(:checked)~.custom-control-label::before,.custom-control-input.is-valid:focus:not(:checked)~.custom-control-label::before{border-color:#28c76f}

.was-validated .custom-file-input:valid~.custom-file-label,.custom-file-input.is-valid~.custom-file-label{border-color:#28c76f}

.was-validated .custom-file-input:valid:focus~.custom-file-label,.custom-file-input.is-valid:focus~.custom-file-label{border-color:#28c76f;box-shadow:0 0 0 .2rem rgba(40,199,111,.25)}

.invalid-feedback{display:none;width:100%;margin-top:.25rem;font-size:0.857rem;color:#ea5455}

.invalid-tooltip{position:absolute;top:100%;z-index:5;display:none;max-width:100%;padding:.4rem .775rem;margin-top:.1rem;font-size:0.857rem;line-height:1.45;color:#fff;background-color:#ea5455;border-radius:.358rem}

[dir="ltr"] .invalid-tooltip{left:0}

[dir="rtl"] .invalid-tooltip{right:0}

[dir="ltr"] .form-row>.col>.invalid-tooltip,[dir="ltr"] .form-row>[class*=col-]>.invalid-tooltip{left:5px}

[dir="rtl"] .form-row>.col>.invalid-tooltip,[dir="rtl"] .form-row>[class*=col-]>.invalid-tooltip{right:5px}

.was-validated :invalid~.invalid-feedback,.was-validated :invalid~.invalid-tooltip,.is-invalid~.invalid-feedback,.is-invalid~.invalid-tooltip{display:block}

.was-validated .form-control:invalid,.form-control.is-invalid{border-color:#ea5455;background-image:url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23ea5455%27 viewBox=%270 0 12 12%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23ea5455%27 stroke=%27none%27/%3e%3c/svg%3e");background-repeat:no-repeat;background-size:calc(0.725em + 0.438rem) calc(0.725em + 0.438rem)}

[dir="ltr"] .was-validated .form-control:invalid,[dir="ltr"] .form-control.is-invalid{padding-right:calc(1.45em + 0.876rem);background-position:right calc(0.3625em + 0.219rem) center}

[dir="rtl"] .was-validated .form-control:invalid,[dir="rtl"] .form-control.is-invalid{padding-left:calc(1.45em + 0.876rem);background-position:left calc(0.3625em + 0.219rem) center}

.was-validated .form-control:invalid:focus,.form-control.is-invalid:focus{border-color:#ea5455;box-shadow:0 0 0 .2rem rgba(234,84,85,.25)}

[dir="ltr"] .was-validated textarea.form-control:invalid,[dir="ltr"] textarea.form-control.is-invalid{padding-right:calc(1.45em + 0.876rem);background-position:top calc(0.3625em + 0.219rem) right calc(0.3625em + 0.219rem)}

[dir="rtl"] .was-validated textarea.form-control:invalid,[dir="rtl"] textarea.form-control.is-invalid{padding-left:calc(1.45em + 0.876rem);background-position:top calc(0.3625em + 0.219rem) left calc(0.3625em + 0.219rem)}

.was-validated .custom-select:invalid,.custom-select.is-invalid{border-color:#ea5455}

[dir="ltr"] .was-validated .custom-select:invalid,[dir="ltr"] .custom-select.is-invalid{padding-right:calc(0.75em + 2.657rem);background:url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%274%27 height=%275%27 viewBox=%270 0 4 5%27%3e%3cpath fill=%27%23d8d6de%27 d=%27M2 0L0 2h4zm0 5L0 3h4z%27/%3e%3c/svg%3e") right 1rem center/10px 10px no-repeat,#fff url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23ea5455%27 viewBox=%270 0 12 12%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23ea5455%27 stroke=%27none%27/%3e%3c/svg%3e") center right 2rem/calc(0.725em + 0.438rem) calc(0.725em + 0.438rem) no-repeat}

[dir="rtl"] .was-validated .custom-select:invalid,[dir="rtl"] .custom-select.is-invalid{padding-left:calc(0.75em + 2.657rem);background:url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%274%27 height=%275%27 viewBox=%270 0 4 5%27%3e%3cpath fill=%27%23d8d6de%27 d=%27M2 0L0 2h4zm0 5L0 3h4z%27/%3e%3c/svg%3e") left 1rem center/10px 10px no-repeat,#fff url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23ea5455%27 viewBox=%270 0 12 12%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23ea5455%27 stroke=%27none%27/%3e%3c/svg%3e") center left 2rem/calc(0.725em + 0.438rem) calc(0.725em + 0.438rem) no-repeat}

.was-validated .custom-select:invalid:focus,.custom-select.is-invalid:focus{border-color:#ea5455;box-shadow:0 0 0 .2rem rgba(234,84,85,.25)}

.was-validated .form-check-input:invalid~.form-check-label,.form-check-input.is-invalid~.form-check-label{color:#ea5455}

.was-validated .form-check-input:invalid~.invalid-feedback,.was-validated .form-check-input:invalid~.invalid-tooltip,.form-check-input.is-invalid~.invalid-feedback,.form-check-input.is-invalid~.invalid-tooltip{display:block}

.was-validated .custom-control-input:invalid~.custom-control-label,.custom-control-input.is-invalid~.custom-control-label{color:#ea5455}

.was-validated .custom-control-input:invalid~.custom-control-label::before,.custom-control-input.is-invalid~.custom-control-label::before{border-color:#ea5455}

.was-validated .custom-control-input:invalid:checked~.custom-control-label::before,.custom-control-input.is-invalid:checked~.custom-control-label::before{border-color:#f08182;background-color:#f08182}

.was-validated .custom-control-input:invalid:focus~.custom-control-label::before,.custom-control-input.is-invalid:focus~.custom-control-label::before{box-shadow:0 0 0 .2rem rgba(234,84,85,.25)}

.was-validated .custom-control-input:invalid:focus:not(:checked)~.custom-control-label::before,.custom-control-input.is-invalid:focus:not(:checked)~.custom-control-label::before{border-color:#ea5455}

.was-validated .custom-file-input:invalid~.custom-file-label,.custom-file-input.is-invalid~.custom-file-label{border-color:#ea5455}

.was-validated .custom-file-input:invalid:focus~.custom-file-label,.custom-file-input.is-invalid:focus~.custom-file-label{border-color:#ea5455;box-shadow:0 0 0 .2rem rgba(234,84,85,.25)}

.form-inline{display:flex;flex-flow:row wrap;align-items:center}

.form-inline .form-check{width:100%}

@media(min-width: 576px){
.form-inline label{display:flex;align-items:center;justify-content:center;margin-bottom:0}

.form-inline .form-group{display:flex;flex:0 0 auto;flex-flow:row wrap;align-items:center;margin-bottom:0}

.form-inline .form-control{display:inline-block;width:auto;vertical-align:middle}

.form-inline .form-control-plaintext{display:inline-block}

.form-inline .input-group,.form-inline .custom-select{width:auto}

.form-inline .form-check{display:flex;align-items:center;justify-content:center;width:auto}

[dir="ltr"] .form-inline .form-check{padding-left:0}

[dir="rtl"] .form-inline .form-check{padding-right:0}

.form-inline .form-check-input{position:relative;flex-shrink:0;margin-top:0}

[dir="ltr"] .form-inline .form-check-input{margin-right:.25rem;margin-left:0}

[dir="rtl"] .form-inline .form-check-input{margin-left:.25rem;margin-right:0}

.form-inline .custom-control{align-items:center;justify-content:center}

.form-inline .custom-control-label{margin-bottom:0}}

.btn{display:inline-block;font-weight:400;color:#6e6b7b;text-align:center;vertical-align:middle;user-select:none;background-color:transparent;border:1px solid transparent;padding:.786rem 1.5rem;font-size:1rem;line-height:1;border-radius:.358rem;transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,background 0s,border 0s}

@media(prefers-reduced-motion: reduce){
.btn{transition:none}}

.btn:hover{color:#6e6b7b;text-decoration:none}

.btn:focus,.btn.focus{outline:0;box-shadow:0 0 0 .2rem rgba(169,76,216,.25)}

.btn.disabled,.btn:disabled{opacity:.65}

.btn:not(:disabled):not(.disabled){cursor:pointer}

a.btn.disabled,fieldset:disabled a.btn{pointer-events:none}

.btn-primary{color:#fff;background-color:#a94cd8;border-color:#a94cd8}

.btn-primary:hover{color:#fff;background-color:#9a2dd0;border-color:#922bc6}

.btn-primary:focus,.btn-primary.focus{color:#fff;background-color:#9a2dd0;border-color:#922bc6;box-shadow:0 0 0 .2rem rgba(182,103,222,.5)}

.btn-primary.disabled,.btn-primary:disabled{color:#fff;background-color:#a94cd8;border-color:#a94cd8}

.btn-primary:not(:disabled):not(.disabled):active,.btn-primary:not(:disabled):not(.disabled).active,.show>.btn-primary.dropdown-toggle{color:#fff;background-color:#922bc6;border-color:#8a29bb}

.btn-primary:not(:disabled):not(.disabled):active:focus,.btn-primary:not(:disabled):not(.disabled).active:focus,.show>.btn-primary.dropdown-toggle:focus{box-shadow:0 0 0 .2rem rgba(182,103,222,.5)}

.btn-secondary{color:#fff;background-color:#82868b;border-color:#82868b}

.btn-secondary:hover{color:#fff;background-color:#6f7378;border-color:#696d71}

.btn-secondary:focus,.btn-secondary.focus{color:#fff;background-color:#6f7378;border-color:#696d71;box-shadow:0 0 0 .2rem rgba(149,152,156,.5)}

.btn-secondary.disabled,.btn-secondary:disabled{color:#fff;background-color:#82868b;border-color:#82868b}

.btn-secondary:not(:disabled):not(.disabled):active,.btn-secondary:not(:disabled):not(.disabled).active,.show>.btn-secondary.dropdown-toggle{color:#fff;background-color:#696d71;border-color:#63666a}

.btn-secondary:not(:disabled):not(.disabled):active:focus,.btn-secondary:not(:disabled):not(.disabled).active:focus,.show>.btn-secondary.dropdown-toggle:focus{box-shadow:0 0 0 .2rem rgba(149,152,156,.5)}

.btn-success{color:#fff;background-color:#28c76f;border-color:#28c76f}

.btn-success:hover{color:#fff;background-color:#22a75d;border-color:#1f9d57}

.btn-success:focus,.btn-success.focus{color:#fff;background-color:#22a75d;border-color:#1f9d57;box-shadow:0 0 0 .2rem rgba(72,207,133,.5)}

.btn-success.disabled,.btn-success:disabled{color:#fff;background-color:#28c76f;border-color:#28c76f}

.btn-success:not(:disabled):not(.disabled):active,.btn-success:not(:disabled):not(.disabled).active,.show>.btn-success.dropdown-toggle{color:#fff;background-color:#1f9d57;border-color:#1d9251}

.btn-success:not(:disabled):not(.disabled):active:focus,.btn-success:not(:disabled):not(.disabled).active:focus,.show>.btn-success.dropdown-toggle:focus{box-shadow:0 0 0 .2rem rgba(72,207,133,.5)}

.btn-info{color:#fff;background-color:#00cfe8;border-color:#00cfe8}

.btn-info:hover{color:#fff;background-color:#00adc2;border-color:#00a1b5}

.btn-info:focus,.btn-info.focus{color:#fff;background-color:#00adc2;border-color:#00a1b5;box-shadow:0 0 0 .2rem rgba(38,214,235,.5)}

.btn-info.disabled,.btn-info:disabled{color:#fff;background-color:#00cfe8;border-color:#00cfe8}

.btn-info:not(:disabled):not(.disabled):active,.btn-info:not(:disabled):not(.disabled).active,.show>.btn-info.dropdown-toggle{color:#fff;background-color:#00a1b5;border-color:#0096a8}

.btn-info:not(:disabled):not(.disabled):active:focus,.btn-info:not(:disabled):not(.disabled).active:focus,.show>.btn-info.dropdown-toggle:focus{box-shadow:0 0 0 .2rem rgba(38,214,235,.5)}

.btn-warning{color:#2a2e30;background-color:#ff9f43;border-color:#ff9f43}

.btn-warning:hover{color:#2a2e30;background-color:#ff8b1d;border-color:#ff8510}

.btn-warning:focus,.btn-warning.focus{color:#2a2e30;background-color:#ff8b1d;border-color:#ff8510;box-shadow:0 0 0 .2rem rgba(223,142,64,.5)}

.btn-warning.disabled,.btn-warning:disabled{color:#2a2e30;background-color:#ff9f43;border-color:#ff9f43}

.btn-warning:not(:disabled):not(.disabled):active,.btn-warning:not(:disabled):not(.disabled).active,.show>.btn-warning.dropdown-toggle{color:#2a2e30;background-color:#ff8510;border-color:#ff7e03}

.btn-warning:not(:disabled):not(.disabled):active:focus,.btn-warning:not(:disabled):not(.disabled).active:focus,.show>.btn-warning.dropdown-toggle:focus{box-shadow:0 0 0 .2rem rgba(223,142,64,.5)}

.btn-danger{color:#fff;background-color:#ea5455;border-color:#ea5455}

.btn-danger:hover{color:#fff;background-color:#e63233;border-color:#e42728}

.btn-danger:focus,.btn-danger.focus{color:#fff;background-color:#e63233;border-color:#e42728;box-shadow:0 0 0 .2rem rgba(237,110,111,.5)}

.btn-danger.disabled,.btn-danger:disabled{color:#fff;background-color:#ea5455;border-color:#ea5455}

.btn-danger:not(:disabled):not(.disabled):active,.btn-danger:not(:disabled):not(.disabled).active,.show>.btn-danger.dropdown-toggle{color:#fff;background-color:#e42728;border-color:#e21c1d}

.btn-danger:not(:disabled):not(.disabled):active:focus,.btn-danger:not(:disabled):not(.disabled).active:focus,.show>.btn-danger.dropdown-toggle:focus{box-shadow:0 0 0 .2rem rgba(237,110,111,.5)}

.btn-light{color:#2a2e30;background-color:#f6f6f6;border-color:#f6f6f6}

.btn-light:hover{color:#2a2e30;background-color:#e3e3e3;border-color:#ddd}

.btn-light:focus,.btn-light.focus{color:#2a2e30;background-color:#e3e3e3;border-color:#ddd;box-shadow:0 0 0 .2rem rgba(215,216,216,.5)}

.btn-light.disabled,.btn-light:disabled{color:#2a2e30;background-color:#f6f6f6;border-color:#f6f6f6}

.btn-light:not(:disabled):not(.disabled):active,.btn-light:not(:disabled):not(.disabled).active,.show>.btn-light.dropdown-toggle{color:#2a2e30;background-color:#ddd;border-color:#d6d6d6}

.btn-light:not(:disabled):not(.disabled):active:focus,.btn-light:not(:disabled):not(.disabled).active:focus,.show>.btn-light.dropdown-toggle:focus{box-shadow:0 0 0 .2rem rgba(215,216,216,.5)}

.btn-dark{color:#fff;background-color:#161d31;border-color:#161d31}

.btn-dark:hover{color:#fff;background-color:#0a0d17;border-color:#06080e}

.btn-dark:focus,.btn-dark.focus{color:#fff;background-color:#0a0d17;border-color:#06080e;box-shadow:0 0 0 .2rem rgba(57,63,80,.5)}

.btn-dark.disabled,.btn-dark:disabled{color:#fff;background-color:#161d31;border-color:#161d31}

.btn-dark:not(:disabled):not(.disabled):active,.btn-dark:not(:disabled):not(.disabled).active,.show>.btn-dark.dropdown-toggle{color:#fff;background-color:#06080e;border-color:#020305}

.btn-dark:not(:disabled):not(.disabled):active:focus,.btn-dark:not(:disabled):not(.disabled).active:focus,.show>.btn-dark.dropdown-toggle:focus{box-shadow:0 0 0 .2rem rgba(57,63,80,.5)}

.btn-outline-primary{color:#a94cd8;border-color:#a94cd8}

.btn-outline-primary:hover{color:#fff;background-color:#a94cd8;border-color:#a94cd8}

.btn-outline-primary:focus,.btn-outline-primary.focus{box-shadow:0 0 0 .2rem rgba(169,76,216,.5)}

.btn-outline-primary.disabled,.btn-outline-primary:disabled{color:#a94cd8;background-color:transparent}

.btn-outline-primary:not(:disabled):not(.disabled):active,.btn-outline-primary:not(:disabled):not(.disabled).active,.show>.btn-outline-primary.dropdown-toggle{color:#fff;background-color:#a94cd8;border-color:#a94cd8}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,.btn-outline-primary:not(:disabled):not(.disabled).active:focus,.show>.btn-outline-primary.dropdown-toggle:focus{box-shadow:0 0 0 .2rem rgba(169,76,216,.5)}

.btn-outline-secondary{color:#82868b;border-color:#82868b}

.btn-outline-secondary:hover{color:#fff;background-color:#82868b;border-color:#82868b}

.btn-outline-secondary:focus,.btn-outline-secondary.focus{box-shadow:0 0 0 .2rem rgba(130,134,139,.5)}

.btn-outline-secondary.disabled,.btn-outline-secondary:disabled{color:#82868b;background-color:transparent}

.btn-outline-secondary:not(:disabled):not(.disabled):active,.btn-outline-secondary:not(:disabled):not(.disabled).active,.show>.btn-outline-secondary.dropdown-toggle{color:#fff;background-color:#82868b;border-color:#82868b}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,.show>.btn-outline-secondary.dropdown-toggle:focus{box-shadow:0 0 0 .2rem rgba(130,134,139,.5)}

.btn-outline-success{color:#28c76f;border-color:#28c76f}

.btn-outline-success:hover{color:#fff;background-color:#28c76f;border-color:#28c76f}

.btn-outline-success:focus,.btn-outline-success.focus{box-shadow:0 0 0 .2rem rgba(40,199,111,.5)}

.btn-outline-success.disabled,.btn-outline-success:disabled{color:#28c76f;background-color:transparent}

.btn-outline-success:not(:disabled):not(.disabled):active,.btn-outline-success:not(:disabled):not(.disabled).active,.show>.btn-outline-success.dropdown-toggle{color:#fff;background-color:#28c76f;border-color:#28c76f}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,.btn-outline-success:not(:disabled):not(.disabled).active:focus,.show>.btn-outline-success.dropdown-toggle:focus{box-shadow:0 0 0 .2rem rgba(40,199,111,.5)}

.btn-outline-info{color:#00cfe8;border-color:#00cfe8}

.btn-outline-info:hover{color:#fff;background-color:#00cfe8;border-color:#00cfe8}

.btn-outline-info:focus,.btn-outline-info.focus{box-shadow:0 0 0 .2rem rgba(0,207,232,.5)}

.btn-outline-info.disabled,.btn-outline-info:disabled{color:#00cfe8;background-color:transparent}

.btn-outline-info:not(:disabled):not(.disabled):active,.btn-outline-info:not(:disabled):not(.disabled).active,.show>.btn-outline-info.dropdown-toggle{color:#fff;background-color:#00cfe8;border-color:#00cfe8}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,.btn-outline-info:not(:disabled):not(.disabled).active:focus,.show>.btn-outline-info.dropdown-toggle:focus{box-shadow:0 0 0 .2rem rgba(0,207,232,.5)}

.btn-outline-warning{color:#ff9f43;border-color:#ff9f43}

.btn-outline-warning:hover{color:#2a2e30;background-color:#ff9f43;border-color:#ff9f43}

.btn-outline-warning:focus,.btn-outline-warning.focus{box-shadow:0 0 0 .2rem rgba(255,159,67,.5)}

.btn-outline-warning.disabled,.btn-outline-warning:disabled{color:#ff9f43;background-color:transparent}

.btn-outline-warning:not(:disabled):not(.disabled):active,.btn-outline-warning:not(:disabled):not(.disabled).active,.show>.btn-outline-warning.dropdown-toggle{color:#2a2e30;background-color:#ff9f43;border-color:#ff9f43}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,.btn-outline-warning:not(:disabled):not(.disabled).active:focus,.show>.btn-outline-warning.dropdown-toggle:focus{box-shadow:0 0 0 .2rem rgba(255,159,67,.5)}

.btn-outline-danger{color:#ea5455;border-color:#ea5455}

.btn-outline-danger:hover{color:#fff;background-color:#ea5455;border-color:#ea5455}

.btn-outline-danger:focus,.btn-outline-danger.focus{box-shadow:0 0 0 .2rem rgba(234,84,85,.5)}

.btn-outline-danger.disabled,.btn-outline-danger:disabled{color:#ea5455;background-color:transparent}

.btn-outline-danger:not(:disabled):not(.disabled):active,.btn-outline-danger:not(:disabled):not(.disabled).active,.show>.btn-outline-danger.dropdown-toggle{color:#fff;background-color:#ea5455;border-color:#ea5455}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,.btn-outline-danger:not(:disabled):not(.disabled).active:focus,.show>.btn-outline-danger.dropdown-toggle:focus{box-shadow:0 0 0 .2rem rgba(234,84,85,.5)}

.btn-outline-light{color:#f6f6f6;border-color:#f6f6f6}

.btn-outline-light:hover{color:#2a2e30;background-color:#f6f6f6;border-color:#f6f6f6}

.btn-outline-light:focus,.btn-outline-light.focus{box-shadow:0 0 0 .2rem rgba(246,246,246,.5)}

.btn-outline-light.disabled,.btn-outline-light:disabled{color:#f6f6f6;background-color:transparent}

.btn-outline-light:not(:disabled):not(.disabled):active,.btn-outline-light:not(:disabled):not(.disabled).active,.show>.btn-outline-light.dropdown-toggle{color:#2a2e30;background-color:#f6f6f6;border-color:#f6f6f6}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,.btn-outline-light:not(:disabled):not(.disabled).active:focus,.show>.btn-outline-light.dropdown-toggle:focus{box-shadow:0 0 0 .2rem rgba(246,246,246,.5)}

.btn-outline-dark{color:#161d31;border-color:#161d31}

.btn-outline-dark:hover{color:#fff;background-color:#161d31;border-color:#161d31}

.btn-outline-dark:focus,.btn-outline-dark.focus{box-shadow:0 0 0 .2rem rgba(22,29,49,.5)}

.btn-outline-dark.disabled,.btn-outline-dark:disabled{color:#161d31;background-color:transparent}

.btn-outline-dark:not(:disabled):not(.disabled):active,.btn-outline-dark:not(:disabled):not(.disabled).active,.show>.btn-outline-dark.dropdown-toggle{color:#fff;background-color:#161d31;border-color:#161d31}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,.btn-outline-dark:not(:disabled):not(.disabled).active:focus,.show>.btn-outline-dark.dropdown-toggle:focus{box-shadow:0 0 0 .2rem rgba(22,29,49,.5)}

.btn-link{font-weight:400;color:#a94cd8;text-decoration:none}

.btn-link:hover{color:#9f37d3;text-decoration:none}

.btn-link:focus,.btn-link.focus{text-decoration:none}

.btn-link:disabled,.btn-link.disabled{color:#b8c2cc;pointer-events:none}

.btn-lg,.btn-group-lg>.btn{padding:.8rem 2rem;font-size:1.25rem;line-height:1.25;border-radius:.358rem}

.btn-sm,.btn-group-sm>.btn{padding:.486rem 1rem;font-size:0.9rem;line-height:1;border-radius:.358rem}

.btn-block{display:block;width:100%}

.btn-block+.btn-block{margin-top:.5rem}

input[type=submit].btn-block,input[type=reset].btn-block,input[type=button].btn-block{width:100%}

.fade{transition:opacity .15s linear}

@media(prefers-reduced-motion: reduce){
.fade{transition:none}}

.fade:not(.show){opacity:0}

.collapse:not(.show){display:none}

.collapsing{position:relative;height:0;overflow:hidden;transition:height .35s ease}

@media(prefers-reduced-motion: reduce){
.collapsing{transition:none}}

.dropup,.dropright,.dropdown,.dropleft{position:relative}

.dropdown-toggle{white-space:nowrap}

.dropdown-toggle::after{display:inline-block;vertical-align:.255em;content:"";border-top:.3em solid;border-right:.3em solid transparent;border-bottom:0;border-left:.3em solid transparent}

[dir="ltr"] .dropdown-toggle::after{margin-left:.255em}

[dir="rtl"] .dropdown-toggle::after{margin-right:.255em}

[dir="ltr"] .dropdown-toggle:empty::after{margin-left:0}

[dir="rtl"] .dropdown-toggle:empty::after{margin-right:0}

.dropdown-menu{position:absolute;top:100%;z-index:10;display:none;min-width:10rem;padding:.5rem 0;margin:0 0 0;font-size:1rem;color:#6e6b7b;list-style:none;background-color:#fff;background-clip:padding-box;border:1px solid rgba(34,41,47,.05);border-radius:.358rem}

[dir="ltr"] .dropdown-menu{left:0;float:left;text-align:left}

[dir="rtl"] .dropdown-menu{right:0;float:right;text-align:right}

[dir="ltr"] .dropdown-menu-left{right:auto;left:0}

[dir="rtl"] .dropdown-menu-left{left:auto;right:0}

[dir="ltr"] .dropdown-menu-right{right:0;left:auto}

[dir="rtl"] .dropdown-menu-right{left:0;right:auto}

@media(min-width: 576px){
[dir="ltr"] .dropdown-menu-sm-left{right:auto;left:0}

[dir="rtl"] .dropdown-menu-sm-left{left:auto;right:0}

[dir="ltr"] .dropdown-menu-sm-right{right:0;left:auto}

[dir="rtl"] .dropdown-menu-sm-right{left:0;right:auto}}

@media(min-width: 768px){
[dir="ltr"] .dropdown-menu-md-left{right:auto;left:0}

[dir="rtl"] .dropdown-menu-md-left{left:auto;right:0}

[dir="ltr"] .dropdown-menu-md-right{right:0;left:auto}

[dir="rtl"] .dropdown-menu-md-right{left:0;right:auto}}

@media(min-width: 992px){
[dir="ltr"] .dropdown-menu-lg-left{right:auto;left:0}

[dir="rtl"] .dropdown-menu-lg-left{left:auto;right:0}

[dir="ltr"] .dropdown-menu-lg-right{right:0;left:auto}

[dir="rtl"] .dropdown-menu-lg-right{left:0;right:auto}}

@media(min-width: 1200px){
[dir="ltr"] .dropdown-menu-xl-left{right:auto;left:0}

[dir="rtl"] .dropdown-menu-xl-left{left:auto;right:0}

[dir="ltr"] .dropdown-menu-xl-right{right:0;left:auto}

[dir="rtl"] .dropdown-menu-xl-right{left:0;right:auto}}

.dropup .dropdown-menu{top:auto;bottom:100%;margin-top:0;margin-bottom:0}

.dropup .dropdown-toggle::after{display:inline-block;vertical-align:.255em;content:"";border-top:0;border-right:.3em solid transparent;border-bottom:.3em solid;border-left:.3em solid transparent}

[dir="ltr"] .dropup .dropdown-toggle::after{margin-left:.255em}

[dir="rtl"] .dropup .dropdown-toggle::after{margin-right:.255em}

[dir="ltr"] .dropup .dropdown-toggle:empty::after{margin-left:0}

[dir="rtl"] .dropup .dropdown-toggle:empty::after{margin-right:0}

.dropright .dropdown-menu{top:0;margin-top:0}

[dir="ltr"] .dropright .dropdown-menu{right:auto;left:100%;margin-left:0}

[dir="rtl"] .dropright .dropdown-menu{left:auto;right:100%;margin-right:0}

.dropright .dropdown-toggle::after{display:inline-block;vertical-align:.255em;content:"";border-top:.3em solid transparent;border-bottom:.3em solid transparent}

[dir="ltr"] .dropright .dropdown-toggle::after{margin-left:.255em;border-right:0;border-left:.3em solid}

[dir="rtl"] .dropright .dropdown-toggle::after{margin-right:.255em;border-left:0;border-right:.3em solid}

[dir="ltr"] .dropright .dropdown-toggle:empty::after{margin-left:0}

[dir="rtl"] .dropright .dropdown-toggle:empty::after{margin-right:0}

.dropright .dropdown-toggle::after{vertical-align:0}

.dropleft .dropdown-menu{top:0;margin-top:0}

[dir="ltr"] .dropleft .dropdown-menu{right:100%;left:auto;margin-right:0}

[dir="rtl"] .dropleft .dropdown-menu{left:100%;right:auto;margin-left:0}

.dropleft .dropdown-toggle::after{display:inline-block;vertical-align:.255em;content:""}

[dir="ltr"] .dropleft .dropdown-toggle::after{margin-left:.255em}

[dir="rtl"] .dropleft .dropdown-toggle::after{margin-right:.255em}

.dropleft .dropdown-toggle::after{display:none}

.dropleft .dropdown-toggle::before{display:inline-block;vertical-align:.255em;content:"";border-top:.3em solid transparent;border-bottom:.3em solid transparent}

[dir="ltr"] .dropleft .dropdown-toggle::before{margin-right:.255em;border-right:.3em solid}

[dir="rtl"] .dropleft .dropdown-toggle::before{margin-left:.255em;border-left:.3em solid}

[dir="ltr"] .dropleft .dropdown-toggle:empty::after{margin-left:0}

[dir="rtl"] .dropleft .dropdown-toggle:empty::after{margin-right:0}

.dropleft .dropdown-toggle::before{vertical-align:0}

.dropdown-menu[x-placement^=top],.dropdown-menu[x-placement^=right],.dropdown-menu[x-placement^=bottom],.dropdown-menu[x-placement^=left]{bottom:auto}

[dir="ltr"] .dropdown-menu[x-placement^=top],[dir="ltr"] .dropdown-menu[x-placement^=right],[dir="ltr"] .dropdown-menu[x-placement^=bottom],[dir="ltr"] .dropdown-menu[x-placement^=left]{right:auto}

[dir="rtl"] .dropdown-menu[x-placement^=top],[dir="rtl"] .dropdown-menu[x-placement^=right],[dir="rtl"] .dropdown-menu[x-placement^=bottom],[dir="rtl"] .dropdown-menu[x-placement^=left]{left:auto}

.dropdown-divider{height:0;margin:.5rem 0;overflow:hidden;border-top:1px solid rgba(34,41,47,.08)}

.dropdown-item{display:block;width:100%;padding:.65rem 1.28rem;clear:both;font-weight:400;color:#6e6b7b;text-align:inherit;white-space:nowrap;background-color:transparent;border:0}

.dropdown-item:hover,.dropdown-item:focus{color:#a94cd8;text-decoration:none;background-color:rgba(169,76,216,.12)}

.dropdown-item.active,.dropdown-item:active{color:#fff;text-decoration:none;background-color:#a94cd8}

.dropdown-item.disabled,.dropdown-item:disabled{color:#b9b9c3;pointer-events:none;background-color:transparent}

.dropdown-menu.show{display:block}

.dropdown-header{display:block;padding:.5rem 1.28rem;margin-bottom:0;font-size:1rem;color:#5e5873;white-space:nowrap}

.dropdown-item-text{display:block;padding:.65rem 1.28rem;color:#6e6b7b}

.btn-group,.btn-group-vertical{position:relative;display:inline-flex;vertical-align:middle}

.btn-group>.btn,.btn-group-vertical>.btn{position:relative;flex:1 1 auto}

.btn-group>.btn:hover,.btn-group-vertical>.btn:hover{z-index:1}

.btn-group>.btn:focus,.btn-group>.btn:active,.btn-group>.btn.active,.btn-group-vertical>.btn:focus,.btn-group-vertical>.btn:active,.btn-group-vertical>.btn.active{z-index:1}

.btn-toolbar{display:flex;flex-wrap:wrap;justify-content:flex-start}

.btn-toolbar .input-group{width:auto}

[dir="ltr"] .btn-group>.btn:not(:first-child),[dir="ltr"] .btn-group>.btn-group:not(:first-child){margin-left:-1px}

[dir="rtl"] .btn-group>.btn:not(:first-child),[dir="rtl"] .btn-group>.btn-group:not(:first-child){margin-right:-1px}

[dir="ltr"] .btn-group>.btn:not(:last-child):not(.dropdown-toggle),[dir="ltr"] .btn-group>.btn-group:not(:last-child)>.btn{border-top-right-radius:0;border-bottom-right-radius:0}

[dir="rtl"] .btn-group>.btn:not(:last-child):not(.dropdown-toggle),[dir="rtl"] .btn-group>.btn-group:not(:last-child)>.btn{border-top-left-radius:0;border-bottom-left-radius:0}

[dir="ltr"] .btn-group>.btn:not(:first-child),[dir="ltr"] .btn-group>.btn-group:not(:first-child)>.btn{border-top-left-radius:0;border-bottom-left-radius:0}

[dir="rtl"] .btn-group>.btn:not(:first-child),[dir="rtl"] .btn-group>.btn-group:not(:first-child)>.btn{border-top-right-radius:0;border-bottom-right-radius:0}

.dropdown-toggle-split{padding-right:1.125rem;padding-left:1.125rem}

[dir="ltr"] .dropdown-toggle-split::after,[dir="ltr"] .dropup .dropdown-toggle-split::after,[dir="ltr"] .dropright .dropdown-toggle-split::after{margin-left:0}

[dir="rtl"] .dropdown-toggle-split::after,[dir="rtl"] .dropup .dropdown-toggle-split::after,[dir="rtl"] .dropright .dropdown-toggle-split::after{margin-right:0}

[dir="ltr"] .dropleft .dropdown-toggle-split::before{margin-right:0}

[dir="rtl"] .dropleft .dropdown-toggle-split::before{margin-left:0}

.btn-sm+.dropdown-toggle-split,.btn-group-sm>.btn+.dropdown-toggle-split{padding-right:.75rem;padding-left:.75rem}

.btn-lg+.dropdown-toggle-split,.btn-group-lg>.btn+.dropdown-toggle-split{padding-right:1.5rem;padding-left:1.5rem}

.btn-group-vertical{flex-direction:column;align-items:flex-start;justify-content:center}

.btn-group-vertical>.btn,.btn-group-vertical>.btn-group{width:100%}

.btn-group-vertical>.btn:not(:first-child),.btn-group-vertical>.btn-group:not(:first-child){margin-top:-1px}

.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),.btn-group-vertical>.btn-group:not(:last-child)>.btn{border-bottom-right-radius:0;border-bottom-left-radius:0}

.btn-group-vertical>.btn:not(:first-child),.btn-group-vertical>.btn-group:not(:first-child)>.btn{border-top-left-radius:0;border-top-right-radius:0}

.btn-group-toggle>.btn,.btn-group-toggle>.btn-group>.btn{margin-bottom:0}

.btn-group-toggle>.btn input[type=radio],.btn-group-toggle>.btn input[type=checkbox],.btn-group-toggle>.btn-group>.btn input[type=radio],.btn-group-toggle>.btn-group>.btn input[type=checkbox]{position:absolute;clip:rect(0, 0, 0, 0);pointer-events:none}

.input-group{position:relative;display:flex;flex-wrap:wrap;align-items:stretch;width:100%}

.input-group>.form-control,.input-group>.form-control-plaintext,.input-group>.custom-select,.input-group>.custom-file{position:relative;flex:1 1 auto;width:1%;min-width:0;margin-bottom:0}

[dir="ltr"] .input-group>.form-control+.form-control,[dir="ltr"] .input-group>.form-control+.custom-select,[dir="ltr"] .input-group>.form-control+.custom-file,[dir="ltr"] .input-group>.form-control-plaintext+.form-control,[dir="ltr"] .input-group>.form-control-plaintext+.custom-select,[dir="ltr"] .input-group>.form-control-plaintext+.custom-file,[dir="ltr"] .input-group>.custom-select+.form-control,[dir="ltr"] .input-group>.custom-select+.custom-select,[dir="ltr"] .input-group>.custom-select+.custom-file,[dir="ltr"] .input-group>.custom-file+.form-control,[dir="ltr"] .input-group>.custom-file+.custom-select,[dir="ltr"] .input-group>.custom-file+.custom-file{margin-left:-1px}

[dir="rtl"] .input-group>.form-control+.form-control,[dir="rtl"] .input-group>.form-control+.custom-select,[dir="rtl"] .input-group>.form-control+.custom-file,[dir="rtl"] .input-group>.form-control-plaintext+.form-control,[dir="rtl"] .input-group>.form-control-plaintext+.custom-select,[dir="rtl"] .input-group>.form-control-plaintext+.custom-file,[dir="rtl"] .input-group>.custom-select+.form-control,[dir="rtl"] .input-group>.custom-select+.custom-select,[dir="rtl"] .input-group>.custom-select+.custom-file,[dir="rtl"] .input-group>.custom-file+.form-control,[dir="rtl"] .input-group>.custom-file+.custom-select,[dir="rtl"] .input-group>.custom-file+.custom-file{margin-right:-1px}

.input-group>.form-control:focus,.input-group>.custom-select:focus,.input-group>.custom-file .custom-file-input:focus~.custom-file-label{z-index:3}

.input-group>.custom-file .custom-file-input:focus{z-index:4}

[dir="ltr"] .input-group>.form-control:not(:first-child),[dir="ltr"] .input-group>.custom-select:not(:first-child){border-top-left-radius:0;border-bottom-left-radius:0}

[dir="rtl"] .input-group>.form-control:not(:first-child),[dir="rtl"] .input-group>.custom-select:not(:first-child){border-top-right-radius:0;border-bottom-right-radius:0}

.input-group>.custom-file{display:flex;align-items:center}

[dir="ltr"] .input-group>.custom-file:not(:last-child) .custom-file-label,[dir="ltr"] .input-group>.custom-file:not(:first-child) .custom-file-label{border-top-left-radius:0;border-bottom-left-radius:0}

[dir="rtl"] .input-group>.custom-file:not(:last-child) .custom-file-label,[dir="rtl"] .input-group>.custom-file:not(:first-child) .custom-file-label{border-top-right-radius:0;border-bottom-right-radius:0}

[dir="ltr"] .input-group:not(.has-validation)>.form-control:not(:last-child),[dir="ltr"] .input-group:not(.has-validation)>.custom-select:not(:last-child),[dir="ltr"] .input-group:not(.has-validation)>.custom-file:not(:last-child) .custom-file-label::after{border-top-right-radius:0;border-bottom-right-radius:0}

[dir="rtl"] .input-group:not(.has-validation)>.form-control:not(:last-child),[dir="rtl"] .input-group:not(.has-validation)>.custom-select:not(:last-child),[dir="rtl"] .input-group:not(.has-validation)>.custom-file:not(:last-child) .custom-file-label::after{border-top-left-radius:0;border-bottom-left-radius:0}

[dir="ltr"] .input-group.has-validation>.form-control:nth-last-child(n+3),[dir="ltr"] .input-group.has-validation>.custom-select:nth-last-child(n+3),[dir="ltr"] .input-group.has-validation>.custom-file:nth-last-child(n+3) .custom-file-label::after{border-top-right-radius:0;border-bottom-right-radius:0}

[dir="rtl"] .input-group.has-validation>.form-control:nth-last-child(n+3),[dir="rtl"] .input-group.has-validation>.custom-select:nth-last-child(n+3),[dir="rtl"] .input-group.has-validation>.custom-file:nth-last-child(n+3) .custom-file-label::after{border-top-left-radius:0;border-bottom-left-radius:0}

.input-group-prepend,.input-group-append{display:flex}

.input-group-prepend .btn,.input-group-append .btn{position:relative;z-index:2}

.input-group-prepend .btn:focus,.input-group-append .btn:focus{z-index:3}

[dir="ltr"] .input-group-prepend .btn+.btn,[dir="ltr"] .input-group-prepend .btn+.input-group-text,[dir="ltr"] .input-group-prepend .input-group-text+.input-group-text,[dir="ltr"] .input-group-prepend .input-group-text+.btn,[dir="ltr"] .input-group-append .btn+.btn,[dir="ltr"] .input-group-append .btn+.input-group-text,[dir="ltr"] .input-group-append .input-group-text+.input-group-text,[dir="ltr"] .input-group-append .input-group-text+.btn{margin-left:-1px}

[dir="rtl"] .input-group-prepend .btn+.btn,[dir="rtl"] .input-group-prepend .btn+.input-group-text,[dir="rtl"] .input-group-prepend .input-group-text+.input-group-text,[dir="rtl"] .input-group-prepend .input-group-text+.btn,[dir="rtl"] .input-group-append .btn+.btn,[dir="rtl"] .input-group-append .btn+.input-group-text,[dir="rtl"] .input-group-append .input-group-text+.input-group-text,[dir="rtl"] .input-group-append .input-group-text+.btn{margin-right:-1px}

[dir="ltr"] .input-group-prepend{margin-right:-1px}

[dir="rtl"] .input-group-prepend{margin-left:-1px}

[dir="ltr"] .input-group-append{margin-left:-1px}

[dir="rtl"] .input-group-append{margin-right:-1px}

.input-group-text{display:flex;align-items:center;padding:.438rem 1rem;margin-bottom:0;font-size:1rem;font-weight:400;line-height:1.45;color:#6e6b7b;text-align:center;white-space:nowrap;background-color:#fff;border:1px solid #d8d6de;border-radius:.357rem}

.input-group-text input[type=radio],.input-group-text input[type=checkbox]{margin-top:0}

.input-group-lg>.form-control:not(textarea),.input-group-lg>.custom-select{height:3.2857rem}

.input-group-lg>.form-control,.input-group-lg>.custom-select,.input-group-lg>.input-group-prepend>.input-group-text,.input-group-lg>.input-group-append>.input-group-text,.input-group-lg>.input-group-prepend>.btn,.input-group-lg>.input-group-append>.btn{padding:.75rem 1.143rem;font-size:1.143rem;line-height:1.25;border-radius:.357rem}

.input-group-sm>.form-control:not(textarea),.input-group-sm>.custom-select{height:2.142rem}

.input-group-sm>.form-control,.input-group-sm>.custom-select,.input-group-sm>.input-group-prepend>.input-group-text,.input-group-sm>.input-group-append>.input-group-text,.input-group-sm>.input-group-prepend>.btn,.input-group-sm>.input-group-append>.btn{padding:.188rem .857rem;font-size:0.857rem;line-height:1;border-radius:.357rem}

[dir="ltr"] .input-group-lg>.custom-select,[dir="ltr"] .input-group-sm>.custom-select{padding-right:2rem}

[dir="rtl"] .input-group-lg>.custom-select,[dir="rtl"] .input-group-sm>.custom-select{padding-left:2rem}

[dir="ltr"] .input-group>.input-group-prepend>.btn,[dir="ltr"] .input-group>.input-group-prepend>.input-group-text,[dir="ltr"] .input-group:not(.has-validation)>.input-group-append:not(:last-child)>.btn,[dir="ltr"] .input-group:not(.has-validation)>.input-group-append:not(:last-child)>.input-group-text,[dir="ltr"] .input-group.has-validation>.input-group-append:nth-last-child(n+3)>.btn,[dir="ltr"] .input-group.has-validation>.input-group-append:nth-last-child(n+3)>.input-group-text,[dir="ltr"] .input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),[dir="ltr"] .input-group>.input-group-append:last-child>.input-group-text:not(:last-child){border-top-right-radius:0;border-bottom-right-radius:0}

[dir="rtl"] .input-group>.input-group-prepend>.btn,[dir="rtl"] .input-group>.input-group-prepend>.input-group-text,[dir="rtl"] .input-group:not(.has-validation)>.input-group-append:not(:last-child)>.btn,[dir="rtl"] .input-group:not(.has-validation)>.input-group-append:not(:last-child)>.input-group-text,[dir="rtl"] .input-group.has-validation>.input-group-append:nth-last-child(n+3)>.btn,[dir="rtl"] .input-group.has-validation>.input-group-append:nth-last-child(n+3)>.input-group-text,[dir="rtl"] .input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),[dir="rtl"] .input-group>.input-group-append:last-child>.input-group-text:not(:last-child){border-top-left-radius:0;border-bottom-left-radius:0}

[dir="ltr"] .input-group>.input-group-append>.btn,[dir="ltr"] .input-group>.input-group-append>.input-group-text,[dir="ltr"] .input-group>.input-group-prepend:not(:first-child)>.btn,[dir="ltr"] .input-group>.input-group-prepend:not(:first-child)>.input-group-text,[dir="ltr"] .input-group>.input-group-prepend:first-child>.btn:not(:first-child),[dir="ltr"] .input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child){border-top-left-radius:0;border-bottom-left-radius:0}

[dir="rtl"] .input-group>.input-group-append>.btn,[dir="rtl"] .input-group>.input-group-append>.input-group-text,[dir="rtl"] .input-group>.input-group-prepend:not(:first-child)>.btn,[dir="rtl"] .input-group>.input-group-prepend:not(:first-child)>.input-group-text,[dir="rtl"] .input-group>.input-group-prepend:first-child>.btn:not(:first-child),[dir="rtl"] .input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child){border-top-right-radius:0;border-bottom-right-radius:0}

.custom-control{position:relative;z-index:1;display:block;min-height:1.45rem;color-adjust:exact}

[dir="ltr"] .custom-control{padding-left:1.5rem}

[dir="rtl"] .custom-control{padding-right:1.5rem}

.custom-control-inline{display:inline-flex}

[dir="ltr"] .custom-control-inline{margin-right:1rem}

[dir="rtl"] .custom-control-inline{margin-left:1rem}

.custom-control-input{position:absolute;z-index:-1;width:1rem;height:1.225rem;opacity:0}

[dir="ltr"] .custom-control-input{left:0}

[dir="rtl"] .custom-control-input{right:0}

.custom-control-input:checked~.custom-control-label::before{color:#fff;border-color:#a94cd8;background-color:#a94cd8}

.custom-control-input:focus~.custom-control-label::before{box-shadow:0 3px 10px 0 rgba(34,41,47,.1)}

.custom-control-input:focus:not(:checked)~.custom-control-label::before{border-color:#a94cd8}

.custom-control-input:not(:disabled):active~.custom-control-label::before{color:#fff;background-color:#efdff8;border-color:#efdff8}

.custom-control-input[disabled]~.custom-control-label,.custom-control-input:disabled~.custom-control-label{color:#b8c2cc}

.custom-control-input[disabled]~.custom-control-label::before,.custom-control-input:disabled~.custom-control-label::before{background-color:#efefef}

.custom-control-label{position:relative;margin-bottom:0;vertical-align:top}

.custom-control-label::before{position:absolute;top:.225rem;display:block;width:1rem;height:1rem;pointer-events:none;content:"";background-color:#fff;border:#d8d6de solid 1px}

[dir="ltr"] .custom-control-label::before{left:-1.5rem}

[dir="rtl"] .custom-control-label::before{right:-1.5rem}

.custom-control-label::after{position:absolute;top:.225rem;display:block;width:1rem;height:1rem;content:"";background:50%/50% 50% no-repeat}

[dir="ltr"] .custom-control-label::after{left:-1.5rem}

[dir="rtl"] .custom-control-label::after{right:-1.5rem}

.custom-checkbox .custom-control-label::before{border-radius:3px}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after{background-image:url("data:image/svg+xml;charset=utf8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 9.5 7.5%27%3E%3Cpolyline points=%270.75 4.35 4.18 6.75 8.75 0.75%27 style=%27fill:none;stroke:%23fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px%27/%3E%3C/svg%3E")}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before{border-color:#a94cd8;background-color:#a94cd8}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after{background-image:url("data:image/svg+xml;charset=utf8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2724%27 height=%2724%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27white%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-minus%27%3E%3Cline x1=%275%27 y1=%2712%27 x2=%2719%27 y2=%2712%27%3E%3C/line%3E%3C/svg%3E")}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before{background-color:rgba(169,76,216,.65)}

.custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label::before{background-color:rgba(169,76,216,.65)}

.custom-radio .custom-control-label::before{border-radius:50%}

.custom-radio .custom-control-input:checked~.custom-control-label::after{background-image:none}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before{background-color:rgba(169,76,216,.65)}

[dir="ltr"] .custom-switch{padding-left:3.5rem}

[dir="rtl"] .custom-switch{padding-right:3.5rem}

.custom-switch .custom-control-label::before{width:3rem;pointer-events:all;border-radius:1rem}

[dir="ltr"] .custom-switch .custom-control-label::before{left:-3.5rem}

[dir="rtl"] .custom-switch .custom-control-label::before{right:-3.5rem}

.custom-switch .custom-control-label::after{top:calc(0.225rem + 2px);width:1rem;height:1rem;background-color:#d8d6de;border-radius:1rem;transition:transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,background 0s,border-color 0s}

[dir="ltr"] .custom-switch .custom-control-label::after{left:calc(-3.5rem + 2px)}

[dir="rtl"] .custom-switch .custom-control-label::after{right:calc(-3.5rem + 2px)}

@media(prefers-reduced-motion: reduce){
.custom-switch .custom-control-label::after{transition:none}}

.custom-switch .custom-control-input:checked~.custom-control-label::after{background-color:#fff}

[dir="ltr"] .custom-switch .custom-control-input:checked~.custom-control-label::after{transform:translateX(2rem)}

[dir="rtl"] .custom-switch .custom-control-input:checked~.custom-control-label::after{transform:translateX(-2rem)}

.custom-switch .custom-control-input:disabled:checked~.custom-control-label::before{background-color:rgba(169,76,216,.65)}

.custom-select{display:inline-block;width:100%;height:2.714rem;font-size:1rem;font-weight:400;line-height:1.45;color:#6e6b7b;vertical-align:middle;border:1px solid #d8d6de;border-radius:.357rem;appearance:none}

[dir="ltr"] .custom-select{padding:.438rem 2rem .438rem 1rem;background:#fff url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%274%27 height=%275%27 viewBox=%270 0 4 5%27%3e%3cpath fill=%27%23d8d6de%27 d=%27M2 0L0 2h4zm0 5L0 3h4z%27/%3e%3c/svg%3e") right 1rem center/10px 10px no-repeat}

[dir="rtl"] .custom-select{padding:.438rem 1rem .438rem 2rem;background:#fff url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%274%27 height=%275%27 viewBox=%270 0 4 5%27%3e%3cpath fill=%27%23d8d6de%27 d=%27M2 0L0 2h4zm0 5L0 3h4z%27/%3e%3c/svg%3e") left 1rem center/10px 10px no-repeat}

.custom-select:focus{border-color:#a94cd8;outline:0;box-shadow:0 3px 10px 0 rgba(34,41,47,.1)}

.custom-select:focus::-ms-value{color:#6e6b7b;background-color:#fff}

.custom-select[multiple],.custom-select[size]:not([size="1"]){height:auto;background-image:none}

[dir="ltr"] .custom-select[multiple],[dir="ltr"] .custom-select[size]:not([size="1"]){padding-right:1rem}

[dir="rtl"] .custom-select[multiple],[dir="rtl"] .custom-select[size]:not([size="1"]){padding-left:1rem}

.custom-select:disabled{color:#b8c2cc;background-color:#efefef}

.custom-select::-ms-expand{display:none}

.custom-select:-moz-focusring{color:transparent;text-shadow:0 0 0 #6e6b7b}

.custom-select-sm{height:2.142rem;padding-top:.188rem;padding-bottom:.188rem;font-size:0.857rem}

[dir="ltr"] .custom-select-sm{padding-left:.857rem}

[dir="rtl"] .custom-select-sm{padding-right:.857rem}

.custom-select-lg{height:3.2857rem;padding-top:.75rem;padding-bottom:.75rem;font-size:1.143rem}

[dir="ltr"] .custom-select-lg{padding-left:1.143rem}

[dir="rtl"] .custom-select-lg{padding-right:1.143rem}

.custom-file{position:relative;display:inline-block;width:100%;height:2.714rem;margin-bottom:0}

.custom-file-input{position:relative;z-index:2;width:100%;height:2.714rem;margin:0;overflow:hidden;opacity:0}

.custom-file-input:focus~.custom-file-label{border-color:#a94cd8;box-shadow:0 3px 10px 0 rgba(34,41,47,.1)}

.custom-file-input[disabled]~.custom-file-label,.custom-file-input:disabled~.custom-file-label{background-color:#efefef}

.custom-file-input:lang(en)~.custom-file-label::after{content:"Browse"}

.custom-file-input~.custom-file-label[data-browse]::after{content:attr(data-browse)}

.custom-file-label{position:absolute;top:0;right:0;left:0;z-index:1;height:2.714rem;padding:.438rem 1rem;overflow:hidden;font-weight:400;line-height:1.45;color:#6e6b7b;background-color:#fff;border:1px solid #d8d6de;border-radius:.357rem}

.custom-file-label::after{position:absolute;top:0;bottom:0;z-index:3;display:block;height:2.714rem;padding:.438rem 1rem;line-height:1.45;color:#6e6b7b;content:"Browse";background-color:#fff}

[dir="ltr"] .custom-file-label::after{right:0;border-left:inherit;border-radius:0 .357rem .357rem 0}

[dir="rtl"] .custom-file-label::after{left:0;border-right:inherit;border-radius:.357rem 0 0 .357rem}

.custom-range{width:100%;height:1.4rem;padding:0;background-color:transparent;appearance:none}

.custom-range:focus{outline:0}

.custom-range:focus::-webkit-slider-thumb{box-shadow:0 0 0 1px #f6f6f6,0 3px 10px 0 rgba(34,41,47,.1)}

.custom-range:focus::-moz-range-thumb{box-shadow:0 0 0 1px #f6f6f6,0 3px 10px 0 rgba(34,41,47,.1)}

.custom-range:focus::-ms-thumb{box-shadow:0 0 0 1px #f6f6f6,0 3px 10px 0 rgba(34,41,47,.1)}

.custom-range::-moz-focus-outer{border:0}

.custom-range::-webkit-slider-thumb{width:1rem;height:1rem;margin-top:-0.25rem;background-color:#a94cd8;border:0;border-radius:1rem;transition:background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,background 0s,border-color 0s;appearance:none}

@media(prefers-reduced-motion: reduce){
.custom-range::-webkit-slider-thumb{transition:none}}

.custom-range::-webkit-slider-thumb:active{background-color:#efdff8}

.custom-range::-webkit-slider-runnable-track{width:100%;height:.5rem;color:transparent;cursor:pointer;background-color:#dae1e7;border-color:transparent;border-radius:1rem}

.custom-range::-moz-range-thumb{width:1rem;height:1rem;background-color:#a94cd8;border:0;border-radius:1rem;transition:background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,background 0s,border-color 0s;appearance:none}

@media(prefers-reduced-motion: reduce){
.custom-range::-moz-range-thumb{transition:none}}

.custom-range::-moz-range-thumb:active{background-color:#efdff8}

.custom-range::-moz-range-track{width:100%;height:.5rem;color:transparent;cursor:pointer;background-color:#dae1e7;border-color:transparent;border-radius:1rem}

.custom-range::-ms-thumb{width:1rem;height:1rem;margin-top:0;margin-right:.2rem;margin-left:.2rem;background-color:#a94cd8;border:0;border-radius:1rem;transition:background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,background 0s,border-color 0s;appearance:none}

@media(prefers-reduced-motion: reduce){
.custom-range::-ms-thumb{transition:none}}

.custom-range::-ms-thumb:active{background-color:#efdff8}

.custom-range::-ms-track{width:100%;height:.5rem;color:transparent;cursor:pointer;background-color:transparent;border-color:transparent;border-width:.5rem}

.custom-range::-ms-fill-lower{background-color:#dae1e7;border-radius:1rem}

.custom-range::-ms-fill-upper{background-color:#dae1e7;border-radius:1rem}

[dir="ltr"] .custom-range::-ms-fill-upper{margin-right:15px}

[dir="rtl"] .custom-range::-ms-fill-upper{margin-left:15px}

.custom-range:disabled::-webkit-slider-thumb{background-color:#adb5bd}

.custom-range:disabled::-webkit-slider-runnable-track{cursor:default}

.custom-range:disabled::-moz-range-thumb{background-color:#adb5bd}

.custom-range:disabled::-moz-range-track{cursor:default}

.custom-range:disabled::-ms-thumb{background-color:#adb5bd}

.custom-control-label::before,.custom-file-label,.custom-select{transition:background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,background 0s,border-color 0s}

@media(prefers-reduced-motion: reduce){
.custom-control-label::before,.custom-file-label,.custom-select{transition:none}}

.nav{display:flex;flex-wrap:wrap;margin-bottom:0;list-style:none}

[dir="ltr"] .nav{padding-left:0}

[dir="rtl"] .nav{padding-right:0}

.nav-link{display:block;padding:.358rem .5rem}

.nav-link:hover,.nav-link:focus{text-decoration:none}

.nav-link.disabled{color:#b9b9c3;pointer-events:none;cursor:default}

.nav-tabs{border-bottom:0 solid #dae1e7}

.nav-tabs .nav-link{margin-bottom:0;border:0 solid transparent;border-top-left-radius:.25;border-top-right-radius:.25}

.nav-tabs .nav-link:hover,.nav-tabs .nav-link:focus{border-color:#ededed #ededed #dae1e7}

.nav-tabs .nav-link.disabled{color:#b9b9c3;background-color:transparent;border-color:transparent}

.nav-tabs .nav-link.active,.nav-tabs .nav-item.show .nav-link{color:#a94cd8;background-color:transparent;border-color:#dae1e7 #dae1e7 transparent}

.nav-tabs .dropdown-menu{margin-top:0;border-top-left-radius:0;border-top-right-radius:0}

.nav-pills .nav-link{border-radius:.358rem}

.nav-pills .nav-link.active,.nav-pills .show>.nav-link{color:#fff;background-color:#a94cd8}

.nav-fill>.nav-link,.nav-fill .nav-item{flex:1 1 auto;text-align:center}

.nav-justified>.nav-link,.nav-justified .nav-item{flex-basis:0;flex-grow:1;text-align:center}

.tab-content>.tab-pane{display:none}

.tab-content>.active{display:block}

.navbar{position:relative;display:flex;flex-wrap:wrap;align-items:center;justify-content:space-between;padding:.5rem 1rem}

.navbar .container,.navbar .container-fluid,.navbar .container-sm,.navbar .container-md,.navbar .container-lg,.navbar .container-xl,.navbar .container-xxl{display:flex;flex-wrap:wrap;align-items:center;justify-content:space-between}

.navbar-brand{display:inline-block;padding-top:-0.367rem;padding-bottom:-0.367rem;font-size:2rem;line-height:inherit;white-space:nowrap}

[dir="ltr"] .navbar-brand{margin-right:1rem}

[dir="rtl"] .navbar-brand{margin-left:1rem}

.navbar-brand:hover,.navbar-brand:focus{text-decoration:none}

.navbar-nav{display:flex;flex-direction:column;margin-bottom:0;list-style:none}

[dir="ltr"] .navbar-nav{padding-left:0}

[dir="rtl"] .navbar-nav{padding-right:0}

.navbar-nav .nav-link{padding-right:0;padding-left:0}

.navbar-nav .dropdown-menu{position:static;float:none}

.navbar-text{display:inline-block;padding-top:.358rem;padding-bottom:.358rem}

.navbar-collapse{flex-basis:100%;flex-grow:1;align-items:center}

.navbar-toggler{padding:.25rem .75rem;font-size:2rem;line-height:1;background-color:transparent;border:1px solid transparent;border-radius:.358rem}

.navbar-toggler:hover,.navbar-toggler:focus{text-decoration:none}

.navbar-toggler-icon{display:inline-block;width:1.5em;height:1.5em;vertical-align:middle;content:"";background:50%/100% 100% no-repeat}

.navbar-nav-scroll{max-height:75vh;overflow-y:auto}

@media(max-width: 575.98px){
.navbar-expand-sm>.container,.navbar-expand-sm>.container-fluid,.navbar-expand-sm>.container-sm,.navbar-expand-sm>.container-md,.navbar-expand-sm>.container-lg,.navbar-expand-sm>.container-xl,.navbar-expand-sm>.container-xxl{padding-right:0;padding-left:0}}

@media(min-width: 576px){
.navbar-expand-sm{flex-flow:row nowrap;justify-content:flex-start}

.navbar-expand-sm .navbar-nav{flex-direction:row}

.navbar-expand-sm .navbar-nav .dropdown-menu{position:absolute}

.navbar-expand-sm .navbar-nav .nav-link{padding-right:.5rem;padding-left:.5rem}

.navbar-expand-sm>.container,.navbar-expand-sm>.container-fluid,.navbar-expand-sm>.container-sm,.navbar-expand-sm>.container-md,.navbar-expand-sm>.container-lg,.navbar-expand-sm>.container-xl,.navbar-expand-sm>.container-xxl{flex-wrap:nowrap}

.navbar-expand-sm .navbar-nav-scroll{overflow:visible}

.navbar-expand-sm .navbar-collapse{display:flex !important;flex-basis:auto}

.navbar-expand-sm .navbar-toggler{display:none}}

@media(max-width: 767.98px){
.navbar-expand-md>.container,.navbar-expand-md>.container-fluid,.navbar-expand-md>.container-sm,.navbar-expand-md>.container-md,.navbar-expand-md>.container-lg,.navbar-expand-md>.container-xl,.navbar-expand-md>.container-xxl{padding-right:0;padding-left:0}}

@media(min-width: 768px){
.navbar-expand-md{flex-flow:row nowrap;justify-content:flex-start}

.navbar-expand-md .navbar-nav{flex-direction:row}

.navbar-expand-md .navbar-nav .dropdown-menu{position:absolute}

.navbar-expand-md .navbar-nav .nav-link{padding-right:.5rem;padding-left:.5rem}

.navbar-expand-md>.container,.navbar-expand-md>.container-fluid,.navbar-expand-md>.container-sm,.navbar-expand-md>.container-md,.navbar-expand-md>.container-lg,.navbar-expand-md>.container-xl,.navbar-expand-md>.container-xxl{flex-wrap:nowrap}

.navbar-expand-md .navbar-nav-scroll{overflow:visible}

.navbar-expand-md .navbar-collapse{display:flex !important;flex-basis:auto}

.navbar-expand-md .navbar-toggler{display:none}}

@media(max-width: 991.98px){
.navbar-expand-lg>.container,.navbar-expand-lg>.container-fluid,.navbar-expand-lg>.container-sm,.navbar-expand-lg>.container-md,.navbar-expand-lg>.container-lg,.navbar-expand-lg>.container-xl,.navbar-expand-lg>.container-xxl{padding-right:0;padding-left:0}}

@media(min-width: 992px){
.navbar-expand-lg{flex-flow:row nowrap;justify-content:flex-start}

.navbar-expand-lg .navbar-nav{flex-direction:row}

.navbar-expand-lg .navbar-nav .dropdown-menu{position:absolute}

.navbar-expand-lg .navbar-nav .nav-link{padding-right:.5rem;padding-left:.5rem}

.navbar-expand-lg>.container,.navbar-expand-lg>.container-fluid,.navbar-expand-lg>.container-sm,.navbar-expand-lg>.container-md,.navbar-expand-lg>.container-lg,.navbar-expand-lg>.container-xl,.navbar-expand-lg>.container-xxl{flex-wrap:nowrap}

.navbar-expand-lg .navbar-nav-scroll{overflow:visible}

.navbar-expand-lg .navbar-collapse{display:flex !important;flex-basis:auto}

.navbar-expand-lg .navbar-toggler{display:none}}

@media(max-width: 1199.98px){
.navbar-expand-xl>.container,.navbar-expand-xl>.container-fluid,.navbar-expand-xl>.container-sm,.navbar-expand-xl>.container-md,.navbar-expand-xl>.container-lg,.navbar-expand-xl>.container-xl,.navbar-expand-xl>.container-xxl{padding-right:0;padding-left:0}}

@media(min-width: 1200px){
.navbar-expand-xl{flex-flow:row nowrap;justify-content:flex-start}

.navbar-expand-xl .navbar-nav{flex-direction:row}

.navbar-expand-xl .navbar-nav .dropdown-menu{position:absolute}

.navbar-expand-xl .navbar-nav .nav-link{padding-right:.5rem;padding-left:.5rem}

.navbar-expand-xl>.container,.navbar-expand-xl>.container-fluid,.navbar-expand-xl>.container-sm,.navbar-expand-xl>.container-md,.navbar-expand-xl>.container-lg,.navbar-expand-xl>.container-xl,.navbar-expand-xl>.container-xxl{flex-wrap:nowrap}

.navbar-expand-xl .navbar-nav-scroll{overflow:visible}

.navbar-expand-xl .navbar-collapse{display:flex !important;flex-basis:auto}

.navbar-expand-xl .navbar-toggler{display:none}}

.navbar-expand{flex-flow:row nowrap;justify-content:flex-start}

.navbar-expand>.container,.navbar-expand>.container-fluid,.navbar-expand>.container-sm,.navbar-expand>.container-md,.navbar-expand>.container-lg,.navbar-expand>.container-xl,.navbar-expand>.container-xxl{padding-right:0;padding-left:0}

.navbar-expand .navbar-nav{flex-direction:row}

.navbar-expand .navbar-nav .dropdown-menu{position:absolute}

.navbar-expand .navbar-nav .nav-link{padding-right:.5rem;padding-left:.5rem}

.navbar-expand>.container,.navbar-expand>.container-fluid,.navbar-expand>.container-sm,.navbar-expand>.container-md,.navbar-expand>.container-lg,.navbar-expand>.container-xl,.navbar-expand>.container-xxl{flex-wrap:nowrap}

.navbar-expand .navbar-nav-scroll{overflow:visible}

.navbar-expand .navbar-collapse{display:flex !important;flex-basis:auto}

.navbar-expand .navbar-toggler{display:none}

.navbar-light .navbar-brand{color:rgba(34,41,47,.9)}

.navbar-light .navbar-brand:hover,.navbar-light .navbar-brand:focus{color:rgba(34,41,47,.9)}

.navbar-light .navbar-nav .nav-link{color:rgba(34,41,47,.5)}

.navbar-light .navbar-nav .nav-link:hover,.navbar-light .navbar-nav .nav-link:focus{color:rgba(34,41,47,.7)}

.navbar-light .navbar-nav .nav-link.disabled{color:rgba(34,41,47,.3)}

.navbar-light .navbar-nav .show>.nav-link,.navbar-light .navbar-nav .active>.nav-link,.navbar-light .navbar-nav .nav-link.show,.navbar-light .navbar-nav .nav-link.active{color:rgba(34,41,47,.9)}

.navbar-light .navbar-toggler{color:rgba(34,41,47,.5);border-color:rgba(34,41,47,.1)}

.navbar-light .navbar-toggler-icon{background-image:url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2730%27 height=%2730%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%2834, 41, 47, 0.5%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e")}

.navbar-light .navbar-text{color:rgba(34,41,47,.5)}

.navbar-light .navbar-text a{color:rgba(34,41,47,.9)}

.navbar-light .navbar-text a:hover,.navbar-light .navbar-text a:focus{color:rgba(34,41,47,.9)}

.navbar-dark .navbar-brand{color:#fff}

.navbar-dark .navbar-brand:hover,.navbar-dark .navbar-brand:focus{color:#fff}

.navbar-dark .navbar-nav .nav-link{color:rgba(255,255,255,.5)}

.navbar-dark .navbar-nav .nav-link:hover,.navbar-dark .navbar-nav .nav-link:focus{color:rgba(255,255,255,.75)}

.navbar-dark .navbar-nav .nav-link.disabled{color:rgba(255,255,255,.25)}

.navbar-dark .navbar-nav .show>.nav-link,.navbar-dark .navbar-nav .active>.nav-link,.navbar-dark .navbar-nav .nav-link.show,.navbar-dark .navbar-nav .nav-link.active{color:#fff}

.navbar-dark .navbar-toggler{color:rgba(255,255,255,.5);border-color:rgba(255,255,255,.1)}

.navbar-dark .navbar-toggler-icon{background-image:url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2730%27 height=%2730%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28255, 255, 255, 0.5%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e")}

.navbar-dark .navbar-text{color:rgba(255,255,255,.5)}

.navbar-dark .navbar-text a{color:#fff}

.navbar-dark .navbar-text a:hover,.navbar-dark .navbar-text a:focus{color:#fff}

.card{position:relative;display:flex;flex-direction:column;min-width:0;word-wrap:break-word;background-color:#fff;background-clip:border-box;border:1px solid rgba(34,41,47,.125);border-radius:1.2rem}

.card>hr{margin-right:0;margin-left:0}

.card>.list-group{border-top:inherit;border-bottom:inherit}

.card>.list-group:first-child{border-top-width:0;border-top-left-radius:calc(1.2rem - 1px);border-top-right-radius:calc(1.2rem - 1px)}

.card>.list-group:last-child{border-bottom-width:0;border-bottom-right-radius:calc(1.2rem - 1px);border-bottom-left-radius:calc(1.2rem - 1px)}

.card>.card-header+.list-group,.card>.list-group+.card-footer{border-top:0}

.card-body{flex:1 1 auto;min-height:1px;padding:1.5rem}

.card-title{margin-bottom:1.5rem}

.card-subtitle{margin-top:-0.75rem;margin-bottom:0}

.card-text:last-child{margin-bottom:0}

.card-link:hover{text-decoration:none}

[dir="ltr"] .card-link+.card-link{margin-left:1.5rem}

[dir="rtl"] .card-link+.card-link{margin-right:1.5rem}

.card-header{padding:1.5rem 1.5rem;margin-bottom:0;background-color:rgba(34,41,47,.03);border-bottom:1px solid rgba(34,41,47,.125)}

.card-header:first-child{border-radius:calc(1.2rem - 1px) calc(1.2rem - 1px) 0 0}

.card-footer{padding:1.5rem 1.5rem;background-color:rgba(34,41,47,.03);border-top:1px solid rgba(34,41,47,.125)}

.card-footer:last-child{border-radius:0 0 calc(1.2rem - 1px) calc(1.2rem - 1px)}

.card-header-tabs{margin-right:-0.75rem;margin-bottom:-1.5rem;margin-left:-0.75rem;border-bottom:0}

.card-header-pills{margin-right:-0.75rem;margin-left:-0.75rem}

.card-img-overlay{position:absolute;top:0;right:0;bottom:0;left:0;padding:1.25rem;border-radius:calc(1.2rem - 1px)}

.card-img,.card-img-top,.card-img-bottom{flex-shrink:0;width:100%}

.card-img,.card-img-top{border-top-left-radius:calc(1.2rem - 1px);border-top-right-radius:calc(1.2rem - 1px)}

.card-img,.card-img-bottom{border-bottom-right-radius:calc(1.2rem - 1px);border-bottom-left-radius:calc(1.2rem - 1px)}

.card-deck .card{margin-bottom:1rem}

@media(min-width: 576px){
.card-deck{display:flex;flex-flow:row wrap;margin-right:-1rem;margin-left:-1rem}

.card-deck .card{flex:1 0 0%;margin-right:1rem;margin-bottom:0;margin-left:1rem}}

.card-group>.card{margin-bottom:1rem}

@media(min-width: 576px){
.card-group{display:flex;flex-flow:row wrap}

.card-group>.card{flex:1 0 0%;margin-bottom:0}

[dir="ltr"] .card-group>.card+.card{margin-left:0;border-left:0}

[dir="rtl"] .card-group>.card+.card{margin-right:0;border-right:0}

[dir="ltr"] .card-group>.card:not(:last-child){border-top-right-radius:0;border-bottom-right-radius:0}

[dir="rtl"] .card-group>.card:not(:last-child){border-top-left-radius:0;border-bottom-left-radius:0}

[dir="ltr"] .card-group>.card:not(:last-child) .card-img-top,[dir="ltr"] .card-group>.card:not(:last-child) .card-header{border-top-right-radius:0}

[dir="rtl"] .card-group>.card:not(:last-child) .card-img-top,[dir="rtl"] .card-group>.card:not(:last-child) .card-header{border-top-left-radius:0}

[dir="ltr"] .card-group>.card:not(:last-child) .card-img-bottom,[dir="ltr"] .card-group>.card:not(:last-child) .card-footer{border-bottom-right-radius:0}

[dir="rtl"] .card-group>.card:not(:last-child) .card-img-bottom,[dir="rtl"] .card-group>.card:not(:last-child) .card-footer{border-bottom-left-radius:0}

[dir="ltr"] .card-group>.card:not(:first-child){border-top-left-radius:0;border-bottom-left-radius:0}

[dir="rtl"] .card-group>.card:not(:first-child){border-top-right-radius:0;border-bottom-right-radius:0}

[dir="ltr"] .card-group>.card:not(:first-child) .card-img-top,[dir="ltr"] .card-group>.card:not(:first-child) .card-header{border-top-left-radius:0}

[dir="rtl"] .card-group>.card:not(:first-child) .card-img-top,[dir="rtl"] .card-group>.card:not(:first-child) .card-header{border-top-right-radius:0}

[dir="ltr"] .card-group>.card:not(:first-child) .card-img-bottom,[dir="ltr"] .card-group>.card:not(:first-child) .card-footer{border-bottom-left-radius:0}

[dir="rtl"] .card-group>.card:not(:first-child) .card-img-bottom,[dir="rtl"] .card-group>.card:not(:first-child) .card-footer{border-bottom-right-radius:0}}

.card-columns .card{margin-bottom:1.5rem}

@media(min-width: 576px){
.card-columns{column-count:3;column-gap:1.25rem;orphans:1;widows:1}

.card-columns .card{display:inline-block;width:100%}}

.accordion{overflow-anchor:none}

.accordion>.card{overflow:hidden}

.accordion>.card:not(:last-of-type){border-bottom:0;border-bottom-right-radius:0;border-bottom-left-radius:0}

.accordion>.card:not(:first-of-type){border-top-left-radius:0;border-top-right-radius:0}

.accordion>.card>.card-header{border-radius:0;margin-bottom:-1px}

.breadcrumb{display:flex;flex-wrap:wrap;padding:.3rem 1rem;margin-bottom:0;font-size:1rem;list-style:none;background-color:transparent;border-radius:0}

[dir="ltr"] .breadcrumb-item+.breadcrumb-item{padding-left:.6rem}

[dir="rtl"] .breadcrumb-item+.breadcrumb-item{padding-right:.6rem}

.breadcrumb-item+.breadcrumb-item::before{color:#6e6b7b;content:"/"}

[dir="ltr"] .breadcrumb-item+.breadcrumb-item::before{float:left;padding-right:.6rem}

[dir="rtl"] .breadcrumb-item+.breadcrumb-item::before{float:right;padding-left:.6rem}

.breadcrumb-item+.breadcrumb-item:hover::before{text-decoration:underline}

.breadcrumb-item+.breadcrumb-item:hover::before{text-decoration:none}

.breadcrumb-item.active{color:#6e6b7b}

.pagination{display:flex;list-style:none;border-radius:.357rem}

[dir="ltr"] .pagination{padding-left:0}

[dir="rtl"] .pagination{padding-right:0}

.page-link{position:relative;display:block;padding:.5rem .85rem;line-height:1.25;color:#6e6b7b;background-color:#f3f2f7;border:1px solid #dae1e7}

[dir="ltr"] .page-link{margin-left:-1px}

[dir="rtl"] .page-link{margin-right:-1px}

.page-link:hover{z-index:2;color:#a94cd8;text-decoration:none;background-color:#f3f2f7;border-color:#dae1e7}

.page-link:focus{z-index:3;outline:0;box-shadow:0 0 0 .2rem rgba(169,76,216,.25)}

[dir="ltr"] .page-item:first-child .page-link{margin-left:0;border-top-left-radius:.357rem;border-bottom-left-radius:.357rem}

[dir="rtl"] .page-item:first-child .page-link{margin-right:0;border-top-right-radius:.357rem;border-bottom-right-radius:.357rem}

[dir="ltr"] .page-item:last-child .page-link{border-top-right-radius:.357rem;border-bottom-right-radius:.357rem}

[dir="rtl"] .page-item:last-child .page-link{border-top-left-radius:.357rem;border-bottom-left-radius:.357rem}

.page-item.active .page-link{z-index:3;color:#a94cd8;background-color:#a94cd8;border-color:#a94cd8}

.page-item.disabled .page-link{color:#b9b9c3;pointer-events:none;cursor:auto;background-color:#f3f2f7;border-color:#dae1e7}

.pagination-lg .page-link{padding:.5575rem 1rem;font-size:2rem;line-height:1.25}

[dir="ltr"] .pagination-lg .page-item:first-child .page-link{border-top-left-radius:.6rem;border-bottom-left-radius:.6rem}

[dir="rtl"] .pagination-lg .page-item:first-child .page-link{border-top-right-radius:.6rem;border-bottom-right-radius:.6rem}

[dir="ltr"] .pagination-lg .page-item:last-child .page-link{border-top-right-radius:.6rem;border-bottom-right-radius:.6rem}

[dir="rtl"] .pagination-lg .page-item:last-child .page-link{border-top-left-radius:.6rem;border-bottom-left-radius:.6rem}

.pagination-sm .page-link{padding:.443rem .745rem;font-size:1rem;line-height:1}

[dir="ltr"] .pagination-sm .page-item:first-child .page-link{border-top-left-radius:.25rem;border-bottom-left-radius:.25rem}

[dir="rtl"] .pagination-sm .page-item:first-child .page-link{border-top-right-radius:.25rem;border-bottom-right-radius:.25rem}

[dir="ltr"] .pagination-sm .page-item:last-child .page-link{border-top-right-radius:.25rem;border-bottom-right-radius:.25rem}

[dir="rtl"] .pagination-sm .page-item:last-child .page-link{border-top-left-radius:.25rem;border-bottom-left-radius:.25rem}

.badge{display:inline-block;padding:.3rem .5rem;font-size:85%;font-weight:600;line-height:1;text-align:center;white-space:nowrap;vertical-align:baseline;border-radius:.358rem;transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,background 0s,border 0s}

@media(prefers-reduced-motion: reduce){
.badge{transition:none}}

a.badge:hover,a.badge:focus{text-decoration:none}

.badge:empty{display:none}

.btn .badge{position:relative;top:-1px}

.badge-pill{padding-right:.5rem;padding-left:.5rem;border-radius:10rem}

.badge-primary{color:#fff;background-color:#a94cd8}

a.badge-primary:hover,a.badge-primary:focus{color:#fff;background-color:#922bc6}

a.badge-primary:focus,a.badge-primary.focus{outline:0;box-shadow:0 0 0 .2rem rgba(169,76,216,.5)}

.badge-secondary{color:#fff;background-color:#82868b}

a.badge-secondary:hover,a.badge-secondary:focus{color:#fff;background-color:#696d71}

a.badge-secondary:focus,a.badge-secondary.focus{outline:0;box-shadow:0 0 0 .2rem rgba(130,134,139,.5)}

.badge-success{color:#fff;background-color:#28c76f}

a.badge-success:hover,a.badge-success:focus{color:#fff;background-color:#1f9d57}

a.badge-success:focus,a.badge-success.focus{outline:0;box-shadow:0 0 0 .2rem rgba(40,199,111,.5)}

.badge-info{color:#fff;background-color:#00cfe8}

a.badge-info:hover,a.badge-info:focus{color:#fff;background-color:#00a1b5}

a.badge-info:focus,a.badge-info.focus{outline:0;box-shadow:0 0 0 .2rem rgba(0,207,232,.5)}

.badge-warning{color:#2a2e30;background-color:#ff9f43}

a.badge-warning:hover,a.badge-warning:focus{color:#2a2e30;background-color:#ff8510}

a.badge-warning:focus,a.badge-warning.focus{outline:0;box-shadow:0 0 0 .2rem rgba(255,159,67,.5)}

.badge-danger{color:#fff;background-color:#ea5455}

a.badge-danger:hover,a.badge-danger:focus{color:#fff;background-color:#e42728}

a.badge-danger:focus,a.badge-danger.focus{outline:0;box-shadow:0 0 0 .2rem rgba(234,84,85,.5)}

.badge-light{color:#2a2e30;background-color:#f6f6f6}

a.badge-light:hover,a.badge-light:focus{color:#2a2e30;background-color:#ddd}

a.badge-light:focus,a.badge-light.focus{outline:0;box-shadow:0 0 0 .2rem rgba(246,246,246,.5)}

.badge-dark{color:#fff;background-color:#161d31}

a.badge-dark:hover,a.badge-dark:focus{color:#fff;background-color:#06080e}

a.badge-dark:focus,a.badge-dark.focus{outline:0;box-shadow:0 0 0 .2rem rgba(22,29,49,.5)}

.jumbotron{padding:2rem 1rem;margin-bottom:2rem;background-color:#ededed;border-radius:.6rem}

@media(min-width: 576px){
.jumbotron{padding:4rem 2rem}}

.jumbotron-fluid{padding-right:0;padding-left:0;border-radius:0}

.alert{position:relative;padding:.71rem 1rem;margin-bottom:1rem;border:1px solid transparent;border-radius:.358rem}

.alert-heading{color:inherit}

.alert-link{font-weight:700}

[dir="ltr"] .alert-dismissible{padding-right:4rem}

[dir="rtl"] .alert-dismissible{padding-left:4rem}

.alert-dismissible .close{position:absolute;top:0;z-index:2;padding:.71rem 1rem;color:inherit}

[dir="ltr"] .alert-dismissible .close{right:0}

[dir="rtl"] .alert-dismissible .close{left:0}

.alert-primary{color:#683b87;background-color:#eedbf7;border-color:#e7cdf4}

.alert-primary hr{border-top-color:#ddb8ef}

.alert-primary .alert-link{color:#4d2b64}

.alert-secondary{color:#54595f;background-color:#e6e7e8;border-color:#dcdddf}

.alert-secondary hr{border-top-color:#cfd0d3}

.alert-secondary .alert-link{color:#3c4044}

.alert-success{color:#257b50;background-color:#d4f4e2;border-color:#c3efd7}

.alert-success hr{border-top-color:#afeaca}

.alert-success .alert-link{color:#195437}

.alert-info{color:#107f8f;background-color:#ccf5fa;border-color:#b8f2f9}

.alert-info hr{border-top-color:#a0eef7}

.alert-info .alert-link{color:#0b5661}

.alert-warning{color:#956639;background-color:#ffecd9;border-color:#ffe4ca}

.alert-warning hr{border-top-color:#ffd7b1}

.alert-warning .alert-link{color:#704d2b}

.alert-danger{color:#8a3f43;background-color:#fbdddd;border-color:#f9cfcf}

.alert-danger hr{border-top-color:#f6b8b8}

.alert-danger .alert-link{color:#672f32}

.alert-light{color:#909496;background-color:#fdfdfd;border-color:#fcfcfc}

.alert-light hr{border-top-color:#efefef}

.alert-light .alert-link{color:#767b7d}

.alert-dark{color:#1c2330;background-color:#d0d2d6;border-color:#bec0c5}

.alert-dark hr{border-top-color:#b1b3b9}

.alert-dark .alert-link{color:#090c10}

@keyframes progress-bar-stripes{
from{background-position:.857rem 0}

to{background-position:0 0}}

.progress{display:flex;height:.857rem;overflow:hidden;line-height:0;font-size:0.857rem;background-color:rgba(169,76,216,.12);border-radius:5rem}

.progress-bar{display:flex;flex-direction:column;justify-content:center;overflow:hidden;color:#fff;text-align:center;white-space:nowrap;background-color:#a94cd8;transition:width .6s ease}

@media(prefers-reduced-motion: reduce){
.progress-bar{transition:none}}

.progress-bar-striped{background-size:.857rem .857rem}

[dir="ltr"] .progress-bar-striped{background-image:linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent)}

[dir="rtl"] .progress-bar-striped{background-image:linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent)}

.progress-bar-animated{animation:1s linear infinite progress-bar-stripes}

@media(prefers-reduced-motion: reduce){
.progress-bar-animated{animation:none}}

.media{display:flex;align-items:flex-start}

.media-body{flex:1}

.list-group{display:flex;flex-direction:column;margin-bottom:0;border-radius:.358rem}

[dir="ltr"] .list-group{padding-left:0}

[dir="rtl"] .list-group{padding-right:0}

.list-group-item-action{width:100%;color:#4e5154;text-align:inherit}

.list-group-item-action:hover,.list-group-item-action:focus{z-index:1;color:#4e5154;text-decoration:none;background-color:#babfc7}

.list-group-item-action:active{color:#6e6b7b;background-color:#ededed}

.list-group-item{position:relative;display:block;padding:.75rem 1.25rem;background-color:#fff;border:1px solid rgba(34,41,47,.125)}

.list-group-item:first-child{border-top-left-radius:inherit;border-top-right-radius:inherit}

.list-group-item:last-child{border-bottom-right-radius:inherit;border-bottom-left-radius:inherit}

.list-group-item.disabled,.list-group-item:disabled{color:#b9b9c3;pointer-events:none;background-color:#fff}

.list-group-item.active{z-index:2;color:#fff;background-color:#a94cd8;border-color:#a94cd8}

.list-group-item+.list-group-item{border-top-width:0}

.list-group-item+.list-group-item.active{margin-top:-1px;border-top-width:1px}

.list-group-horizontal{flex-direction:row}

[dir="ltr"] .list-group-horizontal>.list-group-item:first-child{border-bottom-left-radius:.358rem;border-top-right-radius:0}

[dir="rtl"] .list-group-horizontal>.list-group-item:first-child{border-bottom-right-radius:.358rem;border-top-left-radius:0}

[dir="ltr"] .list-group-horizontal>.list-group-item:last-child{border-top-right-radius:.358rem;border-bottom-left-radius:0}

[dir="rtl"] .list-group-horizontal>.list-group-item:last-child{border-top-left-radius:.358rem;border-bottom-right-radius:0}

.list-group-horizontal>.list-group-item.active{margin-top:0}

.list-group-horizontal>.list-group-item+.list-group-item{border-top-width:1px}

[dir="ltr"] .list-group-horizontal>.list-group-item+.list-group-item{border-left-width:0}

[dir="rtl"] .list-group-horizontal>.list-group-item+.list-group-item{border-right-width:0}

[dir="ltr"] .list-group-horizontal>.list-group-item+.list-group-item.active{margin-left:-1px;border-left-width:1px}

[dir="rtl"] .list-group-horizontal>.list-group-item+.list-group-item.active{margin-right:-1px;border-right-width:1px}

@media(min-width: 576px){
.list-group-horizontal-sm{flex-direction:row}

[dir="ltr"] .list-group-horizontal-sm>.list-group-item:first-child{border-bottom-left-radius:.358rem;border-top-right-radius:0}

[dir="rtl"] .list-group-horizontal-sm>.list-group-item:first-child{border-bottom-right-radius:.358rem;border-top-left-radius:0}

[dir="ltr"] .list-group-horizontal-sm>.list-group-item:last-child{border-top-right-radius:.358rem;border-bottom-left-radius:0}

[dir="rtl"] .list-group-horizontal-sm>.list-group-item:last-child{border-top-left-radius:.358rem;border-bottom-right-radius:0}

.list-group-horizontal-sm>.list-group-item.active{margin-top:0}

.list-group-horizontal-sm>.list-group-item+.list-group-item{border-top-width:1px}

[dir="ltr"] .list-group-horizontal-sm>.list-group-item+.list-group-item{border-left-width:0}

[dir="rtl"] .list-group-horizontal-sm>.list-group-item+.list-group-item{border-right-width:0}

[dir="ltr"] .list-group-horizontal-sm>.list-group-item+.list-group-item.active{margin-left:-1px;border-left-width:1px}

[dir="rtl"] .list-group-horizontal-sm>.list-group-item+.list-group-item.active{margin-right:-1px;border-right-width:1px}}

@media(min-width: 768px){
.list-group-horizontal-md{flex-direction:row}

[dir="ltr"] .list-group-horizontal-md>.list-group-item:first-child{border-bottom-left-radius:.358rem;border-top-right-radius:0}

[dir="rtl"] .list-group-horizontal-md>.list-group-item:first-child{border-bottom-right-radius:.358rem;border-top-left-radius:0}

[dir="ltr"] .list-group-horizontal-md>.list-group-item:last-child{border-top-right-radius:.358rem;border-bottom-left-radius:0}

[dir="rtl"] .list-group-horizontal-md>.list-group-item:last-child{border-top-left-radius:.358rem;border-bottom-right-radius:0}

.list-group-horizontal-md>.list-group-item.active{margin-top:0}

.list-group-horizontal-md>.list-group-item+.list-group-item{border-top-width:1px}

[dir="ltr"] .list-group-horizontal-md>.list-group-item+.list-group-item{border-left-width:0}

[dir="rtl"] .list-group-horizontal-md>.list-group-item+.list-group-item{border-right-width:0}

[dir="ltr"] .list-group-horizontal-md>.list-group-item+.list-group-item.active{margin-left:-1px;border-left-width:1px}

[dir="rtl"] .list-group-horizontal-md>.list-group-item+.list-group-item.active{margin-right:-1px;border-right-width:1px}}

@media(min-width: 992px){
.list-group-horizontal-lg{flex-direction:row}

[dir="ltr"] .list-group-horizontal-lg>.list-group-item:first-child{border-bottom-left-radius:.358rem;border-top-right-radius:0}

[dir="rtl"] .list-group-horizontal-lg>.list-group-item:first-child{border-bottom-right-radius:.358rem;border-top-left-radius:0}

[dir="ltr"] .list-group-horizontal-lg>.list-group-item:last-child{border-top-right-radius:.358rem;border-bottom-left-radius:0}

[dir="rtl"] .list-group-horizontal-lg>.list-group-item:last-child{border-top-left-radius:.358rem;border-bottom-right-radius:0}

.list-group-horizontal-lg>.list-group-item.active{margin-top:0}

.list-group-horizontal-lg>.list-group-item+.list-group-item{border-top-width:1px}

[dir="ltr"] .list-group-horizontal-lg>.list-group-item+.list-group-item{border-left-width:0}

[dir="rtl"] .list-group-horizontal-lg>.list-group-item+.list-group-item{border-right-width:0}

[dir="ltr"] .list-group-horizontal-lg>.list-group-item+.list-group-item.active{margin-left:-1px;border-left-width:1px}

[dir="rtl"] .list-group-horizontal-lg>.list-group-item+.list-group-item.active{margin-right:-1px;border-right-width:1px}}

@media(min-width: 1200px){
.list-group-horizontal-xl{flex-direction:row}

[dir="ltr"] .list-group-horizontal-xl>.list-group-item:first-child{border-bottom-left-radius:.358rem;border-top-right-radius:0}

[dir="rtl"] .list-group-horizontal-xl>.list-group-item:first-child{border-bottom-right-radius:.358rem;border-top-left-radius:0}

[dir="ltr"] .list-group-horizontal-xl>.list-group-item:last-child{border-top-right-radius:.358rem;border-bottom-left-radius:0}

[dir="rtl"] .list-group-horizontal-xl>.list-group-item:last-child{border-top-left-radius:.358rem;border-bottom-right-radius:0}

.list-group-horizontal-xl>.list-group-item.active{margin-top:0}

.list-group-horizontal-xl>.list-group-item+.list-group-item{border-top-width:1px}

[dir="ltr"] .list-group-horizontal-xl>.list-group-item+.list-group-item{border-left-width:0}

[dir="rtl"] .list-group-horizontal-xl>.list-group-item+.list-group-item{border-right-width:0}

[dir="ltr"] .list-group-horizontal-xl>.list-group-item+.list-group-item.active{margin-left:-1px;border-left-width:1px}

[dir="rtl"] .list-group-horizontal-xl>.list-group-item+.list-group-item.active{margin-right:-1px;border-right-width:1px}}

.list-group-flush{border-radius:0}

.list-group-flush>.list-group-item{border-width:0 0 1px}

.list-group-flush>.list-group-item:last-child{border-bottom-width:0}

.list-group-item-primary{color:#683b87;background-color:#e7cdf4}

.list-group-item-primary.list-group-item-action:hover,.list-group-item-primary.list-group-item-action:focus{color:#683b87;background-color:#ddb8ef}

.list-group-item-primary.list-group-item-action.active{color:#fff;background-color:#683b87;border-color:#683b87}

.list-group-item-secondary{color:#54595f;background-color:#dcdddf}

.list-group-item-secondary.list-group-item-action:hover,.list-group-item-secondary.list-group-item-action:focus{color:#54595f;background-color:#cfd0d3}

.list-group-item-secondary.list-group-item-action.active{color:#fff;background-color:#54595f;border-color:#54595f}

.list-group-item-success{color:#257b50;background-color:#c3efd7}

.list-group-item-success.list-group-item-action:hover,.list-group-item-success.list-group-item-action:focus{color:#257b50;background-color:#afeaca}

.list-group-item-success.list-group-item-action.active{color:#fff;background-color:#257b50;border-color:#257b50}

.list-group-item-info{color:#107f8f;background-color:#b8f2f9}

.list-group-item-info.list-group-item-action:hover,.list-group-item-info.list-group-item-action:focus{color:#107f8f;background-color:#a0eef7}

.list-group-item-info.list-group-item-action.active{color:#fff;background-color:#107f8f;border-color:#107f8f}

.list-group-item-warning{color:#956639;background-color:#ffe4ca}

.list-group-item-warning.list-group-item-action:hover,.list-group-item-warning.list-group-item-action:focus{color:#956639;background-color:#ffd7b1}

.list-group-item-warning.list-group-item-action.active{color:#fff;background-color:#956639;border-color:#956639}

.list-group-item-danger{color:#8a3f43;background-color:#f9cfcf}

.list-group-item-danger.list-group-item-action:hover,.list-group-item-danger.list-group-item-action:focus{color:#8a3f43;background-color:#f6b8b8}

.list-group-item-danger.list-group-item-action.active{color:#fff;background-color:#8a3f43;border-color:#8a3f43}

.list-group-item-light{color:#909496;background-color:#fcfcfc}

.list-group-item-light.list-group-item-action:hover,.list-group-item-light.list-group-item-action:focus{color:#909496;background-color:#efefef}

.list-group-item-light.list-group-item-action.active{color:#fff;background-color:#909496;border-color:#909496}

.list-group-item-dark{color:#1c2330;background-color:#bec0c5}

.list-group-item-dark.list-group-item-action:hover,.list-group-item-dark.list-group-item-action:focus{color:#1c2330;background-color:#b1b3b9}

.list-group-item-dark.list-group-item-action.active{color:#fff;background-color:#1c2330;border-color:#1c2330}

.close{font-size:2rem;font-weight:400;line-height:1;color:#5e5873;text-shadow:0 1px 0 #fff;opacity:.5}

[dir="ltr"] .close{float:right}

[dir="rtl"] .close{float:left}

.close:hover{color:#5e5873;text-decoration:none}

.close:not(:disabled):not(.disabled):hover,.close:not(:disabled):not(.disabled):focus{opacity:.75}

button.close{padding:0;background-color:transparent;border:0}

a.close.disabled{pointer-events:none}

.toast{flex-basis:380px;max-width:380px;font-size:0.857rem;color:#6e6b7b;background-color:rgba(255,255,255,.85);background-clip:padding-box;border:0 solid rgba(0,0,0,.1);box-shadow:0 2px 20px 0 rgba(34,41,47,.08);opacity:0;border-radius:.286rem}

.toast:not(:last-child){margin-bottom:1.14rem}

.toast.showing{opacity:1}

.toast.show{display:block;opacity:1}

.toast.hide{display:none}

.toast-header{display:flex;align-items:center;padding:.15rem 1.14rem;color:#6e6b7b;background-color:#fff;background-clip:padding-box;border-bottom:0 solid rgba(0,0,0,.05);border-top-left-radius:.286rem;border-top-right-radius:.286rem}

.toast-body{padding:1.14rem}

.modal-open{overflow:hidden}

.modal-open .modal{overflow-x:hidden;overflow-y:auto}

.modal{position:fixed;top:0;z-index:1050;display:none;width:100%;height:100%;overflow:hidden;outline:0}

[dir="ltr"] .modal{left:0}

[dir="rtl"] .modal{right:0}

.modal-dialog{position:relative;width:auto;margin:.5rem;pointer-events:none}

.modal.fade .modal-dialog{transition:transform .3s ease-out;transform:translate(0, -50px)}

@media(prefers-reduced-motion: reduce){
.modal.fade .modal-dialog{transition:none}}

.modal.show .modal-dialog{transform:none}

.modal.modal-static .modal-dialog{transform:scale(1.02)}

.modal-dialog-scrollable{display:flex;max-height:calc(100% - 1rem)}

.modal-dialog-scrollable .modal-content{max-height:calc(100vh - 1rem);overflow:hidden}

.modal-dialog-scrollable .modal-header,.modal-dialog-scrollable .modal-footer{flex-shrink:0}

.modal-dialog-scrollable .modal-body{overflow-y:auto}

.modal-dialog-centered{display:flex;align-items:center;min-height:calc(100% - 1rem)}

.modal-dialog-centered::before{display:block;height:calc(100vh - 1rem);height:min-content;content:""}

.modal-dialog-centered.modal-dialog-scrollable{flex-direction:column;justify-content:center;height:100%}

.modal-dialog-centered.modal-dialog-scrollable .modal-content{max-height:none}

.modal-dialog-centered.modal-dialog-scrollable::before{content:none}

.modal-content{position:relative;display:flex;flex-direction:column;width:100%;pointer-events:auto;background-color:#fff;background-clip:padding-box;border:1px solid rgba(34,41,47,.2);border-radius:.358rem;outline:0}

.modal-backdrop{position:fixed;top:0;z-index:1040;width:100vw;height:100vh;background-color:#22292f}

[dir="ltr"] .modal-backdrop{left:0}

[dir="rtl"] .modal-backdrop{right:0}

.modal-backdrop.fade{opacity:0}

.modal-backdrop.show{opacity:.5}

.modal-header{display:flex;align-items:flex-start;justify-content:space-between;padding:.8rem 1.4rem;border-bottom:1px solid #ebe9f1;border-top-left-radius:calc(0.358rem - 1px);border-top-right-radius:calc(0.358rem - 1px)}

.modal-header .close{padding:.8rem 1.4rem}

[dir="ltr"] .modal-header .close{margin:-0.8rem -1.4rem -0.8rem auto}

[dir="rtl"] .modal-header .close{margin:-0.8rem auto -0.8rem -1.4rem}

.modal-title{margin-bottom:0;line-height:1.45}

.modal-body{position:relative;flex:1 1 auto;padding:.8rem 1.4rem}

.modal-footer{display:flex;flex-wrap:wrap;align-items:center;justify-content:flex-end;padding:0.8rem 1.4rem-0.25rem;border-top:1px solid rgba(34,41,47,.05);border-bottom-right-radius:calc(0.358rem - 1px);border-bottom-left-radius:calc(0.358rem - 1px)}

.modal-footer>*{margin:.25rem}

.modal-scrollbar-measure{position:absolute;top:-9999px;width:50px;height:50px;overflow:scroll}

@media(min-width: 576px){
.modal-dialog{max-width:500px;margin:1.75rem auto}

.modal-dialog-scrollable{max-height:calc(100% - 3.5rem)}

.modal-dialog-scrollable .modal-content{max-height:calc(100vh - 3.5rem)}

.modal-dialog-centered{min-height:calc(100% - 3.5rem)}

.modal-dialog-centered::before{height:calc(100vh - 3.5rem);height:min-content}

.modal-sm{max-width:400px}}

@media(min-width: 992px){
.modal-lg,.modal-xl{max-width:800px}}

@media(min-width: 1200px){
.modal-xl{max-width:94%}}

.tooltip{position:absolute;z-index:1070;display:block;margin:0;font-family:"Montserrat",Helvetica,Arial,serif;font-style:normal;font-weight:400;line-height:1.45;text-align:left;text-align:start;text-decoration:none;text-shadow:none;text-transform:none;letter-spacing:normal;word-break:normal;word-spacing:normal;white-space:normal;line-break:auto;font-size:0.857rem;word-wrap:break-word;opacity:0}

.tooltip.show{opacity:1}

.tooltip .arrow{position:absolute;display:block;width:.8rem;height:.4rem}

.tooltip .arrow::before{position:absolute;content:"";border-color:transparent;border-style:solid}

.bs-tooltip-top,.tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=top],.tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=top],.tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=top],.tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=top],.tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=top],.tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=top],.tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=top],.tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=top],.bs-tooltip-auto[x-placement^=top]{padding:.4rem 0}

.bs-tooltip-top .arrow,.tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=top] .arrow,.tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=top] .arrow,.tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=top] .arrow,.tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=top] .arrow,.tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=top] .arrow,.tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=top] .arrow,.tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=top] .arrow,.tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=top] .arrow,.bs-tooltip-auto[x-placement^=top] .arrow{bottom:0}

.bs-tooltip-top .arrow::before,.tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=top] .arrow::before,.tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=top] .arrow::before,.tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=top] .arrow::before,.tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=top] .arrow::before,.tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=top] .arrow::before,.tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=top] .arrow::before,.tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=top] .arrow::before,.tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=top] .arrow::before,.bs-tooltip-auto[x-placement^=top] .arrow::before{top:0;border-width:.4rem .4rem 0;border-top-color:#323232}

.bs-tooltip-right,.tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=right],.tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=right],.tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=right],.tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=right],.tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=right],.tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=right],.tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=right],.tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=right],.bs-tooltip-auto[x-placement^=right]{padding:0 .4rem}

.bs-tooltip-right .arrow,.tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=right] .arrow,.tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=right] .arrow,.tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=right] .arrow,.tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=right] .arrow,.tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=right] .arrow,.tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=right] .arrow,.tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=right] .arrow,.tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=right] .arrow,.bs-tooltip-auto[x-placement^=right] .arrow{width:.4rem;height:.8rem}

[dir="ltr"] .bs-tooltip-right .arrow,[dir="ltr"] .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=right] .arrow,[dir="ltr"] .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=right] .arrow,[dir="ltr"] .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=right] .arrow,[dir="ltr"] .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=right] .arrow,[dir="ltr"] .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=right] .arrow,[dir="ltr"] .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=right] .arrow,[dir="ltr"] .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=right] .arrow,[dir="ltr"] .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=right] .arrow,[dir="ltr"] .bs-tooltip-auto[x-placement^=right] .arrow{left:0}

[dir="rtl"] .bs-tooltip-right .arrow,[dir="rtl"] .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=right] .arrow,[dir="rtl"] .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=right] .arrow,[dir="rtl"] .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=right] .arrow,[dir="rtl"] .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=right] .arrow,[dir="rtl"] .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=right] .arrow,[dir="rtl"] .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=right] .arrow,[dir="rtl"] .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=right] .arrow,[dir="rtl"] .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=right] .arrow,[dir="rtl"] .bs-tooltip-auto[x-placement^=right] .arrow{right:0}

[dir="ltr"] .bs-tooltip-right .arrow::before,[dir="ltr"] .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=right] .arrow::before,[dir="ltr"] .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=right] .arrow::before,[dir="ltr"] .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=right] .arrow::before,[dir="ltr"] .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=right] .arrow::before,[dir="ltr"] .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=right] .arrow::before,[dir="ltr"] .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=right] .arrow::before,[dir="ltr"] .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=right] .arrow::before,[dir="ltr"] .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=right] .arrow::before,[dir="ltr"] .bs-tooltip-auto[x-placement^=right] .arrow::before{right:0;border-width:.4rem .4rem .4rem 0;border-right-color:#323232}

[dir="rtl"] .bs-tooltip-right .arrow::before,[dir="rtl"] .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=right] .arrow::before,[dir="rtl"] .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=right] .arrow::before,[dir="rtl"] .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=right] .arrow::before,[dir="rtl"] .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=right] .arrow::before,[dir="rtl"] .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=right] .arrow::before,[dir="rtl"] .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=right] .arrow::before,[dir="rtl"] .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=right] .arrow::before,[dir="rtl"] .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=right] .arrow::before,[dir="rtl"] .bs-tooltip-auto[x-placement^=right] .arrow::before{left:0;border-width:.4rem 0 .4rem .4rem;border-left-color:#323232}

.bs-tooltip-bottom,.tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=bottom],.tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=bottom],.tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=bottom],.tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=bottom],.tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=bottom],.tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=bottom],.tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=bottom],.tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=bottom],.bs-tooltip-auto[x-placement^=bottom]{padding:.4rem 0}

.bs-tooltip-bottom .arrow,.tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=bottom] .arrow,.tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=bottom] .arrow,.tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=bottom] .arrow,.tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=bottom] .arrow,.tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=bottom] .arrow,.tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=bottom] .arrow,.tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=bottom] .arrow,.tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=bottom] .arrow,.bs-tooltip-auto[x-placement^=bottom] .arrow{top:0}

.bs-tooltip-bottom .arrow::before,.tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=bottom] .arrow::before,.tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=bottom] .arrow::before,.tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=bottom] .arrow::before,.tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=bottom] .arrow::before,.tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=bottom] .arrow::before,.tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=bottom] .arrow::before,.tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=bottom] .arrow::before,.tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=bottom] .arrow::before,.bs-tooltip-auto[x-placement^=bottom] .arrow::before{bottom:0;border-width:0 .4rem .4rem;border-bottom-color:#323232}

.bs-tooltip-left,.tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=left],.tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=left],.tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=left],.tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=left],.tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=left],.tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=left],.tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=left],.tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=left],.bs-tooltip-auto[x-placement^=left]{padding:0 .4rem}

.bs-tooltip-left .arrow,.tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=left] .arrow,.tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=left] .arrow,.tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=left] .arrow,.tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=left] .arrow,.tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=left] .arrow,.tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=left] .arrow,.tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=left] .arrow,.tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=left] .arrow,.bs-tooltip-auto[x-placement^=left] .arrow{width:.4rem;height:.8rem}

[dir="ltr"] .bs-tooltip-left .arrow,[dir="ltr"] .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=left] .arrow,[dir="ltr"] .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=left] .arrow,[dir="ltr"] .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=left] .arrow,[dir="ltr"] .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=left] .arrow,[dir="ltr"] .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=left] .arrow,[dir="ltr"] .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=left] .arrow,[dir="ltr"] .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=left] .arrow,[dir="ltr"] .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=left] .arrow,[dir="ltr"] .bs-tooltip-auto[x-placement^=left] .arrow{right:0}

[dir="rtl"] .bs-tooltip-left .arrow,[dir="rtl"] .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=left] .arrow,[dir="rtl"] .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=left] .arrow,[dir="rtl"] .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=left] .arrow,[dir="rtl"] .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=left] .arrow,[dir="rtl"] .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=left] .arrow,[dir="rtl"] .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=left] .arrow,[dir="rtl"] .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=left] .arrow,[dir="rtl"] .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=left] .arrow,[dir="rtl"] .bs-tooltip-auto[x-placement^=left] .arrow{left:0}

[dir="ltr"] .bs-tooltip-left .arrow::before,[dir="ltr"] .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=left] .arrow::before,[dir="ltr"] .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=left] .arrow::before,[dir="ltr"] .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=left] .arrow::before,[dir="ltr"] .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=left] .arrow::before,[dir="ltr"] .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=left] .arrow::before,[dir="ltr"] .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=left] .arrow::before,[dir="ltr"] .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=left] .arrow::before,[dir="ltr"] .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=left] .arrow::before,[dir="ltr"] .bs-tooltip-auto[x-placement^=left] .arrow::before{left:0;border-width:.4rem 0 .4rem .4rem;border-left-color:#323232}

[dir="rtl"] .bs-tooltip-left .arrow::before,[dir="rtl"] .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=left] .arrow::before,[dir="rtl"] .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=left] .arrow::before,[dir="rtl"] .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=left] .arrow::before,[dir="rtl"] .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=left] .arrow::before,[dir="rtl"] .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=left] .arrow::before,[dir="rtl"] .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=left] .arrow::before,[dir="rtl"] .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=left] .arrow::before,[dir="rtl"] .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=left] .arrow::before,[dir="rtl"] .bs-tooltip-auto[x-placement^=left] .arrow::before{right:0;border-width:.4rem .4rem .4rem 0;border-right-color:#323232}

.tooltip-inner{max-width:220px;padding:.4rem .775rem;color:#fff;text-align:center;background-color:#323232;border-radius:.358rem}

.popover{position:absolute;top:0;z-index:1060;display:block;max-width:276px;font-family:"Montserrat",Helvetica,Arial,serif;font-style:normal;font-weight:400;line-height:1.45;text-align:left;text-align:start;text-decoration:none;text-shadow:none;text-transform:none;letter-spacing:normal;word-break:normal;word-spacing:normal;white-space:normal;line-break:auto;font-size:1rem;word-wrap:break-word;background-color:#fff;background-clip:padding-box;border:0 solid #ebe9f1;border-radius:.358rem}

[dir="ltr"] .popover{left:0}

[dir="rtl"] .popover{right:0}

.popover .arrow{position:absolute;display:block;width:1rem;height:.5rem;margin:0 .358rem}

.popover .arrow::before,.popover .arrow::after{position:absolute;display:block;content:"";border-color:transparent;border-style:solid}

.bs-popover-top,.b-popover-dark.bs-popover-auto[x-placement^=top],.b-popover-light.bs-popover-auto[x-placement^=top],.b-popover-danger.bs-popover-auto[x-placement^=top],.b-popover-warning.bs-popover-auto[x-placement^=top],.b-popover-info.bs-popover-auto[x-placement^=top],.b-popover-success.bs-popover-auto[x-placement^=top],.b-popover-secondary.bs-popover-auto[x-placement^=top],.b-popover-primary.bs-popover-auto[x-placement^=top],.bs-popover-auto[x-placement^=top]{margin-bottom:.5rem}

.bs-popover-top>.arrow,.bs-popover-auto[x-placement^=top]>.arrow{bottom:-0.5rem}

.bs-popover-top>.arrow::before,.bs-popover-auto[x-placement^=top]>.arrow::before{bottom:0;border-width:.5rem .5rem 0;border-top-color:#ebe9f1}

.bs-popover-top>.arrow::after,.bs-popover-auto[x-placement^=top]>.arrow::after{bottom:0;border-width:.5rem .5rem 0;border-top-color:#fff}

[dir="ltr"] .bs-popover-right,[dir="ltr"] .b-popover-dark.bs-popover-auto[x-placement^=right],[dir="ltr"] .b-popover-light.bs-popover-auto[x-placement^=right],[dir="ltr"] .b-popover-danger.bs-popover-auto[x-placement^=right],[dir="ltr"] .b-popover-warning.bs-popover-auto[x-placement^=right],[dir="ltr"] .b-popover-info.bs-popover-auto[x-placement^=right],[dir="ltr"] .b-popover-success.bs-popover-auto[x-placement^=right],[dir="ltr"] .b-popover-secondary.bs-popover-auto[x-placement^=right],[dir="ltr"] .b-popover-primary.bs-popover-auto[x-placement^=right],[dir="ltr"] .bs-popover-auto[x-placement^=right]{margin-left:.5rem}

[dir="rtl"] .bs-popover-right,[dir="rtl"] .b-popover-dark.bs-popover-auto[x-placement^=right],[dir="rtl"] .b-popover-light.bs-popover-auto[x-placement^=right],[dir="rtl"] .b-popover-danger.bs-popover-auto[x-placement^=right],[dir="rtl"] .b-popover-warning.bs-popover-auto[x-placement^=right],[dir="rtl"] .b-popover-info.bs-popover-auto[x-placement^=right],[dir="rtl"] .b-popover-success.bs-popover-auto[x-placement^=right],[dir="rtl"] .b-popover-secondary.bs-popover-auto[x-placement^=right],[dir="rtl"] .b-popover-primary.bs-popover-auto[x-placement^=right],[dir="rtl"] .bs-popover-auto[x-placement^=right]{margin-right:.5rem}

.bs-popover-right>.arrow,.bs-popover-auto[x-placement^=right]>.arrow{width:.5rem;height:1rem;margin:.358rem 0}

[dir="ltr"] .bs-popover-right>.arrow,[dir="ltr"] .bs-popover-auto[x-placement^=right]>.arrow{left:-0.5rem}

[dir="rtl"] .bs-popover-right>.arrow,[dir="rtl"] .bs-popover-auto[x-placement^=right]>.arrow{right:-0.5rem}

[dir="ltr"] .bs-popover-right>.arrow::before,[dir="ltr"] .bs-popover-auto[x-placement^=right]>.arrow::before{left:0;border-width:.5rem .5rem .5rem 0;border-right-color:#ebe9f1}

[dir="rtl"] .bs-popover-right>.arrow::before,[dir="rtl"] .bs-popover-auto[x-placement^=right]>.arrow::before{right:0;border-width:.5rem 0 .5rem .5rem;border-left-color:#ebe9f1}

[dir="ltr"] .bs-popover-right>.arrow::after,[dir="ltr"] .bs-popover-auto[x-placement^=right]>.arrow::after{left:0;border-width:.5rem .5rem .5rem 0;border-right-color:#fff}

[dir="rtl"] .bs-popover-right>.arrow::after,[dir="rtl"] .bs-popover-auto[x-placement^=right]>.arrow::after{right:0;border-width:.5rem 0 .5rem .5rem;border-left-color:#fff}

.bs-popover-bottom,.b-popover-dark.bs-popover-auto[x-placement^=bottom],.b-popover-light.bs-popover-auto[x-placement^=bottom],.b-popover-danger.bs-popover-auto[x-placement^=bottom],.b-popover-warning.bs-popover-auto[x-placement^=bottom],.b-popover-info.bs-popover-auto[x-placement^=bottom],.b-popover-success.bs-popover-auto[x-placement^=bottom],.b-popover-secondary.bs-popover-auto[x-placement^=bottom],.b-popover-primary.bs-popover-auto[x-placement^=bottom],.bs-popover-auto[x-placement^=bottom]{margin-top:.5rem}

.bs-popover-bottom>.arrow,.bs-popover-auto[x-placement^=bottom]>.arrow{top:-0.5rem}

.bs-popover-bottom>.arrow::before,.bs-popover-auto[x-placement^=bottom]>.arrow::before{top:0;border-width:0 .5rem .5rem .5rem;border-bottom-color:#ebe9f1}

.bs-popover-bottom>.arrow::after,.bs-popover-auto[x-placement^=bottom]>.arrow::after{top:0;border-width:0 .5rem .5rem .5rem;border-bottom-color:#fff}

.bs-popover-bottom .popover-header::before,.bs-popover-auto[x-placement^=bottom] .popover-header::before{position:absolute;top:0;display:block;width:1rem;content:"";border-bottom:0 solid #a94cd8}

[dir="ltr"] .bs-popover-bottom .popover-header::before,[dir="ltr"] .bs-popover-auto[x-placement^=bottom] .popover-header::before{left:50%;margin-left:-0.5rem}

[dir="rtl"] .bs-popover-bottom .popover-header::before,[dir="rtl"] .bs-popover-auto[x-placement^=bottom] .popover-header::before{right:50%;margin-right:-0.5rem}

[dir="ltr"] .bs-popover-left,[dir="ltr"] .b-popover-dark.bs-popover-auto[x-placement^=left],[dir="ltr"] .b-popover-light.bs-popover-auto[x-placement^=left],[dir="ltr"] .b-popover-danger.bs-popover-auto[x-placement^=left],[dir="ltr"] .b-popover-warning.bs-popover-auto[x-placement^=left],[dir="ltr"] .b-popover-info.bs-popover-auto[x-placement^=left],[dir="ltr"] .b-popover-success.bs-popover-auto[x-placement^=left],[dir="ltr"] .b-popover-secondary.bs-popover-auto[x-placement^=left],[dir="ltr"] .b-popover-primary.bs-popover-auto[x-placement^=left],[dir="ltr"] .bs-popover-auto[x-placement^=left]{margin-right:.5rem}

[dir="rtl"] .bs-popover-left,[dir="rtl"] .b-popover-dark.bs-popover-auto[x-placement^=left],[dir="rtl"] .b-popover-light.bs-popover-auto[x-placement^=left],[dir="rtl"] .b-popover-danger.bs-popover-auto[x-placement^=left],[dir="rtl"] .b-popover-warning.bs-popover-auto[x-placement^=left],[dir="rtl"] .b-popover-info.bs-popover-auto[x-placement^=left],[dir="rtl"] .b-popover-success.bs-popover-auto[x-placement^=left],[dir="rtl"] .b-popover-secondary.bs-popover-auto[x-placement^=left],[dir="rtl"] .b-popover-primary.bs-popover-auto[x-placement^=left],[dir="rtl"] .bs-popover-auto[x-placement^=left]{margin-left:.5rem}

.bs-popover-left>.arrow,.bs-popover-auto[x-placement^=left]>.arrow{width:.5rem;height:1rem;margin:.358rem 0}

[dir="ltr"] .bs-popover-left>.arrow,[dir="ltr"] .bs-popover-auto[x-placement^=left]>.arrow{right:-0.5rem}

[dir="rtl"] .bs-popover-left>.arrow,[dir="rtl"] .bs-popover-auto[x-placement^=left]>.arrow{left:-0.5rem}

[dir="ltr"] .bs-popover-left>.arrow::before,[dir="ltr"] .bs-popover-auto[x-placement^=left]>.arrow::before{right:0;border-width:.5rem 0 .5rem .5rem;border-left-color:#ebe9f1}

[dir="rtl"] .bs-popover-left>.arrow::before,[dir="rtl"] .bs-popover-auto[x-placement^=left]>.arrow::before{left:0;border-width:.5rem .5rem .5rem 0;border-right-color:#ebe9f1}

[dir="ltr"] .bs-popover-left>.arrow::after,[dir="ltr"] .bs-popover-auto[x-placement^=left]>.arrow::after{right:0;border-width:.5rem 0 .5rem .5rem;border-left-color:#fff}

[dir="rtl"] .bs-popover-left>.arrow::after,[dir="rtl"] .bs-popover-auto[x-placement^=left]>.arrow::after{left:0;border-width:.5rem .5rem .5rem 0;border-right-color:#fff}

.popover-header{padding:.65rem 1.21rem;margin-bottom:0;font-size:1rem;color:#fff;background-color:#a94cd8;border-bottom:0 solid #9f37d3;border-top-left-radius:.358rem;border-top-right-radius:.358rem}

.popover-header:empty{display:none}

.popover-body{padding:.65rem 1.21rem;color:#6e6b7b}

.carousel{position:relative}

.carousel.pointer-event{touch-action:pan-y}

.carousel-inner{position:relative;width:100%;overflow:hidden}

.carousel-inner::after{display:block;clear:both;content:""}

.carousel-item{position:relative;display:none;width:100%;backface-visibility:hidden;transition:transform .6s ease-in-out}

[dir="ltr"] .carousel-item{float:left;margin-right:-100%}

[dir="rtl"] .carousel-item{float:right;margin-left:-100%}

@media(prefers-reduced-motion: reduce){
.carousel-item{transition:none}}

.carousel-item.active,.carousel-item-next,.carousel-item-prev{display:block}

[dir="ltr"] .carousel-item-next:not(.carousel-item-left),[dir="ltr"] .active.carousel-item-right{transform:translateX(100%)}

[dir="rtl"] .carousel-item-next:not(.carousel-item-left),[dir="rtl"] .active.carousel-item-right{transform:translateX(-100%)}

[dir="ltr"] .carousel-item-prev:not(.carousel-item-right),[dir="ltr"] .active.carousel-item-left{transform:translateX(-100%)}

[dir="rtl"] .carousel-item-prev:not(.carousel-item-right),[dir="rtl"] .active.carousel-item-left{transform:translateX(100%)}

.carousel-fade .carousel-item{opacity:0;transition-property:opacity;transform:none}

.carousel-fade .carousel-item.active,.carousel-fade .carousel-item-next.carousel-item-left,.carousel-fade .carousel-item-prev.carousel-item-right{z-index:1;opacity:1}

.carousel-fade .active.carousel-item-left,.carousel-fade .active.carousel-item-right{z-index:0;opacity:0;transition:opacity 0s .6s}

@media(prefers-reduced-motion: reduce){
.carousel-fade .active.carousel-item-left,.carousel-fade .active.carousel-item-right{transition:none}}

.carousel-control-prev,.carousel-control-next{position:absolute;top:0;bottom:0;z-index:1;display:flex;align-items:center;justify-content:center;width:15%;color:#fff;text-align:center;opacity:.5;transition:opacity .15s ease}

@media(prefers-reduced-motion: reduce){
.carousel-control-prev,.carousel-control-next{transition:none}}

.carousel-control-prev:hover,.carousel-control-prev:focus,.carousel-control-next:hover,.carousel-control-next:focus{color:#fff;text-decoration:none;outline:0;opacity:.9}

[dir="ltr"] .carousel-control-prev{left:0}

[dir="rtl"] .carousel-control-prev{right:0}

[dir="ltr"] .carousel-control-next{right:0}

[dir="rtl"] .carousel-control-next{left:0}

.carousel-control-prev-icon,.carousel-control-next-icon{display:inline-block;width:20px;height:20px;background:50%/100% 100% no-repeat}

.carousel-control-prev-icon{background-image:url("data:image/svg+xml,%3csvg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"%23fff\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" class=\"feather feather-chevron-left\"%3e%3cpolyline points=\"15 18 9 12 15 6\"%3e%3c/polyline%3e%3c/svg%3e")}

.carousel-control-next-icon{background-image:url("data:image/svg+xml,%3csvg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"%23fff\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" class=\"feather feather-chevron-right\"%3e%3cpolyline points=\"9 18 15 12 9 6\"%3e%3c/polyline%3e%3c/svg%3e")}

.carousel-indicators{position:absolute;right:0;bottom:0;left:0;z-index:15;display:flex;justify-content:center;margin-right:15%;margin-left:15%;list-style:none}

[dir="ltr"] .carousel-indicators{padding-left:0}

[dir="rtl"] .carousel-indicators{padding-right:0}

.carousel-indicators li{box-sizing:content-box;flex:0 1 auto;width:30px;height:3px;margin-right:3px;margin-left:3px;text-indent:-999px;cursor:pointer;background-color:#fff;background-clip:padding-box;border-top:10px solid transparent;border-bottom:10px solid transparent;opacity:.5;transition:opacity .6s ease}

@media(prefers-reduced-motion: reduce){
.carousel-indicators li{transition:none}}

.carousel-indicators .active{opacity:1}

.carousel-caption{position:absolute;right:15%;bottom:20px;left:15%;z-index:10;padding-top:20px;padding-bottom:20px;color:#fff;text-align:center}

@keyframes spinner-border{
to{transform:rotate(360deg)}}

.spinner-border{display:inline-block;width:2rem;height:2rem;vertical-align:text-bottom;border:.25em solid currentColor;border-radius:50%;animation:.75s linear infinite spinner-border}

[dir="ltr"] .spinner-border{border-right-color:transparent}

[dir="rtl"] .spinner-border{border-left-color:transparent}

.spinner-border-sm{width:1rem;height:1rem;border-width:.2em}

@keyframes spinner-grow{
0%{transform:scale(0)}

50%{opacity:1;transform:none}}

.spinner-grow{display:inline-block;width:2rem;height:2rem;vertical-align:text-bottom;background-color:currentColor;border-radius:50%;opacity:0;animation:.75s linear infinite spinner-grow}

.spinner-grow-sm{width:1rem;height:1rem}

@media(prefers-reduced-motion: reduce){
.spinner-border,.spinner-grow{animation-duration:1.5s}}

.align-baseline{vertical-align:baseline !important}

.align-top{vertical-align:top !important}

.align-middle{vertical-align:middle !important}

.align-bottom{vertical-align:bottom !important}

.align-text-bottom{vertical-align:text-bottom !important}

.align-text-top{vertical-align:text-top !important}

.bg-primary{background-color:#a94cd8 !important}

a.bg-primary:hover,a.bg-primary:focus,button.bg-primary:hover,button.bg-primary:focus{background-color:#922bc6 !important}

.bg-secondary{background-color:#82868b !important}

a.bg-secondary:hover,a.bg-secondary:focus,button.bg-secondary:hover,button.bg-secondary:focus{background-color:#696d71 !important}

.bg-success{background-color:#28c76f !important}

a.bg-success:hover,a.bg-success:focus,button.bg-success:hover,button.bg-success:focus{background-color:#1f9d57 !important}

.bg-info{background-color:#00cfe8 !important}

a.bg-info:hover,a.bg-info:focus,button.bg-info:hover,button.bg-info:focus{background-color:#00a1b5 !important}

.bg-warning{background-color:#ff9f43 !important}

a.bg-warning:hover,a.bg-warning:focus,button.bg-warning:hover,button.bg-warning:focus{background-color:#ff8510 !important}

.bg-danger{background-color:#ea5455 !important}

a.bg-danger:hover,a.bg-danger:focus,button.bg-danger:hover,button.bg-danger:focus{background-color:#e42728 !important}

.bg-light{background-color:#f6f6f6 !important}

a.bg-light:hover,a.bg-light:focus,button.bg-light:hover,button.bg-light:focus{background-color:#ddd !important}

.bg-dark{background-color:#161d31 !important}

a.bg-dark:hover,a.bg-dark:focus,button.bg-dark:hover,button.bg-dark:focus{background-color:#06080e !important}

.bg-white{background-color:#fff !important}

.bg-transparent{background-color:transparent !important}

.border{border:1px solid #ebe9f1 !important}

.border-top{border-top:1px solid #ebe9f1 !important}

[dir="ltr"] .border-right{border-right:1px solid #ebe9f1 !important}

[dir="rtl"] .border-right{border-left:1px solid #ebe9f1 !important}

.border-bottom{border-bottom:1px solid #ebe9f1 !important}

[dir="ltr"] .border-left{border-left:1px solid #ebe9f1 !important}

[dir="rtl"] .border-left{border-right:1px solid #ebe9f1 !important}

.border-0{border:0 !important}

.border-top-0{border-top:0 !important}

[dir="ltr"] .border-right-0{border-right:0 !important}

[dir="rtl"] .border-right-0{border-left:0 !important}

.border-bottom-0{border-bottom:0 !important}

[dir="ltr"] .border-left-0{border-left:0 !important}

[dir="rtl"] .border-left-0{border-right:0 !important}

.border-primary{border-color:#a94cd8 !important}

.border-secondary{border-color:#82868b !important}

.border-success{border-color:#28c76f !important}

.border-info{border-color:#00cfe8 !important}

.border-warning{border-color:#ff9f43 !important}

.border-danger{border-color:#ea5455 !important}

.border-light{border-color:#f6f6f6 !important}

.border-dark{border-color:#161d31 !important}

.border-white{border-color:#fff !important}

.rounded-sm{border-radius:.25rem !important}

.rounded{border-radius:.357rem !important}

.rounded-top{border-top-left-radius:.357rem !important;border-top-right-radius:.357rem !important}

[dir="ltr"] .rounded-right{border-top-right-radius:.357rem !important;border-bottom-right-radius:.357rem !important}

[dir="rtl"] .rounded-right{border-top-left-radius:.357rem !important;border-bottom-left-radius:.357rem !important}

.rounded-bottom{border-bottom-right-radius:.357rem !important;border-bottom-left-radius:.357rem !important}

[dir="ltr"] .rounded-left{border-top-left-radius:.357rem !important;border-bottom-left-radius:.357rem !important}

[dir="rtl"] .rounded-left{border-top-right-radius:.357rem !important;border-bottom-right-radius:.357rem !important}

.rounded-lg{border-radius:.6rem !important}

.rounded-circle{border-radius:50% !important}

.rounded-pill{border-radius:50rem !important}

.rounded-0{border-radius:0 !important}

.clearfix::after{display:block;clear:both;content:""}

.d-none{display:none !important}

.d-inline{display:inline !important}

.d-inline-block{display:inline-block !important}

.d-block{display:block !important}

.d-table{display:table !important}

.d-table-row{display:table-row !important}

.d-table-cell{display:table-cell !important}

.d-flex{display:flex !important}

.d-inline-flex{display:inline-flex !important}

@media(min-width: 576px){
.d-sm-none{display:none !important}

.d-sm-inline{display:inline !important}

.d-sm-inline-block{display:inline-block !important}

.d-sm-block{display:block !important}

.d-sm-table{display:table !important}

.d-sm-table-row{display:table-row !important}

.d-sm-table-cell{display:table-cell !important}

.d-sm-flex{display:flex !important}

.d-sm-inline-flex{display:inline-flex !important}}

@media(min-width: 768px){
.d-md-none{display:none !important}

.d-md-inline{display:inline !important}

.d-md-inline-block{display:inline-block !important}

.d-md-block{display:block !important}

.d-md-table{display:table !important}

.d-md-table-row{display:table-row !important}

.d-md-table-cell{display:table-cell !important}

.d-md-flex{display:flex !important}

.d-md-inline-flex{display:inline-flex !important}}

@media(min-width: 992px){
.d-lg-none{display:none !important}

.d-lg-inline{display:inline !important}

.d-lg-inline-block{display:inline-block !important}

.d-lg-block{display:block !important}

.d-lg-table{display:table !important}

.d-lg-table-row{display:table-row !important}

.d-lg-table-cell{display:table-cell !important}

.d-lg-flex{display:flex !important}

.d-lg-inline-flex{display:inline-flex !important}}

@media(min-width: 1200px){
.d-xl-none{display:none !important}

.d-xl-inline{display:inline !important}

.d-xl-inline-block{display:inline-block !important}

.d-xl-block{display:block !important}

.d-xl-table{display:table !important}

.d-xl-table-row{display:table-row !important}

.d-xl-table-cell{display:table-cell !important}

.d-xl-flex{display:flex !important}

.d-xl-inline-flex{display:inline-flex !important}}

@media print{
.d-print-none{display:none !important}

.d-print-inline{display:inline !important}

.d-print-inline-block{display:inline-block !important}

.d-print-block{display:block !important}

.d-print-table{display:table !important}

.d-print-table-row{display:table-row !important}

.d-print-table-cell{display:table-cell !important}

.d-print-flex{display:flex !important}

.d-print-inline-flex{display:inline-flex !important}}

.embed-responsive{position:relative;display:block;width:100%;padding:0;overflow:hidden}

.embed-responsive::before{display:block;content:""}

.embed-responsive .embed-responsive-item,.embed-responsive iframe,.embed-responsive embed,.embed-responsive object,.embed-responsive video{position:absolute;top:0;bottom:0;width:100%;height:100%;border:0}

[dir="ltr"] .embed-responsive .embed-responsive-item,[dir="ltr"] .embed-responsive iframe,[dir="ltr"] .embed-responsive embed,[dir="ltr"] .embed-responsive object,[dir="ltr"] .embed-responsive video{left:0}

[dir="rtl"] .embed-responsive .embed-responsive-item,[dir="rtl"] .embed-responsive iframe,[dir="rtl"] .embed-responsive embed,[dir="rtl"] .embed-responsive object,[dir="rtl"] .embed-responsive video{right:0}

.embed-responsive-21by9::before{padding-top:42.8571428571%}

.embed-responsive-16by9::before{padding-top:56.25%}

.embed-responsive-4by3::before{padding-top:75%}

.embed-responsive-1by1::before{padding-top:100%}

.embed-responsive-21by9::before{padding-top:42.8571428571%}

.embed-responsive-16by9::before{padding-top:56.25%}

.embed-responsive-4by3::before{padding-top:75%}

.embed-responsive-1by1::before{padding-top:100%}

.flex-row{flex-direction:row !important}

.flex-column{flex-direction:column !important}

.flex-row-reverse{flex-direction:row-reverse !important}

.flex-column-reverse{flex-direction:column-reverse !important}

.flex-wrap{flex-wrap:wrap !important}

.flex-nowrap{flex-wrap:nowrap !important}

.flex-wrap-reverse{flex-wrap:wrap-reverse !important}

.flex-fill{flex:1 1 auto !important}

.flex-grow-0{flex-grow:0 !important}

.flex-grow-1{flex-grow:1 !important}

.flex-shrink-0{flex-shrink:0 !important}

.flex-shrink-1{flex-shrink:1 !important}

.justify-content-start{justify-content:flex-start !important}

.justify-content-end{justify-content:flex-end !important}

.justify-content-center{justify-content:center !important}

.justify-content-between{justify-content:space-between !important}

.justify-content-around{justify-content:space-around !important}

.align-items-start{align-items:flex-start !important}

.align-items-end{align-items:flex-end !important}

.align-items-center{align-items:center !important}

.align-items-baseline{align-items:baseline !important}

.align-items-stretch{align-items:stretch !important}

.align-content-start{align-content:flex-start !important}

.align-content-end{align-content:flex-end !important}

.align-content-center{align-content:center !important}

.align-content-between{align-content:space-between !important}

.align-content-around{align-content:space-around !important}

.align-content-stretch{align-content:stretch !important}

.align-self-auto{align-self:auto !important}

.align-self-start{align-self:flex-start !important}

.align-self-end{align-self:flex-end !important}

.align-self-center{align-self:center !important}

.align-self-baseline{align-self:baseline !important}

.align-self-stretch{align-self:stretch !important}

@media(min-width: 576px){
.flex-sm-row{flex-direction:row !important}

.flex-sm-column{flex-direction:column !important}

.flex-sm-row-reverse{flex-direction:row-reverse !important}

.flex-sm-column-reverse{flex-direction:column-reverse !important}

.flex-sm-wrap{flex-wrap:wrap !important}

.flex-sm-nowrap{flex-wrap:nowrap !important}

.flex-sm-wrap-reverse{flex-wrap:wrap-reverse !important}

.flex-sm-fill{flex:1 1 auto !important}

.flex-sm-grow-0{flex-grow:0 !important}

.flex-sm-grow-1{flex-grow:1 !important}

.flex-sm-shrink-0{flex-shrink:0 !important}

.flex-sm-shrink-1{flex-shrink:1 !important}

.justify-content-sm-start{justify-content:flex-start !important}

.justify-content-sm-end{justify-content:flex-end !important}

.justify-content-sm-center{justify-content:center !important}

.justify-content-sm-between{justify-content:space-between !important}

.justify-content-sm-around{justify-content:space-around !important}

.align-items-sm-start{align-items:flex-start !important}

.align-items-sm-end{align-items:flex-end !important}

.align-items-sm-center{align-items:center !important}

.align-items-sm-baseline{align-items:baseline !important}

.align-items-sm-stretch{align-items:stretch !important}

.align-content-sm-start{align-content:flex-start !important}

.align-content-sm-end{align-content:flex-end !important}

.align-content-sm-center{align-content:center !important}

.align-content-sm-between{align-content:space-between !important}

.align-content-sm-around{align-content:space-around !important}

.align-content-sm-stretch{align-content:stretch !important}

.align-self-sm-auto{align-self:auto !important}

.align-self-sm-start{align-self:flex-start !important}

.align-self-sm-end{align-self:flex-end !important}

.align-self-sm-center{align-self:center !important}

.align-self-sm-baseline{align-self:baseline !important}

.align-self-sm-stretch{align-self:stretch !important}}

@media(min-width: 768px){
.flex-md-row{flex-direction:row !important}

.flex-md-column{flex-direction:column !important}

.flex-md-row-reverse{flex-direction:row-reverse !important}

.flex-md-column-reverse{flex-direction:column-reverse !important}

.flex-md-wrap{flex-wrap:wrap !important}

.flex-md-nowrap{flex-wrap:nowrap !important}

.flex-md-wrap-reverse{flex-wrap:wrap-reverse !important}

.flex-md-fill{flex:1 1 auto !important}

.flex-md-grow-0{flex-grow:0 !important}

.flex-md-grow-1{flex-grow:1 !important}

.flex-md-shrink-0{flex-shrink:0 !important}

.flex-md-shrink-1{flex-shrink:1 !important}

.justify-content-md-start{justify-content:flex-start !important}

.justify-content-md-end{justify-content:flex-end !important}

.justify-content-md-center{justify-content:center !important}

.justify-content-md-between{justify-content:space-between !important}

.justify-content-md-around{justify-content:space-around !important}

.align-items-md-start{align-items:flex-start !important}

.align-items-md-end{align-items:flex-end !important}

.align-items-md-center{align-items:center !important}

.align-items-md-baseline{align-items:baseline !important}

.align-items-md-stretch{align-items:stretch !important}

.align-content-md-start{align-content:flex-start !important}

.align-content-md-end{align-content:flex-end !important}

.align-content-md-center{align-content:center !important}

.align-content-md-between{align-content:space-between !important}

.align-content-md-around{align-content:space-around !important}

.align-content-md-stretch{align-content:stretch !important}

.align-self-md-auto{align-self:auto !important}

.align-self-md-start{align-self:flex-start !important}

.align-self-md-end{align-self:flex-end !important}

.align-self-md-center{align-self:center !important}

.align-self-md-baseline{align-self:baseline !important}

.align-self-md-stretch{align-self:stretch !important}}

@media(min-width: 992px){
.flex-lg-row{flex-direction:row !important}

.flex-lg-column{flex-direction:column !important}

.flex-lg-row-reverse{flex-direction:row-reverse !important}

.flex-lg-column-reverse{flex-direction:column-reverse !important}

.flex-lg-wrap{flex-wrap:wrap !important}

.flex-lg-nowrap{flex-wrap:nowrap !important}

.flex-lg-wrap-reverse{flex-wrap:wrap-reverse !important}

.flex-lg-fill{flex:1 1 auto !important}

.flex-lg-grow-0{flex-grow:0 !important}

.flex-lg-grow-1{flex-grow:1 !important}

.flex-lg-shrink-0{flex-shrink:0 !important}

.flex-lg-shrink-1{flex-shrink:1 !important}

.justify-content-lg-start{justify-content:flex-start !important}

.justify-content-lg-end{justify-content:flex-end !important}

.justify-content-lg-center{justify-content:center !important}

.justify-content-lg-between{justify-content:space-between !important}

.justify-content-lg-around{justify-content:space-around !important}

.align-items-lg-start{align-items:flex-start !important}

.align-items-lg-end{align-items:flex-end !important}

.align-items-lg-center{align-items:center !important}

.align-items-lg-baseline{align-items:baseline !important}

.align-items-lg-stretch{align-items:stretch !important}

.align-content-lg-start{align-content:flex-start !important}

.align-content-lg-end{align-content:flex-end !important}

.align-content-lg-center{align-content:center !important}

.align-content-lg-between{align-content:space-between !important}

.align-content-lg-around{align-content:space-around !important}

.align-content-lg-stretch{align-content:stretch !important}

.align-self-lg-auto{align-self:auto !important}

.align-self-lg-start{align-self:flex-start !important}

.align-self-lg-end{align-self:flex-end !important}

.align-self-lg-center{align-self:center !important}

.align-self-lg-baseline{align-self:baseline !important}

.align-self-lg-stretch{align-self:stretch !important}}

@media(min-width: 1200px){
.flex-xl-row{flex-direction:row !important}

.flex-xl-column{flex-direction:column !important}

.flex-xl-row-reverse{flex-direction:row-reverse !important}

.flex-xl-column-reverse{flex-direction:column-reverse !important}

.flex-xl-wrap{flex-wrap:wrap !important}

.flex-xl-nowrap{flex-wrap:nowrap !important}

.flex-xl-wrap-reverse{flex-wrap:wrap-reverse !important}

.flex-xl-fill{flex:1 1 auto !important}

.flex-xl-grow-0{flex-grow:0 !important}

.flex-xl-grow-1{flex-grow:1 !important}

.flex-xl-shrink-0{flex-shrink:0 !important}

.flex-xl-shrink-1{flex-shrink:1 !important}

.justify-content-xl-start{justify-content:flex-start !important}

.justify-content-xl-end{justify-content:flex-end !important}

.justify-content-xl-center{justify-content:center !important}

.justify-content-xl-between{justify-content:space-between !important}

.justify-content-xl-around{justify-content:space-around !important}

.align-items-xl-start{align-items:flex-start !important}

.align-items-xl-end{align-items:flex-end !important}

.align-items-xl-center{align-items:center !important}

.align-items-xl-baseline{align-items:baseline !important}

.align-items-xl-stretch{align-items:stretch !important}

.align-content-xl-start{align-content:flex-start !important}

.align-content-xl-end{align-content:flex-end !important}

.align-content-xl-center{align-content:center !important}

.align-content-xl-between{align-content:space-between !important}

.align-content-xl-around{align-content:space-around !important}

.align-content-xl-stretch{align-content:stretch !important}

.align-self-xl-auto{align-self:auto !important}

.align-self-xl-start{align-self:flex-start !important}

.align-self-xl-end{align-self:flex-end !important}

.align-self-xl-center{align-self:center !important}

.align-self-xl-baseline{align-self:baseline !important}

.align-self-xl-stretch{align-self:stretch !important}}

[dir="ltr"] .float-left{float:left !important}

[dir="rtl"] .float-left{float:right !important}

[dir="ltr"] .float-right{float:right !important}

[dir="rtl"] .float-right{float:left !important}

.float-none{float:none !important}

@media(min-width: 576px){
[dir="ltr"] .float-sm-left{float:left !important}

[dir="rtl"] .float-sm-left{float:right !important}

[dir="ltr"] .float-sm-right{float:right !important}

[dir="rtl"] .float-sm-right{float:left !important}

.float-sm-none{float:none !important}}

@media(min-width: 768px){
[dir="ltr"] .float-md-left{float:left !important}

[dir="rtl"] .float-md-left{float:right !important}

[dir="ltr"] .float-md-right{float:right !important}

[dir="rtl"] .float-md-right{float:left !important}

.float-md-none{float:none !important}}

@media(min-width: 992px){
[dir="ltr"] .float-lg-left{float:left !important}

[dir="rtl"] .float-lg-left{float:right !important}

[dir="ltr"] .float-lg-right{float:right !important}

[dir="rtl"] .float-lg-right{float:left !important}

.float-lg-none{float:none !important}}

@media(min-width: 1200px){
[dir="ltr"] .float-xl-left{float:left !important}

[dir="rtl"] .float-xl-left{float:right !important}

[dir="ltr"] .float-xl-right{float:right !important}

[dir="rtl"] .float-xl-right{float:left !important}

.float-xl-none{float:none !important}}

.user-select-all{user-select:all !important}

.user-select-auto{user-select:auto !important}

.user-select-none{user-select:none !important}

.overflow-auto{overflow:auto !important}

.overflow-hidden{overflow:hidden !important}

.position-static{position:static !important}

.position-relative{position:relative !important}

.position-absolute{position:absolute !important}

.position-fixed{position:fixed !important}

.position-sticky{position:sticky !important}

.fixed-top{position:fixed;top:0;right:0;left:0;z-index:1030}

.fixed-bottom{position:fixed;right:0;bottom:0;left:0;z-index:1030}

@supports(position: sticky){
.sticky-top{position:sticky;top:0;z-index:1020}}

.sr-only{position:absolute;width:1px;height:1px;padding:0;margin:-1px;overflow:hidden;clip:rect(0, 0, 0, 0);white-space:nowrap;border:0}

.sr-only-focusable:active,.sr-only-focusable:focus{position:static;width:auto;height:auto;overflow:visible;clip:auto;white-space:normal}

.shadow-sm{box-shadow:0 .125rem .25rem rgba(34,41,47,.075) !important}

.shadow{box-shadow:0 4px 24px 0 rgba(34,41,47,.1) !important}

.shadow-lg{box-shadow:0 1rem 3rem rgba(34,41,47,.175) !important}

.shadow-none{box-shadow:none !important}

.w-25{width:25% !important}

.w-50{width:50% !important}

.w-75{width:75% !important}

.w-100{width:100% !important}

.w-auto{width:auto !important}

.h-25{height:25% !important}

.h-50{height:50% !important}

.h-75{height:75% !important}

.h-100{height:100% !important}

.h-auto{height:auto !important}

.mw-100{max-width:100% !important}

.mh-100{max-height:100% !important}

.min-vw-100{min-width:100vw !important}

.min-vh-100{min-height:100vh !important}

.vw-100{width:100vw !important}

.vh-100{height:100vh !important}

.m-0{margin:0 !important}

.mt-0,.my-0{margin-top:0 !important}

[dir="ltr"] .mr-0,[dir="ltr"] .mx-0{margin-right:0 !important}

[dir="rtl"] .mr-0,[dir="rtl"] .mx-0{margin-left:0 !important}

.mb-0,.my-0{margin-bottom:0 !important}

[dir="ltr"] .ml-0,[dir="ltr"] .mx-0{margin-left:0 !important}

[dir="rtl"] .ml-0,[dir="rtl"] .mx-0{margin-right:0 !important}

.m-1{margin:1rem !important}

.mt-1,.my-1{margin-top:1rem !important}

[dir="ltr"] .mr-1,[dir="ltr"] .mx-1{margin-right:1rem !important}

[dir="rtl"] .mr-1,[dir="rtl"] .mx-1{margin-left:1rem !important}

.mb-1,.my-1{margin-bottom:1rem !important}

[dir="ltr"] .ml-1,[dir="ltr"] .mx-1{margin-left:1rem !important}

[dir="rtl"] .ml-1,[dir="rtl"] .mx-1{margin-right:1rem !important}

.m-2{margin:1.5rem !important}

.mt-2,.my-2{margin-top:1.5rem !important}

[dir="ltr"] .mr-2,[dir="ltr"] .mx-2{margin-right:1.5rem !important}

[dir="rtl"] .mr-2,[dir="rtl"] .mx-2{margin-left:1.5rem !important}

.mb-2,.my-2{margin-bottom:1.5rem !important}

[dir="ltr"] .ml-2,[dir="ltr"] .mx-2{margin-left:1.5rem !important}

[dir="rtl"] .ml-2,[dir="rtl"] .mx-2{margin-right:1.5rem !important}

.m-3{margin:3rem !important}

.mt-3,.my-3{margin-top:3rem !important}

[dir="ltr"] .mr-3,[dir="ltr"] .mx-3{margin-right:3rem !important}

[dir="rtl"] .mr-3,[dir="rtl"] .mx-3{margin-left:3rem !important}

.mb-3,.my-3{margin-bottom:3rem !important}

[dir="ltr"] .ml-3,[dir="ltr"] .mx-3{margin-left:3rem !important}

[dir="rtl"] .ml-3,[dir="rtl"] .mx-3{margin-right:3rem !important}

.m-4{margin:3.5rem !important}

.mt-4,.my-4{margin-top:3.5rem !important}

[dir="ltr"] .mr-4,[dir="ltr"] .mx-4{margin-right:3.5rem !important}

[dir="rtl"] .mr-4,[dir="rtl"] .mx-4{margin-left:3.5rem !important}

.mb-4,.my-4{margin-bottom:3.5rem !important}

[dir="ltr"] .ml-4,[dir="ltr"] .mx-4{margin-left:3.5rem !important}

[dir="rtl"] .ml-4,[dir="rtl"] .mx-4{margin-right:3.5rem !important}

.m-5{margin:4rem !important}

.mt-5,.my-5{margin-top:4rem !important}

[dir="ltr"] .mr-5,[dir="ltr"] .mx-5{margin-right:4rem !important}

[dir="rtl"] .mr-5,[dir="rtl"] .mx-5{margin-left:4rem !important}

.mb-5,.my-5{margin-bottom:4rem !important}

[dir="ltr"] .ml-5,[dir="ltr"] .mx-5{margin-left:4rem !important}

[dir="rtl"] .ml-5,[dir="rtl"] .mx-5{margin-right:4rem !important}

.m-25{margin:.25rem !important}

.mt-25,.my-25{margin-top:.25rem !important}

[dir="ltr"] .mr-25,[dir="ltr"] .mx-25{margin-right:.25rem !important}

[dir="rtl"] .mr-25,[dir="rtl"] .mx-25{margin-left:.25rem !important}

.mb-25,.my-25{margin-bottom:.25rem !important}

[dir="ltr"] .ml-25,[dir="ltr"] .mx-25{margin-left:.25rem !important}

[dir="rtl"] .ml-25,[dir="rtl"] .mx-25{margin-right:.25rem !important}

.m-50{margin:.5rem !important}

.mt-50,.my-50{margin-top:.5rem !important}

[dir="ltr"] .mr-50,[dir="ltr"] .mx-50{margin-right:.5rem !important}

[dir="rtl"] .mr-50,[dir="rtl"] .mx-50{margin-left:.5rem !important}

.mb-50,.my-50{margin-bottom:.5rem !important}

[dir="ltr"] .ml-50,[dir="ltr"] .mx-50{margin-left:.5rem !important}

[dir="rtl"] .ml-50,[dir="rtl"] .mx-50{margin-right:.5rem !important}

.m-75{margin:.75rem !important}

.mt-75,.my-75{margin-top:.75rem !important}

[dir="ltr"] .mr-75,[dir="ltr"] .mx-75{margin-right:.75rem !important}

[dir="rtl"] .mr-75,[dir="rtl"] .mx-75{margin-left:.75rem !important}

.mb-75,.my-75{margin-bottom:.75rem !important}

[dir="ltr"] .ml-75,[dir="ltr"] .mx-75{margin-left:.75rem !important}

[dir="rtl"] .ml-75,[dir="rtl"] .mx-75{margin-right:.75rem !important}

.p-0{padding:0 !important}

.pt-0,.py-0{padding-top:0 !important}

[dir="ltr"] .pr-0,[dir="ltr"] .px-0{padding-right:0 !important}

[dir="rtl"] .pr-0,[dir="rtl"] .px-0{padding-left:0 !important}

.pb-0,.py-0{padding-bottom:0 !important}

[dir="ltr"] .pl-0,[dir="ltr"] .px-0{padding-left:0 !important}

[dir="rtl"] .pl-0,[dir="rtl"] .px-0{padding-right:0 !important}

.p-1{padding:1rem !important}

.pt-1,.py-1{padding-top:1rem !important}

[dir="ltr"] .pr-1,[dir="ltr"] .px-1{padding-right:1rem !important}

[dir="rtl"] .pr-1,[dir="rtl"] .px-1{padding-left:1rem !important}

.pb-1,.py-1{padding-bottom:1rem !important}

[dir="ltr"] .pl-1,[dir="ltr"] .px-1{padding-left:1rem !important}

[dir="rtl"] .pl-1,[dir="rtl"] .px-1{padding-right:1rem !important}

.p-2{padding:1.5rem !important}

.pt-2,.py-2{padding-top:1.5rem !important}

[dir="ltr"] .pr-2,[dir="ltr"] .px-2{padding-right:1.5rem !important}

[dir="rtl"] .pr-2,[dir="rtl"] .px-2{padding-left:1.5rem !important}

.pb-2,.py-2{padding-bottom:1.5rem !important}

[dir="ltr"] .pl-2,[dir="ltr"] .px-2{padding-left:1.5rem !important}

[dir="rtl"] .pl-2,[dir="rtl"] .px-2{padding-right:1.5rem !important}

.p-3{padding:3rem !important}

.pt-3,.py-3{padding-top:3rem !important}

[dir="ltr"] .pr-3,[dir="ltr"] .px-3{padding-right:3rem !important}

[dir="rtl"] .pr-3,[dir="rtl"] .px-3{padding-left:3rem !important}

.pb-3,.py-3{padding-bottom:3rem !important}

[dir="ltr"] .pl-3,[dir="ltr"] .px-3{padding-left:3rem !important}

[dir="rtl"] .pl-3,[dir="rtl"] .px-3{padding-right:3rem !important}

.p-4{padding:3.5rem !important}

.pt-4,.py-4{padding-top:3.5rem !important}

[dir="ltr"] .pr-4,[dir="ltr"] .px-4{padding-right:3.5rem !important}

[dir="rtl"] .pr-4,[dir="rtl"] .px-4{padding-left:3.5rem !important}

.pb-4,.py-4{padding-bottom:3.5rem !important}

[dir="ltr"] .pl-4,[dir="ltr"] .px-4{padding-left:3.5rem !important}

[dir="rtl"] .pl-4,[dir="rtl"] .px-4{padding-right:3.5rem !important}

.p-5{padding:4rem !important}

.pt-5,.py-5{padding-top:4rem !important}

[dir="ltr"] .pr-5,[dir="ltr"] .px-5{padding-right:4rem !important}

[dir="rtl"] .pr-5,[dir="rtl"] .px-5{padding-left:4rem !important}

.pb-5,.py-5{padding-bottom:4rem !important}

[dir="ltr"] .pl-5,[dir="ltr"] .px-5{padding-left:4rem !important}

[dir="rtl"] .pl-5,[dir="rtl"] .px-5{padding-right:4rem !important}

.p-25{padding:.25rem !important}

.pt-25,.py-25{padding-top:.25rem !important}

[dir="ltr"] .pr-25,[dir="ltr"] .px-25{padding-right:.25rem !important}

[dir="rtl"] .pr-25,[dir="rtl"] .px-25{padding-left:.25rem !important}

.pb-25,.py-25{padding-bottom:.25rem !important}

[dir="ltr"] .pl-25,[dir="ltr"] .px-25{padding-left:.25rem !important}

[dir="rtl"] .pl-25,[dir="rtl"] .px-25{padding-right:.25rem !important}

.p-50{padding:.5rem !important}

.pt-50,.py-50{padding-top:.5rem !important}

[dir="ltr"] .pr-50,[dir="ltr"] .px-50{padding-right:.5rem !important}

[dir="rtl"] .pr-50,[dir="rtl"] .px-50{padding-left:.5rem !important}

.pb-50,.py-50{padding-bottom:.5rem !important}

[dir="ltr"] .pl-50,[dir="ltr"] .px-50{padding-left:.5rem !important}

[dir="rtl"] .pl-50,[dir="rtl"] .px-50{padding-right:.5rem !important}

.p-75{padding:.75rem !important}

.pt-75,.py-75{padding-top:.75rem !important}

[dir="ltr"] .pr-75,[dir="ltr"] .px-75{padding-right:.75rem !important}

[dir="rtl"] .pr-75,[dir="rtl"] .px-75{padding-left:.75rem !important}

.pb-75,.py-75{padding-bottom:.75rem !important}

[dir="ltr"] .pl-75,[dir="ltr"] .px-75{padding-left:.75rem !important}

[dir="rtl"] .pl-75,[dir="rtl"] .px-75{padding-right:.75rem !important}

.m-n1{margin:-1rem !important}

.mt-n1,.my-n1{margin-top:-1rem !important}

[dir="ltr"] .mr-n1,[dir="ltr"] .mx-n1{margin-right:-1rem !important}

[dir="rtl"] .mr-n1,[dir="rtl"] .mx-n1{margin-left:-1rem !important}

.mb-n1,.my-n1{margin-bottom:-1rem !important}

[dir="ltr"] .ml-n1,[dir="ltr"] .mx-n1{margin-left:-1rem !important}

[dir="rtl"] .ml-n1,[dir="rtl"] .mx-n1{margin-right:-1rem !important}

.m-n2{margin:-1.5rem !important}

.mt-n2,.my-n2{margin-top:-1.5rem !important}

[dir="ltr"] .mr-n2,[dir="ltr"] .mx-n2{margin-right:-1.5rem !important}

[dir="rtl"] .mr-n2,[dir="rtl"] .mx-n2{margin-left:-1.5rem !important}

.mb-n2,.my-n2{margin-bottom:-1.5rem !important}

[dir="ltr"] .ml-n2,[dir="ltr"] .mx-n2{margin-left:-1.5rem !important}

[dir="rtl"] .ml-n2,[dir="rtl"] .mx-n2{margin-right:-1.5rem !important}

.m-n3{margin:-3rem !important}

.mt-n3,.my-n3{margin-top:-3rem !important}

[dir="ltr"] .mr-n3,[dir="ltr"] .mx-n3{margin-right:-3rem !important}

[dir="rtl"] .mr-n3,[dir="rtl"] .mx-n3{margin-left:-3rem !important}

.mb-n3,.my-n3{margin-bottom:-3rem !important}

[dir="ltr"] .ml-n3,[dir="ltr"] .mx-n3{margin-left:-3rem !important}

[dir="rtl"] .ml-n3,[dir="rtl"] .mx-n3{margin-right:-3rem !important}

.m-n4{margin:-3.5rem !important}

.mt-n4,.my-n4{margin-top:-3.5rem !important}

[dir="ltr"] .mr-n4,[dir="ltr"] .mx-n4{margin-right:-3.5rem !important}

[dir="rtl"] .mr-n4,[dir="rtl"] .mx-n4{margin-left:-3.5rem !important}

.mb-n4,.my-n4{margin-bottom:-3.5rem !important}

[dir="ltr"] .ml-n4,[dir="ltr"] .mx-n4{margin-left:-3.5rem !important}

[dir="rtl"] .ml-n4,[dir="rtl"] .mx-n4{margin-right:-3.5rem !important}

.m-n5{margin:-4rem !important}

.mt-n5,.my-n5{margin-top:-4rem !important}

[dir="ltr"] .mr-n5,[dir="ltr"] .mx-n5{margin-right:-4rem !important}

[dir="rtl"] .mr-n5,[dir="rtl"] .mx-n5{margin-left:-4rem !important}

.mb-n5,.my-n5{margin-bottom:-4rem !important}

[dir="ltr"] .ml-n5,[dir="ltr"] .mx-n5{margin-left:-4rem !important}

[dir="rtl"] .ml-n5,[dir="rtl"] .mx-n5{margin-right:-4rem !important}

.m-n25{margin:-0.25rem !important}

.mt-n25,.my-n25{margin-top:-0.25rem !important}

[dir="ltr"] .mr-n25,[dir="ltr"] .mx-n25{margin-right:-0.25rem !important}

[dir="rtl"] .mr-n25,[dir="rtl"] .mx-n25{margin-left:-0.25rem !important}

.mb-n25,.my-n25{margin-bottom:-0.25rem !important}

[dir="ltr"] .ml-n25,[dir="ltr"] .mx-n25{margin-left:-0.25rem !important}

[dir="rtl"] .ml-n25,[dir="rtl"] .mx-n25{margin-right:-0.25rem !important}

.m-n50{margin:-0.5rem !important}

.mt-n50,.my-n50{margin-top:-0.5rem !important}

[dir="ltr"] .mr-n50,[dir="ltr"] .mx-n50{margin-right:-0.5rem !important}

[dir="rtl"] .mr-n50,[dir="rtl"] .mx-n50{margin-left:-0.5rem !important}

.mb-n50,.my-n50{margin-bottom:-0.5rem !important}

[dir="ltr"] .ml-n50,[dir="ltr"] .mx-n50{margin-left:-0.5rem !important}

[dir="rtl"] .ml-n50,[dir="rtl"] .mx-n50{margin-right:-0.5rem !important}

.m-n75{margin:-0.75rem !important}

.mt-n75,.my-n75{margin-top:-0.75rem !important}

[dir="ltr"] .mr-n75,[dir="ltr"] .mx-n75{margin-right:-0.75rem !important}

[dir="rtl"] .mr-n75,[dir="rtl"] .mx-n75{margin-left:-0.75rem !important}

.mb-n75,.my-n75{margin-bottom:-0.75rem !important}

[dir="ltr"] .ml-n75,[dir="ltr"] .mx-n75{margin-left:-0.75rem !important}

[dir="rtl"] .ml-n75,[dir="rtl"] .mx-n75{margin-right:-0.75rem !important}

.m-auto{margin:auto !important}

.mt-auto,.my-auto{margin-top:auto !important}

[dir="ltr"] .mr-auto,[dir="ltr"] .mx-auto{margin-right:auto !important}

[dir="rtl"] .mr-auto,[dir="rtl"] .mx-auto{margin-left:auto !important}

.mb-auto,.my-auto{margin-bottom:auto !important}

[dir="ltr"] .ml-auto,[dir="ltr"] .mx-auto{margin-left:auto !important}

[dir="rtl"] .ml-auto,[dir="rtl"] .mx-auto{margin-right:auto !important}

@media(min-width: 576px){
.m-sm-0{margin:0 !important}

.mt-sm-0,.my-sm-0{margin-top:0 !important}

[dir="ltr"] .mr-sm-0,[dir="ltr"] .mx-sm-0{margin-right:0 !important}

[dir="rtl"] .mr-sm-0,[dir="rtl"] .mx-sm-0{margin-left:0 !important}

.mb-sm-0,.my-sm-0{margin-bottom:0 !important}

[dir="ltr"] .ml-sm-0,[dir="ltr"] .mx-sm-0{margin-left:0 !important}

[dir="rtl"] .ml-sm-0,[dir="rtl"] .mx-sm-0{margin-right:0 !important}

.m-sm-1{margin:1rem !important}

.mt-sm-1,.my-sm-1{margin-top:1rem !important}

[dir="ltr"] .mr-sm-1,[dir="ltr"] .mx-sm-1{margin-right:1rem !important}

[dir="rtl"] .mr-sm-1,[dir="rtl"] .mx-sm-1{margin-left:1rem !important}

.mb-sm-1,.my-sm-1{margin-bottom:1rem !important}

[dir="ltr"] .ml-sm-1,[dir="ltr"] .mx-sm-1{margin-left:1rem !important}

[dir="rtl"] .ml-sm-1,[dir="rtl"] .mx-sm-1{margin-right:1rem !important}

.m-sm-2{margin:1.5rem !important}

.mt-sm-2,.my-sm-2{margin-top:1.5rem !important}

[dir="ltr"] .mr-sm-2,[dir="ltr"] .mx-sm-2{margin-right:1.5rem !important}

[dir="rtl"] .mr-sm-2,[dir="rtl"] .mx-sm-2{margin-left:1.5rem !important}

.mb-sm-2,.my-sm-2{margin-bottom:1.5rem !important}

[dir="ltr"] .ml-sm-2,[dir="ltr"] .mx-sm-2{margin-left:1.5rem !important}

[dir="rtl"] .ml-sm-2,[dir="rtl"] .mx-sm-2{margin-right:1.5rem !important}

.m-sm-3{margin:3rem !important}

.mt-sm-3,.my-sm-3{margin-top:3rem !important}

[dir="ltr"] .mr-sm-3,[dir="ltr"] .mx-sm-3{margin-right:3rem !important}

[dir="rtl"] .mr-sm-3,[dir="rtl"] .mx-sm-3{margin-left:3rem !important}

.mb-sm-3,.my-sm-3{margin-bottom:3rem !important}

[dir="ltr"] .ml-sm-3,[dir="ltr"] .mx-sm-3{margin-left:3rem !important}

[dir="rtl"] .ml-sm-3,[dir="rtl"] .mx-sm-3{margin-right:3rem !important}

.m-sm-4{margin:3.5rem !important}

.mt-sm-4,.my-sm-4{margin-top:3.5rem !important}

[dir="ltr"] .mr-sm-4,[dir="ltr"] .mx-sm-4{margin-right:3.5rem !important}

[dir="rtl"] .mr-sm-4,[dir="rtl"] .mx-sm-4{margin-left:3.5rem !important}

.mb-sm-4,.my-sm-4{margin-bottom:3.5rem !important}

[dir="ltr"] .ml-sm-4,[dir="ltr"] .mx-sm-4{margin-left:3.5rem !important}

[dir="rtl"] .ml-sm-4,[dir="rtl"] .mx-sm-4{margin-right:3.5rem !important}

.m-sm-5{margin:4rem !important}

.mt-sm-5,.my-sm-5{margin-top:4rem !important}

[dir="ltr"] .mr-sm-5,[dir="ltr"] .mx-sm-5{margin-right:4rem !important}

[dir="rtl"] .mr-sm-5,[dir="rtl"] .mx-sm-5{margin-left:4rem !important}

.mb-sm-5,.my-sm-5{margin-bottom:4rem !important}

[dir="ltr"] .ml-sm-5,[dir="ltr"] .mx-sm-5{margin-left:4rem !important}

[dir="rtl"] .ml-sm-5,[dir="rtl"] .mx-sm-5{margin-right:4rem !important}

.m-sm-25{margin:.25rem !important}

.mt-sm-25,.my-sm-25{margin-top:.25rem !important}

[dir="ltr"] .mr-sm-25,[dir="ltr"] .mx-sm-25{margin-right:.25rem !important}

[dir="rtl"] .mr-sm-25,[dir="rtl"] .mx-sm-25{margin-left:.25rem !important}

.mb-sm-25,.my-sm-25{margin-bottom:.25rem !important}

[dir="ltr"] .ml-sm-25,[dir="ltr"] .mx-sm-25{margin-left:.25rem !important}

[dir="rtl"] .ml-sm-25,[dir="rtl"] .mx-sm-25{margin-right:.25rem !important}

.m-sm-50{margin:.5rem !important}

.mt-sm-50,.my-sm-50{margin-top:.5rem !important}

[dir="ltr"] .mr-sm-50,[dir="ltr"] .mx-sm-50{margin-right:.5rem !important}

[dir="rtl"] .mr-sm-50,[dir="rtl"] .mx-sm-50{margin-left:.5rem !important}

.mb-sm-50,.my-sm-50{margin-bottom:.5rem !important}

[dir="ltr"] .ml-sm-50,[dir="ltr"] .mx-sm-50{margin-left:.5rem !important}

[dir="rtl"] .ml-sm-50,[dir="rtl"] .mx-sm-50{margin-right:.5rem !important}

.m-sm-75{margin:.75rem !important}

.mt-sm-75,.my-sm-75{margin-top:.75rem !important}

[dir="ltr"] .mr-sm-75,[dir="ltr"] .mx-sm-75{margin-right:.75rem !important}

[dir="rtl"] .mr-sm-75,[dir="rtl"] .mx-sm-75{margin-left:.75rem !important}

.mb-sm-75,.my-sm-75{margin-bottom:.75rem !important}

[dir="ltr"] .ml-sm-75,[dir="ltr"] .mx-sm-75{margin-left:.75rem !important}

[dir="rtl"] .ml-sm-75,[dir="rtl"] .mx-sm-75{margin-right:.75rem !important}

.p-sm-0{padding:0 !important}

.pt-sm-0,.py-sm-0{padding-top:0 !important}

[dir="ltr"] .pr-sm-0,[dir="ltr"] .px-sm-0{padding-right:0 !important}

[dir="rtl"] .pr-sm-0,[dir="rtl"] .px-sm-0{padding-left:0 !important}

.pb-sm-0,.py-sm-0{padding-bottom:0 !important}

[dir="ltr"] .pl-sm-0,[dir="ltr"] .px-sm-0{padding-left:0 !important}

[dir="rtl"] .pl-sm-0,[dir="rtl"] .px-sm-0{padding-right:0 !important}

.p-sm-1{padding:1rem !important}

.pt-sm-1,.py-sm-1{padding-top:1rem !important}

[dir="ltr"] .pr-sm-1,[dir="ltr"] .px-sm-1{padding-right:1rem !important}

[dir="rtl"] .pr-sm-1,[dir="rtl"] .px-sm-1{padding-left:1rem !important}

.pb-sm-1,.py-sm-1{padding-bottom:1rem !important}

[dir="ltr"] .pl-sm-1,[dir="ltr"] .px-sm-1{padding-left:1rem !important}

[dir="rtl"] .pl-sm-1,[dir="rtl"] .px-sm-1{padding-right:1rem !important}

.p-sm-2{padding:1.5rem !important}

.pt-sm-2,.py-sm-2{padding-top:1.5rem !important}

[dir="ltr"] .pr-sm-2,[dir="ltr"] .px-sm-2{padding-right:1.5rem !important}

[dir="rtl"] .pr-sm-2,[dir="rtl"] .px-sm-2{padding-left:1.5rem !important}

.pb-sm-2,.py-sm-2{padding-bottom:1.5rem !important}

[dir="ltr"] .pl-sm-2,[dir="ltr"] .px-sm-2{padding-left:1.5rem !important}

[dir="rtl"] .pl-sm-2,[dir="rtl"] .px-sm-2{padding-right:1.5rem !important}

.p-sm-3{padding:3rem !important}

.pt-sm-3,.py-sm-3{padding-top:3rem !important}

[dir="ltr"] .pr-sm-3,[dir="ltr"] .px-sm-3{padding-right:3rem !important}

[dir="rtl"] .pr-sm-3,[dir="rtl"] .px-sm-3{padding-left:3rem !important}

.pb-sm-3,.py-sm-3{padding-bottom:3rem !important}

[dir="ltr"] .pl-sm-3,[dir="ltr"] .px-sm-3{padding-left:3rem !important}

[dir="rtl"] .pl-sm-3,[dir="rtl"] .px-sm-3{padding-right:3rem !important}

.p-sm-4{padding:3.5rem !important}

.pt-sm-4,.py-sm-4{padding-top:3.5rem !important}

[dir="ltr"] .pr-sm-4,[dir="ltr"] .px-sm-4{padding-right:3.5rem !important}

[dir="rtl"] .pr-sm-4,[dir="rtl"] .px-sm-4{padding-left:3.5rem !important}

.pb-sm-4,.py-sm-4{padding-bottom:3.5rem !important}

[dir="ltr"] .pl-sm-4,[dir="ltr"] .px-sm-4{padding-left:3.5rem !important}

[dir="rtl"] .pl-sm-4,[dir="rtl"] .px-sm-4{padding-right:3.5rem !important}

.p-sm-5{padding:4rem !important}

.pt-sm-5,.py-sm-5{padding-top:4rem !important}

[dir="ltr"] .pr-sm-5,[dir="ltr"] .px-sm-5{padding-right:4rem !important}

[dir="rtl"] .pr-sm-5,[dir="rtl"] .px-sm-5{padding-left:4rem !important}

.pb-sm-5,.py-sm-5{padding-bottom:4rem !important}

[dir="ltr"] .pl-sm-5,[dir="ltr"] .px-sm-5{padding-left:4rem !important}

[dir="rtl"] .pl-sm-5,[dir="rtl"] .px-sm-5{padding-right:4rem !important}

.p-sm-25{padding:.25rem !important}

.pt-sm-25,.py-sm-25{padding-top:.25rem !important}

[dir="ltr"] .pr-sm-25,[dir="ltr"] .px-sm-25{padding-right:.25rem !important}

[dir="rtl"] .pr-sm-25,[dir="rtl"] .px-sm-25{padding-left:.25rem !important}

.pb-sm-25,.py-sm-25{padding-bottom:.25rem !important}

[dir="ltr"] .pl-sm-25,[dir="ltr"] .px-sm-25{padding-left:.25rem !important}

[dir="rtl"] .pl-sm-25,[dir="rtl"] .px-sm-25{padding-right:.25rem !important}

.p-sm-50{padding:.5rem !important}

.pt-sm-50,.py-sm-50{padding-top:.5rem !important}

[dir="ltr"] .pr-sm-50,[dir="ltr"] .px-sm-50{padding-right:.5rem !important}

[dir="rtl"] .pr-sm-50,[dir="rtl"] .px-sm-50{padding-left:.5rem !important}

.pb-sm-50,.py-sm-50{padding-bottom:.5rem !important}

[dir="ltr"] .pl-sm-50,[dir="ltr"] .px-sm-50{padding-left:.5rem !important}

[dir="rtl"] .pl-sm-50,[dir="rtl"] .px-sm-50{padding-right:.5rem !important}

.p-sm-75{padding:.75rem !important}

.pt-sm-75,.py-sm-75{padding-top:.75rem !important}

[dir="ltr"] .pr-sm-75,[dir="ltr"] .px-sm-75{padding-right:.75rem !important}

[dir="rtl"] .pr-sm-75,[dir="rtl"] .px-sm-75{padding-left:.75rem !important}

.pb-sm-75,.py-sm-75{padding-bottom:.75rem !important}

[dir="ltr"] .pl-sm-75,[dir="ltr"] .px-sm-75{padding-left:.75rem !important}

[dir="rtl"] .pl-sm-75,[dir="rtl"] .px-sm-75{padding-right:.75rem !important}

.m-sm-n1{margin:-1rem !important}

.mt-sm-n1,.my-sm-n1{margin-top:-1rem !important}

[dir="ltr"] .mr-sm-n1,[dir="ltr"] .mx-sm-n1{margin-right:-1rem !important}

[dir="rtl"] .mr-sm-n1,[dir="rtl"] .mx-sm-n1{margin-left:-1rem !important}

.mb-sm-n1,.my-sm-n1{margin-bottom:-1rem !important}

[dir="ltr"] .ml-sm-n1,[dir="ltr"] .mx-sm-n1{margin-left:-1rem !important}

[dir="rtl"] .ml-sm-n1,[dir="rtl"] .mx-sm-n1{margin-right:-1rem !important}

.m-sm-n2{margin:-1.5rem !important}

.mt-sm-n2,.my-sm-n2{margin-top:-1.5rem !important}

[dir="ltr"] .mr-sm-n2,[dir="ltr"] .mx-sm-n2{margin-right:-1.5rem !important}

[dir="rtl"] .mr-sm-n2,[dir="rtl"] .mx-sm-n2{margin-left:-1.5rem !important}

.mb-sm-n2,.my-sm-n2{margin-bottom:-1.5rem !important}

[dir="ltr"] .ml-sm-n2,[dir="ltr"] .mx-sm-n2{margin-left:-1.5rem !important}

[dir="rtl"] .ml-sm-n2,[dir="rtl"] .mx-sm-n2{margin-right:-1.5rem !important}

.m-sm-n3{margin:-3rem !important}

.mt-sm-n3,.my-sm-n3{margin-top:-3rem !important}

[dir="ltr"] .mr-sm-n3,[dir="ltr"] .mx-sm-n3{margin-right:-3rem !important}

[dir="rtl"] .mr-sm-n3,[dir="rtl"] .mx-sm-n3{margin-left:-3rem !important}

.mb-sm-n3,.my-sm-n3{margin-bottom:-3rem !important}

[dir="ltr"] .ml-sm-n3,[dir="ltr"] .mx-sm-n3{margin-left:-3rem !important}

[dir="rtl"] .ml-sm-n3,[dir="rtl"] .mx-sm-n3{margin-right:-3rem !important}

.m-sm-n4{margin:-3.5rem !important}

.mt-sm-n4,.my-sm-n4{margin-top:-3.5rem !important}

[dir="ltr"] .mr-sm-n4,[dir="ltr"] .mx-sm-n4{margin-right:-3.5rem !important}

[dir="rtl"] .mr-sm-n4,[dir="rtl"] .mx-sm-n4{margin-left:-3.5rem !important}

.mb-sm-n4,.my-sm-n4{margin-bottom:-3.5rem !important}

[dir="ltr"] .ml-sm-n4,[dir="ltr"] .mx-sm-n4{margin-left:-3.5rem !important}

[dir="rtl"] .ml-sm-n4,[dir="rtl"] .mx-sm-n4{margin-right:-3.5rem !important}

.m-sm-n5{margin:-4rem !important}

.mt-sm-n5,.my-sm-n5{margin-top:-4rem !important}

[dir="ltr"] .mr-sm-n5,[dir="ltr"] .mx-sm-n5{margin-right:-4rem !important}

[dir="rtl"] .mr-sm-n5,[dir="rtl"] .mx-sm-n5{margin-left:-4rem !important}

.mb-sm-n5,.my-sm-n5{margin-bottom:-4rem !important}

[dir="ltr"] .ml-sm-n5,[dir="ltr"] .mx-sm-n5{margin-left:-4rem !important}

[dir="rtl"] .ml-sm-n5,[dir="rtl"] .mx-sm-n5{margin-right:-4rem !important}

.m-sm-n25{margin:-0.25rem !important}

.mt-sm-n25,.my-sm-n25{margin-top:-0.25rem !important}

[dir="ltr"] .mr-sm-n25,[dir="ltr"] .mx-sm-n25{margin-right:-0.25rem !important}

[dir="rtl"] .mr-sm-n25,[dir="rtl"] .mx-sm-n25{margin-left:-0.25rem !important}

.mb-sm-n25,.my-sm-n25{margin-bottom:-0.25rem !important}

[dir="ltr"] .ml-sm-n25,[dir="ltr"] .mx-sm-n25{margin-left:-0.25rem !important}

[dir="rtl"] .ml-sm-n25,[dir="rtl"] .mx-sm-n25{margin-right:-0.25rem !important}

.m-sm-n50{margin:-0.5rem !important}

.mt-sm-n50,.my-sm-n50{margin-top:-0.5rem !important}

[dir="ltr"] .mr-sm-n50,[dir="ltr"] .mx-sm-n50{margin-right:-0.5rem !important}

[dir="rtl"] .mr-sm-n50,[dir="rtl"] .mx-sm-n50{margin-left:-0.5rem !important}

.mb-sm-n50,.my-sm-n50{margin-bottom:-0.5rem !important}

[dir="ltr"] .ml-sm-n50,[dir="ltr"] .mx-sm-n50{margin-left:-0.5rem !important}

[dir="rtl"] .ml-sm-n50,[dir="rtl"] .mx-sm-n50{margin-right:-0.5rem !important}

.m-sm-n75{margin:-0.75rem !important}

.mt-sm-n75,.my-sm-n75{margin-top:-0.75rem !important}

[dir="ltr"] .mr-sm-n75,[dir="ltr"] .mx-sm-n75{margin-right:-0.75rem !important}

[dir="rtl"] .mr-sm-n75,[dir="rtl"] .mx-sm-n75{margin-left:-0.75rem !important}

.mb-sm-n75,.my-sm-n75{margin-bottom:-0.75rem !important}

[dir="ltr"] .ml-sm-n75,[dir="ltr"] .mx-sm-n75{margin-left:-0.75rem !important}

[dir="rtl"] .ml-sm-n75,[dir="rtl"] .mx-sm-n75{margin-right:-0.75rem !important}

.m-sm-auto{margin:auto !important}

.mt-sm-auto,.my-sm-auto{margin-top:auto !important}

[dir="ltr"] .mr-sm-auto,[dir="ltr"] .mx-sm-auto{margin-right:auto !important}

[dir="rtl"] .mr-sm-auto,[dir="rtl"] .mx-sm-auto{margin-left:auto !important}

.mb-sm-auto,.my-sm-auto{margin-bottom:auto !important}

[dir="ltr"] .ml-sm-auto,[dir="ltr"] .mx-sm-auto{margin-left:auto !important}

[dir="rtl"] .ml-sm-auto,[dir="rtl"] .mx-sm-auto{margin-right:auto !important}}

@media(min-width: 768px){
.m-md-0{margin:0 !important}

.mt-md-0,.my-md-0{margin-top:0 !important}

[dir="ltr"] .mr-md-0,[dir="ltr"] .mx-md-0{margin-right:0 !important}

[dir="rtl"] .mr-md-0,[dir="rtl"] .mx-md-0{margin-left:0 !important}

.mb-md-0,.my-md-0{margin-bottom:0 !important}

[dir="ltr"] .ml-md-0,[dir="ltr"] .mx-md-0{margin-left:0 !important}

[dir="rtl"] .ml-md-0,[dir="rtl"] .mx-md-0{margin-right:0 !important}

.m-md-1{margin:1rem !important}

.mt-md-1,.my-md-1{margin-top:1rem !important}

[dir="ltr"] .mr-md-1,[dir="ltr"] .mx-md-1{margin-right:1rem !important}

[dir="rtl"] .mr-md-1,[dir="rtl"] .mx-md-1{margin-left:1rem !important}

.mb-md-1,.my-md-1{margin-bottom:1rem !important}

[dir="ltr"] .ml-md-1,[dir="ltr"] .mx-md-1{margin-left:1rem !important}

[dir="rtl"] .ml-md-1,[dir="rtl"] .mx-md-1{margin-right:1rem !important}

.m-md-2{margin:1.5rem !important}

.mt-md-2,.my-md-2{margin-top:1.5rem !important}

[dir="ltr"] .mr-md-2,[dir="ltr"] .mx-md-2{margin-right:1.5rem !important}

[dir="rtl"] .mr-md-2,[dir="rtl"] .mx-md-2{margin-left:1.5rem !important}

.mb-md-2,.my-md-2{margin-bottom:1.5rem !important}

[dir="ltr"] .ml-md-2,[dir="ltr"] .mx-md-2{margin-left:1.5rem !important}

[dir="rtl"] .ml-md-2,[dir="rtl"] .mx-md-2{margin-right:1.5rem !important}

.m-md-3{margin:3rem !important}

.mt-md-3,.my-md-3{margin-top:3rem !important}

[dir="ltr"] .mr-md-3,[dir="ltr"] .mx-md-3{margin-right:3rem !important}

[dir="rtl"] .mr-md-3,[dir="rtl"] .mx-md-3{margin-left:3rem !important}

.mb-md-3,.my-md-3{margin-bottom:3rem !important}

[dir="ltr"] .ml-md-3,[dir="ltr"] .mx-md-3{margin-left:3rem !important}

[dir="rtl"] .ml-md-3,[dir="rtl"] .mx-md-3{margin-right:3rem !important}

.m-md-4{margin:3.5rem !important}

.mt-md-4,.my-md-4{margin-top:3.5rem !important}

[dir="ltr"] .mr-md-4,[dir="ltr"] .mx-md-4{margin-right:3.5rem !important}

[dir="rtl"] .mr-md-4,[dir="rtl"] .mx-md-4{margin-left:3.5rem !important}

.mb-md-4,.my-md-4{margin-bottom:3.5rem !important}

[dir="ltr"] .ml-md-4,[dir="ltr"] .mx-md-4{margin-left:3.5rem !important}

[dir="rtl"] .ml-md-4,[dir="rtl"] .mx-md-4{margin-right:3.5rem !important}

.m-md-5{margin:4rem !important}

.mt-md-5,.my-md-5{margin-top:4rem !important}

[dir="ltr"] .mr-md-5,[dir="ltr"] .mx-md-5{margin-right:4rem !important}

[dir="rtl"] .mr-md-5,[dir="rtl"] .mx-md-5{margin-left:4rem !important}

.mb-md-5,.my-md-5{margin-bottom:4rem !important}

[dir="ltr"] .ml-md-5,[dir="ltr"] .mx-md-5{margin-left:4rem !important}

[dir="rtl"] .ml-md-5,[dir="rtl"] .mx-md-5{margin-right:4rem !important}

.m-md-25{margin:.25rem !important}

.mt-md-25,.my-md-25{margin-top:.25rem !important}

[dir="ltr"] .mr-md-25,[dir="ltr"] .mx-md-25{margin-right:.25rem !important}

[dir="rtl"] .mr-md-25,[dir="rtl"] .mx-md-25{margin-left:.25rem !important}

.mb-md-25,.my-md-25{margin-bottom:.25rem !important}

[dir="ltr"] .ml-md-25,[dir="ltr"] .mx-md-25{margin-left:.25rem !important}

[dir="rtl"] .ml-md-25,[dir="rtl"] .mx-md-25{margin-right:.25rem !important}

.m-md-50{margin:.5rem !important}

.mt-md-50,.my-md-50{margin-top:.5rem !important}

[dir="ltr"] .mr-md-50,[dir="ltr"] .mx-md-50{margin-right:.5rem !important}

[dir="rtl"] .mr-md-50,[dir="rtl"] .mx-md-50{margin-left:.5rem !important}

.mb-md-50,.my-md-50{margin-bottom:.5rem !important}

[dir="ltr"] .ml-md-50,[dir="ltr"] .mx-md-50{margin-left:.5rem !important}

[dir="rtl"] .ml-md-50,[dir="rtl"] .mx-md-50{margin-right:.5rem !important}

.m-md-75{margin:.75rem !important}

.mt-md-75,.my-md-75{margin-top:.75rem !important}

[dir="ltr"] .mr-md-75,[dir="ltr"] .mx-md-75{margin-right:.75rem !important}

[dir="rtl"] .mr-md-75,[dir="rtl"] .mx-md-75{margin-left:.75rem !important}

.mb-md-75,.my-md-75{margin-bottom:.75rem !important}

[dir="ltr"] .ml-md-75,[dir="ltr"] .mx-md-75{margin-left:.75rem !important}

[dir="rtl"] .ml-md-75,[dir="rtl"] .mx-md-75{margin-right:.75rem !important}

.p-md-0{padding:0 !important}

.pt-md-0,.py-md-0{padding-top:0 !important}

[dir="ltr"] .pr-md-0,[dir="ltr"] .px-md-0{padding-right:0 !important}

[dir="rtl"] .pr-md-0,[dir="rtl"] .px-md-0{padding-left:0 !important}

.pb-md-0,.py-md-0{padding-bottom:0 !important}

[dir="ltr"] .pl-md-0,[dir="ltr"] .px-md-0{padding-left:0 !important}

[dir="rtl"] .pl-md-0,[dir="rtl"] .px-md-0{padding-right:0 !important}

.p-md-1{padding:1rem !important}

.pt-md-1,.py-md-1{padding-top:1rem !important}

[dir="ltr"] .pr-md-1,[dir="ltr"] .px-md-1{padding-right:1rem !important}

[dir="rtl"] .pr-md-1,[dir="rtl"] .px-md-1{padding-left:1rem !important}

.pb-md-1,.py-md-1{padding-bottom:1rem !important}

[dir="ltr"] .pl-md-1,[dir="ltr"] .px-md-1{padding-left:1rem !important}

[dir="rtl"] .pl-md-1,[dir="rtl"] .px-md-1{padding-right:1rem !important}

.p-md-2{padding:1.5rem !important}

.pt-md-2,.py-md-2{padding-top:1.5rem !important}

[dir="ltr"] .pr-md-2,[dir="ltr"] .px-md-2{padding-right:1.5rem !important}

[dir="rtl"] .pr-md-2,[dir="rtl"] .px-md-2{padding-left:1.5rem !important}

.pb-md-2,.py-md-2{padding-bottom:1.5rem !important}

[dir="ltr"] .pl-md-2,[dir="ltr"] .px-md-2{padding-left:1.5rem !important}

[dir="rtl"] .pl-md-2,[dir="rtl"] .px-md-2{padding-right:1.5rem !important}

.p-md-3{padding:3rem !important}

.pt-md-3,.py-md-3{padding-top:3rem !important}

[dir="ltr"] .pr-md-3,[dir="ltr"] .px-md-3{padding-right:3rem !important}

[dir="rtl"] .pr-md-3,[dir="rtl"] .px-md-3{padding-left:3rem !important}

.pb-md-3,.py-md-3{padding-bottom:3rem !important}

[dir="ltr"] .pl-md-3,[dir="ltr"] .px-md-3{padding-left:3rem !important}

[dir="rtl"] .pl-md-3,[dir="rtl"] .px-md-3{padding-right:3rem !important}

.p-md-4{padding:3.5rem !important}

.pt-md-4,.py-md-4{padding-top:3.5rem !important}

[dir="ltr"] .pr-md-4,[dir="ltr"] .px-md-4{padding-right:3.5rem !important}

[dir="rtl"] .pr-md-4,[dir="rtl"] .px-md-4{padding-left:3.5rem !important}

.pb-md-4,.py-md-4{padding-bottom:3.5rem !important}

[dir="ltr"] .pl-md-4,[dir="ltr"] .px-md-4{padding-left:3.5rem !important}

[dir="rtl"] .pl-md-4,[dir="rtl"] .px-md-4{padding-right:3.5rem !important}

.p-md-5{padding:4rem !important}

.pt-md-5,.py-md-5{padding-top:4rem !important}

[dir="ltr"] .pr-md-5,[dir="ltr"] .px-md-5{padding-right:4rem !important}

[dir="rtl"] .pr-md-5,[dir="rtl"] .px-md-5{padding-left:4rem !important}

.pb-md-5,.py-md-5{padding-bottom:4rem !important}

[dir="ltr"] .pl-md-5,[dir="ltr"] .px-md-5{padding-left:4rem !important}

[dir="rtl"] .pl-md-5,[dir="rtl"] .px-md-5{padding-right:4rem !important}

.p-md-25{padding:.25rem !important}

.pt-md-25,.py-md-25{padding-top:.25rem !important}

[dir="ltr"] .pr-md-25,[dir="ltr"] .px-md-25{padding-right:.25rem !important}

[dir="rtl"] .pr-md-25,[dir="rtl"] .px-md-25{padding-left:.25rem !important}

.pb-md-25,.py-md-25{padding-bottom:.25rem !important}

[dir="ltr"] .pl-md-25,[dir="ltr"] .px-md-25{padding-left:.25rem !important}

[dir="rtl"] .pl-md-25,[dir="rtl"] .px-md-25{padding-right:.25rem !important}

.p-md-50{padding:.5rem !important}

.pt-md-50,.py-md-50{padding-top:.5rem !important}

[dir="ltr"] .pr-md-50,[dir="ltr"] .px-md-50{padding-right:.5rem !important}

[dir="rtl"] .pr-md-50,[dir="rtl"] .px-md-50{padding-left:.5rem !important}

.pb-md-50,.py-md-50{padding-bottom:.5rem !important}

[dir="ltr"] .pl-md-50,[dir="ltr"] .px-md-50{padding-left:.5rem !important}

[dir="rtl"] .pl-md-50,[dir="rtl"] .px-md-50{padding-right:.5rem !important}

.p-md-75{padding:.75rem !important}

.pt-md-75,.py-md-75{padding-top:.75rem !important}

[dir="ltr"] .pr-md-75,[dir="ltr"] .px-md-75{padding-right:.75rem !important}

[dir="rtl"] .pr-md-75,[dir="rtl"] .px-md-75{padding-left:.75rem !important}

.pb-md-75,.py-md-75{padding-bottom:.75rem !important}

[dir="ltr"] .pl-md-75,[dir="ltr"] .px-md-75{padding-left:.75rem !important}

[dir="rtl"] .pl-md-75,[dir="rtl"] .px-md-75{padding-right:.75rem !important}

.m-md-n1{margin:-1rem !important}

.mt-md-n1,.my-md-n1{margin-top:-1rem !important}

[dir="ltr"] .mr-md-n1,[dir="ltr"] .mx-md-n1{margin-right:-1rem !important}

[dir="rtl"] .mr-md-n1,[dir="rtl"] .mx-md-n1{margin-left:-1rem !important}

.mb-md-n1,.my-md-n1{margin-bottom:-1rem !important}

[dir="ltr"] .ml-md-n1,[dir="ltr"] .mx-md-n1{margin-left:-1rem !important}

[dir="rtl"] .ml-md-n1,[dir="rtl"] .mx-md-n1{margin-right:-1rem !important}

.m-md-n2{margin:-1.5rem !important}

.mt-md-n2,.my-md-n2{margin-top:-1.5rem !important}

[dir="ltr"] .mr-md-n2,[dir="ltr"] .mx-md-n2{margin-right:-1.5rem !important}

[dir="rtl"] .mr-md-n2,[dir="rtl"] .mx-md-n2{margin-left:-1.5rem !important}

.mb-md-n2,.my-md-n2{margin-bottom:-1.5rem !important}

[dir="ltr"] .ml-md-n2,[dir="ltr"] .mx-md-n2{margin-left:-1.5rem !important}

[dir="rtl"] .ml-md-n2,[dir="rtl"] .mx-md-n2{margin-right:-1.5rem !important}

.m-md-n3{margin:-3rem !important}

.mt-md-n3,.my-md-n3{margin-top:-3rem !important}

[dir="ltr"] .mr-md-n3,[dir="ltr"] .mx-md-n3{margin-right:-3rem !important}

[dir="rtl"] .mr-md-n3,[dir="rtl"] .mx-md-n3{margin-left:-3rem !important}

.mb-md-n3,.my-md-n3{margin-bottom:-3rem !important}

[dir="ltr"] .ml-md-n3,[dir="ltr"] .mx-md-n3{margin-left:-3rem !important}

[dir="rtl"] .ml-md-n3,[dir="rtl"] .mx-md-n3{margin-right:-3rem !important}

.m-md-n4{margin:-3.5rem !important}

.mt-md-n4,.my-md-n4{margin-top:-3.5rem !important}

[dir="ltr"] .mr-md-n4,[dir="ltr"] .mx-md-n4{margin-right:-3.5rem !important}

[dir="rtl"] .mr-md-n4,[dir="rtl"] .mx-md-n4{margin-left:-3.5rem !important}

.mb-md-n4,.my-md-n4{margin-bottom:-3.5rem !important}

[dir="ltr"] .ml-md-n4,[dir="ltr"] .mx-md-n4{margin-left:-3.5rem !important}

[dir="rtl"] .ml-md-n4,[dir="rtl"] .mx-md-n4{margin-right:-3.5rem !important}

.m-md-n5{margin:-4rem !important}

.mt-md-n5,.my-md-n5{margin-top:-4rem !important}

[dir="ltr"] .mr-md-n5,[dir="ltr"] .mx-md-n5{margin-right:-4rem !important}

[dir="rtl"] .mr-md-n5,[dir="rtl"] .mx-md-n5{margin-left:-4rem !important}

.mb-md-n5,.my-md-n5{margin-bottom:-4rem !important}

[dir="ltr"] .ml-md-n5,[dir="ltr"] .mx-md-n5{margin-left:-4rem !important}

[dir="rtl"] .ml-md-n5,[dir="rtl"] .mx-md-n5{margin-right:-4rem !important}

.m-md-n25{margin:-0.25rem !important}

.mt-md-n25,.my-md-n25{margin-top:-0.25rem !important}

[dir="ltr"] .mr-md-n25,[dir="ltr"] .mx-md-n25{margin-right:-0.25rem !important}

[dir="rtl"] .mr-md-n25,[dir="rtl"] .mx-md-n25{margin-left:-0.25rem !important}

.mb-md-n25,.my-md-n25{margin-bottom:-0.25rem !important}

[dir="ltr"] .ml-md-n25,[dir="ltr"] .mx-md-n25{margin-left:-0.25rem !important}

[dir="rtl"] .ml-md-n25,[dir="rtl"] .mx-md-n25{margin-right:-0.25rem !important}

.m-md-n50{margin:-0.5rem !important}

.mt-md-n50,.my-md-n50{margin-top:-0.5rem !important}

[dir="ltr"] .mr-md-n50,[dir="ltr"] .mx-md-n50{margin-right:-0.5rem !important}

[dir="rtl"] .mr-md-n50,[dir="rtl"] .mx-md-n50{margin-left:-0.5rem !important}

.mb-md-n50,.my-md-n50{margin-bottom:-0.5rem !important}

[dir="ltr"] .ml-md-n50,[dir="ltr"] .mx-md-n50{margin-left:-0.5rem !important}

[dir="rtl"] .ml-md-n50,[dir="rtl"] .mx-md-n50{margin-right:-0.5rem !important}

.m-md-n75{margin:-0.75rem !important}

.mt-md-n75,.my-md-n75{margin-top:-0.75rem !important}

[dir="ltr"] .mr-md-n75,[dir="ltr"] .mx-md-n75{margin-right:-0.75rem !important}

[dir="rtl"] .mr-md-n75,[dir="rtl"] .mx-md-n75{margin-left:-0.75rem !important}

.mb-md-n75,.my-md-n75{margin-bottom:-0.75rem !important}

[dir="ltr"] .ml-md-n75,[dir="ltr"] .mx-md-n75{margin-left:-0.75rem !important}

[dir="rtl"] .ml-md-n75,[dir="rtl"] .mx-md-n75{margin-right:-0.75rem !important}

.m-md-auto{margin:auto !important}

.mt-md-auto,.my-md-auto{margin-top:auto !important}

[dir="ltr"] .mr-md-auto,[dir="ltr"] .mx-md-auto{margin-right:auto !important}

[dir="rtl"] .mr-md-auto,[dir="rtl"] .mx-md-auto{margin-left:auto !important}

.mb-md-auto,.my-md-auto{margin-bottom:auto !important}

[dir="ltr"] .ml-md-auto,[dir="ltr"] .mx-md-auto{margin-left:auto !important}

[dir="rtl"] .ml-md-auto,[dir="rtl"] .mx-md-auto{margin-right:auto !important}}

@media(min-width: 992px){
.m-lg-0{margin:0 !important}

.mt-lg-0,.my-lg-0{margin-top:0 !important}

[dir="ltr"] .mr-lg-0,[dir="ltr"] .mx-lg-0{margin-right:0 !important}

[dir="rtl"] .mr-lg-0,[dir="rtl"] .mx-lg-0{margin-left:0 !important}

.mb-lg-0,.my-lg-0{margin-bottom:0 !important}

[dir="ltr"] .ml-lg-0,[dir="ltr"] .mx-lg-0{margin-left:0 !important}

[dir="rtl"] .ml-lg-0,[dir="rtl"] .mx-lg-0{margin-right:0 !important}

.m-lg-1{margin:1rem !important}

.mt-lg-1,.my-lg-1{margin-top:1rem !important}

[dir="ltr"] .mr-lg-1,[dir="ltr"] .mx-lg-1{margin-right:1rem !important}

[dir="rtl"] .mr-lg-1,[dir="rtl"] .mx-lg-1{margin-left:1rem !important}

.mb-lg-1,.my-lg-1{margin-bottom:1rem !important}

[dir="ltr"] .ml-lg-1,[dir="ltr"] .mx-lg-1{margin-left:1rem !important}

[dir="rtl"] .ml-lg-1,[dir="rtl"] .mx-lg-1{margin-right:1rem !important}

.m-lg-2{margin:1.5rem !important}

.mt-lg-2,.my-lg-2{margin-top:1.5rem !important}

[dir="ltr"] .mr-lg-2,[dir="ltr"] .mx-lg-2{margin-right:1.5rem !important}

[dir="rtl"] .mr-lg-2,[dir="rtl"] .mx-lg-2{margin-left:1.5rem !important}

.mb-lg-2,.my-lg-2{margin-bottom:1.5rem !important}

[dir="ltr"] .ml-lg-2,[dir="ltr"] .mx-lg-2{margin-left:1.5rem !important}

[dir="rtl"] .ml-lg-2,[dir="rtl"] .mx-lg-2{margin-right:1.5rem !important}

.m-lg-3{margin:3rem !important}

.mt-lg-3,.my-lg-3{margin-top:3rem !important}

[dir="ltr"] .mr-lg-3,[dir="ltr"] .mx-lg-3{margin-right:3rem !important}

[dir="rtl"] .mr-lg-3,[dir="rtl"] .mx-lg-3{margin-left:3rem !important}

.mb-lg-3,.my-lg-3{margin-bottom:3rem !important}

[dir="ltr"] .ml-lg-3,[dir="ltr"] .mx-lg-3{margin-left:3rem !important}

[dir="rtl"] .ml-lg-3,[dir="rtl"] .mx-lg-3{margin-right:3rem !important}

.m-lg-4{margin:3.5rem !important}

.mt-lg-4,.my-lg-4{margin-top:3.5rem !important}

[dir="ltr"] .mr-lg-4,[dir="ltr"] .mx-lg-4{margin-right:3.5rem !important}

[dir="rtl"] .mr-lg-4,[dir="rtl"] .mx-lg-4{margin-left:3.5rem !important}

.mb-lg-4,.my-lg-4{margin-bottom:3.5rem !important}

[dir="ltr"] .ml-lg-4,[dir="ltr"] .mx-lg-4{margin-left:3.5rem !important}

[dir="rtl"] .ml-lg-4,[dir="rtl"] .mx-lg-4{margin-right:3.5rem !important}

.m-lg-5{margin:4rem !important}

.mt-lg-5,.my-lg-5{margin-top:4rem !important}

[dir="ltr"] .mr-lg-5,[dir="ltr"] .mx-lg-5{margin-right:4rem !important}

[dir="rtl"] .mr-lg-5,[dir="rtl"] .mx-lg-5{margin-left:4rem !important}

.mb-lg-5,.my-lg-5{margin-bottom:4rem !important}

[dir="ltr"] .ml-lg-5,[dir="ltr"] .mx-lg-5{margin-left:4rem !important}

[dir="rtl"] .ml-lg-5,[dir="rtl"] .mx-lg-5{margin-right:4rem !important}

.m-lg-25{margin:.25rem !important}

.mt-lg-25,.my-lg-25{margin-top:.25rem !important}

[dir="ltr"] .mr-lg-25,[dir="ltr"] .mx-lg-25{margin-right:.25rem !important}

[dir="rtl"] .mr-lg-25,[dir="rtl"] .mx-lg-25{margin-left:.25rem !important}

.mb-lg-25,.my-lg-25{margin-bottom:.25rem !important}

[dir="ltr"] .ml-lg-25,[dir="ltr"] .mx-lg-25{margin-left:.25rem !important}

[dir="rtl"] .ml-lg-25,[dir="rtl"] .mx-lg-25{margin-right:.25rem !important}

.m-lg-50{margin:.5rem !important}

.mt-lg-50,.my-lg-50{margin-top:.5rem !important}

[dir="ltr"] .mr-lg-50,[dir="ltr"] .mx-lg-50{margin-right:.5rem !important}

[dir="rtl"] .mr-lg-50,[dir="rtl"] .mx-lg-50{margin-left:.5rem !important}

.mb-lg-50,.my-lg-50{margin-bottom:.5rem !important}

[dir="ltr"] .ml-lg-50,[dir="ltr"] .mx-lg-50{margin-left:.5rem !important}

[dir="rtl"] .ml-lg-50,[dir="rtl"] .mx-lg-50{margin-right:.5rem !important}

.m-lg-75{margin:.75rem !important}

.mt-lg-75,.my-lg-75{margin-top:.75rem !important}

[dir="ltr"] .mr-lg-75,[dir="ltr"] .mx-lg-75{margin-right:.75rem !important}

[dir="rtl"] .mr-lg-75,[dir="rtl"] .mx-lg-75{margin-left:.75rem !important}

.mb-lg-75,.my-lg-75{margin-bottom:.75rem !important}

[dir="ltr"] .ml-lg-75,[dir="ltr"] .mx-lg-75{margin-left:.75rem !important}

[dir="rtl"] .ml-lg-75,[dir="rtl"] .mx-lg-75{margin-right:.75rem !important}

.p-lg-0{padding:0 !important}

.pt-lg-0,.py-lg-0{padding-top:0 !important}

[dir="ltr"] .pr-lg-0,[dir="ltr"] .px-lg-0{padding-right:0 !important}

[dir="rtl"] .pr-lg-0,[dir="rtl"] .px-lg-0{padding-left:0 !important}

.pb-lg-0,.py-lg-0{padding-bottom:0 !important}

[dir="ltr"] .pl-lg-0,[dir="ltr"] .px-lg-0{padding-left:0 !important}

[dir="rtl"] .pl-lg-0,[dir="rtl"] .px-lg-0{padding-right:0 !important}

.p-lg-1{padding:1rem !important}

.pt-lg-1,.py-lg-1{padding-top:1rem !important}

[dir="ltr"] .pr-lg-1,[dir="ltr"] .px-lg-1{padding-right:1rem !important}

[dir="rtl"] .pr-lg-1,[dir="rtl"] .px-lg-1{padding-left:1rem !important}

.pb-lg-1,.py-lg-1{padding-bottom:1rem !important}

[dir="ltr"] .pl-lg-1,[dir="ltr"] .px-lg-1{padding-left:1rem !important}

[dir="rtl"] .pl-lg-1,[dir="rtl"] .px-lg-1{padding-right:1rem !important}

.p-lg-2{padding:1.5rem !important}

.pt-lg-2,.py-lg-2{padding-top:1.5rem !important}

[dir="ltr"] .pr-lg-2,[dir="ltr"] .px-lg-2{padding-right:1.5rem !important}

[dir="rtl"] .pr-lg-2,[dir="rtl"] .px-lg-2{padding-left:1.5rem !important}

.pb-lg-2,.py-lg-2{padding-bottom:1.5rem !important}

[dir="ltr"] .pl-lg-2,[dir="ltr"] .px-lg-2{padding-left:1.5rem !important}

[dir="rtl"] .pl-lg-2,[dir="rtl"] .px-lg-2{padding-right:1.5rem !important}

.p-lg-3{padding:3rem !important}

.pt-lg-3,.py-lg-3{padding-top:3rem !important}

[dir="ltr"] .pr-lg-3,[dir="ltr"] .px-lg-3{padding-right:3rem !important}

[dir="rtl"] .pr-lg-3,[dir="rtl"] .px-lg-3{padding-left:3rem !important}

.pb-lg-3,.py-lg-3{padding-bottom:3rem !important}

[dir="ltr"] .pl-lg-3,[dir="ltr"] .px-lg-3{padding-left:3rem !important}

[dir="rtl"] .pl-lg-3,[dir="rtl"] .px-lg-3{padding-right:3rem !important}

.p-lg-4{padding:3.5rem !important}

.pt-lg-4,.py-lg-4{padding-top:3.5rem !important}

[dir="ltr"] .pr-lg-4,[dir="ltr"] .px-lg-4{padding-right:3.5rem !important}

[dir="rtl"] .pr-lg-4,[dir="rtl"] .px-lg-4{padding-left:3.5rem !important}

.pb-lg-4,.py-lg-4{padding-bottom:3.5rem !important}

[dir="ltr"] .pl-lg-4,[dir="ltr"] .px-lg-4{padding-left:3.5rem !important}

[dir="rtl"] .pl-lg-4,[dir="rtl"] .px-lg-4{padding-right:3.5rem !important}

.p-lg-5{padding:4rem !important}

.pt-lg-5,.py-lg-5{padding-top:4rem !important}

[dir="ltr"] .pr-lg-5,[dir="ltr"] .px-lg-5{padding-right:4rem !important}

[dir="rtl"] .pr-lg-5,[dir="rtl"] .px-lg-5{padding-left:4rem !important}

.pb-lg-5,.py-lg-5{padding-bottom:4rem !important}

[dir="ltr"] .pl-lg-5,[dir="ltr"] .px-lg-5{padding-left:4rem !important}

[dir="rtl"] .pl-lg-5,[dir="rtl"] .px-lg-5{padding-right:4rem !important}

.p-lg-25{padding:.25rem !important}

.pt-lg-25,.py-lg-25{padding-top:.25rem !important}

[dir="ltr"] .pr-lg-25,[dir="ltr"] .px-lg-25{padding-right:.25rem !important}

[dir="rtl"] .pr-lg-25,[dir="rtl"] .px-lg-25{padding-left:.25rem !important}

.pb-lg-25,.py-lg-25{padding-bottom:.25rem !important}

[dir="ltr"] .pl-lg-25,[dir="ltr"] .px-lg-25{padding-left:.25rem !important}

[dir="rtl"] .pl-lg-25,[dir="rtl"] .px-lg-25{padding-right:.25rem !important}

.p-lg-50{padding:.5rem !important}

.pt-lg-50,.py-lg-50{padding-top:.5rem !important}

[dir="ltr"] .pr-lg-50,[dir="ltr"] .px-lg-50{padding-right:.5rem !important}

[dir="rtl"] .pr-lg-50,[dir="rtl"] .px-lg-50{padding-left:.5rem !important}

.pb-lg-50,.py-lg-50{padding-bottom:.5rem !important}

[dir="ltr"] .pl-lg-50,[dir="ltr"] .px-lg-50{padding-left:.5rem !important}

[dir="rtl"] .pl-lg-50,[dir="rtl"] .px-lg-50{padding-right:.5rem !important}

.p-lg-75{padding:.75rem !important}

.pt-lg-75,.py-lg-75{padding-top:.75rem !important}

[dir="ltr"] .pr-lg-75,[dir="ltr"] .px-lg-75{padding-right:.75rem !important}

[dir="rtl"] .pr-lg-75,[dir="rtl"] .px-lg-75{padding-left:.75rem !important}

.pb-lg-75,.py-lg-75{padding-bottom:.75rem !important}

[dir="ltr"] .pl-lg-75,[dir="ltr"] .px-lg-75{padding-left:.75rem !important}

[dir="rtl"] .pl-lg-75,[dir="rtl"] .px-lg-75{padding-right:.75rem !important}

.m-lg-n1{margin:-1rem !important}

.mt-lg-n1,.my-lg-n1{margin-top:-1rem !important}

[dir="ltr"] .mr-lg-n1,[dir="ltr"] .mx-lg-n1{margin-right:-1rem !important}

[dir="rtl"] .mr-lg-n1,[dir="rtl"] .mx-lg-n1{margin-left:-1rem !important}

.mb-lg-n1,.my-lg-n1{margin-bottom:-1rem !important}

[dir="ltr"] .ml-lg-n1,[dir="ltr"] .mx-lg-n1{margin-left:-1rem !important}

[dir="rtl"] .ml-lg-n1,[dir="rtl"] .mx-lg-n1{margin-right:-1rem !important}

.m-lg-n2{margin:-1.5rem !important}

.mt-lg-n2,.my-lg-n2{margin-top:-1.5rem !important}

[dir="ltr"] .mr-lg-n2,[dir="ltr"] .mx-lg-n2{margin-right:-1.5rem !important}

[dir="rtl"] .mr-lg-n2,[dir="rtl"] .mx-lg-n2{margin-left:-1.5rem !important}

.mb-lg-n2,.my-lg-n2{margin-bottom:-1.5rem !important}

[dir="ltr"] .ml-lg-n2,[dir="ltr"] .mx-lg-n2{margin-left:-1.5rem !important}

[dir="rtl"] .ml-lg-n2,[dir="rtl"] .mx-lg-n2{margin-right:-1.5rem !important}

.m-lg-n3{margin:-3rem !important}

.mt-lg-n3,.my-lg-n3{margin-top:-3rem !important}

[dir="ltr"] .mr-lg-n3,[dir="ltr"] .mx-lg-n3{margin-right:-3rem !important}

[dir="rtl"] .mr-lg-n3,[dir="rtl"] .mx-lg-n3{margin-left:-3rem !important}

.mb-lg-n3,.my-lg-n3{margin-bottom:-3rem !important}

[dir="ltr"] .ml-lg-n3,[dir="ltr"] .mx-lg-n3{margin-left:-3rem !important}

[dir="rtl"] .ml-lg-n3,[dir="rtl"] .mx-lg-n3{margin-right:-3rem !important}

.m-lg-n4{margin:-3.5rem !important}

.mt-lg-n4,.my-lg-n4{margin-top:-3.5rem !important}

[dir="ltr"] .mr-lg-n4,[dir="ltr"] .mx-lg-n4{margin-right:-3.5rem !important}

[dir="rtl"] .mr-lg-n4,[dir="rtl"] .mx-lg-n4{margin-left:-3.5rem !important}

.mb-lg-n4,.my-lg-n4{margin-bottom:-3.5rem !important}

[dir="ltr"] .ml-lg-n4,[dir="ltr"] .mx-lg-n4{margin-left:-3.5rem !important}

[dir="rtl"] .ml-lg-n4,[dir="rtl"] .mx-lg-n4{margin-right:-3.5rem !important}

.m-lg-n5{margin:-4rem !important}

.mt-lg-n5,.my-lg-n5{margin-top:-4rem !important}

[dir="ltr"] .mr-lg-n5,[dir="ltr"] .mx-lg-n5{margin-right:-4rem !important}

[dir="rtl"] .mr-lg-n5,[dir="rtl"] .mx-lg-n5{margin-left:-4rem !important}

.mb-lg-n5,.my-lg-n5{margin-bottom:-4rem !important}

[dir="ltr"] .ml-lg-n5,[dir="ltr"] .mx-lg-n5{margin-left:-4rem !important}

[dir="rtl"] .ml-lg-n5,[dir="rtl"] .mx-lg-n5{margin-right:-4rem !important}

.m-lg-n25{margin:-0.25rem !important}

.mt-lg-n25,.my-lg-n25{margin-top:-0.25rem !important}

[dir="ltr"] .mr-lg-n25,[dir="ltr"] .mx-lg-n25{margin-right:-0.25rem !important}

[dir="rtl"] .mr-lg-n25,[dir="rtl"] .mx-lg-n25{margin-left:-0.25rem !important}

.mb-lg-n25,.my-lg-n25{margin-bottom:-0.25rem !important}

[dir="ltr"] .ml-lg-n25,[dir="ltr"] .mx-lg-n25{margin-left:-0.25rem !important}

[dir="rtl"] .ml-lg-n25,[dir="rtl"] .mx-lg-n25{margin-right:-0.25rem !important}

.m-lg-n50{margin:-0.5rem !important}

.mt-lg-n50,.my-lg-n50{margin-top:-0.5rem !important}

[dir="ltr"] .mr-lg-n50,[dir="ltr"] .mx-lg-n50{margin-right:-0.5rem !important}

[dir="rtl"] .mr-lg-n50,[dir="rtl"] .mx-lg-n50{margin-left:-0.5rem !important}

.mb-lg-n50,.my-lg-n50{margin-bottom:-0.5rem !important}

[dir="ltr"] .ml-lg-n50,[dir="ltr"] .mx-lg-n50{margin-left:-0.5rem !important}

[dir="rtl"] .ml-lg-n50,[dir="rtl"] .mx-lg-n50{margin-right:-0.5rem !important}

.m-lg-n75{margin:-0.75rem !important}

.mt-lg-n75,.my-lg-n75{margin-top:-0.75rem !important}

[dir="ltr"] .mr-lg-n75,[dir="ltr"] .mx-lg-n75{margin-right:-0.75rem !important}

[dir="rtl"] .mr-lg-n75,[dir="rtl"] .mx-lg-n75{margin-left:-0.75rem !important}

.mb-lg-n75,.my-lg-n75{margin-bottom:-0.75rem !important}

[dir="ltr"] .ml-lg-n75,[dir="ltr"] .mx-lg-n75{margin-left:-0.75rem !important}

[dir="rtl"] .ml-lg-n75,[dir="rtl"] .mx-lg-n75{margin-right:-0.75rem !important}

.m-lg-auto{margin:auto !important}

.mt-lg-auto,.my-lg-auto{margin-top:auto !important}

[dir="ltr"] .mr-lg-auto,[dir="ltr"] .mx-lg-auto{margin-right:auto !important}

[dir="rtl"] .mr-lg-auto,[dir="rtl"] .mx-lg-auto{margin-left:auto !important}

.mb-lg-auto,.my-lg-auto{margin-bottom:auto !important}

[dir="ltr"] .ml-lg-auto,[dir="ltr"] .mx-lg-auto{margin-left:auto !important}

[dir="rtl"] .ml-lg-auto,[dir="rtl"] .mx-lg-auto{margin-right:auto !important}}

@media(min-width: 1200px){
.m-xl-0{margin:0 !important}

.mt-xl-0,.my-xl-0{margin-top:0 !important}

[dir="ltr"] .mr-xl-0,[dir="ltr"] .mx-xl-0{margin-right:0 !important}

[dir="rtl"] .mr-xl-0,[dir="rtl"] .mx-xl-0{margin-left:0 !important}

.mb-xl-0,.my-xl-0{margin-bottom:0 !important}

[dir="ltr"] .ml-xl-0,[dir="ltr"] .mx-xl-0{margin-left:0 !important}

[dir="rtl"] .ml-xl-0,[dir="rtl"] .mx-xl-0{margin-right:0 !important}

.m-xl-1{margin:1rem !important}

.mt-xl-1,.my-xl-1{margin-top:1rem !important}

[dir="ltr"] .mr-xl-1,[dir="ltr"] .mx-xl-1{margin-right:1rem !important}

[dir="rtl"] .mr-xl-1,[dir="rtl"] .mx-xl-1{margin-left:1rem !important}

.mb-xl-1,.my-xl-1{margin-bottom:1rem !important}

[dir="ltr"] .ml-xl-1,[dir="ltr"] .mx-xl-1{margin-left:1rem !important}

[dir="rtl"] .ml-xl-1,[dir="rtl"] .mx-xl-1{margin-right:1rem !important}

.m-xl-2{margin:1.5rem !important}

.mt-xl-2,.my-xl-2{margin-top:1.5rem !important}

[dir="ltr"] .mr-xl-2,[dir="ltr"] .mx-xl-2{margin-right:1.5rem !important}

[dir="rtl"] .mr-xl-2,[dir="rtl"] .mx-xl-2{margin-left:1.5rem !important}

.mb-xl-2,.my-xl-2{margin-bottom:1.5rem !important}

[dir="ltr"] .ml-xl-2,[dir="ltr"] .mx-xl-2{margin-left:1.5rem !important}

[dir="rtl"] .ml-xl-2,[dir="rtl"] .mx-xl-2{margin-right:1.5rem !important}

.m-xl-3{margin:3rem !important}

.mt-xl-3,.my-xl-3{margin-top:3rem !important}

[dir="ltr"] .mr-xl-3,[dir="ltr"] .mx-xl-3{margin-right:3rem !important}

[dir="rtl"] .mr-xl-3,[dir="rtl"] .mx-xl-3{margin-left:3rem !important}

.mb-xl-3,.my-xl-3{margin-bottom:3rem !important}

[dir="ltr"] .ml-xl-3,[dir="ltr"] .mx-xl-3{margin-left:3rem !important}

[dir="rtl"] .ml-xl-3,[dir="rtl"] .mx-xl-3{margin-right:3rem !important}

.m-xl-4{margin:3.5rem !important}

.mt-xl-4,.my-xl-4{margin-top:3.5rem !important}

[dir="ltr"] .mr-xl-4,[dir="ltr"] .mx-xl-4{margin-right:3.5rem !important}

[dir="rtl"] .mr-xl-4,[dir="rtl"] .mx-xl-4{margin-left:3.5rem !important}

.mb-xl-4,.my-xl-4{margin-bottom:3.5rem !important}

[dir="ltr"] .ml-xl-4,[dir="ltr"] .mx-xl-4{margin-left:3.5rem !important}

[dir="rtl"] .ml-xl-4,[dir="rtl"] .mx-xl-4{margin-right:3.5rem !important}

.m-xl-5{margin:4rem !important}

.mt-xl-5,.my-xl-5{margin-top:4rem !important}

[dir="ltr"] .mr-xl-5,[dir="ltr"] .mx-xl-5{margin-right:4rem !important}

[dir="rtl"] .mr-xl-5,[dir="rtl"] .mx-xl-5{margin-left:4rem !important}

.mb-xl-5,.my-xl-5{margin-bottom:4rem !important}

[dir="ltr"] .ml-xl-5,[dir="ltr"] .mx-xl-5{margin-left:4rem !important}

[dir="rtl"] .ml-xl-5,[dir="rtl"] .mx-xl-5{margin-right:4rem !important}

.m-xl-25{margin:.25rem !important}

.mt-xl-25,.my-xl-25{margin-top:.25rem !important}

[dir="ltr"] .mr-xl-25,[dir="ltr"] .mx-xl-25{margin-right:.25rem !important}

[dir="rtl"] .mr-xl-25,[dir="rtl"] .mx-xl-25{margin-left:.25rem !important}

.mb-xl-25,.my-xl-25{margin-bottom:.25rem !important}

[dir="ltr"] .ml-xl-25,[dir="ltr"] .mx-xl-25{margin-left:.25rem !important}

[dir="rtl"] .ml-xl-25,[dir="rtl"] .mx-xl-25{margin-right:.25rem !important}

.m-xl-50{margin:.5rem !important}

.mt-xl-50,.my-xl-50{margin-top:.5rem !important}

[dir="ltr"] .mr-xl-50,[dir="ltr"] .mx-xl-50{margin-right:.5rem !important}

[dir="rtl"] .mr-xl-50,[dir="rtl"] .mx-xl-50{margin-left:.5rem !important}

.mb-xl-50,.my-xl-50{margin-bottom:.5rem !important}

[dir="ltr"] .ml-xl-50,[dir="ltr"] .mx-xl-50{margin-left:.5rem !important}

[dir="rtl"] .ml-xl-50,[dir="rtl"] .mx-xl-50{margin-right:.5rem !important}

.m-xl-75{margin:.75rem !important}

.mt-xl-75,.my-xl-75{margin-top:.75rem !important}

[dir="ltr"] .mr-xl-75,[dir="ltr"] .mx-xl-75{margin-right:.75rem !important}

[dir="rtl"] .mr-xl-75,[dir="rtl"] .mx-xl-75{margin-left:.75rem !important}

.mb-xl-75,.my-xl-75{margin-bottom:.75rem !important}

[dir="ltr"] .ml-xl-75,[dir="ltr"] .mx-xl-75{margin-left:.75rem !important}

[dir="rtl"] .ml-xl-75,[dir="rtl"] .mx-xl-75{margin-right:.75rem !important}

.p-xl-0{padding:0 !important}

.pt-xl-0,.py-xl-0{padding-top:0 !important}

[dir="ltr"] .pr-xl-0,[dir="ltr"] .px-xl-0{padding-right:0 !important}

[dir="rtl"] .pr-xl-0,[dir="rtl"] .px-xl-0{padding-left:0 !important}

.pb-xl-0,.py-xl-0{padding-bottom:0 !important}

[dir="ltr"] .pl-xl-0,[dir="ltr"] .px-xl-0{padding-left:0 !important}

[dir="rtl"] .pl-xl-0,[dir="rtl"] .px-xl-0{padding-right:0 !important}

.p-xl-1{padding:1rem !important}

.pt-xl-1,.py-xl-1{padding-top:1rem !important}

[dir="ltr"] .pr-xl-1,[dir="ltr"] .px-xl-1{padding-right:1rem !important}

[dir="rtl"] .pr-xl-1,[dir="rtl"] .px-xl-1{padding-left:1rem !important}

.pb-xl-1,.py-xl-1{padding-bottom:1rem !important}

[dir="ltr"] .pl-xl-1,[dir="ltr"] .px-xl-1{padding-left:1rem !important}

[dir="rtl"] .pl-xl-1,[dir="rtl"] .px-xl-1{padding-right:1rem !important}

.p-xl-2{padding:1.5rem !important}

.pt-xl-2,.py-xl-2{padding-top:1.5rem !important}

[dir="ltr"] .pr-xl-2,[dir="ltr"] .px-xl-2{padding-right:1.5rem !important}

[dir="rtl"] .pr-xl-2,[dir="rtl"] .px-xl-2{padding-left:1.5rem !important}

.pb-xl-2,.py-xl-2{padding-bottom:1.5rem !important}

[dir="ltr"] .pl-xl-2,[dir="ltr"] .px-xl-2{padding-left:1.5rem !important}

[dir="rtl"] .pl-xl-2,[dir="rtl"] .px-xl-2{padding-right:1.5rem !important}

.p-xl-3{padding:3rem !important}

.pt-xl-3,.py-xl-3{padding-top:3rem !important}

[dir="ltr"] .pr-xl-3,[dir="ltr"] .px-xl-3{padding-right:3rem !important}

[dir="rtl"] .pr-xl-3,[dir="rtl"] .px-xl-3{padding-left:3rem !important}

.pb-xl-3,.py-xl-3{padding-bottom:3rem !important}

[dir="ltr"] .pl-xl-3,[dir="ltr"] .px-xl-3{padding-left:3rem !important}

[dir="rtl"] .pl-xl-3,[dir="rtl"] .px-xl-3{padding-right:3rem !important}

.p-xl-4{padding:3.5rem !important}

.pt-xl-4,.py-xl-4{padding-top:3.5rem !important}

[dir="ltr"] .pr-xl-4,[dir="ltr"] .px-xl-4{padding-right:3.5rem !important}

[dir="rtl"] .pr-xl-4,[dir="rtl"] .px-xl-4{padding-left:3.5rem !important}

.pb-xl-4,.py-xl-4{padding-bottom:3.5rem !important}

[dir="ltr"] .pl-xl-4,[dir="ltr"] .px-xl-4{padding-left:3.5rem !important}

[dir="rtl"] .pl-xl-4,[dir="rtl"] .px-xl-4{padding-right:3.5rem !important}

.p-xl-5{padding:4rem !important}

.pt-xl-5,.py-xl-5{padding-top:4rem !important}

[dir="ltr"] .pr-xl-5,[dir="ltr"] .px-xl-5{padding-right:4rem !important}

[dir="rtl"] .pr-xl-5,[dir="rtl"] .px-xl-5{padding-left:4rem !important}

.pb-xl-5,.py-xl-5{padding-bottom:4rem !important}

[dir="ltr"] .pl-xl-5,[dir="ltr"] .px-xl-5{padding-left:4rem !important}

[dir="rtl"] .pl-xl-5,[dir="rtl"] .px-xl-5{padding-right:4rem !important}

.p-xl-25{padding:.25rem !important}

.pt-xl-25,.py-xl-25{padding-top:.25rem !important}

[dir="ltr"] .pr-xl-25,[dir="ltr"] .px-xl-25{padding-right:.25rem !important}

[dir="rtl"] .pr-xl-25,[dir="rtl"] .px-xl-25{padding-left:.25rem !important}

.pb-xl-25,.py-xl-25{padding-bottom:.25rem !important}

[dir="ltr"] .pl-xl-25,[dir="ltr"] .px-xl-25{padding-left:.25rem !important}

[dir="rtl"] .pl-xl-25,[dir="rtl"] .px-xl-25{padding-right:.25rem !important}

.p-xl-50{padding:.5rem !important}

.pt-xl-50,.py-xl-50{padding-top:.5rem !important}

[dir="ltr"] .pr-xl-50,[dir="ltr"] .px-xl-50{padding-right:.5rem !important}

[dir="rtl"] .pr-xl-50,[dir="rtl"] .px-xl-50{padding-left:.5rem !important}

.pb-xl-50,.py-xl-50{padding-bottom:.5rem !important}

[dir="ltr"] .pl-xl-50,[dir="ltr"] .px-xl-50{padding-left:.5rem !important}

[dir="rtl"] .pl-xl-50,[dir="rtl"] .px-xl-50{padding-right:.5rem !important}

.p-xl-75{padding:.75rem !important}

.pt-xl-75,.py-xl-75{padding-top:.75rem !important}

[dir="ltr"] .pr-xl-75,[dir="ltr"] .px-xl-75{padding-right:.75rem !important}

[dir="rtl"] .pr-xl-75,[dir="rtl"] .px-xl-75{padding-left:.75rem !important}

.pb-xl-75,.py-xl-75{padding-bottom:.75rem !important}

[dir="ltr"] .pl-xl-75,[dir="ltr"] .px-xl-75{padding-left:.75rem !important}

[dir="rtl"] .pl-xl-75,[dir="rtl"] .px-xl-75{padding-right:.75rem !important}

.m-xl-n1{margin:-1rem !important}

.mt-xl-n1,.my-xl-n1{margin-top:-1rem !important}

[dir="ltr"] .mr-xl-n1,[dir="ltr"] .mx-xl-n1{margin-right:-1rem !important}

[dir="rtl"] .mr-xl-n1,[dir="rtl"] .mx-xl-n1{margin-left:-1rem !important}

.mb-xl-n1,.my-xl-n1{margin-bottom:-1rem !important}

[dir="ltr"] .ml-xl-n1,[dir="ltr"] .mx-xl-n1{margin-left:-1rem !important}

[dir="rtl"] .ml-xl-n1,[dir="rtl"] .mx-xl-n1{margin-right:-1rem !important}

.m-xl-n2{margin:-1.5rem !important}

.mt-xl-n2,.my-xl-n2{margin-top:-1.5rem !important}

[dir="ltr"] .mr-xl-n2,[dir="ltr"] .mx-xl-n2{margin-right:-1.5rem !important}

[dir="rtl"] .mr-xl-n2,[dir="rtl"] .mx-xl-n2{margin-left:-1.5rem !important}

.mb-xl-n2,.my-xl-n2{margin-bottom:-1.5rem !important}

[dir="ltr"] .ml-xl-n2,[dir="ltr"] .mx-xl-n2{margin-left:-1.5rem !important}

[dir="rtl"] .ml-xl-n2,[dir="rtl"] .mx-xl-n2{margin-right:-1.5rem !important}

.m-xl-n3{margin:-3rem !important}

.mt-xl-n3,.my-xl-n3{margin-top:-3rem !important}

[dir="ltr"] .mr-xl-n3,[dir="ltr"] .mx-xl-n3{margin-right:-3rem !important}

[dir="rtl"] .mr-xl-n3,[dir="rtl"] .mx-xl-n3{margin-left:-3rem !important}

.mb-xl-n3,.my-xl-n3{margin-bottom:-3rem !important}

[dir="ltr"] .ml-xl-n3,[dir="ltr"] .mx-xl-n3{margin-left:-3rem !important}

[dir="rtl"] .ml-xl-n3,[dir="rtl"] .mx-xl-n3{margin-right:-3rem !important}

.m-xl-n4{margin:-3.5rem !important}

.mt-xl-n4,.my-xl-n4{margin-top:-3.5rem !important}

[dir="ltr"] .mr-xl-n4,[dir="ltr"] .mx-xl-n4{margin-right:-3.5rem !important}

[dir="rtl"] .mr-xl-n4,[dir="rtl"] .mx-xl-n4{margin-left:-3.5rem !important}

.mb-xl-n4,.my-xl-n4{margin-bottom:-3.5rem !important}

[dir="ltr"] .ml-xl-n4,[dir="ltr"] .mx-xl-n4{margin-left:-3.5rem !important}

[dir="rtl"] .ml-xl-n4,[dir="rtl"] .mx-xl-n4{margin-right:-3.5rem !important}

.m-xl-n5{margin:-4rem !important}

.mt-xl-n5,.my-xl-n5{margin-top:-4rem !important}

[dir="ltr"] .mr-xl-n5,[dir="ltr"] .mx-xl-n5{margin-right:-4rem !important}

[dir="rtl"] .mr-xl-n5,[dir="rtl"] .mx-xl-n5{margin-left:-4rem !important}

.mb-xl-n5,.my-xl-n5{margin-bottom:-4rem !important}

[dir="ltr"] .ml-xl-n5,[dir="ltr"] .mx-xl-n5{margin-left:-4rem !important}

[dir="rtl"] .ml-xl-n5,[dir="rtl"] .mx-xl-n5{margin-right:-4rem !important}

.m-xl-n25{margin:-0.25rem !important}

.mt-xl-n25,.my-xl-n25{margin-top:-0.25rem !important}

[dir="ltr"] .mr-xl-n25,[dir="ltr"] .mx-xl-n25{margin-right:-0.25rem !important}

[dir="rtl"] .mr-xl-n25,[dir="rtl"] .mx-xl-n25{margin-left:-0.25rem !important}

.mb-xl-n25,.my-xl-n25{margin-bottom:-0.25rem !important}

[dir="ltr"] .ml-xl-n25,[dir="ltr"] .mx-xl-n25{margin-left:-0.25rem !important}

[dir="rtl"] .ml-xl-n25,[dir="rtl"] .mx-xl-n25{margin-right:-0.25rem !important}

.m-xl-n50{margin:-0.5rem !important}

.mt-xl-n50,.my-xl-n50{margin-top:-0.5rem !important}

[dir="ltr"] .mr-xl-n50,[dir="ltr"] .mx-xl-n50{margin-right:-0.5rem !important}

[dir="rtl"] .mr-xl-n50,[dir="rtl"] .mx-xl-n50{margin-left:-0.5rem !important}

.mb-xl-n50,.my-xl-n50{margin-bottom:-0.5rem !important}

[dir="ltr"] .ml-xl-n50,[dir="ltr"] .mx-xl-n50{margin-left:-0.5rem !important}

[dir="rtl"] .ml-xl-n50,[dir="rtl"] .mx-xl-n50{margin-right:-0.5rem !important}

.m-xl-n75{margin:-0.75rem !important}

.mt-xl-n75,.my-xl-n75{margin-top:-0.75rem !important}

[dir="ltr"] .mr-xl-n75,[dir="ltr"] .mx-xl-n75{margin-right:-0.75rem !important}

[dir="rtl"] .mr-xl-n75,[dir="rtl"] .mx-xl-n75{margin-left:-0.75rem !important}

.mb-xl-n75,.my-xl-n75{margin-bottom:-0.75rem !important}

[dir="ltr"] .ml-xl-n75,[dir="ltr"] .mx-xl-n75{margin-left:-0.75rem !important}

[dir="rtl"] .ml-xl-n75,[dir="rtl"] .mx-xl-n75{margin-right:-0.75rem !important}

.m-xl-auto{margin:auto !important}

.mt-xl-auto,.my-xl-auto{margin-top:auto !important}

[dir="ltr"] .mr-xl-auto,[dir="ltr"] .mx-xl-auto{margin-right:auto !important}

[dir="rtl"] .mr-xl-auto,[dir="rtl"] .mx-xl-auto{margin-left:auto !important}

.mb-xl-auto,.my-xl-auto{margin-bottom:auto !important}

[dir="ltr"] .ml-xl-auto,[dir="ltr"] .mx-xl-auto{margin-left:auto !important}

[dir="rtl"] .ml-xl-auto,[dir="rtl"] .mx-xl-auto{margin-right:auto !important}}

.stretched-link::after{position:absolute;top:0;right:0;bottom:0;left:0;z-index:1;pointer-events:auto;content:"";background-color:rgba(0,0,0,0)}

.text-monospace{font-family:"Montserrat",Helvetica,Arial,serif !important}

.text-justify{text-align:justify !important}

.text-wrap{white-space:normal !important}

.text-nowrap{white-space:nowrap !important}

.text-truncate{overflow:hidden;text-overflow:ellipsis;white-space:nowrap}

[dir="ltr"] .text-left{text-align:left !important}

[dir="rtl"] .text-left{text-align:right !important}

[dir="ltr"] .text-right{text-align:right !important}

[dir="rtl"] .text-right{text-align:left !important}

.text-center{text-align:center !important}

@media(min-width: 576px){
[dir="ltr"] .text-sm-left{text-align:left !important}

[dir="rtl"] .text-sm-left{text-align:right !important}

[dir="ltr"] .text-sm-right{text-align:right !important}

[dir="rtl"] .text-sm-right{text-align:left !important}

.text-sm-center{text-align:center !important}}

@media(min-width: 768px){
[dir="ltr"] .text-md-left{text-align:left !important}

[dir="rtl"] .text-md-left{text-align:right !important}

[dir="ltr"] .text-md-right{text-align:right !important}

[dir="rtl"] .text-md-right{text-align:left !important}

.text-md-center{text-align:center !important}}

@media(min-width: 992px){
[dir="ltr"] .text-lg-left{text-align:left !important}

[dir="rtl"] .text-lg-left{text-align:right !important}

[dir="ltr"] .text-lg-right{text-align:right !important}

[dir="rtl"] .text-lg-right{text-align:left !important}

.text-lg-center{text-align:center !important}}

@media(min-width: 1200px){
[dir="ltr"] .text-xl-left{text-align:left !important}

[dir="rtl"] .text-xl-left{text-align:right !important}

[dir="ltr"] .text-xl-right{text-align:right !important}

[dir="rtl"] .text-xl-right{text-align:left !important}

.text-xl-center{text-align:center !important}}

.text-lowercase{text-transform:lowercase !important}

.text-uppercase{text-transform:uppercase !important}

.text-capitalize{text-transform:capitalize !important}

.font-weight-light{font-weight:300 !important}

.font-weight-lighter{font-weight:lighter !important}

.font-weight-normal{font-weight:400 !important}

.font-weight-bold{font-weight:500 !important}

.font-weight-bolder{font-weight:600 !important}

.font-italic{font-style:italic !important}

.text-white{color:#fff !important}

.text-primary{color:#a94cd8 !important}

a.text-primary:hover,a.text-primary:focus{color:#8227b1 !important}

.text-secondary{color:#82868b !important}

a.text-secondary:hover,a.text-secondary:focus{color:#5d6064 !important}

.text-success{color:#28c76f !important}

a.text-success:hover,a.text-success:focus{color:#1b874b !important}

.text-info{color:#00cfe8 !important}

a.text-info:hover,a.text-info:focus{color:#008b9c !important}

.text-warning{color:#ff9f43 !important}

a.text-warning:hover,a.text-warning:focus{color:#f67800 !important}

.text-danger{color:#ea5455 !important}

a.text-danger:hover,a.text-danger:focus{color:#d71a1c !important}

.text-light{color:#f6f6f6 !important}

a.text-light:hover,a.text-light:focus{color:#d0d0d0 !important}

.text-dark{color:#161d31 !important}

a.text-dark:hover,a.text-dark:focus{color:#000 !important}

.text-body{color:#6e6b7b !important}

.text-muted{color:#b9b9c3 !important}

.text-black-50{color:rgba(34,41,47,.5) !important}

.text-white-50{color:rgba(255,255,255,.5) !important}

.text-hide{font:0/0 a;color:transparent;text-shadow:none;background-color:transparent;border:0}

.text-decoration-none{text-decoration:none !important}

.text-break{word-break:break-word !important;word-wrap:break-word !important}

.text-reset{color:inherit !important}

.visible{visibility:visible !important}

.invisible{visibility:hidden !important}

@media print{
*,*::before,*::after{text-shadow:none !important;box-shadow:none !important}

a:not(.btn){text-decoration:underline}

abbr[title]::after{content:" (" attr(title) ")"}

pre{white-space:pre-wrap !important}

pre,blockquote{border:1px solid #adb5bd;page-break-inside:avoid}

thead{display:table-header-group}

tr,img{page-break-inside:avoid}

p,h2,h3{orphans:3;widows:3}

h2,h3{page-break-after:avoid}

@page{size:a3}

body{min-width:992px !important}

.container{min-width:992px !important}

.navbar{display:none}

.badge{border:1px solid #22292f}

.table{border-collapse:collapse !important}

.table td,.table th{background-color:#fff !important}

.table-bordered th,.table-bordered td{border:1px solid #dae1e7 !important}

.table-dark{color:inherit}

.table-dark th,.table-dark td,.table-dark thead th,.table-dark tbody+tbody{border-color:#ebe9f1}

.table .thead-dark th{color:inherit;border-color:#ebe9f1}}

a:focus{outline:none}

a:not([href]){color:inherit;text-decoration:none}

a:not([href]):hover{color:inherit;text-decoration:none}

.list-style-icons{list-style:none}

[dir="ltr"] .list-style-icons{padding-left:10px;margin-left:0}

[dir="rtl"] .list-style-icons{padding-right:10px;margin-right:0}

[dir="ltr"] .list-style-icons>li svg,[dir="ltr"] .list-style-icons>li i{margin-right:6px}

[dir="rtl"] .list-style-icons>li svg,[dir="rtl"] .list-style-icons>li i{margin-left:6px}

.pull-up{transition:all .25s ease}

.pull-up:hover{transform:translateY(-4px) scale(1.02);box-shadow:0px 14px 24px rgba(62,57,107,.2);z-index:30}

.spinner{display:inline-block;animation:spin 1s linear infinite}

@keyframes spin{
0%{transform:rotate(0deg)}

100%{transform:rotate(360deg)}}

.spinner-reverse{display:inline-block;animation:spin-reverse 1s linear infinite}

@keyframes spin-reverse{
0%{transform:rotate(0deg)}

100%{transform:rotate(-360deg)}}

.bullet{width:1rem;height:1rem;border-radius:50%;display:inline-block}

.bullet.bullet-xs{width:.5rem;height:.5rem}

.bullet.bullet-sm{width:.714rem;height:.714rem}

.bullet.bullet-lg{width:1.25rem;height:1.25rem}

.section-label{font-size:.85rem;color:#b9b9c3;text-transform:uppercase;letter-spacing:.6px}

.cell-fit{width:.1%;white-space:nowrap}

.match-height>[class*=col]{display:flex;flex-flow:column}

.match-height>[class*=col]>.card{flex:1 1 auto}

.text-body-heading{color:#5e5873}

.text-body-heading:hover{color:inherit !important}

.text-body[href]:hover{color:#9f37d3 !important}

code{padding:.1rem .4rem;font-size:90%;color:#e83e8c;background-color:#eee;border-radius:.1785rem}

pre{background-color:#f7f7f9}

pre code{background-color:transparent !important}

.table-white-space th,.table-white-space td{white-space:nowrap}

.table thead th,.table tfoot th{vertical-align:top;text-transform:uppercase;font-size:.857rem;letter-spacing:.5px}

.table th,.table td{padding:.72rem 2rem;vertical-align:middle}

.table.table-sm th,.table.table-sm td{padding:.3rem .5rem}

[dir="ltr"] .table.table-sm th:first-child,[dir="ltr"] .table.table-sm td:first-child{padding-left:.75rem}

[dir="rtl"] .table.table-sm th:first-child,[dir="rtl"] .table.table-sm td:first-child{padding-right:.75rem}

.table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,.table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th{background-color:#f3f2f7}

.table-hover tbody tr{cursor:pointer}

.table-hover-animation{background-color:#f6f6f6}

.table-hover-animation thead th{border-bottom:0}

.table-hover-animation th,.table-hover-animation td{border:0}

.table-hover-animation tbody tr{transition:all .25s ease;background-color:#fff}

.table-hover-animation tbody tr:hover{transform:translateY(-4px)}

.card .table{margin-bottom:0;border-bottom-left-radius:.357rem;border-bottom-right-radius:.357rem}

[dir="ltr"] .card .table tbody tr:last-child td:first-child{border-bottom-left-radius:.357rem}

[dir="rtl"] .card .table tbody tr:last-child td:first-child{border-bottom-right-radius:.357rem}

[dir="ltr"] .card .table tbody tr:last-child td:last-child{border-bottom-right-radius:.357rem}

[dir="rtl"] .card .table tbody tr:last-child td:last-child{border-bottom-left-radius:.357rem}

[dir="ltr"] .card table tr[class*=table-]:last-child td:first-child{border-bottom-left-radius:.357rem}

[dir="rtl"] .card table tr[class*=table-]:last-child td:first-child{border-bottom-right-radius:.357rem}

[dir="ltr"] .card table tr[class*=table-]:last-child td:last-child{border-bottom-right-radius:.357rem}

[dir="rtl"] .card table tr[class*=table-]:last-child td:last-child{border-bottom-left-radius:.357rem}

@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none){
.table-hover-animation tr:hover{border-bottom-color:#ebe9f1}}

label{color:#5e5873;font-size:.857rem}

.form-control::placeholder{transition:all .2s ease}

.form-control:focus::placeholder{transition:all .2s ease}

[dir="ltr"] .form-control:focus::placeholder{transform:translate(5px)}

[dir="rtl"] .form-control:focus::placeholder{transform:translate(-5px)}

.form-control:focus:valid,.form-control:focus.is-valid{box-shadow:0 3px 10px 0 rgba(34,41,47,.1)}

.form-control-plaintext:focus{outline:none}

.custom-file-label{line-height:1.75;height:2.714rem !important}

.custom-file-label:after{height:2.58rem;line-height:1.75}

.valid-tooltip,.invalid-tooltip{top:102%}

[dir="ltr"] .valid-tooltip,[dir="ltr"] .invalid-tooltip{left:5px}

[dir="rtl"] .valid-tooltip,[dir="rtl"] .invalid-tooltip{right:5px}

.input-group:not(.bootstrap-touchspin):focus-within{box-shadow:0 3px 10px 0 rgba(34,41,47,.1);border-radius:.357rem}

.input-group:not(.bootstrap-touchspin):focus-within .form-control,.input-group:not(.bootstrap-touchspin):focus-within .input-group-text{border-color:#a94cd8;box-shadow:none}

.input-group:not(.bootstrap-touchspin):focus-within .form-control.is-valid,.input-group:not(.bootstrap-touchspin):focus-within .input-group-text.is-valid{border-color:#28c76f}

.input-group:not(.bootstrap-touchspin):focus-within .form-control.is-invalid,.input-group:not(.bootstrap-touchspin):focus-within .input-group-text.is-invalid{border-color:#ea5455}

.input-group.is-valid .input-group-text{border-color:#28c76f !important}

.input-group.is-valid:not(.bootstrap-touchspin):focus-within .input-group-text{border-color:#28c76f}

.input-group.is-invalid .input-group-text{border-color:#ea5455 !important}

.input-group.is-invalid:not(.bootstrap-touchspin):focus-within .input-group-text{border-color:#ea5455}

.input-group.disabled .input-group-text{background-color:#efefef}

.input-group.round .form-control,.input-group.round .input-group-text,.input-group.round:focus-within{border-radius:1.5rem}

.input-group.square .form-control,.input-group.square .input-group-text,.input-group.square:focus-within{border-radius:0}

[dir="ltr"] .input-group.round .input-group-prepend .input-group-text,[dir="ltr"] .input-group.square .input-group-prepend .input-group-text{border-top-right-radius:0;border-bottom-right-radius:0}

[dir="rtl"] .input-group.round .input-group-prepend .input-group-text,[dir="rtl"] .input-group.square .input-group-prepend .input-group-text{border-top-left-radius:0;border-bottom-left-radius:0}

[dir="ltr"] .input-group.round .input-group-append .input-group-text,[dir="ltr"] .input-group.square .input-group-append .input-group-text{border-top-left-radius:0;border-bottom-left-radius:0}

[dir="rtl"] .input-group.round .input-group-append .input-group-text,[dir="rtl"] .input-group.square .input-group-append .input-group-text{border-top-right-radius:0;border-bottom-right-radius:0}

[dir="ltr"] .input-group.round .form-control:not(:first-child),[dir="ltr"] .input-group.square .form-control:not(:first-child){border-top-left-radius:0;border-bottom-left-radius:0}

[dir="rtl"] .input-group.round .form-control:not(:first-child),[dir="rtl"] .input-group.square .form-control:not(:first-child){border-top-right-radius:0;border-bottom-right-radius:0}

[dir="ltr"] .input-group.round .form-control:not(:last-child),[dir="ltr"] .input-group.square .form-control:not(:last-child){border-top-right-radius:0;border-bottom-right-radius:0}

[dir="rtl"] .input-group.round .form-control:not(:last-child),[dir="rtl"] .input-group.square .form-control:not(:last-child){border-top-left-radius:0;border-bottom-left-radius:0}

.input-group.square .form-control,.input-group.square .input-group-text{border-radius:0}

.input-group-text{transition:border-color .15s ease-in-out,box-shadow .15s ease-in-out}

@media(prefers-reduced-motion: reduce){
.input-group-text{transition:none}}

[dir="ltr"] .input-group-prepend .input-group-text{border-right:0}

[dir="rtl"] .input-group-prepend .input-group-text{border-left:0}

[dir="ltr"] .input-group-append .input-group-text{border-left:0}

[dir="rtl"] .input-group-append .input-group-text{border-right:0}

[dir="ltr"] .input-group-append:not(:last-child) .input-group-text{border-right:0}

[dir="rtl"] .input-group-append:not(:last-child) .input-group-text{border-left:0}

[dir="ltr"] .input-group-merge .form-control:not(:first-child){padding-left:0;border-left:0}

[dir="rtl"] .input-group-merge .form-control:not(:first-child){padding-right:0;border-right:0}

[dir="ltr"] .input-group-merge .form-control:not(:last-child){padding-right:0;border-right:0}

[dir="rtl"] .input-group-merge .form-control:not(:last-child){padding-left:0;border-left:0}

.input-group-merge .form-control.is-valid~.input-group-append .input-group-text{border-color:#28c76f}

.input-group-text .input-group-merge .input-group-prepend~.form-control.is-valid{border-color:#28c76f}

textarea.form-control{line-height:1.6rem;padding:.8rem 1rem !important}

textarea.form-control-sm{padding:.4rem .857rem !important}

textarea.form-control-lg{padding:1rem 1.143rem !important}

.form-actions{border-top:1px solid #ddd;padding:20px 0;margin-top:20px}

.form-actions.filled{background-color:#f6f6f6}

.form-actions.center{text-align:center}

[dir="ltr"] .form-actions.right{text-align:right}

[dir="rtl"] .form-actions.right{text-align:left}

.form-actions.top{border-top:0;border-bottom:1px solid #ddd;margin-top:0;margin-bottom:20px}

@media(max-width: 767.98px){
.form-actions .buttons-group{margin-bottom:10px}

[dir="ltr"] .form-actions .buttons-group{float:left !important}

[dir="rtl"] .form-actions .buttons-group{float:right !important}}

@media(min-width: 576px){
[dir="ltr"] .form-horizontal .form-group .label-control{text-align:right}

[dir="rtl"] .form-horizontal .form-group .label-control{text-align:left}}

select.form-control:not([multiple=multiple]){background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23d8d6de%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-down%27%3E%3Cpolyline points=%276 9 12 15 18 9%27%3E%3C/polyline%3E%3C/svg%3E");background-size:18px 14px,18px 14px;background-repeat:no-repeat;-webkit-appearance:none;-moz-appearance:none}

[dir="ltr"] select.form-control:not([multiple=multiple]){background-position:calc(100% - 12px) 13px,calc(100% - 20px) 13px,100% 0;padding-right:1.5rem}

[dir="rtl"] select.form-control:not([multiple=multiple]){background-position:calc(100% - (100% - 12px)) 13px,calc(100% - (100% - 20px)) 13px,0% 0;padding-left:1.5rem}

[dir="ltr"] select.form-control.form-control-lg{background-position:calc(100% - 12px) 16px,calc(100% - 20px) 16px,100% 0}

[dir="rtl"] select.form-control.form-control-lg{background-position:calc(100% - (100% - 12px)) 16px,calc(100% - (100% - 20px)) 16px,0% 0}

[dir="ltr"] select.form-control.form-control-sm{background-position:calc(100% - 12px) 8px,calc(100% - 20px) 8px,100% 0}

[dir="rtl"] select.form-control.form-control-sm{background-position:calc(100% - (100% - 12px)) 8px,calc(100% - (100% - 20px)) 8px,0% 0}

.custom-select{-moz-appearance:none;-webkit-appearance:none}

.custom-checkbox .custom-control-label::before,.custom-checkbox .custom-control-label::after{top:.07rem}

.custom-checkbox .custom-control-label::after{background-size:57%}

.custom-radio .custom-control-label::before{top:.1rem}

[dir="ltr"] .custom-checkbox.custom-control,[dir="ltr"] .custom-radio.custom-control{padding-left:1.8rem}

[dir="rtl"] .custom-checkbox.custom-control,[dir="rtl"] .custom-radio.custom-control{padding-right:1.8rem}

[dir="ltr"] .input-group .custom-checkbox.custom-control,[dir="ltr"] .input-group .custom-radio.custom-control{padding-left:1.3rem}

[dir="rtl"] .input-group .custom-checkbox.custom-control,[dir="rtl"] .input-group .custom-radio.custom-control{padding-right:1.3rem}

.custom-checkbox .custom-control-input,.custom-radio .custom-control-input{width:1.285rem;height:1.285rem}

.custom-checkbox .custom-control-label,.custom-radio .custom-control-label{font-size:1rem;position:static}

.custom-checkbox .custom-control-label::before,.custom-checkbox .custom-control-label::after,.custom-radio .custom-control-label::before,.custom-radio .custom-control-label::after{width:18px;height:18px}

[dir="ltr"] .custom-checkbox .custom-control-label::before,[dir="ltr"] .custom-checkbox .custom-control-label::after,[dir="ltr"] .custom-radio .custom-control-label::before,[dir="ltr"] .custom-radio .custom-control-label::after{left:0}

[dir="rtl"] .custom-checkbox .custom-control-label::before,[dir="rtl"] .custom-checkbox .custom-control-label::after,[dir="rtl"] .custom-radio .custom-control-label::before,[dir="rtl"] .custom-radio .custom-control-label::after{right:0}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before,.custom-radio .custom-control-input:checked~.custom-control-label::before{box-shadow:0 2px 4px 0 rgba(169,76,216,.4) !important}

.custom-checkbox .custom-control-input:disabled~.custom-control-label::before,.custom-radio .custom-control-input:disabled~.custom-control-label::before{border:none;box-shadow:none !important}

.custom-checkbox .custom-control-input:focus~.custom-control-label::before,.custom-radio .custom-control-input:focus~.custom-control-label::before{border-color:none;box-shadow:0 2px 4px 0 rgba(169,76,216,.4) !important}

.custom-control-input:not(:disabled):active~.custom-control-label::before{background-color:#a94cd8;border-color:#a94cd8}

.form-label-group{position:relative;margin-bottom:1rem}

.form-label-group .form-control-lg~label{font-size:1rem;padding:1rem}

.form-label-group>label{position:absolute;top:0;display:block;transition:all .25s ease-in-out;padding:.6rem;pointer-events:none;cursor:text;color:rgba(34,41,47,.4);font-size:.7rem;opacity:0}

[dir="ltr"] .form-label-group>label{left:0}

[dir="rtl"] .form-label-group>label{right:0}

.form-label-group>input:focus:not(:placeholder-shown)~label,.form-label-group>input:not(:active):not(:placeholder-shown)~label,.form-label-group textarea:focus:not(:placeholder-shown)~label,.form-label-group textarea:not(:active):not(:placeholder-shown)~label{color:#a94cd8 !important;transition:all .25s ease-in-out;opacity:1}

.form-label-group>input:not(:focus):not(:placeholder-shown)~label,.form-label-group textarea:not(:focus):not(:placeholder-shown)~label{color:rgba(34,41,47,.4) !important}

.form-label-group>input:not(:placeholder-shown)~label,.form-label-group textarea:not(:placeholder-shown)~label{padding:.25rem 0;top:-20px}

[dir="ltr"] .form-label-group>input:not(:placeholder-shown)~label,[dir="ltr"] .form-label-group textarea:not(:placeholder-shown)~label{left:3px}

[dir="rtl"] .form-label-group>input:not(:placeholder-shown)~label,[dir="rtl"] .form-label-group textarea:not(:placeholder-shown)~label{right:3px}

.form-label-group>input.form-control-lg:not(:placeholder-shown)~label,.form-label-group textarea.form-control-lg:not(:placeholder-shown)~label{top:-23px}

.form-label-group>input.form-control-sm:not(:placeholder-shown)~label,.form-label-group textarea.form-control-sm:not(:placeholder-shown)~label{top:-18px}

.custom-switch{line-height:1.7rem}

[dir="ltr"] .custom-switch{padding-left:0}

[dir="rtl"] .custom-switch{padding-right:0}

.custom-switch .custom-control-label{line-height:1.7rem}

[dir="ltr"] .custom-switch .custom-control-label{padding-left:3.5rem}

[dir="rtl"] .custom-switch .custom-control-label{padding-right:3.5rem}

.custom-switch .custom-control-label::before{border:none;background-color:#e2e2e2;height:1.7rem;box-shadow:none !important;transition:opacity .25s ease,background-color .1s ease;cursor:pointer;user-select:none;top:0}

[dir="ltr"] .custom-switch .custom-control-label::before{left:0}

[dir="rtl"] .custom-switch .custom-control-label::before{right:0}

.custom-switch .custom-control-label:after{position:absolute;top:4px;background-color:#fff;transition:all .15s ease-out;cursor:pointer;user-select:none}

[dir="ltr"] .custom-switch .custom-control-label:after{left:4px;box-shadow:-1px 2px 3px 0 rgba(34,41,47,.2)}

[dir="rtl"] .custom-switch .custom-control-label:after{right:4px;box-shadow:1px 2px 3px 0 rgba(34,41,47,.2)}

.custom-switch .custom-control-label .switch-text-left,.custom-switch .custom-control-label .switch-text-right,.custom-switch .custom-control-label .switch-icon-left,.custom-switch .custom-control-label .switch-icon-right{position:absolute;cursor:pointer;user-select:none;line-height:1.8}

.custom-switch .custom-control-label .switch-text-left i,.custom-switch .custom-control-label .switch-text-left svg,.custom-switch .custom-control-label .switch-text-right i,.custom-switch .custom-control-label .switch-text-right svg,.custom-switch .custom-control-label .switch-icon-left i,.custom-switch .custom-control-label .switch-icon-left svg,.custom-switch .custom-control-label .switch-icon-right i,.custom-switch .custom-control-label .switch-icon-right svg{height:13px;width:13px;font-size:13px}

.custom-switch .custom-control-label .switch-text-left,.custom-switch .custom-control-label .switch-icon-left{color:#fff;opacity:0;transition:opacity .1s ease,transform .15s ease}

[dir="ltr"] .custom-switch .custom-control-label .switch-text-left,[dir="ltr"] .custom-switch .custom-control-label .switch-icon-left{left:6px;transform:translateX(8px)}

[dir="rtl"] .custom-switch .custom-control-label .switch-text-left,[dir="rtl"] .custom-switch .custom-control-label .switch-icon-left{right:6px;transform:translateX(-8px)}

.custom-switch .custom-control-label .switch-text-right,.custom-switch .custom-control-label .switch-icon-right{opacity:1;transform:translateX(0px);transition:opacity .08s ease,transform .15s ease}

[dir="ltr"] .custom-switch .custom-control-label .switch-text-right,[dir="ltr"] .custom-switch .custom-control-label .switch-icon-right{right:13px}

[dir="rtl"] .custom-switch .custom-control-label .switch-text-right,[dir="rtl"] .custom-switch .custom-control-label .switch-icon-right{left:13px}

.custom-switch .custom-control-label:focus{outline:0}

[dir="ltr"] .custom-switch .switch-label{padding-left:1rem}

[dir="rtl"] .custom-switch .switch-label{padding-right:1rem}

.custom-switch .custom-control-input:checked~.custom-control-label::before{box-shadow:none}

[dir="ltr"] .custom-switch .custom-control-input:checked~.custom-control-label::after{transform:translateX(1.4rem)}

[dir="rtl"] .custom-switch .custom-control-input:checked~.custom-control-label::after{transform:translateX(-1.4rem)}

.custom-switch .custom-control-input:checked~.custom-control-label .switch-text-left,.custom-switch .custom-control-input:checked~.custom-control-label .switch-icon-left{transform:translateX(0);opacity:1}

.custom-switch .custom-control-input:checked~.custom-control-label .switch-text-right,.custom-switch .custom-control-input:checked~.custom-control-label .switch-icon-right{opacity:0}

[dir="ltr"] .custom-switch .custom-control-input:checked~.custom-control-label .switch-text-right,[dir="ltr"] .custom-switch .custom-control-input:checked~.custom-control-label .switch-icon-right{transform:translateX(-8px)}

[dir="rtl"] .custom-switch .custom-control-input:checked~.custom-control-label .switch-text-right,[dir="rtl"] .custom-switch .custom-control-input:checked~.custom-control-label .switch-icon-right{transform:translateX(8px)}

.custom-switch .custom-control-input:not(:checked)~.custom-control-label .switch-text-left{opacity:0}

.custom-switch .custom-control-input:not(:checked)~.custom-control-label .switch-text-right{opacity:1}

.custom-switch .custom-control-input:checked~.custom-control-label .switch-text-right{opacity:0}

.custom-switch .custom-control-input:checked~.custom-control-label .switch-text-left{opacity:1}

.textarea-counter-value{background-color:#a94cd8;color:#fff;padding:1px 6px;font-size:.6rem;border-radius:0 0 5px 5px}

[dir="ltr"] .textarea-counter-value{margin-right:1rem}

[dir="rtl"] .textarea-counter-value{margin-left:1rem}

.btn.disabled-max-min{background-color:rgba(34,41,47,.5) !important;cursor:default}

input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button{-webkit-appearance:none}

@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none){
.form-label-group label{display:none}

select.form-control:not([multiple=multiple]){background:none}}

.picker__input.form-control{background-color:#fff}

.btn{box-shadow:none;font-weight:500}

.btn[class*=bg-gradient-]{transition:all .2s ease}

.btn[class*=bg-gradient-]:hover{transform:translateY(-2px)}

.btn.btn-icon{padding:.715rem .736rem}

.btn.btn-sm.btn-icon,.btn-group-sm>.btn.btn-icon{padding:.5rem;line-height:.5}

.btn.btn-lg.btn-icon,.btn-group-lg>.btn.btn-icon{padding:1rem;line-height:.75}

.btn:focus,.btn.focus,.btn:active,.btn.active{outline:none;box-shadow:none}

.btn:not(:disabled):not(.disabled):active:focus,.btn:not(:disabled):not(.disabled).active:focus{box-shadow:none !important}

.btn .feather{vertical-align:bottom}

.btn.waves-input-wrapper{padding:0}

@media(max-width: 767.98px){
.btn-sm-block{display:block;width:100%}}

.waves-effect{cursor:inherit}

.dropdown-toggle-split{padding-right:.7894736842rem;padding-left:.7894736842rem}

.btn-lg+.dropdown-toggle-split,.btn-group-lg>.btn+.dropdown-toggle-split{padding-right:1.1428571429rem;padding-left:1.1428571429rem}

.btn-sm+.dropdown-toggle-split,.btn-group-sm>.btn+.dropdown-toggle-split{padding-right:.5263157895rem;padding-left:.5263157895rem}

.btn-group [class*=btn-]:not([class*=btn-outline-]):not([class*=btn-flat-]):not([class*=btn-gradient-]):not([class*=btn-relief-]){border-left-color:rgba(34,41,47,.08) !important;border-right-color:rgba(34,41,47,.08) !important}

[dir="ltr"] .btn-group>.btn:not([class*=btn-outline-]):not([class*=btn-flat-]):not([class*=btn-gradient-]):not([class*=btn-relief-]):first-child,[dir="ltr"] .btn-group>.btn:not([class*=btn-outline-]):not([class*=btn-flat-]):not([class*=btn-gradient-]):not([class*=btn-relief-]):first-of-type{border-left-color:transparent !important}

[dir="rtl"] .btn-group>.btn:not([class*=btn-outline-]):not([class*=btn-flat-]):not([class*=btn-gradient-]):not([class*=btn-relief-]):first-child,[dir="rtl"] .btn-group>.btn:not([class*=btn-outline-]):not([class*=btn-flat-]):not([class*=btn-gradient-]):not([class*=btn-relief-]):first-of-type{border-right-color:transparent !important}

[dir="ltr"] .btn-group>.btn:not([class*=btn-outline-]):not([class*=btn-flat-]):not([class*=btn-gradient-]):not([class*=btn-relief-]):last-child,[dir="ltr"] .btn-group>.btn:not([class*=btn-outline-]):not([class*=btn-flat-]):not([class*=btn-gradient-]):not([class*=btn-relief-]):last-of-type{border-right-color:transparent !important}

[dir="rtl"] .btn-group>.btn:not([class*=btn-outline-]):not([class*=btn-flat-]):not([class*=btn-gradient-]):not([class*=btn-relief-]):last-child,[dir="rtl"] .btn-group>.btn:not([class*=btn-outline-]):not([class*=btn-flat-]):not([class*=btn-gradient-]):not([class*=btn-relief-]):last-of-type{border-left-color:transparent !important}

[dir="ltr"] .btn-group-toggle [class*=btn-outline-]:not(:last-child){border-right-width:0 !important}

[dir="rtl"] .btn-group-toggle [class*=btn-outline-]:not(:last-child){border-left-width:0 !important}

.btn-group-toggle :not([class*=btn-outline-]).active,.btn-group-toggle :not([class*=btn-outline-]):active{box-shadow:inset 0 4px 24px 0 rgba(34,41,47,.1)}

[dir="ltr"] .dropdown [class*=btn-outline-].dropdown-toggle.dropdown-toggle-split{border-left:0 !important}

[dir="rtl"] .dropdown [class*=btn-outline-].dropdown-toggle.dropdown-toggle-split{border-right:0 !important}

.dropdown.no-arrow .dropdown-toggle:after{display:none}

[dir="ltr"] .dropdown.no-arrow .dropdown-toggle i,[dir="ltr"] .dropdown.no-arrow .dropdown-toggle svg{margin-right:0}

[dir="rtl"] .dropdown.no-arrow .dropdown-toggle i,[dir="rtl"] .dropdown.no-arrow .dropdown-toggle svg{margin-left:0}

.dropdown-menu{border-radius:.358rem;transform:scale(1, 0);box-shadow:0 5px 25px rgba(34,41,47,.1)}

.dropdown-menu .dropdown-item{width:auto;cursor:pointer}

.dropdown-menu .dropdown-header{font-weight:500;line-height:1}

.show>.dropdown-menu{transform:scale(1, 1);opacity:1;display:block}

.show .dropdown-toggle:focus{box-shadow:none}

.show .btn.dropdown-toggle:focus{box-shadow:none}

.dropdown-toggle::after{border:none !important;content:" ";background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23fff%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-down%27%3E%3Cpolyline points=%276 9 12 15 18 9%27%3E%3C/polyline%3E%3C/svg%3E");background-repeat:no-repeat;background-position:center;background-size:16px;color:#6e6b7b;width:14px;height:11px;position:relative;top:1px;padding:0;margin:0;vertical-align:0}

[dir="ltr"] .dropdown-toggle::after{right:0px;left:.714rem}

[dir="rtl"] .dropdown-toggle::after{left:0px;right:.714rem}

.dropdown-toggle.btn-lg::after,.btn-group-lg>.dropdown-toggle.btn::after{background-size:18px}

.dropdown-toggle.btn-sm::after,.btn-group-sm>.dropdown-toggle.btn::after{background-size:14px}

[dir="ltr"] .dropdown-toggle.dropdown-toggle-split:after{left:0}

[dir="rtl"] .dropdown-toggle.dropdown-toggle-split:after{right:0}

.dropdown-toggle.nav-hide-arrow::after{display:none}

.dropdown-toggle:focus{outline:0}

.dropup{position:relative}

.dropup .dropdown-toggle::after{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23fff%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-up%27%3E%3Cpolyline points=%2718 15 12 9 6 15%27%3E%3C/polyline%3E%3C/svg%3E");background-repeat:no-repeat;background-position:center;background-size:16px;width:14px;height:11px;content:"";vertical-align:.05rem}

.dropup .dropdown-menu{min-width:8rem}

.dropleft .dropdown-toggle::before{border:none !important;background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23fff%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-left%27%3E%3Cpolyline points=%2715 18 9 12 15 6%27%3E%3C/polyline%3E%3C/svg%3E");background-repeat:no-repeat;background-position:center;background-size:16px;width:14px;height:11px;content:"";position:relative}

[dir="ltr"] .dropleft .dropdown-toggle::before{left:0}

[dir="rtl"] .dropleft .dropdown-toggle::before{right:0}

.dropleft .dropdown-menu{min-width:8rem}

.dropright .dropdown-toggle::after{border:none !important;background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23fff%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-right%27%3E%3Cpolyline points=%279 18 15 12 9 6%27%3E%3C/polyline%3E%3C/svg%3E");background-repeat:no-repeat;background-position:center;background-size:16px;width:14px;height:11px;content:""}

.dropright .dropdown-menu{min-width:8rem}

.dropdown-menu[x-placement=bottom-start],.dropdown-menu[x-placement=bottom-end]{margin-top:.5rem}

.dropdown-menu[x-placement=top-start],.dropdown-menu[x-placement=top-end]{margin-bottom:.5rem}

[dir="ltr"] .dropdown-menu[x-placement=right-start]{margin-left:.5rem}

[dir="rtl"] .dropdown-menu[x-placement=right-start]{margin-right:.5rem}

[dir="ltr"] .dropdown-menu[x-placement=left-start]{margin-right:.5rem}

[dir="rtl"] .dropdown-menu[x-placement=left-start]{margin-left:.5rem}

.dropdown.dropdown-icon-wrapper .dropdown-toggle:after,.dropup.dropdown-icon-wrapper .dropdown-toggle:after{display:none}

.dropdown.dropdown-icon-wrapper .dropdown-menu,.dropup.dropdown-icon-wrapper .dropdown-menu{min-width:auto}

.dropdown.dropdown-icon-wrapper .dropdown-menu .dropdown-item,.dropup.dropdown-icon-wrapper .dropdown-menu .dropdown-item{padding:.5rem 1.1rem;cursor:pointer}

.dropdown.dropdown-icon-wrapper .dropdown-menu .dropdown-item i,.dropdown.dropdown-icon-wrapper .dropdown-menu .dropdown-item svg,.dropup.dropdown-icon-wrapper .dropdown-menu .dropdown-item i,.dropup.dropdown-icon-wrapper .dropdown-menu .dropdown-item svg{height:1.3rem;width:1.3rem;font-size:1.3rem}

.horizontal-menu-wrapper .dropdown-menu,.header-navbar .dropdown-menu{animation-duration:.3s;animation-fill-mode:both;animation-name:slideIn}

.dropdown-toggle.hide-arrow::before,.dropdown-toggle.hide-arrow::after,.dropdown-toggle-hide-arrow>.dropdown-toggle::before,.dropdown-toggle-hide-arrow>.dropdown-toggle::after{display:none}

@keyframes slideIn{
0%{transform:translateY(1rem);opacity:0}

100%{transform:translateY(0rem);opacity:1}

0%{transform:translateY(1rem);opacity:0}}

.nav-margin{width:calc( 1200px - calc(1rem + 260px))}

[dir="ltr"] .nav-margin{margin:1.3rem 0 0 calc(1rem + 260px)}

[dir="rtl"] .nav-margin{margin:1.3rem calc(1rem + 260px) 0 0}

.nav-margin-full-width{margin:1.3rem 1rem 0 1rem !important}

.header-navbar{padding:0px;min-height:4.45rem;font-family:"Montserrat",Helvetica,Arial,serif;transition:300ms ease all,background 0s;z-index:997}

[dir="ltr"] .header-navbar.fixed-top{left:260px}

[dir="rtl"] .header-navbar.fixed-top{right:260px}

.header-navbar[class*=bg-] .navbar-nav .nav-item>a{color:#fff !important}

.header-navbar[class*=bg-] .navbar-nav .nav-item>a i,.header-navbar[class*=bg-] .navbar-nav .nav-item>a svg,.header-navbar[class*=bg-] .navbar-nav .nav-item>a span{color:#fff !important}

.header-navbar[class*=bg-] .navbar-nav .nav-item .dropdown-menu i,.header-navbar[class*=bg-] .navbar-nav .nav-item .dropdown-menu svg{color:inherit !important}

.header-navbar.floating-nav{position:fixed;width:calc( 1200px - calc(1rem + 260px));border-radius:1.2rem;z-index:12}

[dir="ltr"] .header-navbar.floating-nav{margin:1.3rem 0 0 calc(1rem + 260px)}

[dir="rtl"] .header-navbar.floating-nav{margin:1.3rem calc(1rem + 260px) 0 0}

.header-navbar.floating-nav.full-width-navbar{margin:1.3rem 1rem 0 1rem !important;transform:none !important;left:0 !important;right:0 !important}

.header-navbar.floating-nav.floating-nav-increased{margin-top:4.7rem !important}

.header-navbar.floating-nav.floating-nav-increased.full-width-navbar{margin:4.7rem 1rem 0 1rem !important;transform:none !important;left:0 !important;right:0 !important}

.header-navbar.navbar-static-top{top:0;width:calc(100vw - (100vw - 100%) - 260px);background:transparent;box-shadow:none !important}

[dir="ltr"] .header-navbar.navbar-static-top{right:calc((100% - 900px) / 2);left:260px}

[dir="rtl"] .header-navbar.navbar-static-top{left:calc((100% - 900px) / 2);right:260px}

.header-navbar.navbar-border{border-bottom:1px solid #ebe9f1}

.header-navbar.navbar-shadow{box-shadow:0 4px 24px 0 rgba(34,41,47,.1)}

.header-navbar.navbar-dark.navbar-border{border-bottom:1px solid #3b4253}

.header-navbar:not(.navbar-horizontal) .nav-link.dropdown-toggle::after{display:none}

.header-navbar .navbar-container{padding:.8rem 1rem;flex-basis:100%;transition:300ms ease all}

[dir="ltr"] .header-navbar .navbar-container{margin-left:0}

[dir="rtl"] .header-navbar .navbar-container{margin-right:0}

@media(min-width: 576px){
.header-navbar .navbar-container{padding:.8rem 1.4rem}}

.header-navbar .navbar-container .bookmark-wrapper .bookmark-input{z-index:1}

.header-navbar .navbar-container .dropdown-language .selected-language{font-weight:500}

[dir="ltr"] .header-navbar .navbar-container .dropdown-language .nav-link .flag-icon{margin-right:.4rem}

[dir="rtl"] .header-navbar .navbar-container .dropdown-language .nav-link .flag-icon{margin-left:.4rem}

[dir="ltr"] .header-navbar .navbar-container .dropdown-language .dropdown-menu .dropdown-item .flag-icon{margin-right:.4rem}

[dir="rtl"] .header-navbar .navbar-container .dropdown-language .dropdown-menu .dropdown-item .flag-icon{margin-left:.4rem}

.header-navbar .navbar-container ul.navbar-nav li{line-height:1.5}

.vertical-layout .header-navbar .navbar-container ul.navbar-nav li.dropdown .dropdown-menu{top:41px !important}

[dir="ltr"] .header-navbar .navbar-container ul.navbar-nav li.dropdown-language .dropdown-menu.dropdown-menu-right{right:-2px}

[dir="rtl"] .header-navbar .navbar-container ul.navbar-nav li.dropdown-language .dropdown-menu.dropdown-menu-right{left:-2px}

[dir="ltr"] .header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .badge.badge-up,[dir="ltr"] .header-navbar .navbar-container ul.navbar-nav li.dropdown-notification .badge.badge-up{right:-3px}

[dir="rtl"] .header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .badge.badge-up,[dir="rtl"] .header-navbar .navbar-container ul.navbar-nav li.dropdown-notification .badge.badge-up{left:-3px}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .dropdown-menu.dropdown-menu-right,.header-navbar .navbar-container ul.navbar-nav li.dropdown-notification .dropdown-menu.dropdown-menu-right{padding:0}

[dir="ltr"] .header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .dropdown-menu.dropdown-menu-right,[dir="ltr"] .header-navbar .navbar-container ul.navbar-nav li.dropdown-notification .dropdown-menu.dropdown-menu-right{right:-2px;left:inherit}

[dir="rtl"] .header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .dropdown-menu.dropdown-menu-right,[dir="rtl"] .header-navbar .navbar-container ul.navbar-nav li.dropdown-notification .dropdown-menu.dropdown-menu-right{left:-2px;right:inherit}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .dropdown-menu.dropdown-menu-right::before,.header-navbar .navbar-container ul.navbar-nav li.dropdown-notification .dropdown-menu.dropdown-menu-right::before{background:#a94cd8;border-color:#a94cd8}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .dropdown-menu-header,.header-navbar .navbar-container ul.navbar-nav li.dropdown-notification .dropdown-menu-header{border-top-left-radius:.358rem;border-top-right-radius:.358rem}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .dropdown-menu-header .dropdown-header,.header-navbar .navbar-container ul.navbar-nav li.dropdown-notification .dropdown-menu-header .dropdown-header{padding:1.22rem 1.28rem}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .notification-text,.header-navbar .navbar-container ul.navbar-nav li.dropdown-notification .notification-text{margin-bottom:.5rem;font-size:smaller;color:#b9b9c3}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .dropdown-menu-footer,.header-navbar .navbar-container ul.navbar-nav li.dropdown-notification .dropdown-menu-footer{padding:1.28rem}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-notification .media-body .media-heading{color:#6e6b7b;margin-bottom:0;line-height:1.2}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .media{position:relative}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .media img{background:#f6f6f6}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .media:hover .cart-item-remove{visibility:visible}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .media-heading{width:8rem}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .media-heading h6.cart-item-title{display:-webkit-box;-webkit-box-orient:vertical;overflow:hidden}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .media-heading .cart-item-by{color:#b9b9c3;display:-webkit-box;-webkit-box-orient:vertical;overflow:hidden}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .media-body{display:flex;align-items:center;justify-content:space-between}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .cart-item-price{margin-bottom:0;width:5rem}

[dir="ltr"] .header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .cart-item-price{text-align:right}

[dir="rtl"] .header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .cart-item-price{text-align:left}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .cart-item-remove{position:absolute;top:10px;width:14px;height:14px;cursor:pointer}

[dir="ltr"] .header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .cart-item-remove{right:17px}

[dir="rtl"] .header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .cart-item-remove{left:17px}

@media(min-width: 768px){
.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .cart-item-remove{visibility:hidden}}

.header-navbar .navbar-container ul.navbar-nav li>a.nav-link{color:#6e6b7b;padding:0 .5rem;position:relative}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-user{line-height:1 !important}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-user .dropdown-menu{width:auto;margin-top:10px}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-user .dropdown-menu i,.header-navbar .navbar-container ul.navbar-nav li.dropdown-user .dropdown-menu svg{height:16px;width:16px;font-size:16px;vertical-align:top}

.header-navbar .navbar-container ul.navbar-nav li a.dropdown-user-link{display:flex;align-items:center}

.header-navbar .navbar-container ul.navbar-nav li a.dropdown-user-link .user-name{display:inline-block;margin-bottom:.435rem}

[dir="ltr"] .header-navbar .navbar-container ul.navbar-nav li a.dropdown-user-link .user-name{margin-left:.2rem}

[dir="rtl"] .header-navbar .navbar-container ul.navbar-nav li a.dropdown-user-link .user-name{margin-right:.2rem}

.header-navbar .navbar-container ul.navbar-nav li a.dropdown-user-link .user-status{font-size:smaller}

.header-navbar .navbar-container ul.navbar-nav li a.dropdown-user-link img{box-shadow:0 4px 8px 0 rgba(34,41,47,.12),0 2px 4px 0 rgba(34,41,47,.08)}

.header-navbar .navbar-container ul.navbar-nav li a.menu-toggle i,.header-navbar .navbar-container ul.navbar-nav li a.menu-toggle svg{height:1.6rem;width:1.6rem;font-size:1.6rem}

.header-navbar .navbar-container ul.navbar-nav li a.dropdown-user-link .user-nav{display:flex;flex-direction:column;align-items:flex-end}

[dir="ltr"] .header-navbar .navbar-container ul.navbar-nav li a.dropdown-user-link .user-nav{float:left;margin-right:.8rem}

[dir="rtl"] .header-navbar .navbar-container ul.navbar-nav li a.dropdown-user-link .user-nav{float:right;margin-left:.8rem}

.header-navbar .navbar-container ul.navbar-nav li div.input-group{padding:.7rem 1rem}

.header-navbar .navbar-container ul.navbar-nav li i.ficon,.header-navbar .navbar-container ul.navbar-nav li svg.ficon{height:1.5rem;width:1.5rem;font-size:1.5rem;color:#6e6b7b}

.header-navbar .navbar-container ul.navbar-nav li i.ficon:hover,.header-navbar .navbar-container ul.navbar-nav li svg.ficon:hover{color:#a94cd8}

.header-navbar .navbar-container ul.navbar-nav li .media-list{max-height:25rem}

.header-navbar .navbar-container ul.navbar-nav li .scrollable-container{position:relative}

.header-navbar .navbar-container .dropdown-menu-media{width:30rem}

.header-navbar .navbar-container .dropdown-menu-media .dropdown-menu-header{border-bottom:1px solid #ebe9f1}

.header-navbar .navbar-container .dropdown-menu-media .media-list .media{padding:.9rem 1.28rem;border:none;border-bottom:1px solid #ebe9f1}

.header-navbar .navbar-container .dropdown-menu-media .media-list .media:hover{background:#f6f6f6}

.header-navbar .navbar-container .dropdown-menu-media .media-list .media .media-meta{color:#6e6b7b}

.header-navbar .navbar-container .dropdown-menu-media .media-list a:last-of-type .media{border-bottom:none}

.header-navbar .navbar-container .dropdown-menu-media .dropdown-menu-footer{border-top:1px solid #ebe9f1}

.header-navbar-shadow{display:none}

.navbar-floating .navbar-container{padding:.8rem 1rem}

.navbar-floating .header-navbar-shadow{display:block;padding-top:2.2rem;width:100%;height:102px;position:fixed;top:0;z-index:11}

[dir="ltr"] .navbar-floating .header-navbar-shadow{background:linear-gradient(180deg, rgba(248, 248, 248, 0.95) 44%, rgba(248, 248, 248, 0.46) 73%, rgba(255, 255, 255, 0));left:0}

[dir="rtl"] .navbar-floating .header-navbar-shadow{background:linear-gradient(-180deg, rgba(248, 248, 248, 0.95) 44%, rgba(248, 248, 248, 0.46) 73%, rgba(255, 255, 255, 0));right:0}

[dir] .navbar-floating .header-navbar-shadow{background-repeat:repeat}

.navbar-light{background:#fff}

.navbar-light.navbar-horizontal{background:#fff}

.navbar-light .navbar-nav .active.nav-link{background-color:rgba(34,41,47,.03)}

.navbar-light .navbar-nav .disabled.nav-link{color:#b9b9c3}

.navbar-dark{background:#b8c2cc}

.navbar-dark .navbar-brand{color:#fff !important}

.navbar-dark.navbar-horizontal{background:#b8c2cc}

.navbar-dark .navbar-nav li{line-height:1}

.navbar-dark .navbar-nav .active.nav-link{background-color:rgba(255,255,255,.05)}

.navbar-dark .navbar-nav .disabled.nav-link{color:#dae1e7}

@media(max-width: 767.98px){
.header-navbar .navbar-nav .show{position:static}

.header-navbar .navbar-nav .open-navbar-container{padding-top:.625rem}

.header-navbar .navbar-container .show .dropdown-menu{right:0;left:0 !important;float:none;width:auto !important;margin-top:0;overflow:hidden}

[dir="ltr"] .header-navbar .navbar-container .dropdown-user .dropdown-menu-right{right:0 !important}

[dir="rtl"] .header-navbar .navbar-container .dropdown-user .dropdown-menu-right{left:0 !important}

[dir="ltr"] .header-navbar .navbar-container ul.navbar-nav li .dropdown-toggle::after{margin-right:0;margin-left:-2px}

[dir="rtl"] .header-navbar .navbar-container ul.navbar-nav li .dropdown-toggle::after{margin-left:0;margin-right:-2px}

.navbar-dark .navbar-header .navbar-nav .nav-link{color:#fff}

.navbar-dark .navbar-container .navbar-nav .nav-link{color:#b8c2cc}

.navbar-light .navbar-header .navbar-nav .nav-link{color:#b8c2cc}

.navbar-light .navbar-container .navbar-nav .nav-link{color:#b8c2cc}}

@media(max-width: 1199.98px){
.nav-margin{width:calc(100vw - (100vw - 100%) - calc(1rem * 2))}

.header-navbar.floating-nav{width:calc(100vw - (100vw - 100%) - calc(1rem * 2))}

.header-navbar.navbar-static-top{width:100%}

[dir="ltr"] .header-navbar.navbar-static-top{left:0}

[dir="rtl"] .header-navbar.navbar-static-top{right:0}}

@media(max-width: 991.98px){
.header-navbar .navbar-nav{margin:0;flex-flow:row wrap}

.header-navbar .navbar-nav .dropdown-menu{position:absolute}

[dir="ltr"] .header-navbar .navbar-nav .nav-item{float:left}

[dir="rtl"] .header-navbar .navbar-nav .nav-item{float:right}}

@media(max-width: 767px){
.header-navbar .navbar-nav{margin:0;flex-flow:row wrap}

.header-navbar .navbar-nav .dropdown-menu{position:absolute}

[dir="ltr"] .header-navbar .navbar-nav .nav-item{float:left}

[dir="rtl"] .header-navbar .navbar-nav .nav-item{float:right}}

@media(max-width: 575.98px){
[dir="ltr"] .header-navbar.floating-nav{margin-left:1rem;margin-right:1.2rem}

[dir="rtl"] .header-navbar.floating-nav{margin-right:1rem;margin-left:1.2rem}

.header-navbar .navbar-container ul.navbar-nav li .selected-language{display:none}}

@media only screen and (min-device-width: 26.78em)and (max-device-width: 47.64em)and (-webkit-min-device-pixel-ratio: 2)and (orientation: landscape){
.header-navbar .navbar-container .show .dropdown-menu{max-height:180px}}

@media only screen and (min-device-width: 29.57em)and (max-device-width: 52.57em)and (-webkit-min-device-pixel-ratio: 3)and (orientation: landscape){
.header-navbar .navbar-container .show .dropdown-menu{max-height:280px}}

@media(max-width: 1201px){
[dir="ltr"] .header-navbar.fixed-top{left:0}

[dir="rtl"] .header-navbar.fixed-top{right:0}

.horizontal-layout .header-navbar .navbar-container{padding-left:.8rem;padding-right:.8rem}}

@media(max-width: 1199px){
.header-navbar .navbar-container ul.navbar-nav li a.dropdown-user-link .user-name{margin-bottom:0}}

.card{border:none;margin-bottom:1rem;box-shadow:0 4px 24px 0 rgba(34,41,47,.1);transition:all .3s ease-in-out,background 0s,color 0s,border-color 0s}

.card .card{box-shadow:none !important}

.card .card-title{font-weight:500;font-size:1.285rem;margin-bottom:1.53rem}

.card .card-bordered{border:1px solid rgba(34,41,47,.125)}

.card .card-img{object-fit:cover}

.card .card-img-overlay{border-radius:1.2rem}

.card.card-fullscreen{display:block;z-index:9999;position:fixed;width:100% !important;height:100% !important;top:0;right:0;left:0;bottom:0;overflow:auto}

.card .card-body[class*=border-bottom-]{border-bottom-width:2px !important}

.card .card-img-overlay.bg-overlay{background:rgba(34,41,47,.45)}

.card .card-img-overlay .text-muted{color:#1e1e1e !important}

.card.card-minimal{border:none;box-shadow:none}

.card .card-header{position:relative;display:flex;align-items:center;flex-wrap:wrap;justify-content:space-between;border-bottom:none;padding:1.5rem;background-color:transparent}

.card .card-header .card-title{margin-bottom:0}

.card .card-header .heading-elements{position:relative;top:-1px}

[dir="ltr"] .card .card-header .heading-elements li:not(:first-child) a{margin-left:.75rem}

[dir="rtl"] .card .card-header .heading-elements li:not(:first-child) a{margin-right:.75rem}

.card .card-header .heading-elements a.btn{padding-top:6px;padding-bottom:6px}

.card .card-header .heading-elements a i,.card .card-header .heading-elements a svg{height:1rem;width:1rem;font-size:1rem}

.card .card-header+.card-content>.card-body:first-of-type,.card .card-header+.card-body{padding-top:0px}

.card .card-footer{border-top:1px solid #dae1e7;background-color:transparent}

.card-columns .card{margin-bottom:2.2rem}

.card-group,.card-deck{margin-bottom:.75rem}

.card-head-inverse{color:#fff}

.card-head-inverse .heading-elements i,.card-head-inverse .heading-elements svg{color:#fff}

.card-transparent{background-color:transparent}

.text-white .card-img-overlay .text-muted{color:#fff !important}

.text-white code{background-color:#fff}

.text-white .heading-elements i,.text-white .heading-elements svg{color:#fff}

.overlay-img-card .card-img-overlay,.overlay-img-card img{max-height:34.64rem}

@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none){
.card-body,.card-content{min-height:1px}}

.card-congratulations{color:#fff}

[dir="ltr"] .card-congratulations{background:linear-gradient(118deg, #a94cd8, rgba(169, 76, 216, 0.7))}

[dir="rtl"] .card-congratulations{background:linear-gradient(-118deg, #a94cd8, rgba(169, 76, 216, 0.7))}

.card-congratulations .congratulations-img-left{width:200px;position:absolute;top:0}

[dir="ltr"] .card-congratulations .congratulations-img-left{left:0}

[dir="rtl"] .card-congratulations .congratulations-img-left{right:0}

@media(max-width: 575.98px){
.card-congratulations .congratulations-img-left{width:140px}}

.card-congratulations .congratulations-img-right{width:175px;position:absolute;top:0}

[dir="ltr"] .card-congratulations .congratulations-img-right{right:0}

[dir="rtl"] .card-congratulations .congratulations-img-right{left:0}

@media(max-width: 575.98px){
.card-congratulations .congratulations-img-right{width:140px}}

.card-congratulations .avatar{margin-bottom:2rem}

.congratulation-medal{position:absolute;top:0}

[dir="ltr"] .congratulation-medal{right:37px}

[dir="rtl"] .congratulation-medal{left:37px}

.card-deposits .deposits-divider{margin-top:1.8rem;margin-bottom:1.4rem}

.card-employee-task .employee-task:not(:last-child){margin-bottom:1.5rem}

.card-developer-meetup .meetup-img-wrapper{background-color:rgba(169,76,216,.1)}

.card-developer-meetup .meetup-header{margin-bottom:2rem}

.card-developer-meetup .meetup-header .meetup-day{text-align:center}

[dir="ltr"] .card-developer-meetup .meetup-header .meetup-day{border-right:1px solid #d8d6de;padding-right:1.3rem;margin-right:1.3rem}

[dir="rtl"] .card-developer-meetup .meetup-header .meetup-day{border-left:1px solid #d8d6de;padding-left:1.3rem;margin-left:1.3rem}

.card-developer-meetup .media .avatar .avatar-content{width:34px;height:34px}

.card-developer-meetup .media+.media{margin-top:1.2rem}

.card-developer-meetup .avatar-group{margin-top:2rem}

.card-profile{text-align:center}

.card-profile .card-body{position:relative;padding:5rem 2.3rem 1.5rem 2.3rem}

.card-profile .card-body .profile-image-wrapper{display:flex;justify-content:center}

.card-profile .card-body .profile-image-wrapper .profile-image{position:absolute;top:-4rem;padding:.5rem;border-radius:50%;background-color:#fff;box-shadow:0 0 8px 0 rgba(34,41,47,.14);margin-bottom:1.15rem}

.card-profile .card-body .profile-image-wrapper .profile-image img{width:100px;height:100px}

.card-profile .card-body .profile-badge{margin-top:.8rem;margin-bottom:.8rem}

.card-apply-job .apply-job-package{padding:1.2rem;margin-bottom:1.15rem;display:flex;align-items:center;justify-content:space-between}

.card-apply-job .apply-job-package sup{top:-0.8rem}

.card-transaction .transaction-item{display:flex;align-items:center;justify-content:space-between}

.card-transaction .transaction-item:not(:last-child){margin-bottom:1.2rem}

[dir="ltr"] .card-transaction .transaction-item .avatar{margin-right:1.2rem}

[dir="rtl"] .card-transaction .transaction-item .avatar{margin-left:1.2rem}

.card-transaction .transaction-item .avatar .avatar-content{width:42px;height:42px}

.card-transaction .transaction-item .transaction-title{margin-bottom:.2rem;margin-top:.2rem}

.business-card .business-items:not(:last-child){margin-bottom:1.3rem}

.business-card .business-items .business-item{border:1px solid #ebe9f1;border-radius:.357rem}

[dir="ltr"] .business-card .business-items .business-item{padding:1rem 1rem 1rem 1.3rem}

[dir="rtl"] .business-card .business-items .business-item{padding:1rem 1.3rem 1rem 1rem}

.business-card .business-items .business-item:not(:last-child){margin-bottom:.85rem}

.card-browser-states .browser-states{margin-top:2.14rem;display:flex;justify-content:space-between;align-items:center}

.card-app-design .design-group{margin-bottom:2rem}

[dir="ltr"] .card-app-design .design-group .avatar:not(:last-child){margin-right:.6rem}

[dir="rtl"] .card-app-design .design-group .avatar:not(:last-child){margin-left:.6rem}

.card-app-design .design-planning-wrapper{display:flex;align-items:center;flex-wrap:wrap}

.card-app-design .design-planning-wrapper:not(:last-child){margin-bottom:1.3rem}

.card-app-design .design-planning-wrapper .design-planning{padding:.5rem;margin-bottom:.7rem;text-align:center;background-color:#f6f6f6;border-radius:.357rem;min-width:6.4rem}

[dir="ltr"] .card-app-design .design-planning-wrapper .design-planning:not(:last-child){margin-right:.7rem}

[dir="rtl"] .card-app-design .design-planning-wrapper .design-planning:not(:last-child){margin-left:.7rem}

.card-statistics .statistics-body{padding:2rem 2.4rem 2.8rem !important}

.card-statistics .statistics-body .avatar .avatar-content{width:48px;height:48px}

.card-statistics .statistics-body .avatar .avatar-content .avatar-icon{width:24px;height:24px}

.card-revenue-budget .revenue-report-wrapper{padding:1.286rem 1.5rem}

@media(min-width: 768px){
[dir="ltr"] .card-revenue-budget .revenue-report-wrapper{border-right:1px solid #ebe9f1}

[dir="rtl"] .card-revenue-budget .revenue-report-wrapper{border-left:1px solid #ebe9f1}}

.card-revenue-budget .budget-wrapper{padding:2rem 3rem;text-align:center}

@media(max-width: 767.98px){
.card-revenue-budget .budget-wrapper{padding-top:0}}

.card-revenue-budget .budget-wrapper .budget-dropdown{margin-bottom:2rem}

@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none){
#dashboard-analytics .avatar .avatar-content,#statistics-card .avatar .avatar-content{padding:0 !important}}

.breadcrumb:not([class*=breadcrumb-]) .breadcrumb-item+.breadcrumb-item:before{content:" ";background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%236e6b7b%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-right%27%3E%3Cpolyline points=%279 18 15 12 9 6%27%3E%3C/polyline%3E%3C/svg%3E");background-repeat:no-repeat;background-position:center;color:#6e6b7b;background-size:14px}

[dir="ltr"] .breadcrumb:not([class*=breadcrumb-]) .breadcrumb-item+.breadcrumb-item:before{margin-right:.6rem}

[dir="rtl"] .breadcrumb:not([class*=breadcrumb-]) .breadcrumb-item+.breadcrumb-item:before{margin-left:.6rem}

.breadcrumb .breadcrumb-item+.breadcrumb-item:before{height:20px}

.breadcrumb-slash.breadcrumb .breadcrumb-item+.breadcrumb-item:before{content:"/"}

.breadcrumb-dots.breadcrumb .breadcrumb-item+.breadcrumb-item:before{content:".";position:relative;top:-4px}

.breadcrumb-dashes.breadcrumb .breadcrumb-item+.breadcrumb-item:before{content:"-"}

.breadcrumb-pipes.breadcrumb .breadcrumb-item+.breadcrumb-item:before{content:"|"}

.breadcrumb-chevron.breadcrumb .breadcrumb-item+.breadcrumb-item:before{content:" ";background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%236e6b7b%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-right%27%3E%3Cpolyline points=%279 18 15 12 9 6%27%3E%3C/polyline%3E%3C/svg%3E");background-repeat:no-repeat;background-position:center;color:#6e6b7b;background-size:14px}

[dir="ltr"] .breadcrumb-chevron.breadcrumb .breadcrumb-item+.breadcrumb-item:before{margin-right:.6rem}

[dir="rtl"] .breadcrumb-chevron.breadcrumb .breadcrumb-item+.breadcrumb-item:before{margin-left:.6rem}

@media(max-width: 648px){
.content-header .breadcrumb{display:none}

.breadcrumbs-top .content-header-title{display:contents !important}}

.badge{color:#fff;white-space:break-spaces}

.badge[class*=badge-] [class*=icon-]{line-height:1}

.badge[class*=badge-] a{color:#fff}

.badge[class*=badge-] .dropdown-toggle span,.badge[class*=badge-] .dropdown-toggle i,.badge[class*=badge-] .dropdown-toggle svg,.badge[class*=badge-].dropdown-toggle span,.badge[class*=badge-].dropdown-toggle i,.badge[class*=badge-].dropdown-toggle svg{vertical-align:text-top}

[dir="ltr"] .badge[class*=badge-] .dropdown-toggle i,[dir="ltr"] .badge[class*=badge-] .dropdown-toggle svg,[dir="ltr"] .badge[class*=badge-].dropdown-toggle i,[dir="ltr"] .badge[class*=badge-].dropdown-toggle svg{padding-left:.2rem}

[dir="rtl"] .badge[class*=badge-] .dropdown-toggle i,[dir="rtl"] .badge[class*=badge-] .dropdown-toggle svg,[dir="rtl"] .badge[class*=badge-].dropdown-toggle i,[dir="rtl"] .badge[class*=badge-].dropdown-toggle svg{padding-right:.2rem}

.badge[class*=badge-] .dropdown-toggle::after,.badge[class*=badge-].dropdown-toggle::after{position:relative;top:0;font-size:1rem}

[dir="ltr"] .badge[class*=badge-] .dropdown-toggle::after,[dir="ltr"] .badge[class*=badge-].dropdown-toggle::after{left:0}

[dir="rtl"] .badge[class*=badge-] .dropdown-toggle::after,[dir="rtl"] .badge[class*=badge-].dropdown-toggle::after{right:0}

.badge[class*=badge-] .dropdown-menu a{color:#6e6b7b}

.badge i,.badge svg{height:12px;width:11px;font-size:12px;stroke-width:3;vertical-align:top}

.badge.badge-square{border-radius:0}

.badge.badge-up{position:absolute;top:-11px;min-width:1.429rem;min-height:1.429rem;display:flex;align-items:center;justify-content:center;font-size:.786rem;line-height:.786;padding-left:.25rem;padding-right:.25rem}

[dir="ltr"] .badge.badge-up{right:-9px}

[dir="rtl"] .badge.badge-up{left:-9px}

.badge.badge-up.badge-sm{top:-0.5rem}

[dir="ltr"] .badge.badge-up.badge-sm{right:-0.5rem}

[dir="rtl"] .badge.badge-up.badge-sm{left:-0.5rem}

.badge-icon i,.badge-icon svg{font-size:100%}

[dir="ltr"] .badge-icon i,[dir="ltr"] .badge-icon svg{margin-right:5px}

[dir="rtl"] .badge-icon i,[dir="rtl"] .badge-icon svg{margin-left:5px}

.dropup .badge{cursor:pointer}

.nav{border-radius:.25rem}

.nav.wrap-border{border:1px solid #ddd}

.nav.wrap-border li.nav-header{margin:0 .5rem}

.nav.wrap-border li.nav-item,.nav.wrap-border div{padding:2px .714rem}

.nav.nav-left .nav-item .nav-link{justify-content:flex-start}

.nav.nav-right .nav-item .nav-link{justify-content:flex-end}

.nav.square-border{border-radius:0}

.nav.square-border .nav-item .nav-link.active{border-radius:0}

.nav .modern-nav-toggle{padding:0;margin:1.571rem 0}

.nav .dropdown.show .dropdown-toggle::after{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23fff%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-down%27%3E%3Cpolyline points=%276 9 12 15 18 9%27%3E%3C/polyline%3E%3C/svg%3E")}

.nav .dropdown-toggle:not(.active)::after{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%236e6b7b%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-down%27%3E%3Cpolyline points=%276 9 12 15 18 9%27%3E%3C/polyline%3E%3C/svg%3E")}

.nav-pills .nav-link,.nav-tabs .nav-link{display:flex;align-items:center;justify-content:center}

[dir="ltr"] .nav-pills .nav-link i,[dir="ltr"] .nav-pills .nav-link svg,[dir="ltr"] .nav-tabs .nav-link i,[dir="ltr"] .nav-tabs .nav-link svg{margin-right:.5rem}

[dir="rtl"] .nav-pills .nav-link i,[dir="rtl"] .nav-pills .nav-link svg,[dir="rtl"] .nav-tabs .nav-link i,[dir="rtl"] .nav-tabs .nav-link svg{margin-left:.5rem}

.nav-pills{margin-bottom:1rem}

.nav-pills .nav-link{padding:.786rem 1.5rem;font-size:1rem;line-height:1rem;border:1px solid transparent;color:#5e5873}

.nav-pills .nav-link.active{border-color:#a94cd8;box-shadow:0 4px 18px -4px rgba(169,76,216,.65)}

.nav-pills .nav-link.disabled{color:#b9b9c3}

.nav-pills .nav-link.dropdown-toggle::after{top:1px}

[dir="ltr"] .nav-pills .nav-link.dropdown-toggle::after{left:1px}

[dir="rtl"] .nav-pills .nav-link.dropdown-toggle::after{right:1px}

.nav-pills.dropdown.show .nav-link{color:#fff}

.nav-pills.dropdown.show .dropdown-item.active:hover{color:#a94cd8}

.nav-pills.nav-justified{width:100%}

.nav-pills.nav-justified .nav-item{float:none}

.nav-pills.nav-justified .nav-link{text-align:center;margin-bottom:5px}

.nav-pills.nav-justified>.dropdown .dropdown-menu{top:auto}

[dir="ltr"] .nav-pills.nav-justified>.dropdown .dropdown-menu{left:auto}

[dir="rtl"] .nav-pills.nav-justified>.dropdown .dropdown-menu{right:auto}

@media(min-width: 576px){
.nav-pills.nav-justified .nav-item{display:block;width:1%}

.nav-pills.nav-justified .nav-link{margin-bottom:0}}

.nav-pills.nav-justified .nav-link{border-radius:.357rem}

[dir="ltr"] .nav-pills.nav-justified .nav-link{margin-right:0}

[dir="rtl"] .nav-pills.nav-justified .nav-link{margin-left:0}

@media(min-width: 576px){
.nav-pills.nav-justified .nav-link.active,.nav-pills.nav-justified .nav-link.active:hover,.nav-pills.nav-justified .nav-link.active:focus{border-bottom-color:transparent}}

.nav-pills.nav-justified .nav-link{display:block}

.nav-pills.nav-justified .nav-link.active{border:none}

.nav-pills.nav-justified .nav-link.active:hover,.nav-pills.nav-justified .nav-link.active:focus{border:none}

.nav-tabs{margin-bottom:1rem;position:relative}

.nav-tabs .nav-item{position:relative}

.nav-tabs .nav-link{color:#2a3044 !important;border:none;min-width:auto;font-weight:inherit;padding:.61rem 1.2rem;border-radius:0;position:relative;overflow:hidden;margin:3px;border-radius:9px}

.nav-tabs .nav-link.active{position:relative;color:#fff !important;background:#4a4f5e !important;margin:3px;border-radius:9px}

.nav-tabs .nav-link.active:after{transform:translate3d(0, 0, 0)}

.nav-tabs .nav-link.dropdown-toggle::after{top:1px}

[dir="ltr"] .nav-tabs .nav-link.dropdown-toggle::after{left:1px}

[dir="rtl"] .nav-tabs .nav-link.dropdown-toggle::after{right:1px}

.nav-tabs.nav-justified{width:100%}

.nav-tabs.nav-justified .nav-item{float:none}

.nav-tabs.nav-justified .nav-link{text-align:center;margin-bottom:5px}

.nav-tabs.nav-justified>.dropdown .dropdown-menu{top:auto}

[dir="ltr"] .nav-tabs.nav-justified>.dropdown .dropdown-menu{left:auto}

[dir="rtl"] .nav-tabs.nav-justified>.dropdown .dropdown-menu{right:auto}

@media(min-width: 576px){
.nav-tabs.nav-justified .nav-item{display:block;width:1%}

.nav-tabs.nav-justified .nav-link{margin-bottom:0}}

.nav-tabs.nav-justified .nav-link{border-radius:.357rem}

[dir="ltr"] .nav-tabs.nav-justified .nav-link{margin-right:0}

[dir="rtl"] .nav-tabs.nav-justified .nav-link{margin-left:0}

@media(min-width: 576px){
.nav-tabs.nav-justified .nav-link.active,.nav-tabs.nav-justified .nav-link.active:hover,.nav-tabs.nav-justified .nav-link.active:focus{border-bottom-color:transparent}}

.nav-tabs.nav-justified .nav-item a.nav-link{display:block;border-radius:0}

.nav-tabs.nav-justified .nav-item a.nav-link.active{border:none}

.nav-tabs.nav-justified .nav-item a.nav-link:hover{border-color:transparent}

.nav-vertical{overflow:hidden}

.nav-vertical .nav.nav-tabs .nav-item .nav-link{margin-bottom:0}

[dir="ltr"] .nav-vertical .nav.nav-tabs .nav-item .nav-link:after{transform:rotate(90deg) translate3d(0, 150%, 0);left:70%}

[dir="rtl"] .nav-vertical .nav.nav-tabs .nav-item .nav-link:after{transform:rotate(-90deg) translate3d(0, 150%, 0);right:70%}

.nav-vertical .nav.nav-tabs .nav-item .nav-link.active:after{top:1.25rem;width:2.14rem}

[dir="ltr"] .nav-vertical .nav.nav-tabs .nav-item .nav-link.active:after{left:auto;right:-1.4rem;transform:rotate(90deg) translate3d(0, 225%, 0)}

[dir="rtl"] .nav-vertical .nav.nav-tabs .nav-item .nav-link.active:after{right:auto;left:-1.4rem;transform:rotate(-90deg) translate3d(0, 225%, 0)}

.nav-vertical .nav.nav-tabs.nav-left{display:table}

[dir="ltr"] .nav-vertical .nav.nav-tabs.nav-left{float:left;margin-right:1rem}

[dir="rtl"] .nav-vertical .nav.nav-tabs.nav-left{float:right;margin-left:1rem}

.nav-vertical .nav.nav-tabs.nav-left~.tab-content .tab-pane{display:none;overflow-y:auto}

[dir="ltr"] .nav-vertical .nav.nav-tabs.nav-left~.tab-content .tab-pane{padding-left:1rem}

[dir="rtl"] .nav-vertical .nav.nav-tabs.nav-left~.tab-content .tab-pane{padding-right:1rem}

.nav-vertical .nav.nav-tabs.nav-left~.tab-content .tab-pane.active{display:block}

.nav-vertical .nav.nav-tabs.nav-right{display:table}

[dir="ltr"] .nav-vertical .nav.nav-tabs.nav-right{float:right;margin-left:1rem}

[dir="rtl"] .nav-vertical .nav.nav-tabs.nav-right{float:left;margin-right:1rem}

[dir="ltr"] .nav-vertical .nav.nav-tabs.nav-right .nav-item .nav-link.active:after{left:-0.9rem;transform:rotate(90deg) translate3d(0, 10%, 0)}

[dir="rtl"] .nav-vertical .nav.nav-tabs.nav-right .nav-item .nav-link.active:after{right:-0.9rem;transform:rotate(-90deg) translate3d(0, 10%, 0)}

.nav-vertical .nav.nav-tabs.nav-right~.tab-content .tab-pane{display:none;overflow-y:auto}

[dir="ltr"] .nav-vertical .nav.nav-tabs.nav-right~.tab-content .tab-pane{padding-right:1rem}

[dir="rtl"] .nav-vertical .nav.nav-tabs.nav-right~.tab-content .tab-pane{padding-left:1rem}

.nav-vertical .nav.nav-tabs.nav-right~.tab-content .tab-pane.active{display:block}

.alert{font-weight:500;border:none;padding:0}

.alert.alert-dismissible .close{padding:.5rem 1rem .5rem 1rem;background-color:transparent !important;box-shadow:none !important}

[dir="ltr"] .alert.alert-dismissible .alert-body{padding:.71rem 2rem .71rem 1rem}

[dir="rtl"] .alert.alert-dismissible .alert-body{padding:.71rem 1rem .71rem 2rem}

.alert .close:focus{outline:0}

.alert .alert-link:hover{text-decoration:underline}

.alert p{font-weight:500;padding:2px 0;margin-bottom:0;vertical-align:middle}

.alert .alert-heading{font-weight:600;font-size:1rem;padding:.71rem 1rem;margin-bottom:0}

.alert .alert-body{padding:.71rem 1rem}

.alert .alert-body i,.alert .alert-body svg{position:relative;top:-2px}

.alert.alert-dark .alert-heading{box-shadow:rgba(22,29,49,.4) 0px 6px 15px -7px}

.media-list .media{padding:1.25rem;width:100%;margin-top:0}

[dir="ltr"] .media-list .media .media-left{margin-right:1rem}

[dir="rtl"] .media-list .media .media-left{margin-left:1rem}

[dir="ltr"] .media-list .media .media-right{margin-left:1rem}

[dir="rtl"] .media-list .media .media-right{margin-right:1rem}

.media-list a.media{color:#b8c2cc !important}

.media-bordered .media:not(:first-child){border-top:1px solid #ebe9f1}

.progress .progress-bar+.progress-bar:not(:last-child){border-radius:0}

[dir="ltr"] .progress .progress-bar:last-child{border-top-right-radius:5rem;border-bottom-right-radius:5rem}

[dir="rtl"] .progress .progress-bar:last-child{border-top-left-radius:5rem;border-bottom-left-radius:5rem}

ul.list-inline li{display:inline-block}

ul.list-inline.list-inline-pipe>li+li:before{content:" | "}

[dir="ltr"] ul.list-inline.list-inline-pipe>li+li:before{padding-right:2px}

[dir="rtl"] ul.list-inline.list-inline-pipe>li+li:before{padding-left:2px}

.list-group .list-group-item{line-height:1.5}

.list-group .list-group-item.active:hover{background-color:#a94cd8}

.list-group .list-group-item.active:focus{outline:0}

.list-group .list-group-item.active:focus:hover{color:#a94cd8}

.list-group .list-group-item.active small{color:#fff !important}

.list-group .list-group-item:hover{background-color:#f6f6f6}

.list-group .list-group-item i,.list-group .list-group-item svg{position:relative}

.list-group .list-group-item:not(.active):focus:active{color:inherit}

.list-group .list-group-item-action.active{background-color:#a94cd8;color:#fff}

.list-group .list-group-item-action.active:focus{background-color:#a94cd8}

.list-group .list-group-item-action:focus{background-color:#f6f6f6;outline:0}

.list-group.list-group-circle{border:none}

.list-group.list-group-circle .list-group-item{border:none;position:relative}

[dir="ltr"] .list-group.list-group-circle .list-group-item{padding-left:1.5rem}

[dir="rtl"] .list-group.list-group-circle .list-group-item{padding-right:1.5rem}

.list-group.list-group-circle .list-group-item:after{content:" ";background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2724%27 height=%2724%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%236e6b7b%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-circle%27%3E%3Ccircle cx=%2712%27 cy=%2712%27 r=%2710%27%3E%3C/circle%3E%3C/svg%3E");background-repeat:no-repeat;background-position:center;color:#6e6b7b;background-size:10px;position:absolute;height:10px;width:10px;top:1.15rem}

[dir="ltr"] .list-group.list-group-circle .list-group-item:after{left:0}

[dir="rtl"] .list-group.list-group-circle .list-group-item:after{right:0}

.list-group.list-group-circle .list-group-item:hover{background-color:transparent}

.list-group-item.gu-mirror{border-radius:0}

.toast.show{z-index:1030}

.toast .close:focus{outline:none}

@media(max-width: 575.98px){
[dir="ltr"] .toast{left:1rem}

[dir="rtl"] .toast{right:1rem}}

.page-item{border-radius:5rem}

.page-item:not(.prev-item) .page-link,.page-item:not(.next-item) .page-link,.page-item:not(.first) .page-link,.page-item:not(.last) .page-link,.page-item:not(.active) .page-link{line-height:1.3}

.page-item .page-link{display:flex;align-items:center;justify-content:center}

.page-item.previous .page-link,.page-item.prev .page-link,.page-item.next .page-link,.page-item.first .page-link,.page-item.last .page-link{width:auto}

.page-item.prev-item .page-link,.page-item.next-item .page-link{transition:all .2s ease-out}

[dir="ltr"] .page-item.prev-item{margin-right:.3571rem}

[dir="rtl"] .page-item.prev-item{margin-left:.3571rem}

.page-item.prev-item .page-link{border-radius:50%}

.page-item.prev-item .page-link:before{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%236e6b7b%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-left%27%3E%3Cpolyline points=%2715 18 9 12 15 6%27%3E%3C/polyline%3E%3C/svg%3E");background-repeat:no-repeat;background-position:center;background-size:14px;color:#6e6b7b;width:8px;height:18px;content:""}

.page-item.prev-item .page-link:hover{background:#f3f2f7;color:#a94cd8}

.page-item.prev-item .page-link:active,.page-item.prev-item .page-link:hover{background-color:#a94cd8 !important}

.page-item.prev-item .page-link:active:before,.page-item.prev-item .page-link:hover:before{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23fff%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-left%27%3E%3Cpolyline points=%2715 18 9 12 15 6%27%3E%3C/polyline%3E%3C/svg%3E") !important}

.page-item.prev-item.disabled .page-link:before{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23b9b9c3%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-left%27%3E%3Cpolyline points=%2715 18 9 12 15 6%27%3E%3C/polyline%3E%3C/svg%3E")}

[dir="ltr"] .page-item.prev-item~.page-item:nth-child(2){border-top-left-radius:5rem;border-bottom-left-radius:5rem}

[dir="rtl"] .page-item.prev-item~.page-item:nth-child(2){border-top-right-radius:5rem;border-bottom-right-radius:5rem}

[dir="ltr"] .page-item.prev-item~.page-item:nth-child(2) .page-link{border-top-left-radius:5rem !important;border-bottom-left-radius:5rem !important}

[dir="rtl"] .page-item.prev-item~.page-item:nth-child(2) .page-link{border-top-right-radius:5rem !important;border-bottom-right-radius:5rem !important}

[dir="ltr"] .page-item.prev-item~.page-item:nth-last-child(2){border-top-right-radius:5rem;border-bottom-right-radius:5rem}

[dir="rtl"] .page-item.prev-item~.page-item:nth-last-child(2){border-top-left-radius:5rem;border-bottom-left-radius:5rem}

[dir="ltr"] .page-item.prev-item~.page-item:nth-last-child(2) .page-link{border-top-right-radius:5rem !important;border-bottom-right-radius:5rem !important}

[dir="rtl"] .page-item.prev-item~.page-item:nth-last-child(2) .page-link{border-top-left-radius:5rem !important;border-bottom-left-radius:5rem !important}

[dir="ltr"] .page-item.next-item{margin-left:.3571rem}

[dir="rtl"] .page-item.next-item{margin-right:.3571rem}

.page-item.next-item .page-link{border-radius:50%}

.page-item.next-item .page-link:after{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%236e6b7b%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-right%27%3E%3Cpolyline points=%279 18 15 12 9 6%27%3E%3C/polyline%3E%3C/svg%3E");background-repeat:no-repeat;background-position:center;background-size:14px;width:8px;height:18px;content:""}

.page-item.next-item .page-link:hover{background:#f3f2f7;color:#a94cd8}

.page-item.next-item .page-link:active,.page-item.next-item .page-link:hover{background-color:#a94cd8 !important}

.page-item.next-item .page-link:active:after,.page-item.next-item .page-link:hover:after{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23fff%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-right%27%3E%3Cpolyline points=%279 18 15 12 9 6%27%3E%3C/polyline%3E%3C/svg%3E") !important}

.page-item.next-item.disabled .page-link:after{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23b9b9c3%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-right%27%3E%3Cpolyline points=%279 18 15 12 9 6%27%3E%3C/polyline%3E%3C/svg%3E")}

.page-item.prev .page-link:before,.page-item.previous .page-link:before{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%236e6b7b%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-left%27%3E%3Cpolyline points=%2715 18 9 12 15 6%27%3E%3C/polyline%3E%3C/svg%3E");background-repeat:no-repeat;background-position:center;background-size:14px;color:#6e6b7b;width:12px;height:18px;content:""}

.page-item.prev .page-link:hover:before,.page-item.previous .page-link:hover:before{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23A94CD8%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-left%27%3E%3Cpolyline points=%2715 18 9 12 15 6%27%3E%3C/polyline%3E%3C/svg%3E") !important}

.page-item.prev.disabled .page-link:before,.page-item.previous.disabled .page-link:before{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23b9b9c3%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-left%27%3E%3Cpolyline points=%2715 18 9 12 15 6%27%3E%3C/polyline%3E%3C/svg%3E")}

.page-item.next .page-link:after{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%236e6b7b%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-right%27%3E%3Cpolyline points=%279 18 15 12 9 6%27%3E%3C/polyline%3E%3C/svg%3E");background-repeat:no-repeat;background-position:center;background-size:14px;color:#6e6b7b;width:12px;height:18px;content:""}

.page-item.next .page-link:hover:after{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23A94CD8%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-right%27%3E%3Cpolyline points=%279 18 15 12 9 6%27%3E%3C/polyline%3E%3C/svg%3E") !important}

.page-item.next.disabled .page-link:after{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23b9b9c3%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-right%27%3E%3Cpolyline points=%279 18 15 12 9 6%27%3E%3C/polyline%3E%3C/svg%3E")}

.page-item.disabled .page-link{color:#b9b9c3}

.page-item .page-link{border:none;margin:0;font-size:1rem;min-width:2.286rem}

[dir="ltr"] .page-item .page-link{margin-left:0}

[dir="rtl"] .page-item .page-link{margin-right:0}

.page-item .page-link:focus{box-shadow:none}

.page-item .page-link:hover{color:#a94cd8}

.page-item.active{background-color:#f3f2f7;border-radius:0}

.page-item.active .page-link{z-index:3;border-radius:5rem;background-color:#a94cd8;color:#fff !important;font-weight:600}

[dir="ltr"] .page-item:first-child:not(.prev-item).active{border-top-left-radius:1.428rem;border-bottom-left-radius:1.428rem}

[dir="rtl"] .page-item:first-child:not(.prev-item).active{border-top-right-radius:1.428rem;border-bottom-right-radius:1.428rem}

.page-item:first-child:not(.prev-item).active .page-link{border-radius:5rem}

[dir="ltr"] .page-item:first-child:not(.prev-item) .page-link{border-top-left-radius:1.428rem;border-bottom-left-radius:1.428rem}

[dir="rtl"] .page-item:first-child:not(.prev-item) .page-link{border-top-right-radius:1.428rem;border-bottom-right-radius:1.428rem}

[dir="ltr"] .page-item:last-child:not(.next-item).active{border-top-right-radius:1.428rem;border-bottom-right-radius:1.428rem}

[dir="rtl"] .page-item:last-child:not(.next-item).active{border-top-left-radius:1.428rem;border-bottom-left-radius:1.428rem}

.page-item:last-child:not(.next-item).active .page-link{border-radius:5rem}

[dir="ltr"] .page-item:last-child:not(.next-item) .page-link{border-top-right-radius:1.428rem;border-bottom-right-radius:1.428rem}

[dir="rtl"] .page-item:last-child:not(.next-item) .page-link{border-top-left-radius:1.428rem;border-bottom-left-radius:1.428rem}

.pagination.pagination-lg .page-item .page-link{font-size:1.14rem;min-width:2.6rem}

.pagination.pagination-sm .page-item .page-link{font-size:.857rem;min-width:2rem}

.modal .modal-header{background-color:#f6f6f6;border-bottom:none;border-bottom-left-radius:0;border-bottom-right-radius:0}

.modal .modal-header .close{padding:.2rem .62rem;box-shadow:0 5px 20px 0 rgba(34,41,47,.1);border-radius:.357rem;background:#fff;opacity:1;transition:all .23s ease .1s;position:relative;margin:0 !important}

[dir="ltr"] .modal .modal-header .close{transform:translate(8px, -2px)}

[dir="rtl"] .modal .modal-header .close{transform:translate(-8px, -2px)}

.modal .modal-header .close:hover,.modal .modal-header .close:focus,.modal .modal-header .close:active{opacity:1;outline:none;box-shadow:none}

[dir="ltr"] .modal .modal-header .close:hover,[dir="ltr"] .modal .modal-header .close:focus,[dir="ltr"] .modal .modal-header .close:active{transform:translate(5px, 3px)}

[dir="rtl"] .modal .modal-header .close:hover,[dir="rtl"] .modal .modal-header .close:focus,[dir="rtl"] .modal .modal-header .close:active{transform:translate(-5px, 3px)}

.modal .modal-content{border:none;overflow:visible;box-shadow:0 5px 20px 0 rgba(34,41,47,.1)}

.modal .modal-footer{padding:.8rem 1.4rem}

.modal.modal-sticky{bottom:0;top:auto;height:auto;position:fixed}

[dir="ltr"] .modal.modal-sticky{right:0;left:auto}

[dir="rtl"] .modal.modal-sticky{left:0;right:auto}

.modal.modal-sticky .modal-header{display:flex;align-items:center;justify-content:space-between}

.modal.modal-sticky .modal-dialog{box-shadow:0px 0px 24px 0 rgba(34,41,47,.25);border-radius:.357rem}

[dir="ltr"] .modal.modal-sticky .modal-dialog{margin-right:1rem !important}

[dir="rtl"] .modal.modal-sticky .modal-dialog{margin-left:1rem !important}

.modal.modal-sticky .modal-actions{background:transparent;box-shadow:none;line-height:0}

.modal-xs{max-width:300px}

@media(min-width: 1200px){
.modal-xl{margin-left:3%;margin-right:3%}}

.modal-slide-in,.modal-slide-in .modal{padding:0 !important;overflow:hidden !important}

.modal-slide-in .modal-dialog{position:fixed;top:0;bottom:0;margin:0;max-width:none;width:20rem}

[dir="ltr"] .modal-slide-in .modal-dialog{right:0;left:auto}

[dir="rtl"] .modal-slide-in .modal-dialog{left:0;right:auto}

.modal-slide-in .modal-dialog .modal-content{padding-bottom:3.7rem;padding-top:3.7rem;overflow:auto;border-radius:0;height:100%}

.modal-slide-in .modal-dialog .modal-body{padding-bottom:0;padding-top:0;margin:auto 0;flex-grow:0}

@media(min-width: 576px){
.modal-slide-in .modal-dialog{width:28rem}}

.modal-slide-in .modal-dialog.sidebar-sm{width:25rem}

@media(min-width: 576px){
.modal-slide-in .modal-dialog.sidebar-lg{width:30rem}}

.modal-slide-in .close{top:.4rem;z-index:10;transform:none;position:absolute;top:1.5rem;margin:0;padding:0 !important;line-height:.5;transform:translate(0, -50%)}

[dir="ltr"] .modal-slide-in .close{right:1.4rem}

[dir="rtl"] .modal-slide-in .close{left:1.4rem}

.modal-slide-in.fade .modal-dialog,.modal-slide-in .modal.fade .modal-dialog{transition:transform .1s ease-out}

[dir="ltr"] .modal-slide-in.fade .modal-dialog,[dir="ltr"] .modal-slide-in .modal.fade .modal-dialog{transform:translateX(100%)}

[dir="rtl"] .modal-slide-in.fade .modal-dialog,[dir="rtl"] .modal-slide-in .modal.fade .modal-dialog{transform:translateX(-100%)}

.modal-slide-in.show .modal-dialog,.modal-slide-in .modal.show .modal-dialog{transform:translateX(0) !important;transition:transform .1s ease-out}

@media(max-width: 576px){
.modal{padding-right:1rem;padding-left:1rem}

.modal .modal-xs,.modal .modal-sm{max-width:unset}

.modal.modal-sticky .modal-dialog{margin-right:.2rem !important;margin-left:.2rem;margin-bottom:3rem}}

.popover{box-shadow:0 0 10px 0 rgba(34,41,47,.1)}

.popover .popover-header{font-size:1.07rem;border:1px solid #a94cd8}

.popover .popover-body{border:1px solid #ebe9f1;border-top-color:#fff;border-bottom-left-radius:.358rem;border-bottom-right-radius:.358rem}

.popover.bs-popover-bottom .arrow:after,.popover.bs-popover-auto[x-placement^=bottom] .arrow:after{border-bottom-color:#a94cd8}

.bs-popover-top>.arrow::after,.bs-popover-auto[x-placement^=top]>.arrow::after{bottom:1px}

[dir="ltr"] .bs-popover-right>.arrow::after,[dir="ltr"] .bs-popover-auto[x-placement^=right]>.arrow::after{left:1px}

[dir="rtl"] .bs-popover-right>.arrow::after,[dir="rtl"] .bs-popover-auto[x-placement^=right]>.arrow::after{right:1px}

[dir="ltr"] .bs-popover-left>.arrow::after,[dir="ltr"] .bs-popover-auto[x-placement^=left]>.arrow::after{right:1px}

[dir="rtl"] .bs-popover-left>.arrow::after,[dir="rtl"] .bs-popover-auto[x-placement^=left]>.arrow::after{left:1px}

.font-small-1{font-size:.7rem !important}

svg.font-small-1{height:.7rem !important;width:.7rem !important}

.font-small-2{font-size:.8rem !important}

svg.font-small-2{height:.8rem !important;width:.8rem !important}

.font-small-3{font-size:.9rem !important}

svg.font-small-3{height:.9rem !important;width:.9rem !important}

.font-small-4{font-size:1rem !important}

svg.font-small-4{height:1rem !important;width:1rem !important}

.font-medium-1{font-size:1.1rem !important}

svg.font-medium-1{height:1.1rem !important;width:1.1rem !important}

.font-medium-2{font-size:1.2rem !important}

svg.font-medium-2{height:1.2rem !important;width:1.2rem !important}

.font-medium-3{font-size:1.3rem !important}

svg.font-medium-3{height:1.3rem !important;width:1.3rem !important}

.font-medium-4{font-size:1.4rem !important}

svg.font-medium-4{height:1.4rem !important;width:1.4rem !important}

.font-medium-5{font-size:1.5rem !important}

svg.font-medium-5{height:1.5rem !important;width:1.5rem !important}

.font-large-1{font-size:2rem !important}

svg.font-large-1{height:2rem !important;width:2rem !important}

.font-large-2{font-size:3rem !important}

svg.font-large-2{height:3rem !important;width:3rem !important}

.font-large-3{font-size:4rem !important}

svg.font-large-3{height:4rem !important;width:4rem !important}

.font-large-4{font-size:5rem !important}

svg.font-large-4{height:5rem !important;width:5rem !important}

.font-large-5{font-size:6rem !important}

svg.font-large-5{height:6rem !important;width:6rem !important}

.line-height-1{line-height:1 !important}

.line-height-condensed{line-height:1.5 !important}

.line-height-inherit{line-height:inherit !important}

[dir="ltr"] .rotate-45{transform:rotate(45deg)}

[dir="rtl"] .rotate-45{transform:rotate(-45deg)}

[dir="ltr"] .rotate-45-inverse{transform:rotate(-45deg)}

[dir="rtl"] .rotate-45-inverse{transform:rotate(45deg)}

[dir="ltr"] .rotate-90{transform:rotate(90deg)}

[dir="rtl"] .rotate-90{transform:rotate(-90deg)}

[dir="ltr"] .rotate-90-inverse{transform:rotate(-90deg)}

[dir="rtl"] .rotate-90-inverse{transform:rotate(90deg)}

[dir="ltr"] .rotate-180{transform:rotate(180deg)}

[dir="rtl"] .rotate-180{transform:rotate(-180deg)}

[dir="ltr"] .rotate-180-inverse{transform:rotate(-180deg)}

[dir="rtl"] .rotate-180-inverse{transform:rotate(180deg)}

.border-2{border-width:2px !important}

.border-top-2{border-top-width:2px !important}

.border-bottom-2{border-bottom-width:2px !important}

[dir="ltr"] .border-left-2{border-left-width:2px !important}

[dir="rtl"] .border-left-2{border-right-width:2px !important}

[dir="ltr"] .border-right-2{border-right-width:2px !important}

[dir="rtl"] .border-right-2{border-left-width:2px !important}

.border-3{border-width:3px !important}

.border-top-3{border-top-width:3px !important}

.border-bottom-3{border-bottom-width:3px !important}

[dir="ltr"] .border-left-3{border-left-width:3px !important}

[dir="rtl"] .border-left-3{border-right-width:3px !important}

[dir="ltr"] .border-right-3{border-right-width:3px !important}

[dir="rtl"] .border-right-3{border-left-width:3px !important}

.round{border-radius:1.5rem}

.square{border-radius:0}

.fit{max-width:100% !important}

.half-width{width:50% !important}

.full-width{width:100% !important}

.full-height{height:100% !important}

.height-50{height:50px !important}

.width-50{width:50px !important}

.height-100{height:100px !important}

.width-100{width:100px !important}

.height-150{height:150px !important}

.width-150{width:150px !important}

.height-200{height:200px !important}

.width-200{width:200px !important}

.height-250{height:250px !important}

.width-250{width:250px !important}

.height-300{height:300px !important}

.width-300{width:300px !important}

.height-350{height:350px !important}

.width-350{width:350px !important}

.height-400{height:405px !important}

.width-400{width:405px !important}

.height-450{height:455px !important}

.width-450{width:455px !important}

.height-500{height:500px !important}

.width-500{width:500px !important}

.height-550{height:550px !important}

.width-550{width:550px !important}

.height-600{height:600px !important}

.width-600{width:600px !important}

.height-650{height:650px !important}

.width-650{width:650px !important}

.height-700{height:700px !important}

.width-700{width:700px !important}

.height-750{height:750px !important}

.width-750{width:750px !important}

.height-800{height:800px !important}

.width-800{width:800px !important}

.height-5-per{height:5% !important}

.width-5-per{width:5% !important}

.height-10-per{height:10% !important}

.width-10-per{width:10% !important}

.height-15-per{height:15% !important}

.width-15-per{width:15% !important}

.height-20-per{height:20% !important}

.width-20-per{width:20% !important}

.height-25-per{height:25% !important}

.width-25-per{width:25% !important}

.height-30-per{height:30% !important}

.width-30-per{width:30% !important}

.height-35-per{height:35% !important}

.width-35-per{width:35% !important}

.height-40-per{height:40% !important}

.width-40-per{width:40% !important}

.height-45-per{height:45% !important}

.width-45-per{width:45% !important}

.height-50-per{height:50% !important}

.width-50-per{width:50% !important}

.height-55-per{height:55% !important}

.width-55-per{width:55% !important}

.height-60-per{height:60% !important}

.width-60-per{width:60% !important}

.height-65-per{height:65% !important}

.width-65-per{width:65% !important}

.height-70-per{height:70% !important}

.width-70-per{width:70% !important}

.height-75-per{height:75% !important}

.width-75-per{width:75% !important}

.height-80-per{height:80% !important}

.width-80-per{width:80% !important}

.height-85-per{height:85% !important}

.width-85-per{width:85% !important}

.height-90-per{height:90% !important}

.width-90-per{width:90% !important}

.height-95-per{height:95% !important}

.width-95-per{width:95% !important}

.position-top-0{top:0}

[dir="ltr"] .position-right-0{right:0}

[dir="rtl"] .position-right-0{left:0}

.position-bottom-0{bottom:0}

[dir="ltr"] .position-left-0{left:0}

[dir="rtl"] .position-left-0{right:0}

.overflow-visible{overflow:visible}

.overflow-scroll{overflow:scroll}

.overflow-x-scroll{overflow:scroll}

.overflow-y-scroll{overflow:scroll}

.cursor-pointer{cursor:pointer}

.cursor-move{cursor:move}

.cursor-default{cursor:default}

.cursor-progress{cursor:progress}

.cursor-not-allowed{cursor:not-allowed}

.zindex-1{z-index:1 !important}

.zindex-2{z-index:2 !important}

.zindex-3{z-index:3 !important}

.zindex-4{z-index:4 !important}

.zindex-0{z-index:0 !important}

.zindex-minus-1{z-index:-1 !important}

.zindex-minus-2{z-index:-2 !important}

.zindex-minus-3{z-index:-3 !important}

.zindex-minus-4{z-index:-4 !important}

.box-shadow-0{box-shadow:none !important}

.box-shadow-1{box-shadow:0 2px 5px 0 rgba(34,41,47,.16),0 2px 10px 0 rgba(34,41,47,.12)}

.box-shadow-2{box-shadow:0 8px 17px 0 rgba(34,41,47,.2),0 6px 20px 0 rgba(34,41,47,.19)}

.box-shadow-3{box-shadow:0 12px 15px 0 rgba(34,41,47,.24),0 17px 50px 0 rgba(34,41,47,.19)}

.box-shadow-4{box-shadow:0 16px 28px 0 rgba(34,41,47,.22),0 25px 55px 0 rgba(34,41,47,.21)}

.box-shadow-5{box-shadow:0 27px 24px 0 rgba(34,41,47,.2),0 40px 77px 0 rgba(34,41,47,.22)}

.box-shadow-6{box-shadow:0 4px 8px 0 rgba(34,41,47,.12),0 2px 4px 0 rgba(34,41,47,.08)}

.outline-none{outline:none !important}

.list-style-inside{list-style:inside}

.list-style-circle{list-style:circle}

.list-style-square{list-style:square}

.bg-cover{background-size:cover !important}

.background-repeat{background-repeat:repeat !important}

.background-no-repeat{background-repeat:no-repeat !important}

.icon-bg-circle{color:#fff;padding:.5rem;border-radius:50%}

[dir="ltr"] .icon-left{margin-right:.5rem}

[dir="rtl"] .icon-left{margin-left:.5rem}

[dir="ltr"] .icon-right{margin-right:.5rem}

[dir="rtl"] .icon-right{margin-left:.5rem}

.blockOverlay{z-index:1050 !important}

.blockElement,.blockPage{z-index:1051 !important}

.hidden{display:none;visibility:hidden}

a:hover{cursor:pointer}

.table-middle td{vertical-align:middle}

.fill-current{fill:currentColor}

.pointer-events-none{pointer-events:none}

.bg-white{background-color:#fff !important}

.bg-white .card-header,.bg-white .card-footer{background-color:transparent}

.border-white{border:1px solid #fff !important}

.border-top-white{border-top:1px solid #fff}

.border-bottom-white{border-bottom:1px solid #fff}

[dir="ltr"] .border-left-white{border-left:1px solid #fff}

[dir="rtl"] .border-left-white{border-right:1px solid #fff}

[dir="ltr"] .border-right-white{border-right:1px solid #fff}

[dir="rtl"] .border-right-white{border-left:1px solid #fff}

.bg-white.badge-glow,.border-white.badge-glow,.badge-white.badge-glow{box-shadow:0px 0px 10px #fff}

.overlay-white{background:#fff;background:rgba(255,255,255,.6)}

input:focus~.bg-white{box-shadow:0 0 0 .075rem #fff,0 0 0 .21rem #fff !important}

.bg-black{background-color:#000 !important}

.bg-black .card-header,.bg-black .card-footer{background-color:transparent}

.border-black{border:1px solid #000 !important}

.border-top-black{border-top:1px solid #000}

.border-bottom-black{border-bottom:1px solid #000}

[dir="ltr"] .border-left-black{border-left:1px solid #000}

[dir="rtl"] .border-left-black{border-right:1px solid #000}

[dir="ltr"] .border-right-black{border-right:1px solid #000}

[dir="rtl"] .border-right-black{border-left:1px solid #000}

.bg-black.badge-glow,.border-black.badge-glow,.badge-black.badge-glow{box-shadow:0px 0px 10px #000}

.overlay-black{background:#000;background:rgba(0,0,0,.6)}

input:focus~.bg-black{box-shadow:0 0 0 .075rem #fff,0 0 0 .21rem #000 !important}

.bg-dark{background-color:#161d31 !important}

.bg-dark .card-header,.bg-dark .card-footer{background-color:transparent}

.alert-dark{background:rgba(22,29,49,.12) !important;color:#161d31 !important}

.alert-dark .alert-heading{box-shadow:rgba(22,29,49,.4) 0px 6px 15px -7px}

.alert-dark .alert-link{color:#0e131f !important}

.alert-dark .close{color:#161d31 !important}

.border-dark{border:1px solid #161d31 !important}

.border-top-dark{border-top:1px solid #161d31}

.border-bottom-dark{border-bottom:1px solid #161d31}

[dir="ltr"] .border-left-dark{border-left:1px solid #161d31}

[dir="rtl"] .border-left-dark{border-right:1px solid #161d31}

[dir="ltr"] .border-right-dark{border-right:1px solid #161d31}

[dir="rtl"] .border-right-dark{border-left:1px solid #161d31}

.bg-dark.badge-glow,.border-dark.badge-glow,.badge-dark.badge-glow{box-shadow:0px 0px 10px #161d31}

.badge.badge-light-dark{background-color:rgba(22,29,49,.12);color:#161d31 !important}

.overlay-dark{background:#161d31;background:rgba(22,29,49,.6)}

.btn-dark{border-color:#161d31 !important;background-color:#161d31 !important;color:#fff !important}

.btn-dark:focus,.btn-dark:active,.btn-dark.active{color:#fff;background-color:#343434 !important}

.btn-dark:hover:not(.disabled):not(:disabled){box-shadow:0 8px 25px -8px #161d31}

.btn-dark:not(:disabled):not(.disabled):active:focus{box-shadow:none}

.btn-flat-dark{background-color:transparent;color:#161d31}

.btn-flat-dark:hover{color:#161d31}

.btn-flat-dark:hover:not(.disabled):not(:disabled){background-color:rgba(22,29,49,.12)}

.btn-flat-dark:active,.btn-flat-dark.active,.btn-flat-dark:focus{background-color:rgba(22,29,49,.2);color:#161d31}

.btn-flat-dark.dropdown-toggle::after{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23161d31%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-down%27%3E%3Cpolyline points=%276 9 12 15 18 9%27%3E%3C/polyline%3E%3C/svg%3E")}

.btn-relief-dark{background-color:#161d31;box-shadow:inset 0 -3px 0 0 rgba(34,41,47,.2);color:#fff;transition:all .2s ease}

.btn-relief-dark:hover:not(.disabled):not(:disabled){background-color:#626262}

.btn-relief-dark:active,.btn-relief-dark.active,.btn-relief-dark:focus{background-color:#343434}

.btn-relief-dark:hover{color:#fff}

.btn-relief-dark:active,.btn-relief-dark.active{outline:none;box-shadow:none;transform:translateY(3px)}

.btn-outline-dark{border:1px solid #161d31 !important;background-color:transparent;color:#161d31}

.btn-outline-dark:hover:not(.disabled):not(:disabled){background-color:rgba(22,29,49,.04);color:#161d31}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus{box-shadow:none}

.btn-outline-dark:not(:disabled):not(.disabled):active,.btn-outline-dark:not(:disabled):not(.disabled).active,.btn-outline-dark:not(:disabled):not(.disabled):focus{background-color:rgba(22,29,49,.2);color:#161d31}

.btn-outline-dark.dropdown-toggle::after{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23161d31%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-down%27%3E%3Cpolyline points=%276 9 12 15 18 9%27%3E%3C/polyline%3E%3C/svg%3E")}

.show>.btn-outline-dark.dropdown-toggle{background-color:rgba(22,29,49,.2);color:#161d31}

.btn-outline-dark.waves-effect .waves-ripple,.btn-flat-dark.waves-effect .waves-ripple{background:radial-gradient(rgba(22, 29, 49, 0.2) 0, rgba(22, 29, 49, 0.3) 40%, rgba(22, 29, 49, 0.4) 50%, rgba(22, 29, 49, 0.5) 60%, rgba(255, 255, 255, 0) 70%)}

.bullet.bullet-dark{background-color:#161d31}

.modal.modal-dark .modal-header .modal-title{color:#161d31}

.modal.modal-dark .modal-header .close{color:#161d31 !important}

.progress-bar-dark{background-color:rgba(22,29,49,.12)}

.progress-bar-dark .progress-bar{background-color:#161d31}

.divider.divider-dark .divider-text:before,.divider.divider-dark .divider-text:after{border-color:#161d31 !important}

input:focus~.bg-dark{box-shadow:0 0 0 .075rem #fff,0 0 0 .21rem #161d31 !important}

.custom-control-dark .custom-control-input:checked~.custom-control-label::before,.custom-control-dark .custom-control-input:active~.custom-control-label::before{border-color:#161d31;background-color:#161d31}

.custom-control-dark.custom-checkbox .custom-control-input:checked~.custom-control-label::before,.custom-control-dark.custom-checkbox .custom-control-input:active~.custom-control-label::before,.custom-control-dark.custom-checkbox .custom-control-input:focus~.custom-control-label::before,.custom-control-dark.custom-radio .custom-control-input:checked~.custom-control-label::before,.custom-control-dark.custom-radio .custom-control-input:active~.custom-control-label::before,.custom-control-dark.custom-radio .custom-control-input:focus~.custom-control-label::before{box-shadow:0 2px 4px 0 rgba(22,29,49,.4) !important}

.custom-control-dark .custom-control-input:disabled:checked~.custom-control-label::before{background-color:rgba(22,29,49,.65) !important;border:none;box-shadow:none !important}

.custom-control-dark .custom-control-input:focus~.custom-control-label::before{border-color:#161d31}

.custom-switch-dark .custom-control-input:checked~.custom-control-label::before{background-color:#161d31 !important;color:#fff;transition:all .2s ease-out}

.select2-dark .select2-container--default .select2-selection--multiple .select2-selection__choice{background:#161d31 !important;border-color:#161d31 !important}

.text-dark.text-darken-1{color:#343434 !important}

.bg-dark.bg-darken-1{background-color:#343434 !important}

.border-dark.border-darken-1{border:1px solid #343434 !important}

.border-top-dark.border-top-darken-1{border-top:1px solid #343434 !important}

.border-bottom-dark.border-bottom-darken-1{border-bottom:1px solid #343434 !important}

[dir="ltr"] .border-left-dark.border-left-darken-1{border-left:1px solid #343434 !important}

[dir="rtl"] .border-left-dark.border-left-darken-1{border-right:1px solid #343434 !important}

[dir="ltr"] .border-right-dark.border-right-darken-1{border-right:1px solid #343434 !important}

[dir="rtl"] .border-right-dark.border-right-darken-1{border-left:1px solid #343434 !important}

.overlay-dark.overlay-darken-1{background:#343434;background:rgba(52,52,52,.6)}

.text-dark.text-darken-2{color:#1e1e1e !important}

.bg-dark.bg-darken-2{background-color:#1e1e1e !important}

.border-dark.border-darken-2{border:1px solid #1e1e1e !important}

.border-top-dark.border-top-darken-2{border-top:1px solid #1e1e1e !important}

.border-bottom-dark.border-bottom-darken-2{border-bottom:1px solid #1e1e1e !important}

[dir="ltr"] .border-left-dark.border-left-darken-2{border-left:1px solid #1e1e1e !important}

[dir="rtl"] .border-left-dark.border-left-darken-2{border-right:1px solid #1e1e1e !important}

[dir="ltr"] .border-right-dark.border-right-darken-2{border-right:1px solid #1e1e1e !important}

[dir="rtl"] .border-right-dark.border-right-darken-2{border-left:1px solid #1e1e1e !important}

.overlay-dark.overlay-darken-2{background:#1e1e1e;background:rgba(30,30,30,.6)}

.text-dark.text-darken-3{color:#626262 !important}

.bg-dark.bg-darken-3{background-color:#626262 !important}

.border-dark.border-darken-3{border:1px solid #626262 !important}

.border-top-dark.border-top-darken-3{border-top:1px solid #626262 !important}

.border-bottom-dark.border-bottom-darken-3{border-bottom:1px solid #626262 !important}

[dir="ltr"] .border-left-dark.border-left-darken-3{border-left:1px solid #626262 !important}

[dir="rtl"] .border-left-dark.border-left-darken-3{border-right:1px solid #626262 !important}

[dir="ltr"] .border-right-dark.border-right-darken-3{border-right:1px solid #626262 !important}

[dir="rtl"] .border-right-dark.border-right-darken-3{border-left:1px solid #626262 !important}

.overlay-dark.overlay-darken-3{background:#626262;background:rgba(98,98,98,.6)}

.bg-light{background-color:#f6f6f6 !important}

.bg-light .card-header,.bg-light .card-footer{background-color:transparent}

.border-light{border:1px solid #f6f6f6 !important}

.border-top-light{border-top:1px solid #f6f6f6}

.border-bottom-light{border-bottom:1px solid #f6f6f6}

[dir="ltr"] .border-left-light{border-left:1px solid #f6f6f6}

[dir="rtl"] .border-left-light{border-right:1px solid #f6f6f6}

[dir="ltr"] .border-right-light{border-right:1px solid #f6f6f6}

[dir="rtl"] .border-right-light{border-left:1px solid #f6f6f6}

.bg-light.badge-glow,.border-light.badge-glow,.badge-light.badge-glow{box-shadow:0px 0px 10px #f6f6f6}

.overlay-light{background:#f6f6f6;background:rgba(246,246,246,.6)}

input:focus~.bg-light{box-shadow:0 0 0 .075rem #fff,0 0 0 .21rem #f6f6f6 !important}

.text-primary.text-lighten-5{color:#dbb5ef !important}

.bg-primary.bg-lighten-5{background-color:#dbb5ef !important}

.border-primary.border-lighten-5{border:1px solid #dbb5ef !important}

.border-top-primary.border-top-lighten-5{border-top:1px solid #dbb5ef !important}

.border-bottom-primary.border-bottom-lighten-5{border-bottom:1px solid #dbb5ef !important}

[dir="ltr"] .border-left-primary.border-left-lighten-5{border-left:1px solid #dbb5ef !important}

[dir="rtl"] .border-left-primary.border-left-lighten-5{border-right:1px solid #dbb5ef !important}

[dir="ltr"] .border-right-primary.border-right-lighten-5{border-right:1px solid #dbb5ef !important}

[dir="rtl"] .border-right-primary.border-right-lighten-5{border-left:1px solid #dbb5ef !important}

.overlay-primary.overlay-lighten-5{background:#dbb5ef;background:rgba(219,181,239,.6)}

.text-primary.text-lighten-4{color:#d1a0ea !important}

.bg-primary.bg-lighten-4{background-color:#d1a0ea !important}

.border-primary.border-lighten-4{border:1px solid #d1a0ea !important}

.border-top-primary.border-top-lighten-4{border-top:1px solid #d1a0ea !important}

.border-bottom-primary.border-bottom-lighten-4{border-bottom:1px solid #d1a0ea !important}

[dir="ltr"] .border-left-primary.border-left-lighten-4{border-left:1px solid #d1a0ea !important}

[dir="rtl"] .border-left-primary.border-left-lighten-4{border-right:1px solid #d1a0ea !important}

[dir="ltr"] .border-right-primary.border-right-lighten-4{border-right:1px solid #d1a0ea !important}

[dir="rtl"] .border-right-primary.border-right-lighten-4{border-left:1px solid #d1a0ea !important}

.overlay-primary.overlay-lighten-4{background:#d1a0ea;background:rgba(209,160,234,.6)}

.text-primary.text-lighten-3{color:#c78be6 !important}

.bg-primary.bg-lighten-3{background-color:#c78be6 !important}

.border-primary.border-lighten-3{border:1px solid #c78be6 !important}

.border-top-primary.border-top-lighten-3{border-top:1px solid #c78be6 !important}

.border-bottom-primary.border-bottom-lighten-3{border-bottom:1px solid #c78be6 !important}

[dir="ltr"] .border-left-primary.border-left-lighten-3{border-left:1px solid #c78be6 !important}

[dir="rtl"] .border-left-primary.border-left-lighten-3{border-right:1px solid #c78be6 !important}

[dir="ltr"] .border-right-primary.border-right-lighten-3{border-right:1px solid #c78be6 !important}

[dir="rtl"] .border-right-primary.border-right-lighten-3{border-left:1px solid #c78be6 !important}

.overlay-primary.overlay-lighten-3{background:#c78be6;background:rgba(199,139,230,.6)}

.text-primary.text-lighten-2{color:#bd76e1 !important}

.bg-primary.bg-lighten-2{background-color:#bd76e1 !important}

.border-primary.border-lighten-2{border:1px solid #bd76e1 !important}

.border-top-primary.border-top-lighten-2{border-top:1px solid #bd76e1 !important}

.border-bottom-primary.border-bottom-lighten-2{border-bottom:1px solid #bd76e1 !important}

[dir="ltr"] .border-left-primary.border-left-lighten-2{border-left:1px solid #bd76e1 !important}

[dir="rtl"] .border-left-primary.border-left-lighten-2{border-right:1px solid #bd76e1 !important}

[dir="ltr"] .border-right-primary.border-right-lighten-2{border-right:1px solid #bd76e1 !important}

[dir="rtl"] .border-right-primary.border-right-lighten-2{border-left:1px solid #bd76e1 !important}

.overlay-primary.overlay-lighten-2{background:#bd76e1;background:rgba(189,118,225,.6)}

.text-primary.text-lighten-1{color:#b361dd !important}

.bg-primary.bg-lighten-1{background-color:#b361dd !important}

.border-primary.border-lighten-1{border:1px solid #b361dd !important}

.border-top-primary.border-top-lighten-1{border-top:1px solid #b361dd !important}

.border-bottom-primary.border-bottom-lighten-1{border-bottom:1px solid #b361dd !important}

[dir="ltr"] .border-left-primary.border-left-lighten-1{border-left:1px solid #b361dd !important}

[dir="rtl"] .border-left-primary.border-left-lighten-1{border-right:1px solid #b361dd !important}

[dir="ltr"] .border-right-primary.border-right-lighten-1{border-right:1px solid #b361dd !important}

[dir="rtl"] .border-right-primary.border-right-lighten-1{border-left:1px solid #b361dd !important}

.overlay-primary.overlay-lighten-1{background:#b361dd;background:rgba(179,97,221,.6)}

.bg-primary{background-color:#a94cd8 !important}

.bg-primary .card-header,.bg-primary .card-footer{background-color:transparent}

.alert-primary{background:rgba(169,76,216,.12) !important;color:#a94cd8 !important}

.alert-primary .alert-heading{box-shadow:rgba(169,76,216,.4) 0px 6px 15px -7px}

.alert-primary .alert-link{color:#9f37d3 !important}

.alert-primary .close{color:#a94cd8 !important}

.bg-light-primary{background:rgba(169,76,216,.12) !important;color:#a94cd8 !important}

.bg-light-primary .fc-list-event-dot{border-color:#a94cd8 !important}

.bg-light-primary.fc-list-event:hover td{background:rgba(169,76,216,.1) !important}

.bg-light-primary.fc-list-event .fc-list-event-title{color:#6e6b7b}

.avatar.bg-light-primary{color:#a94cd8 !important}

.border-primary{border:1px solid #a94cd8 !important}

.border-top-primary{border-top:1px solid #a94cd8}

.border-bottom-primary{border-bottom:1px solid #a94cd8}

[dir="ltr"] .border-left-primary{border-left:1px solid #a94cd8}

[dir="rtl"] .border-left-primary{border-right:1px solid #a94cd8}

[dir="ltr"] .border-right-primary{border-right:1px solid #a94cd8}

[dir="rtl"] .border-right-primary{border-left:1px solid #a94cd8}

.bg-primary.badge-glow,.border-primary.badge-glow,.badge-primary.badge-glow{box-shadow:0px 0px 10px #a94cd8}

.badge.badge-light-primary{background-color:rgba(169,76,216,.12);color:#a94cd8 !important}

.overlay-primary{background:#a94cd8;background:rgba(169,76,216,.6)}

.btn-primary{border-color:#a94cd8 !important;background-color:#a94cd8 !important;color:#fff !important}

.btn-primary:focus,.btn-primary:active,.btn-primary.active{color:#fff;background-color:#9f37d3 !important}

.btn-primary:hover:not(.disabled):not(:disabled){box-shadow:0 8px 25px -8px #a94cd8}

.btn-primary:not(:disabled):not(.disabled):active:focus{box-shadow:none}

.btn-flat-primary{background-color:transparent;color:#a94cd8}

.btn-flat-primary:hover{color:#a94cd8}

.btn-flat-primary:hover:not(.disabled):not(:disabled){background-color:rgba(169,76,216,.12)}

.btn-flat-primary:active,.btn-flat-primary.active,.btn-flat-primary:focus{background-color:rgba(169,76,216,.2);color:#a94cd8}

.btn-flat-primary.dropdown-toggle::after{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23A94CD8%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-down%27%3E%3Cpolyline points=%276 9 12 15 18 9%27%3E%3C/polyline%3E%3C/svg%3E")}

.btn-relief-primary{background-color:#a94cd8;box-shadow:inset 0 -3px 0 0 rgba(34,41,47,.2);color:#fff;transition:all .2s ease}

.btn-relief-primary:hover:not(.disabled):not(:disabled){background-color:#b361dd}

.btn-relief-primary:active,.btn-relief-primary.active,.btn-relief-primary:focus{background-color:#9f37d3}

.btn-relief-primary:hover{color:#fff}

.btn-relief-primary:active,.btn-relief-primary.active{outline:none;box-shadow:none;transform:translateY(3px)}

.btn-outline-primary{border:1px solid #a94cd8 !important;background-color:transparent;color:#a94cd8}

.btn-outline-primary:hover:not(.disabled):not(:disabled){background-color:rgba(169,76,216,.04);color:#a94cd8}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus{box-shadow:none}

.btn-outline-primary:not(:disabled):not(.disabled):active,.btn-outline-primary:not(:disabled):not(.disabled).active,.btn-outline-primary:not(:disabled):not(.disabled):focus{background-color:rgba(169,76,216,.2);color:#a94cd8}

.btn-outline-primary.dropdown-toggle::after{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23A94CD8%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-down%27%3E%3Cpolyline points=%276 9 12 15 18 9%27%3E%3C/polyline%3E%3C/svg%3E")}

.show>.btn-outline-primary.dropdown-toggle{background-color:rgba(169,76,216,.2);color:#a94cd8}

.btn-outline-primary.waves-effect .waves-ripple,.btn-flat-primary.waves-effect .waves-ripple{background:radial-gradient(rgba(169, 76, 216, 0.2) 0, rgba(169, 76, 216, 0.3) 40%, rgba(169, 76, 216, 0.4) 50%, rgba(169, 76, 216, 0.5) 60%, rgba(255, 255, 255, 0) 70%)}

.bullet.bullet-primary{background-color:#a94cd8}

.modal.modal-primary .modal-header .modal-title{color:#a94cd8}

.modal.modal-primary .modal-header .close{color:#a94cd8 !important}

.pagination-primary .page-item.active .page-link{background:#a94cd8 !important;color:#fff}

.pagination-primary .page-item.active .page-link:hover{color:#fff}

.pagination-primary .page-item .page-link:hover{color:#a94cd8}

.pagination-primary .page-item.prev-item .page-link:hover,.pagination-primary .page-item.next-item .page-link:hover{background:#a94cd8;color:#fff}

.pagination-primary .page-item.next-item .page-link:active:after,.pagination-primary .page-item.next-item .page-link:hover:after,.pagination-primary .page-item.next .page-link:active:after,.pagination-primary .page-item.next .page-link:hover:after{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23A94CD8%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-right%27%3E%3Cpolyline points=%279 18 15 12 9 6%27%3E%3C/polyline%3E%3C/svg%3E") !important}

.pagination-primary .page-item.prev-item .page-link:active:before,.pagination-primary .page-item.prev-item .page-link:hover:before,.pagination-primary .page-item.prev .page-link:active:before,.pagination-primary .page-item.prev .page-link:hover:before{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23A94CD8%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-left%27%3E%3Cpolyline points=%2715 18 9 12 15 6%27%3E%3C/polyline%3E%3C/svg%3E") !important}

.nav-pill-primary .nav-item .nav-link.active{color:#fff;background-color:#a94cd8 !important;border-color:#a94cd8;box-shadow:0 4px 18px -4px rgba(169,76,216,.65)}

.progress-bar-primary{background-color:rgba(169,76,216,.12)}

.progress-bar-primary .progress-bar{background-color:#a94cd8}

.divider.divider-primary .divider-text:before,.divider.divider-primary .divider-text:after{border-color:#a94cd8 !important}

input:focus~.bg-primary{box-shadow:0 0 0 .075rem #fff,0 0 0 .21rem #a94cd8 !important}

.custom-control-primary .custom-control-input:checked~.custom-control-label::before,.custom-control-primary .custom-control-input:active~.custom-control-label::before{border-color:#a94cd8;background-color:#a94cd8}

.custom-control-primary.custom-checkbox .custom-control-input:checked~.custom-control-label::before,.custom-control-primary.custom-checkbox .custom-control-input:active~.custom-control-label::before,.custom-control-primary.custom-checkbox .custom-control-input:focus~.custom-control-label::before,.custom-control-primary.custom-radio .custom-control-input:checked~.custom-control-label::before,.custom-control-primary.custom-radio .custom-control-input:active~.custom-control-label::before,.custom-control-primary.custom-radio .custom-control-input:focus~.custom-control-label::before{box-shadow:0 2px 4px 0 rgba(169,76,216,.4) !important}

.custom-control-primary .custom-control-input:disabled:checked~.custom-control-label::before{background-color:rgba(169,76,216,.65) !important;border:none;box-shadow:none !important}

.custom-control-primary .custom-control-input:focus~.custom-control-label::before{border-color:#a94cd8}

.custom-switch-primary .custom-control-input:checked~.custom-control-label::before{background-color:#a94cd8 !important;color:#fff;transition:all .2s ease-out}

.select2-primary .select2-container--default .select2-selection--multiple .select2-selection__choice{background:#a94cd8 !important;border-color:#a94cd8 !important}

.text-primary.text-darken-1{color:#9f37d3 !important}

.bg-primary.bg-darken-1{background-color:#9f37d3 !important}

.border-primary.border-darken-1{border:1px solid #9f37d3 !important}

.border-top-primary.border-top-darken-1{border-top:1px solid #9f37d3 !important}

.border-bottom-primary.border-bottom-darken-1{border-bottom:1px solid #9f37d3 !important}

[dir="ltr"] .border-left-primary.border-left-darken-1{border-left:1px solid #9f37d3 !important}

[dir="rtl"] .border-left-primary.border-left-darken-1{border-right:1px solid #9f37d3 !important}

[dir="ltr"] .border-right-primary.border-right-darken-1{border-right:1px solid #9f37d3 !important}

[dir="rtl"] .border-right-primary.border-right-darken-1{border-left:1px solid #9f37d3 !important}

.overlay-primary.overlay-darken-1{background:#9f37d3;background:rgba(159,55,211,.6)}

.text-primary.text-darken-2{color:#922bc6 !important}

.bg-primary.bg-darken-2{background-color:#922bc6 !important}

.border-primary.border-darken-2{border:1px solid #922bc6 !important}

.border-top-primary.border-top-darken-2{border-top:1px solid #922bc6 !important}

.border-bottom-primary.border-bottom-darken-2{border-bottom:1px solid #922bc6 !important}

[dir="ltr"] .border-left-primary.border-left-darken-2{border-left:1px solid #922bc6 !important}

[dir="rtl"] .border-left-primary.border-left-darken-2{border-right:1px solid #922bc6 !important}

[dir="ltr"] .border-right-primary.border-right-darken-2{border-right:1px solid #922bc6 !important}

[dir="rtl"] .border-right-primary.border-right-darken-2{border-left:1px solid #922bc6 !important}

.overlay-primary.overlay-darken-2{background:#922bc6;background:rgba(146,43,198,.6)}

.text-primary.text-darken-3{color:#8227b1 !important}

.bg-primary.bg-darken-3{background-color:#8227b1 !important}

.border-primary.border-darken-3{border:1px solid #8227b1 !important}

.border-top-primary.border-top-darken-3{border-top:1px solid #8227b1 !important}

.border-bottom-primary.border-bottom-darken-3{border-bottom:1px solid #8227b1 !important}

[dir="ltr"] .border-left-primary.border-left-darken-3{border-left:1px solid #8227b1 !important}

[dir="rtl"] .border-left-primary.border-left-darken-3{border-right:1px solid #8227b1 !important}

[dir="ltr"] .border-right-primary.border-right-darken-3{border-right:1px solid #8227b1 !important}

[dir="rtl"] .border-right-primary.border-right-darken-3{border-left:1px solid #8227b1 !important}

.overlay-primary.overlay-darken-3{background:#8227b1;background:rgba(130,39,177,.6)}

.text-primary.text-darken-4{color:#73229c !important}

.bg-primary.bg-darken-4{background-color:#73229c !important}

.border-primary.border-darken-4{border:1px solid #73229c !important}

.border-top-primary.border-top-darken-4{border-top:1px solid #73229c !important}

.border-bottom-primary.border-bottom-darken-4{border-bottom:1px solid #73229c !important}

[dir="ltr"] .border-left-primary.border-left-darken-4{border-left:1px solid #73229c !important}

[dir="rtl"] .border-left-primary.border-left-darken-4{border-right:1px solid #73229c !important}

[dir="ltr"] .border-right-primary.border-right-darken-4{border-right:1px solid #73229c !important}

[dir="rtl"] .border-right-primary.border-right-darken-4{border-left:1px solid #73229c !important}

.overlay-primary.overlay-darken-4{background:#73229c;background:rgba(115,34,156,.6)}

.text-primary.text-accent-1{color:#bdfdff !important}

.bg-primary.bg-accent-1{background-color:#bdfdff !important}

.border-primary.border-accent-1{border:1px solid #bdfdff !important}

.border-top-primary.border-top-accent-1{border-top:1px solid #bdfdff !important}

.border-bottom-primary.border-bottom-accent-1{border-bottom:1px solid #bdfdff !important}

[dir="ltr"] .border-left-primary.border-left-accent-1{border-left:1px solid #bdfdff !important}

[dir="rtl"] .border-left-primary.border-left-accent-1{border-right:1px solid #bdfdff !important}

[dir="ltr"] .border-right-primary.border-right-accent-1{border-right:1px solid #bdfdff !important}

[dir="rtl"] .border-right-primary.border-right-accent-1{border-left:1px solid #bdfdff !important}

.overlay-primary.overlay-accent-1{background:#bdfdff;background:rgba(189,253,255,.6)}

.text-primary.text-accent-2{color:#8afbff !important}

.bg-primary.bg-accent-2{background-color:#8afbff !important}

.border-primary.border-accent-2{border:1px solid #8afbff !important}

.border-top-primary.border-top-accent-2{border-top:1px solid #8afbff !important}

.border-bottom-primary.border-bottom-accent-2{border-bottom:1px solid #8afbff !important}

[dir="ltr"] .border-left-primary.border-left-accent-2{border-left:1px solid #8afbff !important}

[dir="rtl"] .border-left-primary.border-left-accent-2{border-right:1px solid #8afbff !important}

[dir="ltr"] .border-right-primary.border-right-accent-2{border-right:1px solid #8afbff !important}

[dir="rtl"] .border-right-primary.border-right-accent-2{border-left:1px solid #8afbff !important}

.overlay-primary.overlay-accent-2{background:#8afbff;background:rgba(138,251,255,.6)}

.text-primary.text-accent-3{color:#57faff !important}

.bg-primary.bg-accent-3{background-color:#57faff !important}

.border-primary.border-accent-3{border:1px solid #57faff !important}

.border-top-primary.border-top-accent-3{border-top:1px solid #57faff !important}

.border-bottom-primary.border-bottom-accent-3{border-bottom:1px solid #57faff !important}

[dir="ltr"] .border-left-primary.border-left-accent-3{border-left:1px solid #57faff !important}

[dir="rtl"] .border-left-primary.border-left-accent-3{border-right:1px solid #57faff !important}

[dir="ltr"] .border-right-primary.border-right-accent-3{border-right:1px solid #57faff !important}

[dir="rtl"] .border-right-primary.border-right-accent-3{border-left:1px solid #57faff !important}

.overlay-primary.overlay-accent-3{background:#57faff;background:rgba(87,250,255,.6)}

.text-primary.text-accent-4{color:#3df9ff !important}

.bg-primary.bg-accent-4{background-color:#3df9ff !important}

.border-primary.border-accent-4{border:1px solid #3df9ff !important}

.border-top-primary.border-top-accent-4{border-top:1px solid #3df9ff !important}

.border-bottom-primary.border-bottom-accent-4{border-bottom:1px solid #3df9ff !important}

[dir="ltr"] .border-left-primary.border-left-accent-4{border-left:1px solid #3df9ff !important}

[dir="rtl"] .border-left-primary.border-left-accent-4{border-right:1px solid #3df9ff !important}

[dir="ltr"] .border-right-primary.border-right-accent-4{border-right:1px solid #3df9ff !important}

[dir="rtl"] .border-right-primary.border-right-accent-4{border-left:1px solid #3df9ff !important}

.overlay-primary.overlay-accent-4{background:#3df9ff;background:rgba(61,249,255,.6)}

.text-secondary.text-lighten-5{color:#c4c6c8 !important}

.bg-secondary.bg-lighten-5{background-color:#c4c6c8 !important}

.border-secondary.border-lighten-5{border:1px solid #c4c6c8 !important}

.border-top-secondary.border-top-lighten-5{border-top:1px solid #c4c6c8 !important}

.border-bottom-secondary.border-bottom-lighten-5{border-bottom:1px solid #c4c6c8 !important}

[dir="ltr"] .border-left-secondary.border-left-lighten-5{border-left:1px solid #c4c6c8 !important}

[dir="rtl"] .border-left-secondary.border-left-lighten-5{border-right:1px solid #c4c6c8 !important}

[dir="ltr"] .border-right-secondary.border-right-lighten-5{border-right:1px solid #c4c6c8 !important}

[dir="rtl"] .border-right-secondary.border-right-lighten-5{border-left:1px solid #c4c6c8 !important}

.overlay-secondary.overlay-lighten-5{background:#c4c6c8;background:rgba(196,198,200,.6)}

.text-secondary.text-lighten-4{color:#b7b9bc !important}

.bg-secondary.bg-lighten-4{background-color:#b7b9bc !important}

.border-secondary.border-lighten-4{border:1px solid #b7b9bc !important}

.border-top-secondary.border-top-lighten-4{border-top:1px solid #b7b9bc !important}

.border-bottom-secondary.border-bottom-lighten-4{border-bottom:1px solid #b7b9bc !important}

[dir="ltr"] .border-left-secondary.border-left-lighten-4{border-left:1px solid #b7b9bc !important}

[dir="rtl"] .border-left-secondary.border-left-lighten-4{border-right:1px solid #b7b9bc !important}

[dir="ltr"] .border-right-secondary.border-right-lighten-4{border-right:1px solid #b7b9bc !important}

[dir="rtl"] .border-right-secondary.border-right-lighten-4{border-left:1px solid #b7b9bc !important}

.overlay-secondary.overlay-lighten-4{background:#b7b9bc;background:rgba(183,185,188,.6)}

.text-secondary.text-lighten-3{color:#aaacb0 !important}

.bg-secondary.bg-lighten-3{background-color:#aaacb0 !important}

.border-secondary.border-lighten-3{border:1px solid #aaacb0 !important}

.border-top-secondary.border-top-lighten-3{border-top:1px solid #aaacb0 !important}

.border-bottom-secondary.border-bottom-lighten-3{border-bottom:1px solid #aaacb0 !important}

[dir="ltr"] .border-left-secondary.border-left-lighten-3{border-left:1px solid #aaacb0 !important}

[dir="rtl"] .border-left-secondary.border-left-lighten-3{border-right:1px solid #aaacb0 !important}

[dir="ltr"] .border-right-secondary.border-right-lighten-3{border-right:1px solid #aaacb0 !important}

[dir="rtl"] .border-right-secondary.border-right-lighten-3{border-left:1px solid #aaacb0 !important}

.overlay-secondary.overlay-lighten-3{background:#aaacb0;background:rgba(170,172,176,.6)}

.text-secondary.text-lighten-2{color:#9ca0a4 !important}

.bg-secondary.bg-lighten-2{background-color:#9ca0a4 !important}

.border-secondary.border-lighten-2{border:1px solid #9ca0a4 !important}

.border-top-secondary.border-top-lighten-2{border-top:1px solid #9ca0a4 !important}

.border-bottom-secondary.border-bottom-lighten-2{border-bottom:1px solid #9ca0a4 !important}

[dir="ltr"] .border-left-secondary.border-left-lighten-2{border-left:1px solid #9ca0a4 !important}

[dir="rtl"] .border-left-secondary.border-left-lighten-2{border-right:1px solid #9ca0a4 !important}

[dir="ltr"] .border-right-secondary.border-right-lighten-2{border-right:1px solid #9ca0a4 !important}

[dir="rtl"] .border-right-secondary.border-right-lighten-2{border-left:1px solid #9ca0a4 !important}

.overlay-secondary.overlay-lighten-2{background:#9ca0a4;background:rgba(156,160,164,.6)}

.text-secondary.text-lighten-1{color:#8f9397 !important}

.bg-secondary.bg-lighten-1{background-color:#8f9397 !important}

.border-secondary.border-lighten-1{border:1px solid #8f9397 !important}

.border-top-secondary.border-top-lighten-1{border-top:1px solid #8f9397 !important}

.border-bottom-secondary.border-bottom-lighten-1{border-bottom:1px solid #8f9397 !important}

[dir="ltr"] .border-left-secondary.border-left-lighten-1{border-left:1px solid #8f9397 !important}

[dir="rtl"] .border-left-secondary.border-left-lighten-1{border-right:1px solid #8f9397 !important}

[dir="ltr"] .border-right-secondary.border-right-lighten-1{border-right:1px solid #8f9397 !important}

[dir="rtl"] .border-right-secondary.border-right-lighten-1{border-left:1px solid #8f9397 !important}

.overlay-secondary.overlay-lighten-1{background:#8f9397;background:rgba(143,147,151,.6)}

.bg-secondary{background-color:#82868b !important}

.bg-secondary .card-header,.bg-secondary .card-footer{background-color:transparent}

.alert-secondary{background:rgba(130,134,139,.12) !important;color:#82868b !important}

.alert-secondary .alert-heading{box-shadow:rgba(130,134,139,.4) 0px 6px 15px -7px}

.alert-secondary .alert-link{color:#75797e !important}

.alert-secondary .close{color:#82868b !important}

.bg-light-secondary{background:rgba(130,134,139,.12) !important;color:#82868b !important}

.bg-light-secondary .fc-list-event-dot{border-color:#82868b !important}

.bg-light-secondary.fc-list-event:hover td{background:rgba(130,134,139,.1) !important}

.bg-light-secondary.fc-list-event .fc-list-event-title{color:#6e6b7b}

.avatar.bg-light-secondary{color:#82868b !important}

.border-secondary{border:1px solid #82868b !important}

.border-top-secondary{border-top:1px solid #82868b}

.border-bottom-secondary{border-bottom:1px solid #82868b}

[dir="ltr"] .border-left-secondary{border-left:1px solid #82868b}

[dir="rtl"] .border-left-secondary{border-right:1px solid #82868b}

[dir="ltr"] .border-right-secondary{border-right:1px solid #82868b}

[dir="rtl"] .border-right-secondary{border-left:1px solid #82868b}

.bg-secondary.badge-glow,.border-secondary.badge-glow,.badge-secondary.badge-glow{box-shadow:0px 0px 10px #82868b}

.badge.badge-light-secondary{background-color:rgba(130,134,139,.12);color:#82868b !important}

.overlay-secondary{background:#82868b;background:rgba(130,134,139,.6)}

.btn-secondary{border-color:#82868b !important;background-color:#82868b !important;color:#fff !important}

.btn-secondary:focus,.btn-secondary:active,.btn-secondary.active{color:#fff;background-color:#75797e !important}

.btn-secondary:hover:not(.disabled):not(:disabled){box-shadow:0 8px 25px -8px #82868b}

.btn-secondary:not(:disabled):not(.disabled):active:focus{box-shadow:none}

.btn-flat-secondary{background-color:transparent;color:#82868b}

.btn-flat-secondary:hover{color:#82868b}

.btn-flat-secondary:hover:not(.disabled):not(:disabled){background-color:rgba(130,134,139,.12)}

.btn-flat-secondary:active,.btn-flat-secondary.active,.btn-flat-secondary:focus{background-color:rgba(130,134,139,.2);color:#82868b}

.btn-flat-secondary.dropdown-toggle::after{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%2382868b%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-down%27%3E%3Cpolyline points=%276 9 12 15 18 9%27%3E%3C/polyline%3E%3C/svg%3E")}

.btn-relief-secondary{background-color:#82868b;box-shadow:inset 0 -3px 0 0 rgba(34,41,47,.2);color:#fff;transition:all .2s ease}

.btn-relief-secondary:hover:not(.disabled):not(:disabled){background-color:#8f9397}

.btn-relief-secondary:active,.btn-relief-secondary.active,.btn-relief-secondary:focus{background-color:#75797e}

.btn-relief-secondary:hover{color:#fff}

.btn-relief-secondary:active,.btn-relief-secondary.active{outline:none;box-shadow:none;transform:translateY(3px)}

.btn-outline-secondary{border:1px solid #82868b !important;background-color:transparent;color:#82868b}

.btn-outline-secondary:hover:not(.disabled):not(:disabled){background-color:rgba(130,134,139,.04);color:#82868b}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus{box-shadow:none}

.btn-outline-secondary:not(:disabled):not(.disabled):active,.btn-outline-secondary:not(:disabled):not(.disabled).active,.btn-outline-secondary:not(:disabled):not(.disabled):focus{background-color:rgba(130,134,139,.2);color:#82868b}

.btn-outline-secondary.dropdown-toggle::after{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%2382868b%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-down%27%3E%3Cpolyline points=%276 9 12 15 18 9%27%3E%3C/polyline%3E%3C/svg%3E")}

.show>.btn-outline-secondary.dropdown-toggle{background-color:rgba(130,134,139,.2);color:#82868b}

.btn-outline-secondary.waves-effect .waves-ripple,.btn-flat-secondary.waves-effect .waves-ripple{background:radial-gradient(rgba(130, 134, 139, 0.2) 0, rgba(130, 134, 139, 0.3) 40%, rgba(130, 134, 139, 0.4) 50%, rgba(130, 134, 139, 0.5) 60%, rgba(255, 255, 255, 0) 70%)}

.bullet.bullet-secondary{background-color:#82868b}

.modal.modal-secondary .modal-header .modal-title{color:#82868b}

.modal.modal-secondary .modal-header .close{color:#82868b !important}

.pagination-secondary .page-item.active .page-link{background:#82868b !important;color:#fff}

.pagination-secondary .page-item.active .page-link:hover{color:#fff}

.pagination-secondary .page-item .page-link:hover{color:#82868b}

.pagination-secondary .page-item.prev-item .page-link:hover,.pagination-secondary .page-item.next-item .page-link:hover{background:#82868b;color:#fff}

.pagination-secondary .page-item.next-item .page-link:active:after,.pagination-secondary .page-item.next-item .page-link:hover:after,.pagination-secondary .page-item.next .page-link:active:after,.pagination-secondary .page-item.next .page-link:hover:after{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%2382868b%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-right%27%3E%3Cpolyline points=%279 18 15 12 9 6%27%3E%3C/polyline%3E%3C/svg%3E") !important}

.pagination-secondary .page-item.prev-item .page-link:active:before,.pagination-secondary .page-item.prev-item .page-link:hover:before,.pagination-secondary .page-item.prev .page-link:active:before,.pagination-secondary .page-item.prev .page-link:hover:before{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%2382868b%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-left%27%3E%3Cpolyline points=%2715 18 9 12 15 6%27%3E%3C/polyline%3E%3C/svg%3E") !important}

.nav-pill-secondary .nav-item .nav-link.active{color:#fff;background-color:#82868b !important;border-color:#82868b;box-shadow:0 4px 18px -4px rgba(130,134,139,.65)}

.progress-bar-secondary{background-color:rgba(130,134,139,.12)}

.progress-bar-secondary .progress-bar{background-color:#82868b}

.divider.divider-secondary .divider-text:before,.divider.divider-secondary .divider-text:after{border-color:#82868b !important}

input:focus~.bg-secondary{box-shadow:0 0 0 .075rem #fff,0 0 0 .21rem #82868b !important}

.custom-control-secondary .custom-control-input:checked~.custom-control-label::before,.custom-control-secondary .custom-control-input:active~.custom-control-label::before{border-color:#82868b;background-color:#82868b}

.custom-control-secondary.custom-checkbox .custom-control-input:checked~.custom-control-label::before,.custom-control-secondary.custom-checkbox .custom-control-input:active~.custom-control-label::before,.custom-control-secondary.custom-checkbox .custom-control-input:focus~.custom-control-label::before,.custom-control-secondary.custom-radio .custom-control-input:checked~.custom-control-label::before,.custom-control-secondary.custom-radio .custom-control-input:active~.custom-control-label::before,.custom-control-secondary.custom-radio .custom-control-input:focus~.custom-control-label::before{box-shadow:0 2px 4px 0 rgba(130,134,139,.4) !important}

.custom-control-secondary .custom-control-input:disabled:checked~.custom-control-label::before{background-color:rgba(130,134,139,.65) !important;border:none;box-shadow:none !important}

.custom-control-secondary .custom-control-input:focus~.custom-control-label::before{border-color:#82868b}

.custom-switch-secondary .custom-control-input:checked~.custom-control-label::before{background-color:#82868b !important;color:#fff;transition:all .2s ease-out}

.select2-secondary .select2-container--default .select2-selection--multiple .select2-selection__choice{background:#82868b !important;border-color:#82868b !important}

.text-secondary.text-darken-1{color:#75797e !important}

.bg-secondary.bg-darken-1{background-color:#75797e !important}

.border-secondary.border-darken-1{border:1px solid #75797e !important}

.border-top-secondary.border-top-darken-1{border-top:1px solid #75797e !important}

.border-bottom-secondary.border-bottom-darken-1{border-bottom:1px solid #75797e !important}

[dir="ltr"] .border-left-secondary.border-left-darken-1{border-left:1px solid #75797e !important}

[dir="rtl"] .border-left-secondary.border-left-darken-1{border-right:1px solid #75797e !important}

[dir="ltr"] .border-right-secondary.border-right-darken-1{border-right:1px solid #75797e !important}

[dir="rtl"] .border-right-secondary.border-right-darken-1{border-left:1px solid #75797e !important}

.overlay-secondary.overlay-darken-1{background:#75797e;background:rgba(117,121,126,.6)}

.text-secondary.text-darken-2{color:#696d71 !important}

.bg-secondary.bg-darken-2{background-color:#696d71 !important}

.border-secondary.border-darken-2{border:1px solid #696d71 !important}

.border-top-secondary.border-top-darken-2{border-top:1px solid #696d71 !important}

.border-bottom-secondary.border-bottom-darken-2{border-bottom:1px solid #696d71 !important}

[dir="ltr"] .border-left-secondary.border-left-darken-2{border-left:1px solid #696d71 !important}

[dir="rtl"] .border-left-secondary.border-left-darken-2{border-right:1px solid #696d71 !important}

[dir="ltr"] .border-right-secondary.border-right-darken-2{border-right:1px solid #696d71 !important}

[dir="rtl"] .border-right-secondary.border-right-darken-2{border-left:1px solid #696d71 !important}

.overlay-secondary.overlay-darken-2{background:#696d71;background:rgba(105,109,113,.6)}

.text-secondary.text-darken-3{color:#5d6064 !important}

.bg-secondary.bg-darken-3{background-color:#5d6064 !important}

.border-secondary.border-darken-3{border:1px solid #5d6064 !important}

.border-top-secondary.border-top-darken-3{border-top:1px solid #5d6064 !important}

.border-bottom-secondary.border-bottom-darken-3{border-bottom:1px solid #5d6064 !important}

[dir="ltr"] .border-left-secondary.border-left-darken-3{border-left:1px solid #5d6064 !important}

[dir="rtl"] .border-left-secondary.border-left-darken-3{border-right:1px solid #5d6064 !important}

[dir="ltr"] .border-right-secondary.border-right-darken-3{border-right:1px solid #5d6064 !important}

[dir="rtl"] .border-right-secondary.border-right-darken-3{border-left:1px solid #5d6064 !important}

.overlay-secondary.overlay-darken-3{background:#5d6064;background:rgba(93,96,100,.6)}

.text-secondary.text-darken-4{color:#505357 !important}

.bg-secondary.bg-darken-4{background-color:#505357 !important}

.border-secondary.border-darken-4{border:1px solid #505357 !important}

.border-top-secondary.border-top-darken-4{border-top:1px solid #505357 !important}

.border-bottom-secondary.border-bottom-darken-4{border-bottom:1px solid #505357 !important}

[dir="ltr"] .border-left-secondary.border-left-darken-4{border-left:1px solid #505357 !important}

[dir="rtl"] .border-left-secondary.border-left-darken-4{border-right:1px solid #505357 !important}

[dir="ltr"] .border-right-secondary.border-right-darken-4{border-right:1px solid #505357 !important}

[dir="rtl"] .border-right-secondary.border-right-darken-4{border-left:1px solid #505357 !important}

.overlay-secondary.overlay-darken-4{background:#505357;background:rgba(80,83,87,.6)}

.text-success.text-lighten-5{color:#88e7b2 !important}

.bg-success.bg-lighten-5{background-color:#88e7b2 !important}

.border-success.border-lighten-5{border:1px solid #88e7b2 !important}

.border-top-success.border-top-lighten-5{border-top:1px solid #88e7b2 !important}

.border-bottom-success.border-bottom-lighten-5{border-bottom:1px solid #88e7b2 !important}

[dir="ltr"] .border-left-success.border-left-lighten-5{border-left:1px solid #88e7b2 !important}

[dir="rtl"] .border-left-success.border-left-lighten-5{border-right:1px solid #88e7b2 !important}

[dir="ltr"] .border-right-success.border-right-lighten-5{border-right:1px solid #88e7b2 !important}

[dir="rtl"] .border-right-success.border-right-lighten-5{border-left:1px solid #88e7b2 !important}

.overlay-success.overlay-lighten-5{background:#88e7b2;background:rgba(136,231,178,.6)}

.text-success.text-lighten-4{color:#72e3a4 !important}

.bg-success.bg-lighten-4{background-color:#72e3a4 !important}

.border-success.border-lighten-4{border:1px solid #72e3a4 !important}

.border-top-success.border-top-lighten-4{border-top:1px solid #72e3a4 !important}

.border-bottom-success.border-bottom-lighten-4{border-bottom:1px solid #72e3a4 !important}

[dir="ltr"] .border-left-success.border-left-lighten-4{border-left:1px solid #72e3a4 !important}

[dir="rtl"] .border-left-success.border-left-lighten-4{border-right:1px solid #72e3a4 !important}

[dir="ltr"] .border-right-success.border-right-lighten-4{border-right:1px solid #72e3a4 !important}

[dir="rtl"] .border-right-success.border-right-lighten-4{border-left:1px solid #72e3a4 !important}

.overlay-success.overlay-lighten-4{background:#72e3a4;background:rgba(114,227,164,.6)}

.text-success.text-lighten-3{color:#5dde97 !important}

.bg-success.bg-lighten-3{background-color:#5dde97 !important}

.border-success.border-lighten-3{border:1px solid #5dde97 !important}

.border-top-success.border-top-lighten-3{border-top:1px solid #5dde97 !important}

.border-bottom-success.border-bottom-lighten-3{border-bottom:1px solid #5dde97 !important}

[dir="ltr"] .border-left-success.border-left-lighten-3{border-left:1px solid #5dde97 !important}

[dir="rtl"] .border-left-success.border-left-lighten-3{border-right:1px solid #5dde97 !important}

[dir="ltr"] .border-right-success.border-right-lighten-3{border-right:1px solid #5dde97 !important}

[dir="rtl"] .border-right-success.border-right-lighten-3{border-left:1px solid #5dde97 !important}

.overlay-success.overlay-lighten-3{background:#5dde97;background:rgba(93,222,151,.6)}

.text-success.text-lighten-2{color:#48da89 !important}

.bg-success.bg-lighten-2{background-color:#48da89 !important}

.border-success.border-lighten-2{border:1px solid #48da89 !important}

.border-top-success.border-top-lighten-2{border-top:1px solid #48da89 !important}

.border-bottom-success.border-bottom-lighten-2{border-bottom:1px solid #48da89 !important}

[dir="ltr"] .border-left-success.border-left-lighten-2{border-left:1px solid #48da89 !important}

[dir="rtl"] .border-left-success.border-left-lighten-2{border-right:1px solid #48da89 !important}

[dir="ltr"] .border-right-success.border-right-lighten-2{border-right:1px solid #48da89 !important}

[dir="rtl"] .border-right-success.border-right-lighten-2{border-left:1px solid #48da89 !important}

.overlay-success.overlay-lighten-2{background:#48da89;background:rgba(72,218,137,.6)}

.text-success.text-lighten-1{color:#33d67c !important}

.bg-success.bg-lighten-1{background-color:#33d67c !important}

.border-success.border-lighten-1{border:1px solid #33d67c !important}

.border-top-success.border-top-lighten-1{border-top:1px solid #33d67c !important}

.border-bottom-success.border-bottom-lighten-1{border-bottom:1px solid #33d67c !important}

[dir="ltr"] .border-left-success.border-left-lighten-1{border-left:1px solid #33d67c !important}

[dir="rtl"] .border-left-success.border-left-lighten-1{border-right:1px solid #33d67c !important}

[dir="ltr"] .border-right-success.border-right-lighten-1{border-right:1px solid #33d67c !important}

[dir="rtl"] .border-right-success.border-right-lighten-1{border-left:1px solid #33d67c !important}

.overlay-success.overlay-lighten-1{background:#33d67c;background:rgba(51,214,124,.6)}

.bg-success{background-color:#28c76f !important}

.bg-success .card-header,.bg-success .card-footer{background-color:transparent}

.alert-success{background:rgba(40,199,111,.12) !important;color:#28c76f !important}

.alert-success .alert-heading{box-shadow:rgba(40,199,111,.4) 0px 6px 15px -7px}

.alert-success .alert-link{color:#24b263 !important}

.alert-success .close{color:#28c76f !important}

.bg-light-success{background:rgba(40,199,111,.12) !important;color:#28c76f !important}

.bg-light-success .fc-list-event-dot{border-color:#28c76f !important}

.bg-light-success.fc-list-event:hover td{background:rgba(40,199,111,.1) !important}

.bg-light-success.fc-list-event .fc-list-event-title{color:#6e6b7b}

.avatar.bg-light-success{color:#28c76f !important}

.border-success{border:1px solid #28c76f !important}

.border-top-success{border-top:1px solid #28c76f}

.border-bottom-success{border-bottom:1px solid #28c76f}

[dir="ltr"] .border-left-success{border-left:1px solid #28c76f}

[dir="rtl"] .border-left-success{border-right:1px solid #28c76f}

[dir="ltr"] .border-right-success{border-right:1px solid #28c76f}

[dir="rtl"] .border-right-success{border-left:1px solid #28c76f}

.bg-success.badge-glow,.border-success.badge-glow,.badge-success.badge-glow{box-shadow:0px 0px 10px #28c76f}

.badge.badge-light-success{background-color:rgba(40,199,111,.12);color:#28c76f !important}

.overlay-success{background:#28c76f;background:rgba(40,199,111,.6)}

.btn-success{border-color:#28c76f !important;background-color:#28c76f !important;color:#fff !important}

.btn-success:focus,.btn-success:active,.btn-success.active{color:#fff;background-color:#24b263 !important}

.btn-success:hover:not(.disabled):not(:disabled){box-shadow:0 8px 25px -8px #28c76f}

.btn-success:not(:disabled):not(.disabled):active:focus{box-shadow:none}

.btn-flat-success{background-color:transparent;color:#28c76f}

.btn-flat-success:hover{color:#28c76f}

.btn-flat-success:hover:not(.disabled):not(:disabled){background-color:rgba(40,199,111,.12)}

.btn-flat-success:active,.btn-flat-success.active,.btn-flat-success:focus{background-color:rgba(40,199,111,.2);color:#28c76f}

.btn-flat-success.dropdown-toggle::after{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%2328c76f%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-down%27%3E%3Cpolyline points=%276 9 12 15 18 9%27%3E%3C/polyline%3E%3C/svg%3E")}

.btn-relief-success{background-color:#28c76f;box-shadow:inset 0 -3px 0 0 rgba(34,41,47,.2);color:#fff;transition:all .2s ease}

.btn-relief-success:hover:not(.disabled):not(:disabled){background-color:#33d67c}

.btn-relief-success:active,.btn-relief-success.active,.btn-relief-success:focus{background-color:#24b263}

.btn-relief-success:hover{color:#fff}

.btn-relief-success:active,.btn-relief-success.active{outline:none;box-shadow:none;transform:translateY(3px)}

.btn-outline-success{border:1px solid #28c76f !important;background-color:transparent;color:#28c76f}

.btn-outline-success:hover:not(.disabled):not(:disabled){background-color:rgba(40,199,111,.04);color:#28c76f}

.btn-outline-success:not(:disabled):not(.disabled):active:focus{box-shadow:none}

.btn-outline-success:not(:disabled):not(.disabled):active,.btn-outline-success:not(:disabled):not(.disabled).active,.btn-outline-success:not(:disabled):not(.disabled):focus{background-color:rgba(40,199,111,.2);color:#28c76f}

.btn-outline-success.dropdown-toggle::after{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%2328c76f%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-down%27%3E%3Cpolyline points=%276 9 12 15 18 9%27%3E%3C/polyline%3E%3C/svg%3E")}

.show>.btn-outline-success.dropdown-toggle{background-color:rgba(40,199,111,.2);color:#28c76f}

.btn-outline-success.waves-effect .waves-ripple,.btn-flat-success.waves-effect .waves-ripple{background:radial-gradient(rgba(40, 199, 111, 0.2) 0, rgba(40, 199, 111, 0.3) 40%, rgba(40, 199, 111, 0.4) 50%, rgba(40, 199, 111, 0.5) 60%, rgba(255, 255, 255, 0) 70%)}

.bullet.bullet-success{background-color:#28c76f}

.modal.modal-success .modal-header .modal-title{color:#28c76f}

.modal.modal-success .modal-header .close{color:#28c76f !important}

.pagination-success .page-item.active .page-link{background:#28c76f !important;color:#fff}

.pagination-success .page-item.active .page-link:hover{color:#fff}

.pagination-success .page-item .page-link:hover{color:#28c76f}

.pagination-success .page-item.prev-item .page-link:hover,.pagination-success .page-item.next-item .page-link:hover{background:#28c76f;color:#fff}

.pagination-success .page-item.next-item .page-link:active:after,.pagination-success .page-item.next-item .page-link:hover:after,.pagination-success .page-item.next .page-link:active:after,.pagination-success .page-item.next .page-link:hover:after{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%2328c76f%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-right%27%3E%3Cpolyline points=%279 18 15 12 9 6%27%3E%3C/polyline%3E%3C/svg%3E") !important}

.pagination-success .page-item.prev-item .page-link:active:before,.pagination-success .page-item.prev-item .page-link:hover:before,.pagination-success .page-item.prev .page-link:active:before,.pagination-success .page-item.prev .page-link:hover:before{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%2328c76f%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-left%27%3E%3Cpolyline points=%2715 18 9 12 15 6%27%3E%3C/polyline%3E%3C/svg%3E") !important}

.nav-pill-success .nav-item .nav-link.active{color:#fff;background-color:#28c76f !important;border-color:#28c76f;box-shadow:0 4px 18px -4px rgba(40,199,111,.65)}

.progress-bar-success{background-color:rgba(40,199,111,.12)}

.progress-bar-success .progress-bar{background-color:#28c76f}

.divider.divider-success .divider-text:before,.divider.divider-success .divider-text:after{border-color:#28c76f !important}

input:focus~.bg-success{box-shadow:0 0 0 .075rem #fff,0 0 0 .21rem #28c76f !important}

.custom-control-success .custom-control-input:checked~.custom-control-label::before,.custom-control-success .custom-control-input:active~.custom-control-label::before{border-color:#28c76f;background-color:#28c76f}

.custom-control-success.custom-checkbox .custom-control-input:checked~.custom-control-label::before,.custom-control-success.custom-checkbox .custom-control-input:active~.custom-control-label::before,.custom-control-success.custom-checkbox .custom-control-input:focus~.custom-control-label::before,.custom-control-success.custom-radio .custom-control-input:checked~.custom-control-label::before,.custom-control-success.custom-radio .custom-control-input:active~.custom-control-label::before,.custom-control-success.custom-radio .custom-control-input:focus~.custom-control-label::before{box-shadow:0 2px 4px 0 rgba(40,199,111,.4) !important}

.custom-control-success .custom-control-input:disabled:checked~.custom-control-label::before{background-color:rgba(40,199,111,.65) !important;border:none;box-shadow:none !important}

.custom-control-success .custom-control-input:focus~.custom-control-label::before{border-color:#28c76f}

.custom-switch-success .custom-control-input:checked~.custom-control-label::before{background-color:#28c76f !important;color:#fff;transition:all .2s ease-out}

.select2-success .select2-container--default .select2-selection--multiple .select2-selection__choice{background:#28c76f !important;border-color:#28c76f !important}

.text-success.text-darken-1{color:#24b263 !important}

.bg-success.bg-darken-1{background-color:#24b263 !important}

.border-success.border-darken-1{border:1px solid #24b263 !important}

.border-top-success.border-top-darken-1{border-top:1px solid #24b263 !important}

.border-bottom-success.border-bottom-darken-1{border-bottom:1px solid #24b263 !important}

[dir="ltr"] .border-left-success.border-left-darken-1{border-left:1px solid #24b263 !important}

[dir="rtl"] .border-left-success.border-left-darken-1{border-right:1px solid #24b263 !important}

[dir="ltr"] .border-right-success.border-right-darken-1{border-right:1px solid #24b263 !important}

[dir="rtl"] .border-right-success.border-right-darken-1{border-left:1px solid #24b263 !important}

.overlay-success.overlay-darken-1{background:#24b263;background:rgba(36,178,99,.6)}

.text-success.text-darken-2{color:#1f9d57 !important}

.bg-success.bg-darken-2{background-color:#1f9d57 !important}

.border-success.border-darken-2{border:1px solid #1f9d57 !important}

.border-top-success.border-top-darken-2{border-top:1px solid #1f9d57 !important}

.border-bottom-success.border-bottom-darken-2{border-bottom:1px solid #1f9d57 !important}

[dir="ltr"] .border-left-success.border-left-darken-2{border-left:1px solid #1f9d57 !important}

[dir="rtl"] .border-left-success.border-left-darken-2{border-right:1px solid #1f9d57 !important}

[dir="ltr"] .border-right-success.border-right-darken-2{border-right:1px solid #1f9d57 !important}

[dir="rtl"] .border-right-success.border-right-darken-2{border-left:1px solid #1f9d57 !important}

.overlay-success.overlay-darken-2{background:#1f9d57;background:rgba(31,157,87,.6)}

.text-success.text-darken-3{color:#1b874b !important}

.bg-success.bg-darken-3{background-color:#1b874b !important}

.border-success.border-darken-3{border:1px solid #1b874b !important}

.border-top-success.border-top-darken-3{border-top:1px solid #1b874b !important}

.border-bottom-success.border-bottom-darken-3{border-bottom:1px solid #1b874b !important}

[dir="ltr"] .border-left-success.border-left-darken-3{border-left:1px solid #1b874b !important}

[dir="rtl"] .border-left-success.border-left-darken-3{border-right:1px solid #1b874b !important}

[dir="ltr"] .border-right-success.border-right-darken-3{border-right:1px solid #1b874b !important}

[dir="rtl"] .border-right-success.border-right-darken-3{border-left:1px solid #1b874b !important}

.overlay-success.overlay-darken-3{background:#1b874b;background:rgba(27,135,75,.6)}

.text-success.text-darken-4{color:#177240 !important}

.bg-success.bg-darken-4{background-color:#177240 !important}

.border-success.border-darken-4{border:1px solid #177240 !important}

.border-top-success.border-top-darken-4{border-top:1px solid #177240 !important}

.border-bottom-success.border-bottom-darken-4{border-bottom:1px solid #177240 !important}

[dir="ltr"] .border-left-success.border-left-darken-4{border-left:1px solid #177240 !important}

[dir="rtl"] .border-left-success.border-left-darken-4{border-right:1px solid #177240 !important}

[dir="ltr"] .border-right-success.border-right-darken-4{border-right:1px solid #177240 !important}

[dir="rtl"] .border-right-success.border-right-darken-4{border-left:1px solid #177240 !important}

.overlay-success.overlay-darken-4{background:#177240;background:rgba(23,114,64,.6)}

.text-success.text-accent-1{color:#e1fff1 !important}

.bg-success.bg-accent-1{background-color:#e1fff1 !important}

.border-success.border-accent-1{border:1px solid #e1fff1 !important}

.border-top-success.border-top-accent-1{border-top:1px solid #e1fff1 !important}

.border-bottom-success.border-bottom-accent-1{border-bottom:1px solid #e1fff1 !important}

[dir="ltr"] .border-left-success.border-left-accent-1{border-left:1px solid #e1fff1 !important}

[dir="rtl"] .border-left-success.border-left-accent-1{border-right:1px solid #e1fff1 !important}

[dir="ltr"] .border-right-success.border-right-accent-1{border-right:1px solid #e1fff1 !important}

[dir="rtl"] .border-right-success.border-right-accent-1{border-left:1px solid #e1fff1 !important}

.overlay-success.overlay-accent-1{background:#e1fff1;background:rgba(225,255,241,.6)}

.text-success.text-accent-2{color:#aeffd9 !important}

.bg-success.bg-accent-2{background-color:#aeffd9 !important}

.border-success.border-accent-2{border:1px solid #aeffd9 !important}

.border-top-success.border-top-accent-2{border-top:1px solid #aeffd9 !important}

.border-bottom-success.border-bottom-accent-2{border-bottom:1px solid #aeffd9 !important}

[dir="ltr"] .border-left-success.border-left-accent-2{border-left:1px solid #aeffd9 !important}

[dir="rtl"] .border-left-success.border-left-accent-2{border-right:1px solid #aeffd9 !important}

[dir="ltr"] .border-right-success.border-right-accent-2{border-right:1px solid #aeffd9 !important}

[dir="rtl"] .border-right-success.border-right-accent-2{border-left:1px solid #aeffd9 !important}

.overlay-success.overlay-accent-2{background:#aeffd9;background:rgba(174,255,217,.6)}

.text-success.text-accent-3{color:#7bffc1 !important}

.bg-success.bg-accent-3{background-color:#7bffc1 !important}

.border-success.border-accent-3{border:1px solid #7bffc1 !important}

.border-top-success.border-top-accent-3{border-top:1px solid #7bffc1 !important}

.border-bottom-success.border-bottom-accent-3{border-bottom:1px solid #7bffc1 !important}

[dir="ltr"] .border-left-success.border-left-accent-3{border-left:1px solid #7bffc1 !important}

[dir="rtl"] .border-left-success.border-left-accent-3{border-right:1px solid #7bffc1 !important}

[dir="ltr"] .border-right-success.border-right-accent-3{border-right:1px solid #7bffc1 !important}

[dir="rtl"] .border-right-success.border-right-accent-3{border-left:1px solid #7bffc1 !important}

.overlay-success.overlay-accent-3{background:#7bffc1;background:rgba(123,255,193,.6)}

.text-success.text-accent-4{color:#62ffb5 !important}

.bg-success.bg-accent-4{background-color:#62ffb5 !important}

.border-success.border-accent-4{border:1px solid #62ffb5 !important}

.border-top-success.border-top-accent-4{border-top:1px solid #62ffb5 !important}

.border-bottom-success.border-bottom-accent-4{border-bottom:1px solid #62ffb5 !important}

[dir="ltr"] .border-left-success.border-left-accent-4{border-left:1px solid #62ffb5 !important}

[dir="rtl"] .border-left-success.border-left-accent-4{border-right:1px solid #62ffb5 !important}

[dir="ltr"] .border-right-success.border-right-accent-4{border-right:1px solid #62ffb5 !important}

[dir="rtl"] .border-right-success.border-right-accent-4{border-left:1px solid #62ffb5 !important}

.overlay-success.overlay-accent-4{background:#62ffb5;background:rgba(98,255,181,.6)}

.text-info.text-lighten-5{color:#69efff !important}

.bg-info.bg-lighten-5{background-color:#69efff !important}

.border-info.border-lighten-5{border:1px solid #69efff !important}

.border-top-info.border-top-lighten-5{border-top:1px solid #69efff !important}

.border-bottom-info.border-bottom-lighten-5{border-bottom:1px solid #69efff !important}

[dir="ltr"] .border-left-info.border-left-lighten-5{border-left:1px solid #69efff !important}

[dir="rtl"] .border-left-info.border-left-lighten-5{border-right:1px solid #69efff !important}

[dir="ltr"] .border-right-info.border-right-lighten-5{border-right:1px solid #69efff !important}

[dir="rtl"] .border-right-info.border-right-lighten-5{border-left:1px solid #69efff !important}

.overlay-info.overlay-lighten-5{background:#69efff;background:rgba(105,239,255,.6)}

.text-info.text-lighten-4{color:#4fecff !important}

.bg-info.bg-lighten-4{background-color:#4fecff !important}

.border-info.border-lighten-4{border:1px solid #4fecff !important}

.border-top-info.border-top-lighten-4{border-top:1px solid #4fecff !important}

.border-bottom-info.border-bottom-lighten-4{border-bottom:1px solid #4fecff !important}

[dir="ltr"] .border-left-info.border-left-lighten-4{border-left:1px solid #4fecff !important}

[dir="rtl"] .border-left-info.border-left-lighten-4{border-right:1px solid #4fecff !important}

[dir="ltr"] .border-right-info.border-right-lighten-4{border-right:1px solid #4fecff !important}

[dir="rtl"] .border-right-info.border-right-lighten-4{border-left:1px solid #4fecff !important}

.overlay-info.overlay-lighten-4{background:#4fecff;background:rgba(79,236,255,.6)}

.text-info.text-lighten-3{color:#36e9ff !important}

.bg-info.bg-lighten-3{background-color:#36e9ff !important}

.border-info.border-lighten-3{border:1px solid #36e9ff !important}

.border-top-info.border-top-lighten-3{border-top:1px solid #36e9ff !important}

.border-bottom-info.border-bottom-lighten-3{border-bottom:1px solid #36e9ff !important}

[dir="ltr"] .border-left-info.border-left-lighten-3{border-left:1px solid #36e9ff !important}

[dir="rtl"] .border-left-info.border-left-lighten-3{border-right:1px solid #36e9ff !important}

[dir="ltr"] .border-right-info.border-right-lighten-3{border-right:1px solid #36e9ff !important}

[dir="rtl"] .border-right-info.border-right-lighten-3{border-left:1px solid #36e9ff !important}

.overlay-info.overlay-lighten-3{background:#36e9ff;background:rgba(54,233,255,.6)}

.text-info.text-lighten-2{color:#1ce7ff !important}

.bg-info.bg-lighten-2{background-color:#1ce7ff !important}

.border-info.border-lighten-2{border:1px solid #1ce7ff !important}

.border-top-info.border-top-lighten-2{border-top:1px solid #1ce7ff !important}

.border-bottom-info.border-bottom-lighten-2{border-bottom:1px solid #1ce7ff !important}

[dir="ltr"] .border-left-info.border-left-lighten-2{border-left:1px solid #1ce7ff !important}

[dir="rtl"] .border-left-info.border-left-lighten-2{border-right:1px solid #1ce7ff !important}

[dir="ltr"] .border-right-info.border-right-lighten-2{border-right:1px solid #1ce7ff !important}

[dir="rtl"] .border-right-info.border-right-lighten-2{border-left:1px solid #1ce7ff !important}

.overlay-info.overlay-lighten-2{background:#1ce7ff;background:rgba(28,231,255,.6)}

.text-info.text-lighten-1{color:#03e4ff !important}

.bg-info.bg-lighten-1{background-color:#03e4ff !important}

.border-info.border-lighten-1{border:1px solid #03e4ff !important}

.border-top-info.border-top-lighten-1{border-top:1px solid #03e4ff !important}

.border-bottom-info.border-bottom-lighten-1{border-bottom:1px solid #03e4ff !important}

[dir="ltr"] .border-left-info.border-left-lighten-1{border-left:1px solid #03e4ff !important}

[dir="rtl"] .border-left-info.border-left-lighten-1{border-right:1px solid #03e4ff !important}

[dir="ltr"] .border-right-info.border-right-lighten-1{border-right:1px solid #03e4ff !important}

[dir="rtl"] .border-right-info.border-right-lighten-1{border-left:1px solid #03e4ff !important}

.overlay-info.overlay-lighten-1{background:#03e4ff;background:rgba(3,228,255,.6)}

.bg-info{background-color:#00cfe8 !important}

.bg-info .card-header,.bg-info .card-footer{background-color:transparent}

.alert-info{background:rgba(0,207,232,.12) !important;color:#00cfe8 !important}

.alert-info .alert-heading{box-shadow:rgba(0,207,232,.4) 0px 6px 15px -7px}

.alert-info .alert-link{color:#00b8cf !important}

.alert-info .close{color:#00cfe8 !important}

.bg-light-info{background:rgba(0,207,232,.12) !important;color:#00cfe8 !important}

.bg-light-info .fc-list-event-dot{border-color:#00cfe8 !important}

.bg-light-info.fc-list-event:hover td{background:rgba(0,207,232,.1) !important}

.bg-light-info.fc-list-event .fc-list-event-title{color:#6e6b7b}

.avatar.bg-light-info{color:#00cfe8 !important}

.border-info{border:1px solid #00cfe8 !important}

.border-top-info{border-top:1px solid #00cfe8}

.border-bottom-info{border-bottom:1px solid #00cfe8}

[dir="ltr"] .border-left-info{border-left:1px solid #00cfe8}

[dir="rtl"] .border-left-info{border-right:1px solid #00cfe8}

[dir="ltr"] .border-right-info{border-right:1px solid #00cfe8}

[dir="rtl"] .border-right-info{border-left:1px solid #00cfe8}

.bg-info.badge-glow,.border-info.badge-glow,.badge-info.badge-glow{box-shadow:0px 0px 10px #00cfe8}

.badge.badge-light-info{background-color:rgba(0,207,232,.12);color:#00cfe8 !important}

.overlay-info{background:#00cfe8;background:rgba(0,207,232,.6)}

.btn-info{border-color:#00cfe8 !important;background-color:#00cfe8 !important;color:#fff !important}

.btn-info:focus,.btn-info:active,.btn-info.active{color:#fff;background-color:#00b8cf !important}

.btn-info:hover:not(.disabled):not(:disabled){box-shadow:0 8px 25px -8px #00cfe8}

.btn-info:not(:disabled):not(.disabled):active:focus{box-shadow:none}

.btn-flat-info{background-color:transparent;color:#00cfe8}

.btn-flat-info:hover{color:#00cfe8}

.btn-flat-info:hover:not(.disabled):not(:disabled){background-color:rgba(0,207,232,.12)}

.btn-flat-info:active,.btn-flat-info.active,.btn-flat-info:focus{background-color:rgba(0,207,232,.2);color:#00cfe8}

.btn-flat-info.dropdown-toggle::after{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%2300cfe8%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-down%27%3E%3Cpolyline points=%276 9 12 15 18 9%27%3E%3C/polyline%3E%3C/svg%3E")}

.btn-relief-info{background-color:#00cfe8;box-shadow:inset 0 -3px 0 0 rgba(34,41,47,.2);color:#fff;transition:all .2s ease}

.btn-relief-info:hover:not(.disabled):not(:disabled){background-color:#03e4ff}

.btn-relief-info:active,.btn-relief-info.active,.btn-relief-info:focus{background-color:#00b8cf}

.btn-relief-info:hover{color:#fff}

.btn-relief-info:active,.btn-relief-info.active{outline:none;box-shadow:none;transform:translateY(3px)}

.btn-outline-info{border:1px solid #00cfe8 !important;background-color:transparent;color:#00cfe8}

.btn-outline-info:hover:not(.disabled):not(:disabled){background-color:rgba(0,207,232,.04);color:#00cfe8}

.btn-outline-info:not(:disabled):not(.disabled):active:focus{box-shadow:none}

.btn-outline-info:not(:disabled):not(.disabled):active,.btn-outline-info:not(:disabled):not(.disabled).active,.btn-outline-info:not(:disabled):not(.disabled):focus{background-color:rgba(0,207,232,.2);color:#00cfe8}

.btn-outline-info.dropdown-toggle::after{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%2300cfe8%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-down%27%3E%3Cpolyline points=%276 9 12 15 18 9%27%3E%3C/polyline%3E%3C/svg%3E")}

.show>.btn-outline-info.dropdown-toggle{background-color:rgba(0,207,232,.2);color:#00cfe8}

.btn-outline-info.waves-effect .waves-ripple,.btn-flat-info.waves-effect .waves-ripple{background:radial-gradient(rgba(0, 207, 232, 0.2) 0, rgba(0, 207, 232, 0.3) 40%, rgba(0, 207, 232, 0.4) 50%, rgba(0, 207, 232, 0.5) 60%, rgba(255, 255, 255, 0) 70%)}

.bullet.bullet-info{background-color:#00cfe8}

.modal.modal-info .modal-header .modal-title{color:#00cfe8}

.modal.modal-info .modal-header .close{color:#00cfe8 !important}

.pagination-info .page-item.active .page-link{background:#00cfe8 !important;color:#fff}

.pagination-info .page-item.active .page-link:hover{color:#fff}

.pagination-info .page-item .page-link:hover{color:#00cfe8}

.pagination-info .page-item.prev-item .page-link:hover,.pagination-info .page-item.next-item .page-link:hover{background:#00cfe8;color:#fff}

.pagination-info .page-item.next-item .page-link:active:after,.pagination-info .page-item.next-item .page-link:hover:after,.pagination-info .page-item.next .page-link:active:after,.pagination-info .page-item.next .page-link:hover:after{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%2300cfe8%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-right%27%3E%3Cpolyline points=%279 18 15 12 9 6%27%3E%3C/polyline%3E%3C/svg%3E") !important}

.pagination-info .page-item.prev-item .page-link:active:before,.pagination-info .page-item.prev-item .page-link:hover:before,.pagination-info .page-item.prev .page-link:active:before,.pagination-info .page-item.prev .page-link:hover:before{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%2300cfe8%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-left%27%3E%3Cpolyline points=%2715 18 9 12 15 6%27%3E%3C/polyline%3E%3C/svg%3E") !important}

.nav-pill-info .nav-item .nav-link.active{color:#fff;background-color:#00cfe8 !important;border-color:#00cfe8;box-shadow:0 4px 18px -4px rgba(0,207,232,.65)}

.progress-bar-info{background-color:rgba(0,207,232,.12)}

.progress-bar-info .progress-bar{background-color:#00cfe8}

.divider.divider-info .divider-text:before,.divider.divider-info .divider-text:after{border-color:#00cfe8 !important}

input:focus~.bg-info{box-shadow:0 0 0 .075rem #fff,0 0 0 .21rem #00cfe8 !important}

.custom-control-info .custom-control-input:checked~.custom-control-label::before,.custom-control-info .custom-control-input:active~.custom-control-label::before{border-color:#00cfe8;background-color:#00cfe8}

.custom-control-info.custom-checkbox .custom-control-input:checked~.custom-control-label::before,.custom-control-info.custom-checkbox .custom-control-input:active~.custom-control-label::before,.custom-control-info.custom-checkbox .custom-control-input:focus~.custom-control-label::before,.custom-control-info.custom-radio .custom-control-input:checked~.custom-control-label::before,.custom-control-info.custom-radio .custom-control-input:active~.custom-control-label::before,.custom-control-info.custom-radio .custom-control-input:focus~.custom-control-label::before{box-shadow:0 2px 4px 0 rgba(0,207,232,.4) !important}

.custom-control-info .custom-control-input:disabled:checked~.custom-control-label::before{background-color:rgba(0,207,232,.65) !important;border:none;box-shadow:none !important}

.custom-control-info .custom-control-input:focus~.custom-control-label::before{border-color:#00cfe8}

.custom-switch-info .custom-control-input:checked~.custom-control-label::before{background-color:#00cfe8 !important;color:#fff;transition:all .2s ease-out}

.select2-info .select2-container--default .select2-selection--multiple .select2-selection__choice{background:#00cfe8 !important;border-color:#00cfe8 !important}

.text-info.text-darken-1{color:#00b8cf !important}

.bg-info.bg-darken-1{background-color:#00b8cf !important}

.border-info.border-darken-1{border:1px solid #00b8cf !important}

.border-top-info.border-top-darken-1{border-top:1px solid #00b8cf !important}

.border-bottom-info.border-bottom-darken-1{border-bottom:1px solid #00b8cf !important}

[dir="ltr"] .border-left-info.border-left-darken-1{border-left:1px solid #00b8cf !important}

[dir="rtl"] .border-left-info.border-left-darken-1{border-right:1px solid #00b8cf !important}

[dir="ltr"] .border-right-info.border-right-darken-1{border-right:1px solid #00b8cf !important}

[dir="rtl"] .border-right-info.border-right-darken-1{border-left:1px solid #00b8cf !important}

.overlay-info.overlay-darken-1{background:#00b8cf;background:rgba(0,184,207,.6)}

.text-info.text-darken-2{color:#00a1b5 !important}

.bg-info.bg-darken-2{background-color:#00a1b5 !important}

.border-info.border-darken-2{border:1px solid #00a1b5 !important}

.border-top-info.border-top-darken-2{border-top:1px solid #00a1b5 !important}

.border-bottom-info.border-bottom-darken-2{border-bottom:1px solid #00a1b5 !important}

[dir="ltr"] .border-left-info.border-left-darken-2{border-left:1px solid #00a1b5 !important}

[dir="rtl"] .border-left-info.border-left-darken-2{border-right:1px solid #00a1b5 !important}

[dir="ltr"] .border-right-info.border-right-darken-2{border-right:1px solid #00a1b5 !important}

[dir="rtl"] .border-right-info.border-right-darken-2{border-left:1px solid #00a1b5 !important}

.overlay-info.overlay-darken-2{background:#00a1b5;background:rgba(0,161,181,.6)}

.text-info.text-darken-3{color:#008b9c !important}

.bg-info.bg-darken-3{background-color:#008b9c !important}

.border-info.border-darken-3{border:1px solid #008b9c !important}

.border-top-info.border-top-darken-3{border-top:1px solid #008b9c !important}

.border-bottom-info.border-bottom-darken-3{border-bottom:1px solid #008b9c !important}

[dir="ltr"] .border-left-info.border-left-darken-3{border-left:1px solid #008b9c !important}

[dir="rtl"] .border-left-info.border-left-darken-3{border-right:1px solid #008b9c !important}

[dir="ltr"] .border-right-info.border-right-darken-3{border-right:1px solid #008b9c !important}

[dir="rtl"] .border-right-info.border-right-darken-3{border-left:1px solid #008b9c !important}

.overlay-info.overlay-darken-3{background:#008b9c;background:rgba(0,139,156,.6)}

.text-info.text-darken-4{color:#007482 !important}

.bg-info.bg-darken-4{background-color:#007482 !important}

.border-info.border-darken-4{border:1px solid #007482 !important}

.border-top-info.border-top-darken-4{border-top:1px solid #007482 !important}

.border-bottom-info.border-bottom-darken-4{border-bottom:1px solid #007482 !important}

[dir="ltr"] .border-left-info.border-left-darken-4{border-left:1px solid #007482 !important}

[dir="rtl"] .border-left-info.border-left-darken-4{border-right:1px solid #007482 !important}

[dir="ltr"] .border-right-info.border-right-darken-4{border-right:1px solid #007482 !important}

[dir="rtl"] .border-right-info.border-right-darken-4{border-left:1px solid #007482 !important}

.overlay-info.overlay-darken-4{background:#007482;background:rgba(0,116,130,.6)}

.text-info.text-accent-1{color:#feffff !important}

.bg-info.bg-accent-1{background-color:#feffff !important}

.border-info.border-accent-1{border:1px solid #feffff !important}

.border-top-info.border-top-accent-1{border-top:1px solid #feffff !important}

.border-bottom-info.border-bottom-accent-1{border-bottom:1px solid #feffff !important}

[dir="ltr"] .border-left-info.border-left-accent-1{border-left:1px solid #feffff !important}

[dir="rtl"] .border-left-info.border-left-accent-1{border-right:1px solid #feffff !important}

[dir="ltr"] .border-right-info.border-right-accent-1{border-right:1px solid #feffff !important}

[dir="rtl"] .border-right-info.border-right-accent-1{border-left:1px solid #feffff !important}

.overlay-info.overlay-accent-1{background:#feffff;background:rgba(254,255,255,.6)}

.text-info.text-accent-2{color:#cbf5ff !important}

.bg-info.bg-accent-2{background-color:#cbf5ff !important}

.border-info.border-accent-2{border:1px solid #cbf5ff !important}

.border-top-info.border-top-accent-2{border-top:1px solid #cbf5ff !important}

.border-bottom-info.border-bottom-accent-2{border-bottom:1px solid #cbf5ff !important}

[dir="ltr"] .border-left-info.border-left-accent-2{border-left:1px solid #cbf5ff !important}

[dir="rtl"] .border-left-info.border-left-accent-2{border-right:1px solid #cbf5ff !important}

[dir="ltr"] .border-right-info.border-right-accent-2{border-right:1px solid #cbf5ff !important}

[dir="rtl"] .border-right-info.border-right-accent-2{border-left:1px solid #cbf5ff !important}

.overlay-info.overlay-accent-2{background:#cbf5ff;background:rgba(203,245,255,.6)}

.text-info.text-accent-3{color:#98ecff !important}

.bg-info.bg-accent-3{background-color:#98ecff !important}

.border-info.border-accent-3{border:1px solid #98ecff !important}

.border-top-info.border-top-accent-3{border-top:1px solid #98ecff !important}

.border-bottom-info.border-bottom-accent-3{border-bottom:1px solid #98ecff !important}

[dir="ltr"] .border-left-info.border-left-accent-3{border-left:1px solid #98ecff !important}

[dir="rtl"] .border-left-info.border-left-accent-3{border-right:1px solid #98ecff !important}

[dir="ltr"] .border-right-info.border-right-accent-3{border-right:1px solid #98ecff !important}

[dir="rtl"] .border-right-info.border-right-accent-3{border-left:1px solid #98ecff !important}

.overlay-info.overlay-accent-3{background:#98ecff;background:rgba(152,236,255,.6)}

.text-info.text-accent-4{color:#7fe7ff !important}

.bg-info.bg-accent-4{background-color:#7fe7ff !important}

.border-info.border-accent-4{border:1px solid #7fe7ff !important}

.border-top-info.border-top-accent-4{border-top:1px solid #7fe7ff !important}

.border-bottom-info.border-bottom-accent-4{border-bottom:1px solid #7fe7ff !important}

[dir="ltr"] .border-left-info.border-left-accent-4{border-left:1px solid #7fe7ff !important}

[dir="rtl"] .border-left-info.border-left-accent-4{border-right:1px solid #7fe7ff !important}

[dir="ltr"] .border-right-info.border-right-accent-4{border-right:1px solid #7fe7ff !important}

[dir="rtl"] .border-right-info.border-right-accent-4{border-left:1px solid #7fe7ff !important}

.overlay-info.overlay-accent-4{background:#7fe7ff;background:rgba(127,231,255,.6)}

.text-warning.text-lighten-5{color:#ffe0c3 !important}

.bg-warning.bg-lighten-5{background-color:#ffe0c3 !important}

.border-warning.border-lighten-5{border:1px solid #ffe0c3 !important}

.border-top-warning.border-top-lighten-5{border-top:1px solid #ffe0c3 !important}

.border-bottom-warning.border-bottom-lighten-5{border-bottom:1px solid #ffe0c3 !important}

[dir="ltr"] .border-left-warning.border-left-lighten-5{border-left:1px solid #ffe0c3 !important}

[dir="rtl"] .border-left-warning.border-left-lighten-5{border-right:1px solid #ffe0c3 !important}

[dir="ltr"] .border-right-warning.border-right-lighten-5{border-right:1px solid #ffe0c3 !important}

[dir="rtl"] .border-right-warning.border-right-lighten-5{border-left:1px solid #ffe0c3 !important}

.overlay-warning.overlay-lighten-5{background:#ffe0c3;background:rgba(255,224,195,.6)}

.text-warning.text-lighten-4{color:#ffd3a9 !important}

.bg-warning.bg-lighten-4{background-color:#ffd3a9 !important}

.border-warning.border-lighten-4{border:1px solid #ffd3a9 !important}

.border-top-warning.border-top-lighten-4{border-top:1px solid #ffd3a9 !important}

.border-bottom-warning.border-bottom-lighten-4{border-bottom:1px solid #ffd3a9 !important}

[dir="ltr"] .border-left-warning.border-left-lighten-4{border-left:1px solid #ffd3a9 !important}

[dir="rtl"] .border-left-warning.border-left-lighten-4{border-right:1px solid #ffd3a9 !important}

[dir="ltr"] .border-right-warning.border-right-lighten-4{border-right:1px solid #ffd3a9 !important}

[dir="rtl"] .border-right-warning.border-right-lighten-4{border-left:1px solid #ffd3a9 !important}

.overlay-warning.overlay-lighten-4{background:#ffd3a9;background:rgba(255,211,169,.6)}

.text-warning.text-lighten-3{color:#ffc690 !important}

.bg-warning.bg-lighten-3{background-color:#ffc690 !important}

.border-warning.border-lighten-3{border:1px solid #ffc690 !important}

.border-top-warning.border-top-lighten-3{border-top:1px solid #ffc690 !important}

.border-bottom-warning.border-bottom-lighten-3{border-bottom:1px solid #ffc690 !important}

[dir="ltr"] .border-left-warning.border-left-lighten-3{border-left:1px solid #ffc690 !important}

[dir="rtl"] .border-left-warning.border-left-lighten-3{border-right:1px solid #ffc690 !important}

[dir="ltr"] .border-right-warning.border-right-lighten-3{border-right:1px solid #ffc690 !important}

[dir="rtl"] .border-right-warning.border-right-lighten-3{border-left:1px solid #ffc690 !important}

.overlay-warning.overlay-lighten-3{background:#ffc690;background:rgba(255,198,144,.6)}

.text-warning.text-lighten-2{color:#ffb976 !important}

.bg-warning.bg-lighten-2{background-color:#ffb976 !important}

.border-warning.border-lighten-2{border:1px solid #ffb976 !important}

.border-top-warning.border-top-lighten-2{border-top:1px solid #ffb976 !important}

.border-bottom-warning.border-bottom-lighten-2{border-bottom:1px solid #ffb976 !important}

[dir="ltr"] .border-left-warning.border-left-lighten-2{border-left:1px solid #ffb976 !important}

[dir="rtl"] .border-left-warning.border-left-lighten-2{border-right:1px solid #ffb976 !important}

[dir="ltr"] .border-right-warning.border-right-lighten-2{border-right:1px solid #ffb976 !important}

[dir="rtl"] .border-right-warning.border-right-lighten-2{border-left:1px solid #ffb976 !important}

.overlay-warning.overlay-lighten-2{background:#ffb976;background:rgba(255,185,118,.6)}

.text-warning.text-lighten-1{color:#ffac5d !important}

.bg-warning.bg-lighten-1{background-color:#ffac5d !important}

.border-warning.border-lighten-1{border:1px solid #ffac5d !important}

.border-top-warning.border-top-lighten-1{border-top:1px solid #ffac5d !important}

.border-bottom-warning.border-bottom-lighten-1{border-bottom:1px solid #ffac5d !important}

[dir="ltr"] .border-left-warning.border-left-lighten-1{border-left:1px solid #ffac5d !important}

[dir="rtl"] .border-left-warning.border-left-lighten-1{border-right:1px solid #ffac5d !important}

[dir="ltr"] .border-right-warning.border-right-lighten-1{border-right:1px solid #ffac5d !important}

[dir="rtl"] .border-right-warning.border-right-lighten-1{border-left:1px solid #ffac5d !important}

.overlay-warning.overlay-lighten-1{background:#ffac5d;background:rgba(255,172,93,.6)}

.bg-warning{background-color:#ff9f43 !important}

.bg-warning .card-header,.bg-warning .card-footer{background-color:transparent}

.alert-warning{background:rgba(255,159,67,.12) !important;color:#ff9f43 !important}

.alert-warning .alert-heading{box-shadow:rgba(255,159,67,.4) 0px 6px 15px -7px}

.alert-warning .alert-link{color:#ff922a !important}

.alert-warning .close{color:#ff9f43 !important}

.bg-light-warning{background:rgba(255,159,67,.12) !important;color:#ff9f43 !important}

.bg-light-warning .fc-list-event-dot{border-color:#ff9f43 !important}

.bg-light-warning.fc-list-event:hover td{background:rgba(255,159,67,.1) !important}

.bg-light-warning.fc-list-event .fc-list-event-title{color:#6e6b7b}

.avatar.bg-light-warning{color:#ff9f43 !important}

.border-warning{border:1px solid #ff9f43 !important}

.border-top-warning{border-top:1px solid #ff9f43}

.border-bottom-warning{border-bottom:1px solid #ff9f43}

[dir="ltr"] .border-left-warning{border-left:1px solid #ff9f43}

[dir="rtl"] .border-left-warning{border-right:1px solid #ff9f43}

[dir="ltr"] .border-right-warning{border-right:1px solid #ff9f43}

[dir="rtl"] .border-right-warning{border-left:1px solid #ff9f43}

.bg-warning.badge-glow,.border-warning.badge-glow,.badge-warning.badge-glow{box-shadow:0px 0px 10px #ff9f43}

.badge.badge-light-warning{background-color:rgba(255,159,67,.12);color:#ff9f43 !important}

.overlay-warning{background:#ff9f43;background:rgba(255,159,67,.6)}

.btn-warning{border-color:#ff9f43 !important;background-color:#ff9f43 !important;color:#fff !important}

.btn-warning:focus,.btn-warning:active,.btn-warning.active{color:#fff;background-color:#ff922a !important}

.btn-warning:hover:not(.disabled):not(:disabled){box-shadow:0 8px 25px -8px #ff9f43}

.btn-warning:not(:disabled):not(.disabled):active:focus{box-shadow:none}

.btn-flat-warning{background-color:transparent;color:#ff9f43}

.btn-flat-warning:hover{color:#ff9f43}

.btn-flat-warning:hover:not(.disabled):not(:disabled){background-color:rgba(255,159,67,.12)}

.btn-flat-warning:active,.btn-flat-warning.active,.btn-flat-warning:focus{background-color:rgba(255,159,67,.2);color:#ff9f43}

.btn-flat-warning.dropdown-toggle::after{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23ff9f43%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-down%27%3E%3Cpolyline points=%276 9 12 15 18 9%27%3E%3C/polyline%3E%3C/svg%3E")}

.btn-relief-warning{background-color:#ff9f43;box-shadow:inset 0 -3px 0 0 rgba(34,41,47,.2);color:#fff;transition:all .2s ease}

.btn-relief-warning:hover:not(.disabled):not(:disabled){background-color:#ffac5d}

.btn-relief-warning:active,.btn-relief-warning.active,.btn-relief-warning:focus{background-color:#ff922a}

.btn-relief-warning:hover{color:#fff}

.btn-relief-warning:active,.btn-relief-warning.active{outline:none;box-shadow:none;transform:translateY(3px)}

.btn-outline-warning{border:1px solid #ff9f43 !important;background-color:transparent;color:#ff9f43}

.btn-outline-warning:hover:not(.disabled):not(:disabled){background-color:rgba(255,159,67,.04);color:#ff9f43}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus{box-shadow:none}

.btn-outline-warning:not(:disabled):not(.disabled):active,.btn-outline-warning:not(:disabled):not(.disabled).active,.btn-outline-warning:not(:disabled):not(.disabled):focus{background-color:rgba(255,159,67,.2);color:#ff9f43}

.btn-outline-warning.dropdown-toggle::after{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23ff9f43%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-down%27%3E%3Cpolyline points=%276 9 12 15 18 9%27%3E%3C/polyline%3E%3C/svg%3E")}

.show>.btn-outline-warning.dropdown-toggle{background-color:rgba(255,159,67,.2);color:#ff9f43}

.btn-outline-warning.waves-effect .waves-ripple,.btn-flat-warning.waves-effect .waves-ripple{background:radial-gradient(rgba(255, 159, 67, 0.2) 0, rgba(255, 159, 67, 0.3) 40%, rgba(255, 159, 67, 0.4) 50%, rgba(255, 159, 67, 0.5) 60%, rgba(255, 255, 255, 0) 70%)}

.bullet.bullet-warning{background-color:#ff9f43}

.modal.modal-warning .modal-header .modal-title{color:#ff9f43}

.modal.modal-warning .modal-header .close{color:#ff9f43 !important}

.pagination-warning .page-item.active .page-link{background:#ff9f43 !important;color:#fff}

.pagination-warning .page-item.active .page-link:hover{color:#fff}

.pagination-warning .page-item .page-link:hover{color:#ff9f43}

.pagination-warning .page-item.prev-item .page-link:hover,.pagination-warning .page-item.next-item .page-link:hover{background:#ff9f43;color:#fff}

.pagination-warning .page-item.next-item .page-link:active:after,.pagination-warning .page-item.next-item .page-link:hover:after,.pagination-warning .page-item.next .page-link:active:after,.pagination-warning .page-item.next .page-link:hover:after{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23ff9f43%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-right%27%3E%3Cpolyline points=%279 18 15 12 9 6%27%3E%3C/polyline%3E%3C/svg%3E") !important}

.pagination-warning .page-item.prev-item .page-link:active:before,.pagination-warning .page-item.prev-item .page-link:hover:before,.pagination-warning .page-item.prev .page-link:active:before,.pagination-warning .page-item.prev .page-link:hover:before{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23ff9f43%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-left%27%3E%3Cpolyline points=%2715 18 9 12 15 6%27%3E%3C/polyline%3E%3C/svg%3E") !important}

.nav-pill-warning .nav-item .nav-link.active{color:#fff;background-color:#ff9f43 !important;border-color:#ff9f43;box-shadow:0 4px 18px -4px rgba(255,159,67,.65)}

.progress-bar-warning{background-color:rgba(255,159,67,.12)}

.progress-bar-warning .progress-bar{background-color:#ff9f43}

.divider.divider-warning .divider-text:before,.divider.divider-warning .divider-text:after{border-color:#ff9f43 !important}

input:focus~.bg-warning{box-shadow:0 0 0 .075rem #fff,0 0 0 .21rem #ff9f43 !important}

.custom-control-warning .custom-control-input:checked~.custom-control-label::before,.custom-control-warning .custom-control-input:active~.custom-control-label::before{border-color:#ff9f43;background-color:#ff9f43}

.custom-control-warning.custom-checkbox .custom-control-input:checked~.custom-control-label::before,.custom-control-warning.custom-checkbox .custom-control-input:active~.custom-control-label::before,.custom-control-warning.custom-checkbox .custom-control-input:focus~.custom-control-label::before,.custom-control-warning.custom-radio .custom-control-input:checked~.custom-control-label::before,.custom-control-warning.custom-radio .custom-control-input:active~.custom-control-label::before,.custom-control-warning.custom-radio .custom-control-input:focus~.custom-control-label::before{box-shadow:0 2px 4px 0 rgba(255,159,67,.4) !important}

.custom-control-warning .custom-control-input:disabled:checked~.custom-control-label::before{background-color:rgba(255,159,67,.65) !important;border:none;box-shadow:none !important}

.custom-control-warning .custom-control-input:focus~.custom-control-label::before{border-color:#ff9f43}

.custom-switch-warning .custom-control-input:checked~.custom-control-label::before{background-color:#ff9f43 !important;color:#fff;transition:all .2s ease-out}

.select2-warning .select2-container--default .select2-selection--multiple .select2-selection__choice{background:#ff9f43 !important;border-color:#ff9f43 !important}

.text-warning.text-darken-1{color:#ff922a !important}

.bg-warning.bg-darken-1{background-color:#ff922a !important}

.border-warning.border-darken-1{border:1px solid #ff922a !important}

.border-top-warning.border-top-darken-1{border-top:1px solid #ff922a !important}

.border-bottom-warning.border-bottom-darken-1{border-bottom:1px solid #ff922a !important}

[dir="ltr"] .border-left-warning.border-left-darken-1{border-left:1px solid #ff922a !important}

[dir="rtl"] .border-left-warning.border-left-darken-1{border-right:1px solid #ff922a !important}

[dir="ltr"] .border-right-warning.border-right-darken-1{border-right:1px solid #ff922a !important}

[dir="rtl"] .border-right-warning.border-right-darken-1{border-left:1px solid #ff922a !important}

.overlay-warning.overlay-darken-1{background:#ff922a;background:rgba(255,146,42,.6)}

.text-warning.text-darken-2{color:#ff8510 !important}

.bg-warning.bg-darken-2{background-color:#ff8510 !important}

.border-warning.border-darken-2{border:1px solid #ff8510 !important}

.border-top-warning.border-top-darken-2{border-top:1px solid #ff8510 !important}

.border-bottom-warning.border-bottom-darken-2{border-bottom:1px solid #ff8510 !important}

[dir="ltr"] .border-left-warning.border-left-darken-2{border-left:1px solid #ff8510 !important}

[dir="rtl"] .border-left-warning.border-left-darken-2{border-right:1px solid #ff8510 !important}

[dir="ltr"] .border-right-warning.border-right-darken-2{border-right:1px solid #ff8510 !important}

[dir="rtl"] .border-right-warning.border-right-darken-2{border-left:1px solid #ff8510 !important}

.overlay-warning.overlay-darken-2{background:#ff8510;background:rgba(255,133,16,.6)}

.text-warning.text-darken-3{color:#f67800 !important}

.bg-warning.bg-darken-3{background-color:#f67800 !important}

.border-warning.border-darken-3{border:1px solid #f67800 !important}

.border-top-warning.border-top-darken-3{border-top:1px solid #f67800 !important}

.border-bottom-warning.border-bottom-darken-3{border-bottom:1px solid #f67800 !important}

[dir="ltr"] .border-left-warning.border-left-darken-3{border-left:1px solid #f67800 !important}

[dir="rtl"] .border-left-warning.border-left-darken-3{border-right:1px solid #f67800 !important}

[dir="ltr"] .border-right-warning.border-right-darken-3{border-right:1px solid #f67800 !important}

[dir="rtl"] .border-right-warning.border-right-darken-3{border-left:1px solid #f67800 !important}

.overlay-warning.overlay-darken-3{background:#f67800;background:rgba(246,120,0,.6)}

.text-warning.text-darken-4{color:#dc6c00 !important}

.bg-warning.bg-darken-4{background-color:#dc6c00 !important}

.border-warning.border-darken-4{border:1px solid #dc6c00 !important}

.border-top-warning.border-top-darken-4{border-top:1px solid #dc6c00 !important}

.border-bottom-warning.border-bottom-darken-4{border-bottom:1px solid #dc6c00 !important}

[dir="ltr"] .border-left-warning.border-left-darken-4{border-left:1px solid #dc6c00 !important}

[dir="rtl"] .border-left-warning.border-left-darken-4{border-right:1px solid #dc6c00 !important}

[dir="ltr"] .border-right-warning.border-right-darken-4{border-right:1px solid #dc6c00 !important}

[dir="rtl"] .border-right-warning.border-right-darken-4{border-left:1px solid #dc6c00 !important}

.overlay-warning.overlay-darken-4{background:#dc6c00;background:rgba(220,108,0,.6)}

.text-warning.text-accent-1{color:#fff5ef !important}

.bg-warning.bg-accent-1{background-color:#fff5ef !important}

.border-warning.border-accent-1{border:1px solid #fff5ef !important}

.border-top-warning.border-top-accent-1{border-top:1px solid #fff5ef !important}

.border-bottom-warning.border-bottom-accent-1{border-bottom:1px solid #fff5ef !important}

[dir="ltr"] .border-left-warning.border-left-accent-1{border-left:1px solid #fff5ef !important}

[dir="rtl"] .border-left-warning.border-left-accent-1{border-right:1px solid #fff5ef !important}

[dir="ltr"] .border-right-warning.border-right-accent-1{border-right:1px solid #fff5ef !important}

[dir="rtl"] .border-right-warning.border-right-accent-1{border-left:1px solid #fff5ef !important}

.overlay-warning.overlay-accent-1{background:#fff5ef;background:rgba(255,245,239,.6)}

.text-warning.text-accent-2{color:#ffe5d8 !important}

.bg-warning.bg-accent-2{background-color:#ffe5d8 !important}

.border-warning.border-accent-2{border:1px solid #ffe5d8 !important}

.border-top-warning.border-top-accent-2{border-top:1px solid #ffe5d8 !important}

.border-bottom-warning.border-bottom-accent-2{border-bottom:1px solid #ffe5d8 !important}

[dir="ltr"] .border-left-warning.border-left-accent-2{border-left:1px solid #ffe5d8 !important}

[dir="rtl"] .border-left-warning.border-left-accent-2{border-right:1px solid #ffe5d8 !important}

[dir="ltr"] .border-right-warning.border-right-accent-2{border-right:1px solid #ffe5d8 !important}

[dir="rtl"] .border-right-warning.border-right-accent-2{border-left:1px solid #ffe5d8 !important}

.overlay-warning.overlay-accent-2{background:#ffe5d8;background:rgba(255,229,216,.6)}

.text-warning.text-accent-3{color:#fff6f3 !important}

.bg-warning.bg-accent-3{background-color:#fff6f3 !important}

.border-warning.border-accent-3{border:1px solid #fff6f3 !important}

.border-top-warning.border-top-accent-3{border-top:1px solid #fff6f3 !important}

.border-bottom-warning.border-bottom-accent-3{border-bottom:1px solid #fff6f3 !important}

[dir="ltr"] .border-left-warning.border-left-accent-3{border-left:1px solid #fff6f3 !important}

[dir="rtl"] .border-left-warning.border-left-accent-3{border-right:1px solid #fff6f3 !important}

[dir="ltr"] .border-right-warning.border-right-accent-3{border-right:1px solid #fff6f3 !important}

[dir="rtl"] .border-right-warning.border-right-accent-3{border-left:1px solid #fff6f3 !important}

.overlay-warning.overlay-accent-3{background:#fff6f3;background:rgba(255,246,243,.6)}

.text-warning.text-accent-4{color:#ffe3da !important}

.bg-warning.bg-accent-4{background-color:#ffe3da !important}

.border-warning.border-accent-4{border:1px solid #ffe3da !important}

.border-top-warning.border-top-accent-4{border-top:1px solid #ffe3da !important}

.border-bottom-warning.border-bottom-accent-4{border-bottom:1px solid #ffe3da !important}

[dir="ltr"] .border-left-warning.border-left-accent-4{border-left:1px solid #ffe3da !important}

[dir="rtl"] .border-left-warning.border-left-accent-4{border-right:1px solid #ffe3da !important}

[dir="ltr"] .border-right-warning.border-right-accent-4{border-right:1px solid #ffe3da !important}

[dir="rtl"] .border-right-warning.border-right-accent-4{border-left:1px solid #ffe3da !important}

.overlay-warning.overlay-accent-4{background:#ffe3da;background:rgba(255,227,218,.6)}

.text-danger.text-lighten-5{color:#f8c6c6 !important}

.bg-danger.bg-lighten-5{background-color:#f8c6c6 !important}

.border-danger.border-lighten-5{border:1px solid #f8c6c6 !important}

.border-top-danger.border-top-lighten-5{border-top:1px solid #f8c6c6 !important}

.border-bottom-danger.border-bottom-lighten-5{border-bottom:1px solid #f8c6c6 !important}

[dir="ltr"] .border-left-danger.border-left-lighten-5{border-left:1px solid #f8c6c6 !important}

[dir="rtl"] .border-left-danger.border-left-lighten-5{border-right:1px solid #f8c6c6 !important}

[dir="ltr"] .border-right-danger.border-right-lighten-5{border-right:1px solid #f8c6c6 !important}

[dir="rtl"] .border-right-danger.border-right-lighten-5{border-left:1px solid #f8c6c6 !important}

.overlay-danger.overlay-lighten-5{background:#f8c6c6;background:rgba(248,198,198,.6)}

.text-danger.text-lighten-4{color:#f5afaf !important}

.bg-danger.bg-lighten-4{background-color:#f5afaf !important}

.border-danger.border-lighten-4{border:1px solid #f5afaf !important}

.border-top-danger.border-top-lighten-4{border-top:1px solid #f5afaf !important}

.border-bottom-danger.border-bottom-lighten-4{border-bottom:1px solid #f5afaf !important}

[dir="ltr"] .border-left-danger.border-left-lighten-4{border-left:1px solid #f5afaf !important}

[dir="rtl"] .border-left-danger.border-left-lighten-4{border-right:1px solid #f5afaf !important}

[dir="ltr"] .border-right-danger.border-right-lighten-4{border-right:1px solid #f5afaf !important}

[dir="rtl"] .border-right-danger.border-right-lighten-4{border-left:1px solid #f5afaf !important}

.overlay-danger.overlay-lighten-4{background:#f5afaf;background:rgba(245,175,175,.6)}

.text-danger.text-lighten-3{color:#f29899 !important}

.bg-danger.bg-lighten-3{background-color:#f29899 !important}

.border-danger.border-lighten-3{border:1px solid #f29899 !important}

.border-top-danger.border-top-lighten-3{border-top:1px solid #f29899 !important}

.border-bottom-danger.border-bottom-lighten-3{border-bottom:1px solid #f29899 !important}

[dir="ltr"] .border-left-danger.border-left-lighten-3{border-left:1px solid #f29899 !important}

[dir="rtl"] .border-left-danger.border-left-lighten-3{border-right:1px solid #f29899 !important}

[dir="ltr"] .border-right-danger.border-right-lighten-3{border-right:1px solid #f29899 !important}

[dir="rtl"] .border-right-danger.border-right-lighten-3{border-left:1px solid #f29899 !important}

.overlay-danger.overlay-lighten-3{background:#f29899;background:rgba(242,152,153,.6)}

.text-danger.text-lighten-2{color:#f08182 !important}

.bg-danger.bg-lighten-2{background-color:#f08182 !important}

.border-danger.border-lighten-2{border:1px solid #f08182 !important}

.border-top-danger.border-top-lighten-2{border-top:1px solid #f08182 !important}

.border-bottom-danger.border-bottom-lighten-2{border-bottom:1px solid #f08182 !important}

[dir="ltr"] .border-left-danger.border-left-lighten-2{border-left:1px solid #f08182 !important}

[dir="rtl"] .border-left-danger.border-left-lighten-2{border-right:1px solid #f08182 !important}

[dir="ltr"] .border-right-danger.border-right-lighten-2{border-right:1px solid #f08182 !important}

[dir="rtl"] .border-right-danger.border-right-lighten-2{border-left:1px solid #f08182 !important}

.overlay-danger.overlay-lighten-2{background:#f08182;background:rgba(240,129,130,.6)}

.text-danger.text-lighten-1{color:#ed6b6c !important}

.bg-danger.bg-lighten-1{background-color:#ed6b6c !important}

.border-danger.border-lighten-1{border:1px solid #ed6b6c !important}

.border-top-danger.border-top-lighten-1{border-top:1px solid #ed6b6c !important}

.border-bottom-danger.border-bottom-lighten-1{border-bottom:1px solid #ed6b6c !important}

[dir="ltr"] .border-left-danger.border-left-lighten-1{border-left:1px solid #ed6b6c !important}

[dir="rtl"] .border-left-danger.border-left-lighten-1{border-right:1px solid #ed6b6c !important}

[dir="ltr"] .border-right-danger.border-right-lighten-1{border-right:1px solid #ed6b6c !important}

[dir="rtl"] .border-right-danger.border-right-lighten-1{border-left:1px solid #ed6b6c !important}

.overlay-danger.overlay-lighten-1{background:#ed6b6c;background:rgba(237,107,108,.6)}

.bg-danger{background-color:#ea5455 !important}

.bg-danger .card-header,.bg-danger .card-footer{background-color:transparent}

.alert-danger{background:rgba(234,84,85,.12) !important;color:#ea5455 !important}

.alert-danger .alert-heading{box-shadow:rgba(234,84,85,.4) 0px 6px 15px -7px}

.alert-danger .alert-link{color:#e73d3e !important}

.alert-danger .close{color:#ea5455 !important}

.bg-light-danger{background:rgba(234,84,85,.12) !important;color:#ea5455 !important}

.bg-light-danger .fc-list-event-dot{border-color:#ea5455 !important}

.bg-light-danger.fc-list-event:hover td{background:rgba(234,84,85,.1) !important}

.bg-light-danger.fc-list-event .fc-list-event-title{color:#6e6b7b}

.avatar.bg-light-danger{color:#ea5455 !important}

.border-danger{border:1px solid #ea5455 !important}

.border-top-danger{border-top:1px solid #ea5455}

.border-bottom-danger{border-bottom:1px solid #ea5455}

[dir="ltr"] .border-left-danger{border-left:1px solid #ea5455}

[dir="rtl"] .border-left-danger{border-right:1px solid #ea5455}

[dir="ltr"] .border-right-danger{border-right:1px solid #ea5455}

[dir="rtl"] .border-right-danger{border-left:1px solid #ea5455}

.bg-danger.badge-glow,.border-danger.badge-glow,.badge-danger.badge-glow{box-shadow:0px 0px 10px #ea5455}

.badge.badge-light-danger{background-color:rgba(234,84,85,.12);color:#ea5455 !important}

.overlay-danger{background:#ea5455;background:rgba(234,84,85,.6)}

.btn-danger{border-color:#ea5455 !important;background-color:#ea5455 !important;color:#fff !important}

.btn-danger:focus,.btn-danger:active,.btn-danger.active{color:#fff;background-color:#e73d3e !important}

.btn-danger:hover:not(.disabled):not(:disabled){box-shadow:0 8px 25px -8px #ea5455}

.btn-danger:not(:disabled):not(.disabled):active:focus{box-shadow:none}

.btn-flat-danger{background-color:transparent;color:#ea5455}

.btn-flat-danger:hover{color:#ea5455}

.btn-flat-danger:hover:not(.disabled):not(:disabled){background-color:rgba(234,84,85,.12)}

.btn-flat-danger:active,.btn-flat-danger.active,.btn-flat-danger:focus{background-color:rgba(234,84,85,.2);color:#ea5455}

.btn-flat-danger.dropdown-toggle::after{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23ea5455%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-down%27%3E%3Cpolyline points=%276 9 12 15 18 9%27%3E%3C/polyline%3E%3C/svg%3E")}

.btn-relief-danger{background-color:#ea5455;box-shadow:inset 0 -3px 0 0 rgba(34,41,47,.2);color:#fff;transition:all .2s ease}

.btn-relief-danger:hover:not(.disabled):not(:disabled){background-color:#ed6b6c}

.btn-relief-danger:active,.btn-relief-danger.active,.btn-relief-danger:focus{background-color:#e73d3e}

.btn-relief-danger:hover{color:#fff}

.btn-relief-danger:active,.btn-relief-danger.active{outline:none;box-shadow:none;transform:translateY(3px)}

.btn-outline-danger{border:1px solid #ea5455 !important;background-color:transparent;color:#ea5455}

.btn-outline-danger:hover:not(.disabled):not(:disabled){background-color:rgba(234,84,85,.04);color:#ea5455}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus{box-shadow:none}

.btn-outline-danger:not(:disabled):not(.disabled):active,.btn-outline-danger:not(:disabled):not(.disabled).active,.btn-outline-danger:not(:disabled):not(.disabled):focus{background-color:rgba(234,84,85,.2);color:#ea5455}

.btn-outline-danger.dropdown-toggle::after{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23ea5455%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-down%27%3E%3Cpolyline points=%276 9 12 15 18 9%27%3E%3C/polyline%3E%3C/svg%3E")}

.show>.btn-outline-danger.dropdown-toggle{background-color:rgba(234,84,85,.2);color:#ea5455}

.btn-outline-danger.waves-effect .waves-ripple,.btn-flat-danger.waves-effect .waves-ripple{background:radial-gradient(rgba(234, 84, 85, 0.2) 0, rgba(234, 84, 85, 0.3) 40%, rgba(234, 84, 85, 0.4) 50%, rgba(234, 84, 85, 0.5) 60%, rgba(255, 255, 255, 0) 70%)}

.bullet.bullet-danger{background-color:#ea5455}

.modal.modal-danger .modal-header .modal-title{color:#ea5455}

.modal.modal-danger .modal-header .close{color:#ea5455 !important}

.pagination-danger .page-item.active .page-link{background:#ea5455 !important;color:#fff}

.pagination-danger .page-item.active .page-link:hover{color:#fff}

.pagination-danger .page-item .page-link:hover{color:#ea5455}

.pagination-danger .page-item.prev-item .page-link:hover,.pagination-danger .page-item.next-item .page-link:hover{background:#ea5455;color:#fff}

.pagination-danger .page-item.next-item .page-link:active:after,.pagination-danger .page-item.next-item .page-link:hover:after,.pagination-danger .page-item.next .page-link:active:after,.pagination-danger .page-item.next .page-link:hover:after{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23ea5455%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-right%27%3E%3Cpolyline points=%279 18 15 12 9 6%27%3E%3C/polyline%3E%3C/svg%3E") !important}

.pagination-danger .page-item.prev-item .page-link:active:before,.pagination-danger .page-item.prev-item .page-link:hover:before,.pagination-danger .page-item.prev .page-link:active:before,.pagination-danger .page-item.prev .page-link:hover:before{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23ea5455%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-left%27%3E%3Cpolyline points=%2715 18 9 12 15 6%27%3E%3C/polyline%3E%3C/svg%3E") !important}

.nav-pill-danger .nav-item .nav-link.active{color:#fff;background-color:#ea5455 !important;border-color:#ea5455;box-shadow:0 4px 18px -4px rgba(234,84,85,.65)}

.progress-bar-danger{background-color:rgba(234,84,85,.12)}

.progress-bar-danger .progress-bar{background-color:#ea5455}

.divider.divider-danger .divider-text:before,.divider.divider-danger .divider-text:after{border-color:#ea5455 !important}

input:focus~.bg-danger{box-shadow:0 0 0 .075rem #fff,0 0 0 .21rem #ea5455 !important}

.custom-control-danger .custom-control-input:checked~.custom-control-label::before,.custom-control-danger .custom-control-input:active~.custom-control-label::before{border-color:#ea5455;background-color:#ea5455}

.custom-control-danger.custom-checkbox .custom-control-input:checked~.custom-control-label::before,.custom-control-danger.custom-checkbox .custom-control-input:active~.custom-control-label::before,.custom-control-danger.custom-checkbox .custom-control-input:focus~.custom-control-label::before,.custom-control-danger.custom-radio .custom-control-input:checked~.custom-control-label::before,.custom-control-danger.custom-radio .custom-control-input:active~.custom-control-label::before,.custom-control-danger.custom-radio .custom-control-input:focus~.custom-control-label::before{box-shadow:0 2px 4px 0 rgba(234,84,85,.4) !important}

.custom-control-danger .custom-control-input:disabled:checked~.custom-control-label::before{background-color:rgba(234,84,85,.65) !important;border:none;box-shadow:none !important}

.custom-control-danger .custom-control-input:focus~.custom-control-label::before{border-color:#ea5455}

.custom-switch-danger .custom-control-input:checked~.custom-control-label::before{background-color:#ea5455 !important;color:#fff;transition:all .2s ease-out}

.select2-danger .select2-container--default .select2-selection--multiple .select2-selection__choice{background:#ea5455 !important;border-color:#ea5455 !important}

.text-danger.text-darken-1{color:#e73d3e !important}

.bg-danger.bg-darken-1{background-color:#e73d3e !important}

.border-danger.border-darken-1{border:1px solid #e73d3e !important}

.border-top-danger.border-top-darken-1{border-top:1px solid #e73d3e !important}

.border-bottom-danger.border-bottom-darken-1{border-bottom:1px solid #e73d3e !important}

[dir="ltr"] .border-left-danger.border-left-darken-1{border-left:1px solid #e73d3e !important}

[dir="rtl"] .border-left-danger.border-left-darken-1{border-right:1px solid #e73d3e !important}

[dir="ltr"] .border-right-danger.border-right-darken-1{border-right:1px solid #e73d3e !important}

[dir="rtl"] .border-right-danger.border-right-darken-1{border-left:1px solid #e73d3e !important}

.overlay-danger.overlay-darken-1{background:#e73d3e;background:rgba(231,61,62,.6)}

.text-danger.text-darken-2{color:#e42728 !important}

.bg-danger.bg-darken-2{background-color:#e42728 !important}

.border-danger.border-darken-2{border:1px solid #e42728 !important}

.border-top-danger.border-top-darken-2{border-top:1px solid #e42728 !important}

.border-bottom-danger.border-bottom-darken-2{border-bottom:1px solid #e42728 !important}

[dir="ltr"] .border-left-danger.border-left-darken-2{border-left:1px solid #e42728 !important}

[dir="rtl"] .border-left-danger.border-left-darken-2{border-right:1px solid #e42728 !important}

[dir="ltr"] .border-right-danger.border-right-darken-2{border-right:1px solid #e42728 !important}

[dir="rtl"] .border-right-danger.border-right-darken-2{border-left:1px solid #e42728 !important}

.overlay-danger.overlay-darken-2{background:#e42728;background:rgba(228,39,40,.6)}

.text-danger.text-darken-3{color:#d71a1c !important}

.bg-danger.bg-darken-3{background-color:#d71a1c !important}

.border-danger.border-darken-3{border:1px solid #d71a1c !important}

.border-top-danger.border-top-darken-3{border-top:1px solid #d71a1c !important}

.border-bottom-danger.border-bottom-darken-3{border-bottom:1px solid #d71a1c !important}

[dir="ltr"] .border-left-danger.border-left-darken-3{border-left:1px solid #d71a1c !important}

[dir="rtl"] .border-left-danger.border-left-darken-3{border-right:1px solid #d71a1c !important}

[dir="ltr"] .border-right-danger.border-right-darken-3{border-right:1px solid #d71a1c !important}

[dir="rtl"] .border-right-danger.border-right-darken-3{border-left:1px solid #d71a1c !important}

.overlay-danger.overlay-darken-3{background:#d71a1c;background:rgba(215,26,28,.6)}

.text-danger.text-darken-4{color:#c01819 !important}

.bg-danger.bg-darken-4{background-color:#c01819 !important}

.border-danger.border-darken-4{border:1px solid #c01819 !important}

.border-top-danger.border-top-darken-4{border-top:1px solid #c01819 !important}

.border-bottom-danger.border-bottom-darken-4{border-bottom:1px solid #c01819 !important}

[dir="ltr"] .border-left-danger.border-left-darken-4{border-left:1px solid #c01819 !important}

[dir="rtl"] .border-left-danger.border-left-darken-4{border-right:1px solid #c01819 !important}

[dir="ltr"] .border-right-danger.border-right-darken-4{border-right:1px solid #c01819 !important}

[dir="rtl"] .border-right-danger.border-right-darken-4{border-left:1px solid #c01819 !important}

.overlay-danger.overlay-darken-4{background:#c01819;background:rgba(192,24,25,.6)}

.text-danger.text-accent-1{color:#ffeef1 !important}

.bg-danger.bg-accent-1{background-color:#ffeef1 !important}

.border-danger.border-accent-1{border:1px solid #ffeef1 !important}

.border-top-danger.border-top-accent-1{border-top:1px solid #ffeef1 !important}

.border-bottom-danger.border-bottom-accent-1{border-bottom:1px solid #ffeef1 !important}

[dir="ltr"] .border-left-danger.border-left-accent-1{border-left:1px solid #ffeef1 !important}

[dir="rtl"] .border-left-danger.border-left-accent-1{border-right:1px solid #ffeef1 !important}

[dir="ltr"] .border-right-danger.border-right-accent-1{border-right:1px solid #ffeef1 !important}

[dir="rtl"] .border-right-danger.border-right-accent-1{border-left:1px solid #ffeef1 !important}

.overlay-danger.overlay-accent-1{background:#ffeef1;background:rgba(255,238,241,.6)}

.text-danger.text-accent-2{color:#ffd6db !important}

.bg-danger.bg-accent-2{background-color:#ffd6db !important}

.border-danger.border-accent-2{border:1px solid #ffd6db !important}

.border-top-danger.border-top-accent-2{border-top:1px solid #ffd6db !important}

.border-bottom-danger.border-bottom-accent-2{border-bottom:1px solid #ffd6db !important}

[dir="ltr"] .border-left-danger.border-left-accent-2{border-left:1px solid #ffd6db !important}

[dir="rtl"] .border-left-danger.border-left-accent-2{border-right:1px solid #ffd6db !important}

[dir="ltr"] .border-right-danger.border-right-accent-2{border-right:1px solid #ffd6db !important}

[dir="rtl"] .border-right-danger.border-right-accent-2{border-left:1px solid #ffd6db !important}

.overlay-danger.overlay-accent-2{background:#ffd6db;background:rgba(255,214,219,.6)}

.text-danger.text-accent-3{color:#ffecee !important}

.bg-danger.bg-accent-3{background-color:#ffecee !important}

.border-danger.border-accent-3{border:1px solid #ffecee !important}

.border-top-danger.border-top-accent-3{border-top:1px solid #ffecee !important}

.border-bottom-danger.border-bottom-accent-3{border-bottom:1px solid #ffecee !important}

[dir="ltr"] .border-left-danger.border-left-accent-3{border-left:1px solid #ffecee !important}

[dir="rtl"] .border-left-danger.border-left-accent-3{border-right:1px solid #ffecee !important}

[dir="ltr"] .border-right-danger.border-right-accent-3{border-right:1px solid #ffecee !important}

[dir="rtl"] .border-right-danger.border-right-accent-3{border-left:1px solid #ffecee !important}

.overlay-danger.overlay-accent-3{background:#ffecee;background:rgba(255,236,238,.6)}

.text-danger.text-accent-4{color:#ffd3d7 !important}

.bg-danger.bg-accent-4{background-color:#ffd3d7 !important}

.border-danger.border-accent-4{border:1px solid #ffd3d7 !important}

.border-top-danger.border-top-accent-4{border-top:1px solid #ffd3d7 !important}

.border-bottom-danger.border-bottom-accent-4{border-bottom:1px solid #ffd3d7 !important}

[dir="ltr"] .border-left-danger.border-left-accent-4{border-left:1px solid #ffd3d7 !important}

[dir="rtl"] .border-left-danger.border-left-accent-4{border-right:1px solid #ffd3d7 !important}

[dir="ltr"] .border-right-danger.border-right-accent-4{border-right:1px solid #ffd3d7 !important}

[dir="rtl"] .border-right-danger.border-right-accent-4{border-left:1px solid #ffd3d7 !important}

.overlay-danger.overlay-accent-4{background:#ffd3d7;background:rgba(255,211,215,.6)}

.bg-gradient-dark,.btn-gradient-dark{color:#fff;transition:all .2s ease;background-repeat:repeat-x;background-repeat:repeat}

[dir="ltr"] .bg-gradient-dark,[dir="ltr"] .btn-gradient-dark{background-image:linear-gradient(47deg, #161d31, #1e1e1e)}

[dir="rtl"] .bg-gradient-dark,[dir="rtl"] .btn-gradient-dark{background-image:linear-gradient(-47deg, #161d31, #1e1e1e)}

.dark-layout .bg-gradient-dark,.dark-layout .btn-gradient-dark{background-repeat:repeat-x;background-repeat:repeat}

[dir="ltr"] .dark-layout .bg-gradient-dark,[dir="ltr"] .dark-layout .btn-gradient-dark{background-image:linear-gradient(47deg, #1e1e1e, #161d31)}

[dir="rtl"] .dark-layout .bg-gradient-dark,[dir="rtl"] .dark-layout .btn-gradient-dark{background-image:linear-gradient(-47deg, #1e1e1e, #161d31)}

.bg-gradient-dark:hover,.bg-gradient-dark:active,.btn-gradient-dark:hover,.btn-gradient-dark:active{color:#fff}

.bg-gradient-dark:hover:not(.disabled):not(:disabled),.btn-gradient-dark:hover:not(.disabled):not(:disabled){transform:translateY(-2px)}

.bg-gradient-dark:active,.btn-gradient-dark:active{transform:translateY(0)}

.bg-gradient-dark:active,.bg-gradient-dark:focus,.btn-gradient-dark:active,.btn-gradient-dark:focus{background-repeat:repeat-x;background-repeat:repeat}

[dir="ltr"] .bg-gradient-dark:active,[dir="ltr"] .bg-gradient-dark:focus,[dir="ltr"] .btn-gradient-dark:active,[dir="ltr"] .btn-gradient-dark:focus{background-image:linear-gradient(47deg, #1e1e1e, #161d31)}

[dir="rtl"] .bg-gradient-dark:active,[dir="rtl"] .bg-gradient-dark:focus,[dir="rtl"] .btn-gradient-dark:active,[dir="rtl"] .btn-gradient-dark:focus{background-image:linear-gradient(-47deg, #1e1e1e, #161d31)}

.bg-gradient-primary,.btn-gradient-primary{color:#fff;transition:all .2s ease;background-repeat:repeat-x;background-repeat:repeat}

[dir="ltr"] .bg-gradient-primary,[dir="ltr"] .btn-gradient-primary{background-image:linear-gradient(47deg, #A94CD8, #bd76e1)}

[dir="rtl"] .bg-gradient-primary,[dir="rtl"] .btn-gradient-primary{background-image:linear-gradient(-47deg, #A94CD8, #bd76e1)}

.bg-gradient-primary:hover,.bg-gradient-primary:active,.btn-gradient-primary:hover,.btn-gradient-primary:active{color:#fff}

.bg-gradient-primary:hover:not(.disabled):not(:disabled),.btn-gradient-primary:hover:not(.disabled):not(:disabled){transform:translateY(-2px)}

.bg-gradient-primary:active,.btn-gradient-primary:active{transform:translateY(0)}

.bg-gradient-primary:active,.bg-gradient-primary:focus,.btn-gradient-primary:active,.btn-gradient-primary:focus{background-repeat:repeat-x;background-repeat:repeat}

[dir="ltr"] .bg-gradient-primary:active,[dir="ltr"] .bg-gradient-primary:focus,[dir="ltr"] .btn-gradient-primary:active,[dir="ltr"] .btn-gradient-primary:focus{background-image:linear-gradient(47deg, #922bc6, #A94CD8)}

[dir="rtl"] .bg-gradient-primary:active,[dir="rtl"] .bg-gradient-primary:focus,[dir="rtl"] .btn-gradient-primary:active,[dir="rtl"] .btn-gradient-primary:focus{background-image:linear-gradient(-47deg, #922bc6, #A94CD8)}

.bg-gradient-secondary,.btn-gradient-secondary{color:#fff;transition:all .2s ease;background-repeat:repeat-x;background-repeat:repeat}

[dir="ltr"] .bg-gradient-secondary,[dir="ltr"] .btn-gradient-secondary{background-image:linear-gradient(47deg, #82868b, #9ca0a4)}

[dir="rtl"] .bg-gradient-secondary,[dir="rtl"] .btn-gradient-secondary{background-image:linear-gradient(-47deg, #82868b, #9ca0a4)}

.bg-gradient-secondary:hover,.bg-gradient-secondary:active,.btn-gradient-secondary:hover,.btn-gradient-secondary:active{color:#fff}

.bg-gradient-secondary:hover:not(.disabled):not(:disabled),.btn-gradient-secondary:hover:not(.disabled):not(:disabled){transform:translateY(-2px)}

.bg-gradient-secondary:active,.btn-gradient-secondary:active{transform:translateY(0)}

.bg-gradient-secondary:active,.bg-gradient-secondary:focus,.btn-gradient-secondary:active,.btn-gradient-secondary:focus{background-repeat:repeat-x;background-repeat:repeat}

[dir="ltr"] .bg-gradient-secondary:active,[dir="ltr"] .bg-gradient-secondary:focus,[dir="ltr"] .btn-gradient-secondary:active,[dir="ltr"] .btn-gradient-secondary:focus{background-image:linear-gradient(47deg, #696d71, #82868b)}

[dir="rtl"] .bg-gradient-secondary:active,[dir="rtl"] .bg-gradient-secondary:focus,[dir="rtl"] .btn-gradient-secondary:active,[dir="rtl"] .btn-gradient-secondary:focus{background-image:linear-gradient(-47deg, #696d71, #82868b)}

.bg-gradient-success,.btn-gradient-success{color:#fff;transition:all .2s ease;background-repeat:repeat-x;background-repeat:repeat}

[dir="ltr"] .bg-gradient-success,[dir="ltr"] .btn-gradient-success{background-image:linear-gradient(47deg, #28c76f, #48da89)}

[dir="rtl"] .bg-gradient-success,[dir="rtl"] .btn-gradient-success{background-image:linear-gradient(-47deg, #28c76f, #48da89)}

.bg-gradient-success:hover,.bg-gradient-success:active,.btn-gradient-success:hover,.btn-gradient-success:active{color:#fff}

.bg-gradient-success:hover:not(.disabled):not(:disabled),.btn-gradient-success:hover:not(.disabled):not(:disabled){transform:translateY(-2px)}

.bg-gradient-success:active,.btn-gradient-success:active{transform:translateY(0)}

.bg-gradient-success:active,.bg-gradient-success:focus,.btn-gradient-success:active,.btn-gradient-success:focus{background-repeat:repeat-x;background-repeat:repeat}

[dir="ltr"] .bg-gradient-success:active,[dir="ltr"] .bg-gradient-success:focus,[dir="ltr"] .btn-gradient-success:active,[dir="ltr"] .btn-gradient-success:focus{background-image:linear-gradient(47deg, #1f9d57, #28c76f)}

[dir="rtl"] .bg-gradient-success:active,[dir="rtl"] .bg-gradient-success:focus,[dir="rtl"] .btn-gradient-success:active,[dir="rtl"] .btn-gradient-success:focus{background-image:linear-gradient(-47deg, #1f9d57, #28c76f)}

.bg-gradient-info,.btn-gradient-info{color:#fff;transition:all .2s ease;background-repeat:repeat-x;background-repeat:repeat}

[dir="ltr"] .bg-gradient-info,[dir="ltr"] .btn-gradient-info{background-image:linear-gradient(47deg, #00cfe8, #1ce7ff)}

[dir="rtl"] .bg-gradient-info,[dir="rtl"] .btn-gradient-info{background-image:linear-gradient(-47deg, #00cfe8, #1ce7ff)}

.bg-gradient-info:hover,.bg-gradient-info:active,.btn-gradient-info:hover,.btn-gradient-info:active{color:#fff}

.bg-gradient-info:hover:not(.disabled):not(:disabled),.btn-gradient-info:hover:not(.disabled):not(:disabled){transform:translateY(-2px)}

.bg-gradient-info:active,.btn-gradient-info:active{transform:translateY(0)}

.bg-gradient-info:active,.bg-gradient-info:focus,.btn-gradient-info:active,.btn-gradient-info:focus{background-repeat:repeat-x;background-repeat:repeat}

[dir="ltr"] .bg-gradient-info:active,[dir="ltr"] .bg-gradient-info:focus,[dir="ltr"] .btn-gradient-info:active,[dir="ltr"] .btn-gradient-info:focus{background-image:linear-gradient(47deg, #00a1b5, #00cfe8)}

[dir="rtl"] .bg-gradient-info:active,[dir="rtl"] .bg-gradient-info:focus,[dir="rtl"] .btn-gradient-info:active,[dir="rtl"] .btn-gradient-info:focus{background-image:linear-gradient(-47deg, #00a1b5, #00cfe8)}

.bg-gradient-warning,.btn-gradient-warning{color:#fff;transition:all .2s ease;background-repeat:repeat-x;background-repeat:repeat}

[dir="ltr"] .bg-gradient-warning,[dir="ltr"] .btn-gradient-warning{background-image:linear-gradient(47deg, #ff9f43, #ffb976)}

[dir="rtl"] .bg-gradient-warning,[dir="rtl"] .btn-gradient-warning{background-image:linear-gradient(-47deg, #ff9f43, #ffb976)}

.bg-gradient-warning:hover,.bg-gradient-warning:active,.btn-gradient-warning:hover,.btn-gradient-warning:active{color:#fff}

.bg-gradient-warning:hover:not(.disabled):not(:disabled),.btn-gradient-warning:hover:not(.disabled):not(:disabled){transform:translateY(-2px)}

.bg-gradient-warning:active,.btn-gradient-warning:active{transform:translateY(0)}

.bg-gradient-warning:active,.bg-gradient-warning:focus,.btn-gradient-warning:active,.btn-gradient-warning:focus{background-repeat:repeat-x;background-repeat:repeat}

[dir="ltr"] .bg-gradient-warning:active,[dir="ltr"] .bg-gradient-warning:focus,[dir="ltr"] .btn-gradient-warning:active,[dir="ltr"] .btn-gradient-warning:focus{background-image:linear-gradient(47deg, #ff8510, #ff9f43)}

[dir="rtl"] .bg-gradient-warning:active,[dir="rtl"] .bg-gradient-warning:focus,[dir="rtl"] .btn-gradient-warning:active,[dir="rtl"] .btn-gradient-warning:focus{background-image:linear-gradient(-47deg, #ff8510, #ff9f43)}

.bg-gradient-danger,.btn-gradient-danger{color:#fff;transition:all .2s ease;background-repeat:repeat-x;background-repeat:repeat}

[dir="ltr"] .bg-gradient-danger,[dir="ltr"] .btn-gradient-danger{background-image:linear-gradient(47deg, #ea5455, #f08182)}

[dir="rtl"] .bg-gradient-danger,[dir="rtl"] .btn-gradient-danger{background-image:linear-gradient(-47deg, #ea5455, #f08182)}

.bg-gradient-danger:hover,.bg-gradient-danger:active,.btn-gradient-danger:hover,.btn-gradient-danger:active{color:#fff}

.bg-gradient-danger:hover:not(.disabled):not(:disabled),.btn-gradient-danger:hover:not(.disabled):not(:disabled){transform:translateY(-2px)}

.bg-gradient-danger:active,.btn-gradient-danger:active{transform:translateY(0)}

.bg-gradient-danger:active,.bg-gradient-danger:focus,.btn-gradient-danger:active,.btn-gradient-danger:focus{background-repeat:repeat-x;background-repeat:repeat}

[dir="ltr"] .bg-gradient-danger:active,[dir="ltr"] .bg-gradient-danger:focus,[dir="ltr"] .btn-gradient-danger:active,[dir="ltr"] .btn-gradient-danger:focus{background-image:linear-gradient(47deg, #e42728, #ea5455)}

[dir="rtl"] .bg-gradient-danger:active,[dir="rtl"] .bg-gradient-danger:focus,[dir="rtl"] .btn-gradient-danger:active,[dir="rtl"] .btn-gradient-danger:focus{background-image:linear-gradient(-47deg, #e42728, #ea5455)}

html{font-size:14px;height:100%;letter-spacing:.01rem}

html body{height:100%;background-color:#f6f6f6}

html[dir="ltr"] body{direction:ltr}

html[dir="rtl"] body{direction:rtl}

html body p{line-height:1.5rem}

html .content{padding:0;position:relative;transition:300ms ease all;backface-visibility:hidden}

html[dir="ltr"] .content{margin-left:260px}

html[dir="rtl"] .content{margin-right:260px}

html[dir="ltr"] .content.app-content{padding:calc(1rem + 4.45rem + 1.3rem) 0 0 1rem}

html[dir="rtl"] .content.app-content{padding:calc(1rem + 4.45rem + 1.3rem) 1rem 0 0}

html[dir="ltr"] .content.app-content-show-menu{padding:calc( 50px + 1rem + 4.45rem + 1.3rem) 0 0 1rem}

html[dir="rtl"] .content.app-content-show-menu{padding:calc( 50px + 1rem + 4.45rem + 1.3rem) 1rem 0 0}

html .content.app-content.show-overlay .content-overlay{z-index:10;opacity:1}

html .content.app-content.show-overlay .content-overlay~.header-navbar-shadow{display:none}

html .content.app-content .content-overlay{position:fixed;opacity:0;width:100%;height:100%;top:0;left:0;right:0;bottom:0;background-color:rgba(34,41,47,.5);cursor:pointer;transition:all .7s,z-index 0s;z-index:-1}

html .content.app-content .content-area-wrapper{display:flex;position:relative;overflow:hidden}

html .content.app-content .content-area-wrapper .content-wrapper,html .content.app-content .content-area-wrapper .content-body{height:100%}

html[dir="ltr"] .content.app-content.app-content-increased{padding:calc(1rem + 4.45rem + 4.7rem) 0 0 1rem !important}

html[dir="rtl"] .content.app-content.app-content-increased{padding:calc(1rem + 4.45rem + 4.7rem) 1rem 0 0 !important}

html .content.app-content.app-content-increased.app-content-full-width{padding-right:1rem !important;padding-left:1rem !important}

html .content.app-content.app-content-full-width{padding-right:1rem !important;padding-left:1rem !important}

html .content .body-content-overlay{top:0;left:0;right:0;bottom:0;position:absolute;display:block;z-index:4;visibility:hidden;opacity:0;transition:all .3s ease}

html .content .body-content-overlay.show{visibility:visible;transition:all .3s ease;opacity:1;background-color:rgba(34,41,47,.2);border-radius:.1785rem}

html .content .content-wrapper .content-header-title{font-weight:500;color:#636363}

html[dir="ltr"] .content .content-wrapper .content-header-title{padding-right:1rem;border-right:1px solid #d6dce1}

html[dir="rtl"] .content .content-wrapper .content-header-title{padding-left:1rem;border-left:1px solid #d6dce1}

html .content .content-wrapper .content-header-right .dropdown-toggle::after{display:none}

html[dir="ltr"] .content .content-wrapper .content-header-right .dropdown-toggle i,html[dir="ltr"] .content .content-wrapper .content-header-right .dropdown-toggle svg{margin-right:0}

html[dir="rtl"] .content .content-wrapper .content-header-right .dropdown-toggle i,html[dir="rtl"] .content .content-wrapper .content-header-right .dropdown-toggle svg{margin-left:0}

html .content .content-wrapper .content-header-right .btn-icon{padding:.8rem}

html .content .content-wrapper .content-header-right .btn-icon i,html .content .content-wrapper .content-header-right .btn-icon svg{height:1rem;width:1rem}

html .navbar-floating.footer-static .app-content .content-area-wrapper,html .navbar-floating.footer-static .app-content .kanban-wrapper{height:calc(100vh - calc(calc(1rem * 1) + 4.45rem + 3.35rem + 1.3rem + 0rem));height:calc(var(--vh, 1vh) * 100 - calc(calc(1rem * 1) + 4.45rem + 3.35rem + 1.3rem + 0rem))}

@media(max-width: 575.98px){
html .navbar-floating.footer-static .app-content .content-area-wrapper,html .navbar-floating.footer-static .app-content .kanban-wrapper{height:calc(100vh - calc(calc(calc(1rem * 1) - calc(0.8rem * 1)) + 4.45rem + 3.35rem + 1.3rem + 0rem));height:calc(var(--vh, 1vh) * 100 - calc(calc(calc(1rem * 1) - calc(0.8rem * 1)) + 4.45rem + 3.35rem + 1.3rem + 0rem))}}

html .navbar-floating.footer-hidden .app-content .content-area-wrapper,html .navbar-floating.footer-hidden .app-content .kanban-wrapper{height:calc(100vh - calc(calc(1rem * 2) + 4.45rem + 0rem + 1.3rem + 0rem));height:calc(var(--vh, 1vh) * 100 - calc(calc(1rem * 2) + 4.45rem + 0rem + 1.3rem + 0rem))}

@media(max-width: 575.98px){
html .navbar-floating.footer-hidden .app-content .content-area-wrapper,html .navbar-floating.footer-hidden .app-content .kanban-wrapper{height:calc(100vh - calc(calc(calc(1rem * 2) - calc(0.8rem * 2)) + 4.45rem + 0rem + 1.3rem + 0rem));height:calc(var(--vh, 1vh) * 100 - calc(calc(calc(1rem * 2) - calc(0.8rem * 2)) + 4.45rem + 0rem + 1.3rem + 0rem))}}

html .navbar-floating.footer-fixed .app-content .content-area-wrapper,html .navbar-floating.footer-fixed .app-content .kanban-wrapper{height:calc(100vh - calc(calc(1rem * 2) + 4.45rem + 3.35rem + 1.3rem + 0rem));height:calc(var(--vh, 1vh) * 100 - calc(calc(1rem * 2) + 4.45rem + 3.35rem + 1.3rem + 0rem))}

@media(max-width: 575.98px){
html .navbar-floating.footer-fixed .app-content .content-area-wrapper,html .navbar-floating.footer-fixed .app-content .kanban-wrapper{height:calc(100vh - calc(calc(calc(1rem * 2) - calc(0.8rem * 2)) + 4.45rem + 3.35rem + 1.3rem + 0rem));height:calc(var(--vh, 1vh) * 100 - calc(calc(calc(1rem * 2) - calc(0.8rem * 2)) + 4.45rem + 3.35rem + 1.3rem + 0rem))}}

html .navbar-hidden .app-content{padding:1rem 1rem 0 1rem !important}

html .navbar-hidden.footer-static .app-content .content-area-wrapper,html .navbar-hidden.footer-static .app-content .kanban-wrapper{height:calc(100vh - calc(calc(1rem * 1) + 0rem + 3.35rem + 0rem + 0rem));height:calc(var(--vh, 1vh) * 100 - calc(calc(1rem * 1) + 0rem + 3.35rem + 0rem + 0rem))}

@media(max-width: 575.98px){
html .navbar-hidden.footer-static .app-content .content-area-wrapper,html .navbar-hidden.footer-static .app-content .kanban-wrapper{height:calc(100vh - calc(calc(calc(1rem * 1) - calc(0.8rem * 1)) + 0rem + 3.35rem + 0rem + 0rem));height:calc(var(--vh, 1vh) * 100 - calc(calc(calc(1rem * 1) - calc(0.8rem * 1)) + 0rem + 3.35rem + 0rem + 0rem))}}

html .navbar-hidden.footer-hidden .app-content .content-area-wrapper,html .navbar-hidden.footer-hidden .app-content .kanban-wrapper{height:calc(100vh - calc(calc(1rem * 2) + 0rem + 0rem + 0rem + 0rem));height:calc(var(--vh, 1vh) * 100 - calc(calc(1rem * 2) + 0rem + 0rem + 0rem + 0rem))}

@media(max-width: 575.98px){
html .navbar-hidden.footer-hidden .app-content .content-area-wrapper,html .navbar-hidden.footer-hidden .app-content .kanban-wrapper{height:calc(100vh - calc(calc(calc(1rem * 2) - calc(0.8rem * 2)) + 0rem + 0rem + 0rem + 0rem));height:calc(var(--vh, 1vh) * 100 - calc(calc(calc(1rem * 2) - calc(0.8rem * 2)) + 0rem + 0rem + 0rem + 0rem))}}

html .navbar-hidden.footer-fixed .app-content .content-area-wrapper,html .navbar-hidden.footer-fixed .app-content .kanban-wrapper{height:calc(100vh - calc(calc(1rem * 2) + 0rem + 3.35rem + 0rem + 0rem));height:calc(var(--vh, 1vh) * 100 - calc(calc(1rem * 2) + 0rem + 3.35rem + 0rem + 0rem))}

@media(max-width: 575.98px){
html .navbar-hidden.footer-fixed .app-content .content-area-wrapper,html .navbar-hidden.footer-fixed .app-content .kanban-wrapper{height:calc(100vh - calc(calc(calc(1rem * 2) - calc(0.8rem * 2)) + 0rem + 3.35rem + 0rem + 0rem));height:calc(var(--vh, 1vh) * 100 - calc(calc(calc(1rem * 2) - calc(0.8rem * 2)) + 0rem + 3.35rem + 0rem + 0rem))}}

html .navbar-static .main-menu{top:0}

html .navbar-static .content{min-height:calc(100% - calc(4.45rem + 3.35rem))}

html .navbar-static .app-content{padding:1rem 1rem 0 1rem}

html .navbar-static.footer-static .app-content .content-area-wrapper,html .navbar-static.footer-static .app-content .kanban-wrapper{height:calc(100vh - calc(calc(1rem * 1) + 4.45rem + 3.35rem + 0rem + 0rem));height:calc(var(--vh, 1vh) * 100 - calc(calc(1rem * 1) + 4.45rem + 3.35rem + 0rem + 0rem))}

@media(max-width: 575.98px){
html .navbar-static.footer-static .app-content .content-area-wrapper,html .navbar-static.footer-static .app-content .kanban-wrapper{height:calc(100vh - calc(calc(calc(1rem * 1) - calc(0.8rem * 1)) + 4.45rem + 3.35rem + 0rem + 0rem));height:calc(var(--vh, 1vh) * 100 - calc(calc(calc(1rem * 1) - calc(0.8rem * 1)) + 4.45rem + 3.35rem + 0rem + 0rem))}}

html .navbar-static.footer-hidden .app-content .content-area-wrapper,html .navbar-static.footer-hidden .app-content .kanban-wrapper{height:calc(100vh - calc(calc(1rem * 2) + 4.45rem + 0rem + 0rem + 0rem));height:calc(var(--vh, 1vh) * 100 - calc(calc(1rem * 2) + 4.45rem + 0rem + 0rem + 0rem))}

@media(max-width: 575.98px){
html .navbar-static.footer-hidden .app-content .content-area-wrapper,html .navbar-static.footer-hidden .app-content .kanban-wrapper{height:calc(100vh - calc(calc(calc(1rem * 2) - calc(0.8rem * 2)) + 4.45rem + 0rem + 0rem + 0rem));height:calc(var(--vh, 1vh) * 100 - calc(calc(calc(1rem * 2) - calc(0.8rem * 2)) + 4.45rem + 0rem + 0rem + 0rem))}}

html .navbar-static.footer-fixed .app-content .content-area-wrapper,html .navbar-static.footer-fixed .app-content .kanban-wrapper{height:calc(100vh - calc(calc(1rem * 2) + 4.45rem + 3.35rem + 0rem + 0rem));height:calc(var(--vh, 1vh) * 100 - calc(calc(1rem * 2) + 4.45rem + 3.35rem + 0rem + 0rem))}

@media(max-width: 575.98px){
html .navbar-static.footer-fixed .app-content .content-area-wrapper,html .navbar-static.footer-fixed .app-content .kanban-wrapper{height:calc(100vh - calc(calc(calc(1rem * 2) - calc(0.8rem * 2)) + 4.45rem + 3.35rem + 0rem + 0rem));height:calc(var(--vh, 1vh) * 100 - calc(calc(calc(1rem * 2) - calc(0.8rem * 2)) + 4.45rem + 3.35rem + 0rem + 0rem))}}

html .navbar-sticky .app-content{padding:calc(1rem + 4.45rem) 1rem 0 1rem}

html .navbar-sticky.footer-static .app-content .content-area-wrapper,html .navbar-sticky.footer-static .app-content .kanban-wrapper{height:calc(100vh - calc(calc(1rem * 1) + 4.45rem + 3.35rem + 0rem + 0rem));height:calc(var(--vh, 1vh) * 100 - calc(calc(1rem * 1) + 4.45rem + 3.35rem + 0rem + 0rem))}

@media(max-width: 575.98px){
html .navbar-sticky.footer-static .app-content .content-area-wrapper,html .navbar-sticky.footer-static .app-content .kanban-wrapper{height:calc(100vh - calc(calc(calc(1rem * 1) - calc(0.8rem * 1)) + 4.45rem + 3.35rem + 0rem + 0rem));height:calc(var(--vh, 1vh) * 100 - calc(calc(calc(1rem * 1) - calc(0.8rem * 1)) + 4.45rem + 3.35rem + 0rem + 0rem))}}

html .navbar-sticky.footer-hidden .app-content .content-area-wrapper,html .navbar-sticky.footer-hidden .app-content .kanban-wrapper{height:calc(100vh - calc(calc(1rem * 2) + 4.45rem + 0rem + 0rem + 0rem));height:calc(var(--vh, 1vh) * 100 - calc(calc(1rem * 2) + 4.45rem + 0rem + 0rem + 0rem))}

@media(max-width: 575.98px){
html .navbar-sticky.footer-hidden .app-content .content-area-wrapper,html .navbar-sticky.footer-hidden .app-content .kanban-wrapper{height:calc(100vh - calc(calc(calc(1rem * 2) - calc(0.8rem * 2)) + 4.45rem + 0rem + 0rem + 0rem));height:calc(var(--vh, 1vh) * 100 - calc(calc(calc(1rem * 2) - calc(0.8rem * 2)) + 4.45rem + 0rem + 0rem + 0rem))}}

html .navbar-sticky.footer-fixed .app-content .content-area-wrapper,html .navbar-sticky.footer-fixed .app-content .kanban-wrapper{height:calc(100vh - calc(calc(1rem * 2) + 4.45rem + 3.35rem + 0rem + 0rem));height:calc(var(--vh, 1vh) * 100 - calc(calc(1rem * 2) + 4.45rem + 3.35rem + 0rem + 0rem))}

@media(max-width: 575.98px){
html .navbar-sticky.footer-fixed .app-content .content-area-wrapper,html .navbar-sticky.footer-fixed .app-content .kanban-wrapper{height:calc(100vh - calc(calc(calc(1rem * 2) - calc(0.8rem * 2)) + 4.45rem + 3.35rem + 0rem + 0rem));height:calc(var(--vh, 1vh) * 100 - calc(calc(calc(1rem * 2) - calc(0.8rem * 2)) + 4.45rem + 3.35rem + 0rem + 0rem))}}

html .navbar-static .nav.header-navbar .navbar-container,html .navbar-sticky .nav.header-navbar .navbar-container{padding-left:1.6rem;padding-right:1.6rem}

html .footer-fixed .content.app-content{padding-bottom:3.35rem !important}

html [data-col="1-column"] .header-navbar{width:100%}

html[dir="ltr"] [data-col="1-column"] .header-navbar{left:0 !important}

html[dir="rtl"] [data-col="1-column"] .header-navbar{right:0 !important}

html [data-col="1-column"] .header-navbar.floating-nav{width:calc( 100vw - (100vw - 100%) - calc(1rem * 2) ) !important}

html[dir="ltr"] [data-col="1-column"] .content,html[dir="ltr"] [data-col="1-column"] .footer{margin-left:0px !important}

html[dir="rtl"] [data-col="1-column"] .content,html[dir="rtl"] [data-col="1-column"] .footer{margin-right:0px !important}

html [data-col="1-column"].horizontal-layout .header-navbar{width:100% !important}

html [data-col="1-column"].horizontal-layout .app-content{padding:calc(1rem + 4.45rem + 1.3rem) 1rem 0 1rem !important}

html [data-col="1-column"].horizontal-layout.footer-fixed .app-content{padding:calc(1rem + 4.45rem + 1.3rem) 1rem 3.35rem 1rem !important}

html[dir="ltr"] .blank-page .content{margin-left:0}

html[dir="rtl"] .blank-page .content{margin-right:0}

html .blank-page .content.app-content{overflow:overlay;overflow-x:hidden;padding:0 !important;transition:none}

html .blank-page .content.app-content .header-navbar-shadow{display:none}

html .blank-page .content-wrapper{padding:0 !important}

html .blank-page .content-wrapper .flexbox-container{display:flex;align-items:center;height:100vh;height:calc(var(--vh, 1vh) * 100);justify-content:center}

html .pace .pace-progress{background:#a94cd8}

.app-content.center-layout{overflow:hidden}

@media(min-width: 992px){
body .content-right{width:calc(100vw - (100vw - 100%) - 260px)}

[dir="ltr"] body .content-right{float:right}

[dir="rtl"] body .content-right{float:left}

body .content-left{width:calc(100vw - (100vw - 100%) - 260px)}

[dir="ltr"] body .content-left{float:left}

[dir="rtl"] body .content-left{float:right}

body .content-detached{width:100%}

[dir="ltr"] body .content-detached.content-right{float:right;margin-left:-260px}

[dir="rtl"] body .content-detached.content-right{float:left;margin-right:-260px}

[dir="ltr"] body .content-detached.content-right .content-body{margin-left:calc(260px + 1rem)}

[dir="rtl"] body .content-detached.content-right .content-body{margin-right:calc(260px + 1rem)}

[dir="ltr"] body .content-detached.content-left{float:left;margin-right:-260px}

[dir="rtl"] body .content-detached.content-left{float:right;margin-left:-260px}

[dir="ltr"] body .content-detached.content-left .content-body{margin-right:calc(260px + 1rem)}

[dir="rtl"] body .content-detached.content-left .content-body{margin-left:calc(260px + 1rem)}

.sidebar-right.sidebar-sticky{width:260px !important;margin-top:5.7rem}

[dir="ltr"] .sidebar-right.sidebar-sticky{float:right !important;margin-left:-260px}

[dir="rtl"] .sidebar-right.sidebar-sticky{float:left !important;margin-right:-260px}}

@media(max-width: 575.98px){
html body .app-content{padding:calc(1rem + 4.45rem + 1.3rem) 1rem 0 1rem !important}

html body .app-content-show-menu{padding:calc(50px + 1rem + 4.45rem + 1.3rem) 1rem 0 1rem !important}

html body.navbar-static .app-content{padding:calc(1rem - 0.8rem) calc(1rem - 0.8rem) 0 calc(1rem - 0.8rem) !important}

html body.navbar-sticky .app-content{padding:calc(1rem - 0.8rem + 4.45rem) calc(1rem - 0.8rem) 0 calc(1rem - 0.8rem) !important}

html body.navbar-hidden .app-content{padding:calc(1rem - 0.8rem) calc(1rem - 0.8rem) 0 calc(1rem - 0.8rem) !important}}

@media(max-width: 575.98px){
[dir="ltr"] .navbar-static .app-content .header-navbar .navbar-container,[dir="ltr"] .navbar-sticky .app-content .header-navbar .navbar-container{padding-left:1rem;padding-right:.5rem}

[dir="rtl"] .navbar-static .app-content .header-navbar .navbar-container,[dir="rtl"] .navbar-sticky .app-content .header-navbar .navbar-container{padding-right:1rem;padding-left:.5rem}}

@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none){
html.full-screen{width:100%}

html.full-screen .content.app-content{height:100%;overflow:scroll;overflow-x:hidden}}

.sidebar{position:relative;width:100%;height:100%}

@media(min-width: 992px){
.sidebar{vertical-align:top}}

.sidebar-fixed{position:fixed;height:100%;overflow:scroll}

.sidenav-overlay{position:fixed;top:0;left:0;right:0;height:120vh;background-color:rgba(34,41,47,.5);z-index:997;display:block;visibility:hidden;opacity:0;transition:all .5s ease}

.sidenav-overlay.show{opacity:1;visibility:visible;transition:all .5s ease}

.drag-target{height:100%;width:40px;position:fixed;top:0;z-index:1036}

[dir="ltr"] .drag-target{left:-10px}

[dir="rtl"] .drag-target{right:-10px}

.sidebar-detached .sidebar{width:260px}

@media(min-width: 992px){
[dir="ltr"] .sidebar-left{float:left}

[dir="rtl"] .sidebar-left{float:right}

[dir="ltr"] .sidebar-right{float:right}

[dir="rtl"] .sidebar-right{float:left}}

@media(max-width: 991.98px){
.sidebar-detached .sidebar{width:100%}}

footer.footer{padding:.8rem 1rem;transition:300ms ease all;margin-top:auto}

footer.footer span i,footer.footer span svg{height:1.51rem;width:1.51rem;font-size:1.51rem;color:#ea5455}

[dir="ltr"] footer.footer span i,[dir="ltr"] footer.footer span svg{margin-left:.5rem}

[dir="rtl"] footer.footer span i,[dir="rtl"] footer.footer span svg{margin-right:.5rem}

footer.footer .scroll-top{padding:.81rem .83rem}

.footer-fixed footer{position:fixed !important;z-index:1030;right:0;left:0;bottom:0}

.footer-fixed footer.footer-light{background:#fff;box-shadow:0 4px 24px 0 rgba(34,41,47,.1)}

.footer-fixed footer.footer-dark{background:#b8c2cc;color:#fff}

.scroll-top{position:fixed;bottom:5%;display:none;z-index:99}

[dir="ltr"] .scroll-top{right:30px}

[dir="rtl"] .scroll-top{left:30px}

.main-menu{z-index:1031;position:absolute;display:table-cell;height:100%;overflow:hidden}

.main-menu.menu-light{color:#6e6b7b;background:#fff}

.main-menu.menu-light .navigation{background:#283046}

.main-menu.menu-light .navigation .navigation-header{color:#a6a4b0;padding:0;line-height:1.5;letter-spacing:.01rem}

[dir="ltr"] .main-menu.menu-light .navigation .navigation-header{margin:calc(1rem + 0.286rem) 0 .8rem 1.2rem}

[dir="rtl"] .main-menu.menu-light .navigation .navigation-header{margin:calc(1rem + 0.286rem) 1.2rem .8rem 0}

.main-menu.menu-light .navigation .navigation-header span{font-weight:500}

.main-menu.menu-light .navigation li.has-sub>a{transition:all .5s ease,background 0s,color 0s}

.main-menu.menu-light .navigation li a{color:#625f6e;padding:10px 15px 10px 15px;line-height:1.45}

.main-menu.menu-light .navigation li a span.menu-sub-title{color:#bdbbc4}

.main-menu.menu-light .navigation li a>*{transition:transform .25s ease}

.main-menu.menu-light .navigation li.nav-item a:hover>*{transition:transform .25s ease}

[dir="ltr"] .main-menu.menu-light .navigation li.nav-item a:hover>*{transform:translateX(5px)}

[dir="rtl"] .main-menu.menu-light .navigation li.nav-item a:hover>*{transform:translateX(-5px)}

.main-menu.menu-light .navigation li.nav-item ul.menu-content li a:hover>*{transition:transform .25s ease}

[dir="ltr"] .main-menu.menu-light .navigation li.nav-item ul.menu-content li a:hover>*{transform:translateX(5px)}

[dir="rtl"] .main-menu.menu-light .navigation li.nav-item ul.menu-content li a:hover>*{transform:translateX(-5px)}

.main-menu.menu-light .navigation>li>a{margin:0 15px}

.main-menu.menu-light .navigation>li>a .label{margin-top:4px}

[dir="ltr"] .main-menu.menu-light .navigation>li>a .label{margin-right:5px}

[dir="rtl"] .main-menu.menu-light .navigation>li>a .label{margin-left:5px}

.main-menu.menu-light .navigation>li>a i,.main-menu.menu-light .navigation>li>a svg{position:relative;top:1px}

.main-menu.menu-light .navigation>li>ul li:first-of-type{margin-top:7px !important}

.main-menu.menu-light .navigation>li>ul li:not(.has-sub){margin:0 15px}

.main-menu.menu-light .navigation>li>ul li.has-sub>a{margin:0 15px}

.main-menu.menu-light .navigation>li>ul li.has-sub>ul>li{margin:0 15px}

.main-menu.menu-light .navigation>li.open:not(.menu-item-closing)>a,.main-menu.menu-light .navigation>li.sidebar-group-active>a{color:#565360;background:#f5f5f5;border-radius:6px}

.main-menu.menu-light .navigation>li:not(.open)>ul{display:none}

.main-menu.menu-light .navigation>li:not(.open)>ul .active{background:transparent;box-shadow:none}

.main-menu.menu-light .navigation>li.active{z-index:1}

.main-menu.menu-light .navigation>li.active>a{box-shadow:0 0 10px 1px rgba(169,76,216,.7);color:#fff;font-weight:400;border-radius:4px}

[dir="ltr"] .main-menu.menu-light .navigation>li.active>a{background:linear-gradient(118deg, #a94cd8, rgba(169, 76, 216, 0.7))}

[dir="rtl"] .main-menu.menu-light .navigation>li.active>a{background:linear-gradient(-118deg, #a94cd8, rgba(169, 76, 216, 0.7))}

.main-menu.menu-light .navigation>li .active{background:#f0f0f0}

.main-menu.menu-light .navigation>li .active>a{color:#a94cd8;margin-bottom:0}

.main-menu.menu-light .navigation>li .active .hover>a,.main-menu.menu-light .navigation>li .active :hover>a{background:#f5f5f5}

.main-menu.menu-light .navigation>li ul{padding:0;margin:0}

.main-menu.menu-light .navigation>li ul li{color:#6e6b7b;background:transparent}

[dir="ltr"] .main-menu.menu-light .navigation>li ul li>a{padding:10px 15px 10px 20px}

[dir="rtl"] .main-menu.menu-light .navigation>li ul li>a{padding:10px 20px 10px 15px}

[dir="ltr"] .main-menu.menu-light .navigation>li ul li ul a{padding:10px 15px 10px 53px}

[dir="rtl"] .main-menu.menu-light .navigation>li ul li ul a{padding:10px 53px 10px 15px}

.main-menu.menu-light .navigation>li ul .has-sub:not(.open)>ul{display:none}

.main-menu.menu-light .navigation>li ul .has-sub:not(.open)>ul .active{background:transparent;box-shadow:none}

.main-menu.menu-light .navigation>li ul .open>a,.main-menu.menu-light .navigation>li ul .sidebar-group-active>a{color:#6e6b7b;background:#f5f5f5;border-radius:6px}

.main-menu.menu-light .navigation>li ul .open>ul,.main-menu.menu-light .navigation>li ul .sidebar-group-active>ul{display:block}

.main-menu.menu-light .navigation>li ul .open>ul .open>ul,.main-menu.menu-light .navigation>li ul .sidebar-group-active>ul .open>ul{display:block}

.main-menu.menu-light .navigation>li ul .hover>a,.main-menu.menu-light .navigation>li ul:hover>a{color:#787486}

.main-menu.menu-light .navigation>li ul .active{box-shadow:0 0 10px 1px rgba(169,76,216,.7);border-radius:4px;z-index:1}

[dir="ltr"] .main-menu.menu-light .navigation>li ul .active{background:linear-gradient(118deg, #a94cd8, rgba(169, 76, 216, 0.7))}

[dir="rtl"] .main-menu.menu-light .navigation>li ul .active{background:linear-gradient(-118deg, #a94cd8, rgba(169, 76, 216, 0.7))}

.main-menu.menu-light .navigation>li ul .active>a{color:#fff}

.main-menu.menu-dark{color:#dcdcdc;background:#10163a}

.main-menu.menu-dark .navigation{background:#283046}

.main-menu.menu-dark .navigation .navigation-header{color:#fff;padding:0;line-height:1.5;letter-spacing:.01rem}

[dir="ltr"] .main-menu.menu-dark .navigation .navigation-header{margin:calc(1rem + 0.286rem) 0 .8rem 1.2rem}

[dir="rtl"] .main-menu.menu-dark .navigation .navigation-header{margin:calc(1rem + 0.286rem) 1.2rem .8rem 0}

.main-menu.menu-dark .navigation .navigation-header span{font-weight:500}

.main-menu.menu-dark .navigation li.has-sub>a{transition:all .5s ease,background 0s,color 0s}

.main-menu.menu-dark .navigation li a{color:#d0d0d0;padding:10px 15px 10px 15px;line-height:1.45}

.main-menu.menu-dark .navigation li a span.menu-sub-title{color:#fff}

.main-menu.menu-dark .navigation li a>*{transition:transform .25s ease}

.main-menu.menu-dark .navigation li.nav-item a:hover>*{transition:transform .25s ease}

[dir="ltr"] .main-menu.menu-dark .navigation li.nav-item a:hover>*{transform:translateX(5px)}

[dir="rtl"] .main-menu.menu-dark .navigation li.nav-item a:hover>*{transform:translateX(-5px)}

.main-menu.menu-dark .navigation li.nav-item ul.menu-content li a:hover>*{transition:transform .25s ease}

[dir="ltr"] .main-menu.menu-dark .navigation li.nav-item ul.menu-content li a:hover>*{transform:translateX(5px)}

[dir="rtl"] .main-menu.menu-dark .navigation li.nav-item ul.menu-content li a:hover>*{transform:translateX(-5px)}

.main-menu.menu-dark .navigation>li>a{margin:0 15px}

.main-menu.menu-dark .navigation>li>a .label{margin-top:4px}

[dir="ltr"] .main-menu.menu-dark .navigation>li>a .label{margin-right:5px}

[dir="rtl"] .main-menu.menu-dark .navigation>li>a .label{margin-left:5px}

.main-menu.menu-dark .navigation>li>a i,.main-menu.menu-dark .navigation>li>a svg{position:relative;top:1px}

.main-menu.menu-dark .navigation>li>ul li:first-of-type{margin-top:7px !important}

.main-menu.menu-dark .navigation>li>ul li:not(.has-sub){margin:0 15px}

.main-menu.menu-dark .navigation>li>ul li.has-sub>a{margin:0 15px}

.main-menu.menu-dark .navigation>li>ul li.has-sub>ul>li{margin:0 15px}

.main-menu.menu-dark .navigation>li.open:not(.menu-item-closing)>a,.main-menu.menu-dark .navigation>li.sidebar-group-active>a{color:#c3c3c3;background:#0c102a;border-radius:6px}

.main-menu.menu-dark .navigation>li:not(.open)>ul{display:none}

.main-menu.menu-dark .navigation>li:not(.open)>ul .active{background:transparent;box-shadow:none}

.main-menu.menu-dark .navigation>li.active{z-index:1}

.main-menu.menu-dark .navigation>li.active>a{box-shadow:0 0 10px 1px rgba(169,76,216,.7);color:#fff;font-weight:400;border-radius:4px}

[dir="ltr"] .main-menu.menu-dark .navigation>li.active>a{background:linear-gradient(118deg, #a94cd8, rgba(169, 76, 216, 0.7))}

[dir="rtl"] .main-menu.menu-dark .navigation>li.active>a{background:linear-gradient(-118deg, #a94cd8, rgba(169, 76, 216, 0.7))}

.main-menu.menu-dark .navigation>li .active{background:#090d22}

.main-menu.menu-dark .navigation>li .active>a{color:#a94cd8;margin-bottom:0}

.main-menu.menu-dark .navigation>li .active .hover>a,.main-menu.menu-dark .navigation>li .active :hover>a{background:#0c102a}

.main-menu.menu-dark .navigation>li ul{padding:0;margin:0}

.main-menu.menu-dark .navigation>li ul li{color:#dcdcdc;background:transparent}

[dir="ltr"] .main-menu.menu-dark .navigation>li ul li>a{padding:10px 15px 10px 20px}

[dir="rtl"] .main-menu.menu-dark .navigation>li ul li>a{padding:10px 20px 10px 15px}

[dir="ltr"] .main-menu.menu-dark .navigation>li ul li ul a{padding:10px 15px 10px 53px}

[dir="rtl"] .main-menu.menu-dark .navigation>li ul li ul a{padding:10px 53px 10px 15px}

.main-menu.menu-dark .navigation>li ul .has-sub:not(.open)>ul{display:none}

.main-menu.menu-dark .navigation>li ul .has-sub:not(.open)>ul .active{background:transparent;box-shadow:none}

.main-menu.menu-dark .navigation>li ul .open>a,.main-menu.menu-dark .navigation>li ul .sidebar-group-active>a{color:#dcdcdc;background:#0c102a;border-radius:6px}

.main-menu.menu-dark .navigation>li ul .open>ul,.main-menu.menu-dark .navigation>li ul .sidebar-group-active>ul{display:block}

.main-menu.menu-dark .navigation>li ul .open>ul .open>ul,.main-menu.menu-dark .navigation>li ul .sidebar-group-active>ul .open>ul{display:block}

.main-menu.menu-dark .navigation>li ul .hover>a,.main-menu.menu-dark .navigation>li ul:hover>a{color:#e6e6e6}

.main-menu.menu-dark .navigation>li ul .active{box-shadow:0 0 10px 1px rgba(169,76,216,.7);border-radius:4px;z-index:1}

[dir="ltr"] .main-menu.menu-dark .navigation>li ul .active{background:linear-gradient(118deg, #a94cd8, rgba(169, 76, 216, 0.7))}

[dir="rtl"] .main-menu.menu-dark .navigation>li ul .active{background:linear-gradient(-118deg, #a94cd8, rgba(169, 76, 216, 0.7))}

.main-menu.menu-dark .navigation>li ul .active>a{color:#fff}

.main-menu.menu-fixed{position:fixed}

.main-menu.menu-static{height:100%;padding-bottom:calc(100% - 20rem)}

.main-menu.menu-static .main-menu-content{height:unset !important}

.main-menu.menu-shadow{box-shadow:0 0 15px 0 rgba(34,41,47,.05)}

[dir="ltr"] .main-menu.menu-border{border-right:1px solid #ebe9f1}

[dir="rtl"] .main-menu.menu-border{border-left:1px solid #ebe9f1}

.main-menu .shadow-bottom{display:none;position:absolute;z-index:2;height:50px;width:100%;pointer-events:none;margin-top:-0.7rem;filter:blur(5px);background:linear-gradient(#fff 41%, rgba(255, 255, 255, 0.11) 95%, rgba(255, 255, 255, 0))}

.bordered-layout .main-menu .shadow-bottom{background:linear-gradient(#f6f6f6 41%, rgba(255, 255, 255, 0.11) 95%, rgba(255, 255, 255, 0))}

.main-menu.menu-native-scroll .main-menu-content{overflow-y:scroll !important}

.main-menu .navbar-header{height:100%;width:260px;height:4.45rem;position:relative;transition:300ms ease all,background 0s}

[dir="ltr"] .main-menu .navbar-header{padding:.35rem 1rem .3rem 1.64rem}

[dir="rtl"] .main-menu .navbar-header{padding:.35rem 1.64rem .3rem 1rem}

.main-menu .navbar-header .navbar-brand{display:flex;align-items:center;margin-top:1.35rem;font-size:inherit}

.main-menu .navbar-header .navbar-brand .brand-logo img{max-width:24px}

.main-menu .navbar-header .navbar-brand .brand-text{font-family:IBMPlexMono;color:#fff;margin-bottom:0;font-weight:600;letter-spacing:.01rem;font-size:1.25rem;animation:.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein}

[dir="ltr"] .main-menu .navbar-header .navbar-brand .brand-text{padding-left:1rem}

[dir="rtl"] .main-menu .navbar-header .navbar-brand .brand-text{padding-right:1rem}

.main-menu .navbar-header .modern-nav-toggle{animation:.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein}

.main-menu .main-menu-content{height:calc(100% - 4.45rem) !important;position:relative;font-family:IBMPlexMono}

.main-menu .main-menu-content .ps__rail-y{z-index:3}

.main-menu ul{list-style:none;margin:0;padding:0}

.main-menu ul.navigation-main{overflow-x:hidden;padding-bottom:.75rem;font-family:IBMPlexMono}

.main-menu ul.navigation-main>li:first-child{margin-top:.5rem}

.main-menu a{outline:none}

.main-menu a:hover,.main-menu a:focus{text-decoration:none}

.navigation{font-size:1.1rem;font-family:"Montserrat",Helvetica,Arial,serif;font-weight:400;overflow-y:hidden;padding-bottom:20px}

.navigation .navigation-header{font-weight:500;line-height:1.2;padding:12px 22px;font-size:.9rem;text-transform:uppercase;font-family:Montserrat;color:#fff}

.navigation li{position:relative;white-space:nowrap}

.navigation li a{display:block;text-overflow:ellipsis;overflow:hidden}

.navigation li a i,.navigation li a svg{height:11px;width:11px;font-size:11px}

[dir="ltr"] .navigation li a i,[dir="ltr"] .navigation li a svg{margin-right:1.38rem}

[dir="rtl"] .navigation li a i,[dir="rtl"] .navigation li a svg{margin-left:1.38rem}

.navigation li.menu-item-animating{transition:height .25s ease-in-out}

.navigation li.disabled a{cursor:not-allowed;opacity:.65;pointer-events:none}

@media(max-width: 767.98px){
.menu-hide .main-menu,.menu-open .main-menu{transition:transform .25s,top .35s,height .35s}

.main-menu{backface-visibility:hidden;perspective:1000}

[dir="ltr"] .main-menu{transform:translate3d(-240px, 0, 0)}

[dir="rtl"] .main-menu{transform:translate3d(240px, 0, 0)}

.menu-open .main-menu{transform:translate3d(0, 0, 0)}}

@media(min-width: 768px){
.drag-target{z-index:0}}

.avatar{white-space:nowrap;background-color:#c3c3c3;border-radius:50%;position:relative;cursor:pointer;color:#fff;display:inline-flex;font-size:1rem;text-align:center;vertical-align:middle;font-weight:600}

.avatar .avatar-content{width:32px;height:32px;display:flex;justify-content:center;align-items:center;border-radius:50%;font-size:.857rem}

.avatar .avatar-content .avatar-icon{height:1rem;width:1rem}

.avatar [class*=avatar-status-]{border-radius:50%;width:11px;height:11px;position:absolute;bottom:0;border:1px solid #fff}

[dir="ltr"] .avatar [class*=avatar-status-]{right:0}

[dir="rtl"] .avatar [class*=avatar-status-]{left:0}

.avatar [class*=avatar-status-].avatar-status-lg,.avatar [class*=avatar-status-].avatar-status-xl{width:17px;height:17px;border-width:2px}

.avatar .avatar-status-online{background-color:#28c76f}

.avatar .avatar-status-busy{background-color:#ea5455}

.avatar .avatar-status-away{background-color:#ff9f43}

.avatar .avatar-status-offline{background-color:#82868b}

.avatar.avatar-tag{display:flex;align-items:center;justify-content:center;height:42px;width:42px;border-radius:.357rem}

.avatar img{border-radius:50%}

.avatar.avatar-xl{font-size:1.5rem}

.avatar.avatar-xl img{width:70px;height:70px}

.avatar.avatar-xl .avatar-content{height:70px;width:70px;font-size:2.57rem}

.avatar.avatar-xl .avatar-content .avatar-icon,.avatar.avatar-xl .avatar-content i,.avatar.avatar-xl .avatar-content svg{height:3rem;width:3rem;font-size:3rem}

.avatar.avatar-xl [class*=avatar-status-]{bottom:0}

[dir="ltr"] .avatar.avatar-xl [class*=avatar-status-]{right:3px}

[dir="rtl"] .avatar.avatar-xl [class*=avatar-status-]{left:3px}

.avatar.avatar-lg{font-size:1.2rem}

.avatar.avatar-lg img{width:50px;height:50px}

.avatar.avatar-lg .avatar-content{width:50px;height:50px;font-size:1.714rem}

.avatar.avatar-lg .avatar-content .avatar-icon,.avatar.avatar-lg .avatar-content i,.avatar.avatar-lg .avatar-content svg{height:2rem;width:2rem;font-size:2rem}

.avatar.avatar-lg [class*=avatar-status-]{bottom:1px}

[dir="ltr"] .avatar.avatar-lg [class*=avatar-status-]{right:3px}

[dir="rtl"] .avatar.avatar-lg [class*=avatar-status-]{left:3px}

.avatar.avatar-sm .avatar-content{width:24px;height:24px}

.avatar.avatar-sm .avatar-content .avatar-icon,.avatar.avatar-sm .avatar-content i,.avatar.avatar-sm .avatar-content svg{height:1rem;width:1rem;font-size:1rem}

.avatar.avatar-sm img{width:24px;height:24px}

.avatar.avatar-sm [class*=avatar-status-]{bottom:-2px}

[dir="ltr"] .avatar.avatar-sm [class*=avatar-status-]{right:-3px}

[dir="rtl"] .avatar.avatar-sm [class*=avatar-status-]{left:-3px}

.avatar-group{display:flex;justify-content:flex-start}

.avatar-group .avatar{transition:all .25s ease}

.avatar-group .avatar img,.avatar-group .avatar .avatar-content{box-shadow:0 0 0 2px #fff,inset 0 0 0 1px rgba(34,41,47,.07)}

.avatar-group .avatar .avatar-content{background-color:#b7b9bc !important}

.avatar-group .avatar:hover{transition:all .25s ease}

.avatar-group .avatar.pull-up:hover{transform:translateY(-4px) scale(1.07)}

[dir="ltr"] .avatar-group .avatar:not(:first-child){margin-left:-0.55rem}

[dir="rtl"] .avatar-group .avatar:not(:first-child){margin-right:-0.55rem}

[dir="ltr"] .avatar-group .avatar-sm:not(:first-child){margin-left:-0.4rem}

[dir="rtl"] .avatar-group .avatar-sm:not(:first-child){margin-right:-0.4rem}

[dir="ltr"] .avatar-group .avatar-lg:not(:first-child){margin-left:-1rem}

[dir="rtl"] .avatar-group .avatar-lg:not(:first-child){margin-right:-1rem}

[dir="ltr"] .avatar-group .avatar-xl:not(:first-child){margin-left:-1.5rem}

[dir="rtl"] .avatar-group .avatar-xl:not(:first-child){margin-right:-1.5rem}

.btn-social,.btn-social-icon{position:relative;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}

[dir="ltr"] .btn-social,[dir="ltr"] .btn-social-icon{padding-left:4.95rem;text-align:left}

[dir="rtl"] .btn-social,[dir="rtl"] .btn-social-icon{padding-right:4.95rem;text-align:right}

.btn-social>:first-child,.btn-social-icon>:first-child{position:absolute;top:2px;bottom:0;width:2.95rem;line-height:2.7rem;font-size:1rem;text-align:center}

[dir="ltr"] .btn-social>:first-child,[dir="ltr"] .btn-social-icon>:first-child{left:0;border-right:1px solid rgba(34,41,47,.2)}

[dir="rtl"] .btn-social>:first-child,[dir="rtl"] .btn-social-icon>:first-child{right:0;border-left:1px solid rgba(34,41,47,.2)}

.btn-social-icon{height:2.95rem;width:2.95rem;padding:0}

.btn-social-icon>:first-child{border:none;text-align:center;width:100% !important}

.btn-adn{background-color:#d87a68;color:#fff;background-color:#d87a68;border-color:#fff;color:#fff !important;border:none}

.btn-adn:hover{color:#fff;background-color:#d87a68;border-color:#d87a68}

.btn-adn:focus,.btn-adn.focus{color:#fff;background-color:#d87a68;border-color:#d87a68;box-shadow:0 0 0 .2rem rgba(255,255,255,.5)}

.btn-adn.disabled,.btn-adn:disabled{color:#fff;background-color:#d87a68;border-color:#fff}

.btn-adn:not(:disabled):not(.disabled):active,.btn-adn:not(:disabled):not(.disabled).active,.show>.btn-adn.dropdown-toggle{color:#fff;background-color:#ce563f;border-color:#dfdfdf}

.btn-adn:not(:disabled):not(.disabled):active:focus,.btn-adn:not(:disabled):not(.disabled).active:focus,.show>.btn-adn.dropdown-toggle:focus{box-shadow:0 0 0 .2rem rgba(255,255,255,.5)}

.btn-bitbucket{background-color:#205081;color:#fff;background-color:#205081;border-color:#fff;color:#fff !important;border:none}

.btn-bitbucket:hover{color:#fff;background-color:#205081;border-color:#205081}

.btn-bitbucket:focus,.btn-bitbucket.focus{color:#fff;background-color:#205081;border-color:#205081;box-shadow:0 0 0 .2rem rgba(255,255,255,.5)}

.btn-bitbucket.disabled,.btn-bitbucket:disabled{color:#fff;background-color:#205081;border-color:#fff}

.btn-bitbucket:not(:disabled):not(.disabled):active,.btn-bitbucket:not(:disabled):not(.disabled).active,.show>.btn-bitbucket.dropdown-toggle{color:#fff;background-color:#163758;border-color:#dfdfdf}

.btn-bitbucket:not(:disabled):not(.disabled):active:focus,.btn-bitbucket:not(:disabled):not(.disabled).active:focus,.show>.btn-bitbucket.dropdown-toggle:focus{box-shadow:0 0 0 .2rem rgba(255,255,255,.5)}

.btn-dropbox{background-color:#1087dd;color:#fff;background-color:#1087dd;border-color:#fff;color:#fff !important;border:none}

.btn-dropbox:hover{color:#fff;background-color:#1087dd;border-color:#1087dd}

.btn-dropbox:focus,.btn-dropbox.focus{color:#fff;background-color:#1087dd;border-color:#1087dd;box-shadow:0 0 0 .2rem rgba(255,255,255,.5)}

.btn-dropbox.disabled,.btn-dropbox:disabled{color:#fff;background-color:#1087dd;border-color:#fff}

.btn-dropbox:not(:disabled):not(.disabled):active,.btn-dropbox:not(:disabled):not(.disabled).active,.show>.btn-dropbox.dropdown-toggle{color:#fff;background-color:#0d6aad;border-color:#dfdfdf}

.btn-dropbox:not(:disabled):not(.disabled):active:focus,.btn-dropbox:not(:disabled):not(.disabled).active:focus,.show>.btn-dropbox.dropdown-toggle:focus{box-shadow:0 0 0 .2rem rgba(255,255,255,.5)}

.btn-facebook{background-color:#3b5998;color:#fff;background-color:#3b5998;border-color:#fff;color:#fff !important;border:none}

.btn-facebook:hover{color:#fff;background-color:#3b5998;border-color:#3b5998}

.btn-facebook:focus,.btn-facebook.focus{color:#fff;background-color:#3b5998;border-color:#3b5998;box-shadow:0 0 0 .2rem rgba(255,255,255,.5)}

.btn-facebook.disabled,.btn-facebook:disabled{color:#fff;background-color:#3b5998;border-color:#fff}

.btn-facebook:not(:disabled):not(.disabled):active,.btn-facebook:not(:disabled):not(.disabled).active,.show>.btn-facebook.dropdown-toggle{color:#fff;background-color:#2d4373;border-color:#dfdfdf}

.btn-facebook:not(:disabled):not(.disabled):active:focus,.btn-facebook:not(:disabled):not(.disabled).active:focus,.show>.btn-facebook.dropdown-toggle:focus{box-shadow:0 0 0 .2rem rgba(255,255,255,.5)}

.btn-flickr{background-color:#ff0084;color:#fff;background-color:#ff0084;border-color:#fff;color:#fff !important;border:none}

.btn-flickr:hover{color:#fff;background-color:#ff0084;border-color:#ff0084}

.btn-flickr:focus,.btn-flickr.focus{color:#fff;background-color:#ff0084;border-color:#ff0084;box-shadow:0 0 0 .2rem rgba(255,255,255,.5)}

.btn-flickr.disabled,.btn-flickr:disabled{color:#fff;background-color:#ff0084;border-color:#fff}

.btn-flickr:not(:disabled):not(.disabled):active,.btn-flickr:not(:disabled):not(.disabled).active,.show>.btn-flickr.dropdown-toggle{color:#fff;background-color:#cc006a;border-color:#dfdfdf}

.btn-flickr:not(:disabled):not(.disabled):active:focus,.btn-flickr:not(:disabled):not(.disabled).active:focus,.show>.btn-flickr.dropdown-toggle:focus{box-shadow:0 0 0 .2rem rgba(255,255,255,.5)}

.btn-foursquare{background-color:#f94877;color:#fff;background-color:#f94877;border-color:#fff;color:#fff !important;border:none}

.btn-foursquare:hover{color:#fff;background-color:#f94877;border-color:#f94877}

.btn-foursquare:focus,.btn-foursquare.focus{color:#fff;background-color:#f94877;border-color:#f94877;box-shadow:0 0 0 .2rem rgba(255,255,255,.5)}

.btn-foursquare.disabled,.btn-foursquare:disabled{color:#fff;background-color:#f94877;border-color:#fff}

.btn-foursquare:not(:disabled):not(.disabled):active,.btn-foursquare:not(:disabled):not(.disabled).active,.show>.btn-foursquare.dropdown-toggle{color:#fff;background-color:#f71752;border-color:#dfdfdf}

.btn-foursquare:not(:disabled):not(.disabled):active:focus,.btn-foursquare:not(:disabled):not(.disabled).active:focus,.show>.btn-foursquare.dropdown-toggle:focus{box-shadow:0 0 0 .2rem rgba(255,255,255,.5)}

.btn-github{background-color:#444;color:#fff;background-color:#444;border-color:#fff;color:#fff !important;border:none}

.btn-github:hover{color:#fff;background-color:#444;border-color:#444}

.btn-github:focus,.btn-github.focus{color:#fff;background-color:#444;border-color:#444;box-shadow:0 0 0 .2rem rgba(255,255,255,.5)}

.btn-github.disabled,.btn-github:disabled{color:#fff;background-color:#444;border-color:#fff}

.btn-github:not(:disabled):not(.disabled):active,.btn-github:not(:disabled):not(.disabled).active,.show>.btn-github.dropdown-toggle{color:#fff;background-color:#2b2b2b;border-color:#dfdfdf}

.btn-github:not(:disabled):not(.disabled):active:focus,.btn-github:not(:disabled):not(.disabled).active:focus,.show>.btn-github.dropdown-toggle:focus{box-shadow:0 0 0 .2rem rgba(255,255,255,.5)}

.btn-google{background-color:#dd4b39;color:#fff;background-color:#dd4b39;border-color:#fff;color:#fff !important;border:none}

.btn-google:hover{color:#fff;background-color:#dd4b39;border-color:#dd4b39}

.btn-google:focus,.btn-google.focus{color:#fff;background-color:#dd4b39;border-color:#dd4b39;box-shadow:0 0 0 .2rem rgba(255,255,255,.5)}

.btn-google.disabled,.btn-google:disabled{color:#fff;background-color:#dd4b39;border-color:#fff}

.btn-google:not(:disabled):not(.disabled):active,.btn-google:not(:disabled):not(.disabled).active,.show>.btn-google.dropdown-toggle{color:#fff;background-color:#c23321;border-color:#dfdfdf}

.btn-google:not(:disabled):not(.disabled):active:focus,.btn-google:not(:disabled):not(.disabled).active:focus,.show>.btn-google.dropdown-toggle:focus{box-shadow:0 0 0 .2rem rgba(255,255,255,.5)}

.btn-instagram{background-color:#3f729b;color:#fff;background-color:#3f729b;border-color:#fff;color:#fff !important;border:none}

.btn-instagram:hover{color:#fff;background-color:#3f729b;border-color:#3f729b}

.btn-instagram:focus,.btn-instagram.focus{color:#fff;background-color:#3f729b;border-color:#3f729b;box-shadow:0 0 0 .2rem rgba(255,255,255,.5)}

.btn-instagram.disabled,.btn-instagram:disabled{color:#fff;background-color:#3f729b;border-color:#fff}

.btn-instagram:not(:disabled):not(.disabled):active,.btn-instagram:not(:disabled):not(.disabled).active,.show>.btn-instagram.dropdown-toggle{color:#fff;background-color:#305777;border-color:#dfdfdf}

.btn-instagram:not(:disabled):not(.disabled):active:focus,.btn-instagram:not(:disabled):not(.disabled).active:focus,.show>.btn-instagram.dropdown-toggle:focus{box-shadow:0 0 0 .2rem rgba(255,255,255,.5)}

.btn-linkedin{background-color:#007bb6;color:#fff;background-color:#007bb6;border-color:#fff;color:#fff !important;border:none}

.btn-linkedin:hover{color:#fff;background-color:#007bb6;border-color:#007bb6}

.btn-linkedin:focus,.btn-linkedin.focus{color:#fff;background-color:#007bb6;border-color:#007bb6;box-shadow:0 0 0 .2rem rgba(255,255,255,.5)}

.btn-linkedin.disabled,.btn-linkedin:disabled{color:#fff;background-color:#007bb6;border-color:#fff}

.btn-linkedin:not(:disabled):not(.disabled):active,.btn-linkedin:not(:disabled):not(.disabled).active,.show>.btn-linkedin.dropdown-toggle{color:#fff;background-color:#005983;border-color:#dfdfdf}

.btn-linkedin:not(:disabled):not(.disabled):active:focus,.btn-linkedin:not(:disabled):not(.disabled).active:focus,.show>.btn-linkedin.dropdown-toggle:focus{box-shadow:0 0 0 .2rem rgba(255,255,255,.5)}

.btn-microsoft{background-color:#2672ec;color:#fff;background-color:#2672ec;border-color:#fff;color:#fff !important;border:none}

.btn-microsoft:hover{color:#fff;background-color:#2672ec;border-color:#2672ec}

.btn-microsoft:focus,.btn-microsoft.focus{color:#fff;background-color:#2672ec;border-color:#2672ec;box-shadow:0 0 0 .2rem rgba(255,255,255,.5)}

.btn-microsoft.disabled,.btn-microsoft:disabled{color:#fff;background-color:#2672ec;border-color:#fff}

.btn-microsoft:not(:disabled):not(.disabled):active,.btn-microsoft:not(:disabled):not(.disabled).active,.show>.btn-microsoft.dropdown-toggle{color:#fff;background-color:#125acd;border-color:#dfdfdf}

.btn-microsoft:not(:disabled):not(.disabled):active:focus,.btn-microsoft:not(:disabled):not(.disabled).active:focus,.show>.btn-microsoft.dropdown-toggle:focus{box-shadow:0 0 0 .2rem rgba(255,255,255,.5)}

.btn-odnoklassniki{background-color:#f4731c;color:#fff;background-color:#f4731c;border-color:#fff;color:#fff !important;border:none}

.btn-odnoklassniki:hover{color:#fff;background-color:#f4731c;border-color:#f4731c}

.btn-odnoklassniki:focus,.btn-odnoklassniki.focus{color:#fff;background-color:#f4731c;border-color:#f4731c;box-shadow:0 0 0 .2rem rgba(255,255,255,.5)}

.btn-odnoklassniki.disabled,.btn-odnoklassniki:disabled{color:#fff;background-color:#f4731c;border-color:#fff}

.btn-odnoklassniki:not(:disabled):not(.disabled):active,.btn-odnoklassniki:not(:disabled):not(.disabled).active,.show>.btn-odnoklassniki.dropdown-toggle{color:#fff;background-color:#d35b0a;border-color:#dfdfdf}

.btn-odnoklassniki:not(:disabled):not(.disabled):active:focus,.btn-odnoklassniki:not(:disabled):not(.disabled).active:focus,.show>.btn-odnoklassniki.dropdown-toggle:focus{box-shadow:0 0 0 .2rem rgba(255,255,255,.5)}

.btn-openid{background-color:#f7931e;color:#2a2e30;background-color:#f7931e;border-color:#fff;color:#fff !important;border:none}

.btn-openid:hover{color:#2a2e30;background-color:#f7931e;border-color:#f7931e}

.btn-openid:focus,.btn-openid.focus{color:#2a2e30;background-color:#f7931e;border-color:#f7931e;box-shadow:0 0 0 .2rem rgba(223,224,224,.5)}

.btn-openid.disabled,.btn-openid:disabled{color:#2a2e30;background-color:#f7931e;border-color:#fff}

.btn-openid:not(:disabled):not(.disabled):active,.btn-openid:not(:disabled):not(.disabled).active,.show>.btn-openid.dropdown-toggle{color:#fff;background-color:#da7908;border-color:#dfdfdf}

.btn-openid:not(:disabled):not(.disabled):active:focus,.btn-openid:not(:disabled):not(.disabled).active:focus,.show>.btn-openid.dropdown-toggle:focus{box-shadow:0 0 0 .2rem rgba(223,224,224,.5)}

.btn-pinterest{background-color:#cb2027;color:#fff;background-color:#cb2027;border-color:#fff;color:#fff !important;border:none}

.btn-pinterest:hover{color:#fff;background-color:#cb2027;border-color:#cb2027}

.btn-pinterest:focus,.btn-pinterest.focus{color:#fff;background-color:#cb2027;border-color:#cb2027;box-shadow:0 0 0 .2rem rgba(255,255,255,.5)}

.btn-pinterest.disabled,.btn-pinterest:disabled{color:#fff;background-color:#cb2027;border-color:#fff}

.btn-pinterest:not(:disabled):not(.disabled):active,.btn-pinterest:not(:disabled):not(.disabled).active,.show>.btn-pinterest.dropdown-toggle{color:#fff;background-color:#9f191f;border-color:#dfdfdf}

.btn-pinterest:not(:disabled):not(.disabled):active:focus,.btn-pinterest:not(:disabled):not(.disabled).active:focus,.show>.btn-pinterest.dropdown-toggle:focus{box-shadow:0 0 0 .2rem rgba(255,255,255,.5)}

.btn-reddit{background-color:#eff7ff;color:#2a2e30;background-color:#eff7ff;border-color:#000;color:#fff !important;border:none}

.btn-reddit:hover{color:#fff;background-color:#000;border-color:#000}

.btn-reddit:focus,.btn-reddit.focus{color:#fff;background-color:#000;border-color:#000;box-shadow:0 0 0 .2rem rgba(6,7,7,.5)}

.btn-reddit.disabled,.btn-reddit:disabled{color:#2a2e30;background-color:#eff7ff;border-color:#000}

.btn-reddit:not(:disabled):not(.disabled):active,.btn-reddit:not(:disabled):not(.disabled).active,.show>.btn-reddit.dropdown-toggle{color:#2a2e30;background-color:#bcdeff;border-color:#000}

.btn-reddit:not(:disabled):not(.disabled):active:focus,.btn-reddit:not(:disabled):not(.disabled).active:focus,.show>.btn-reddit.dropdown-toggle:focus{box-shadow:0 0 0 .2rem rgba(6,7,7,.5)}

.btn-soundcloud{background-color:#f50;color:#fff;background-color:#f50;border-color:#fff;color:#fff !important;border:none}

.btn-soundcloud:hover{color:#fff;background-color:#f50;border-color:#f50}

.btn-soundcloud:focus,.btn-soundcloud.focus{color:#fff;background-color:#f50;border-color:#f50;box-shadow:0 0 0 .2rem rgba(255,255,255,.5)}

.btn-soundcloud.disabled,.btn-soundcloud:disabled{color:#fff;background-color:#f50;border-color:#fff}

.btn-soundcloud:not(:disabled):not(.disabled):active,.btn-soundcloud:not(:disabled):not(.disabled).active,.show>.btn-soundcloud.dropdown-toggle{color:#fff;background-color:#c40;border-color:#dfdfdf}

.btn-soundcloud:not(:disabled):not(.disabled):active:focus,.btn-soundcloud:not(:disabled):not(.disabled).active:focus,.show>.btn-soundcloud.dropdown-toggle:focus{box-shadow:0 0 0 .2rem rgba(255,255,255,.5)}

.btn-tumblr{background-color:#2c4762;color:#fff;background-color:#2c4762;border-color:#fff;color:#fff !important;border:none}

.btn-tumblr:hover{color:#fff;background-color:#2c4762;border-color:#2c4762}

.btn-tumblr:focus,.btn-tumblr.focus{color:#fff;background-color:#2c4762;border-color:#2c4762;box-shadow:0 0 0 .2rem rgba(255,255,255,.5)}

.btn-tumblr.disabled,.btn-tumblr:disabled{color:#fff;background-color:#2c4762;border-color:#fff}

.btn-tumblr:not(:disabled):not(.disabled):active,.btn-tumblr:not(:disabled):not(.disabled).active,.show>.btn-tumblr.dropdown-toggle{color:#fff;background-color:#1c2e3f;border-color:#dfdfdf}

.btn-tumblr:not(:disabled):not(.disabled):active:focus,.btn-tumblr:not(:disabled):not(.disabled).active:focus,.show>.btn-tumblr.dropdown-toggle:focus{box-shadow:0 0 0 .2rem rgba(255,255,255,.5)}

.btn-twitter{background-color:#55acee;color:#2a2e30;background-color:#55acee;border-color:#fff;color:#fff !important;border:none}

.btn-twitter:hover{color:#2a2e30;background-color:#55acee;border-color:#55acee}

.btn-twitter:focus,.btn-twitter.focus{color:#2a2e30;background-color:#55acee;border-color:#55acee;box-shadow:0 0 0 .2rem rgba(223,224,224,.5)}

.btn-twitter.disabled,.btn-twitter:disabled{color:#2a2e30;background-color:#55acee;border-color:#fff}

.btn-twitter:not(:disabled):not(.disabled):active,.btn-twitter:not(:disabled):not(.disabled).active,.show>.btn-twitter.dropdown-toggle{color:#fff;background-color:#2795e9;border-color:#dfdfdf}

.btn-twitter:not(:disabled):not(.disabled):active:focus,.btn-twitter:not(:disabled):not(.disabled).active:focus,.show>.btn-twitter.dropdown-toggle:focus{box-shadow:0 0 0 .2rem rgba(223,224,224,.5)}

.btn-vimeo{background-color:#1ab7ea;color:#fff;background-color:#1ab7ea;border-color:#fff;color:#fff !important;border:none}

.btn-vimeo:hover{color:#fff;background-color:#1ab7ea;border-color:#1ab7ea}

.btn-vimeo:focus,.btn-vimeo.focus{color:#fff;background-color:#1ab7ea;border-color:#1ab7ea;box-shadow:0 0 0 .2rem rgba(255,255,255,.5)}

.btn-vimeo.disabled,.btn-vimeo:disabled{color:#fff;background-color:#1ab7ea;border-color:#fff}

.btn-vimeo:not(:disabled):not(.disabled):active,.btn-vimeo:not(:disabled):not(.disabled).active,.show>.btn-vimeo.dropdown-toggle{color:#fff;background-color:#1295bf;border-color:#dfdfdf}

.btn-vimeo:not(:disabled):not(.disabled):active:focus,.btn-vimeo:not(:disabled):not(.disabled).active:focus,.show>.btn-vimeo.dropdown-toggle:focus{box-shadow:0 0 0 .2rem rgba(255,255,255,.5)}

.btn-vk{background-color:#587ea3;color:#fff;background-color:#587ea3;border-color:#fff;color:#fff !important;border:none}

.btn-vk:hover{color:#fff;background-color:#587ea3;border-color:#587ea3}

.btn-vk:focus,.btn-vk.focus{color:#fff;background-color:#587ea3;border-color:#587ea3;box-shadow:0 0 0 .2rem rgba(255,255,255,.5)}

.btn-vk.disabled,.btn-vk:disabled{color:#fff;background-color:#587ea3;border-color:#fff}

.btn-vk:not(:disabled):not(.disabled):active,.btn-vk:not(:disabled):not(.disabled).active,.show>.btn-vk.dropdown-toggle{color:#fff;background-color:#466482;border-color:#dfdfdf}

.btn-vk:not(:disabled):not(.disabled):active:focus,.btn-vk:not(:disabled):not(.disabled).active:focus,.show>.btn-vk.dropdown-toggle:focus{box-shadow:0 0 0 .2rem rgba(255,255,255,.5)}

.btn-yahoo{background-color:#720e9e;color:#fff;background-color:#720e9e;border-color:#fff;color:#fff !important;border:none}

.btn-yahoo:hover{color:#fff;background-color:#720e9e;border-color:#720e9e}

.btn-yahoo:focus,.btn-yahoo.focus{color:#fff;background-color:#720e9e;border-color:#720e9e;box-shadow:0 0 0 .2rem rgba(255,255,255,.5)}

.btn-yahoo.disabled,.btn-yahoo:disabled{color:#fff;background-color:#720e9e;border-color:#fff}

.btn-yahoo:not(:disabled):not(.disabled):active,.btn-yahoo:not(:disabled):not(.disabled).active,.show>.btn-yahoo.dropdown-toggle{color:#fff;background-color:#500a6f;border-color:#dfdfdf}

.btn-yahoo:not(:disabled):not(.disabled):active:focus,.btn-yahoo:not(:disabled):not(.disabled).active:focus,.show>.btn-yahoo.dropdown-toggle:focus{box-shadow:0 0 0 .2rem rgba(255,255,255,.5)}

.btn-outline-adn{border:1px solid #d87a68 !important;color:#d87a68}

.btn-outline-bitbucket{border:1px solid #205081 !important;color:#205081}

.btn-outline-dropbox{border:1px solid #1087dd !important;color:#1087dd}

.btn-outline-facebook{border:1px solid #3b5998 !important;color:#3b5998}

.btn-outline-flickr{border:1px solid #ff0084 !important;color:#ff0084}

.btn-outline-foursquare{border:1px solid #f94877 !important;color:#f94877}

.btn-outline-github{border:1px solid #444 !important;color:#444}

.btn-outline-google{border:1px solid #dd4b39 !important;color:#dd4b39}

.btn-outline-instagram{border:1px solid #3f729b !important;color:#3f729b}

.btn-outline-linkedin{border:1px solid #007bb6 !important;color:#007bb6}

.btn-outline-microsoft{border:1px solid #2672ec !important;color:#2672ec}

.btn-outline-odnoklassniki{border:1px solid #f4731c !important;color:#f4731c}

.btn-outline-openid{border:1px solid #f7931e !important;color:#f7931e}

.btn-outline-pinterest{border:1px solid #cb2027 !important;color:#cb2027}

.btn-outline-reddit{border:1px solid #ff4500 !important;color:#ff4500}

.btn-outline-soundcloud{border:1px solid #f50 !important;color:#f50}

.btn-outline-tumblr{border:1px solid #2c4762 !important;color:#2c4762}

.btn-outline-twitter{border:1px solid #55acee !important;color:#55acee}

.btn-outline-vimeo{border:1px solid #1ab7ea !important;color:#1ab7ea}

.btn-outline-vk{border:1px solid #587ea3 !important;color:#587ea3}

.btn-outline-yahoo{border:1px solid #720e9e !important;color:#720e9e}

.btn-outline-adn:hover{color:#ad412d;border:1px solid #ad412d !important}

.btn-outline-bitbucket:hover{color:#0c1d2f;border:1px solid #0c1d2f !important}

.btn-outline-dropbox:hover{color:#094d7e;border:1px solid #094d7e !important}

.btn-outline-facebook:hover{color:#1e2e4f;border:1px solid #1e2e4f !important}

.btn-outline-flickr:hover{color:#99004f;border:1px solid #99004f !important}

.btn-outline-foursquare:hover{color:#d4073d;border:1px solid #d4073d !important}

.btn-outline-github:hover{color:#111;border:1px solid #111 !important}

.btn-outline-google:hover{color:#96271a;border:1px solid #96271a !important}

.btn-outline-instagram:hover{color:#223d52;border:1px solid #223d52 !important}

.btn-outline-linkedin:hover{color:#003650;border:1px solid #003650 !important}

.btn-outline-microsoft:hover{color:#0e459e;border:1px solid #0e459e !important}

.btn-outline-odnoklassniki:hover{color:#a24608;border:1px solid #a24608 !important}

.btn-outline-openid:hover{color:#a95e06;border:1px solid #a95e06 !important}

.btn-outline-pinterest:hover{color:#731216;border:1px solid #731216 !important}

.btn-outline-reddit:hover{color:#992900;border:1px solid #992900 !important}

.btn-outline-soundcloud:hover{color:#930;border:1px solid #930 !important}

.btn-outline-tumblr:hover{color:#0c141c;border:1px solid #0c141c !important}

.btn-outline-twitter:hover{color:#147bc9;border:1px solid #147bc9 !important}

.btn-outline-vimeo:hover{color:#0d7091;border:1px solid #0d7091 !important}

.btn-outline-vk:hover{color:#344b61;border:1px solid #344b61 !important}

.btn-outline-yahoo:hover{color:#2e0640;border:1px solid #2e0640 !important}

.bg-adn{background-color:#d87a68}

.bg-bitbucket{background-color:#205081}

.bg-dropbox{background-color:#1087dd}

.bg-facebook{background-color:#3b5998}

.bg-flickr{background-color:#ff0084}

.bg-foursquare{background-color:#f94877}

.bg-github{background-color:#444}

.bg-google{background-color:#dd4b39}

.bg-instagram{background-color:#3f729b}

.bg-linkedin{background-color:#007bb6}

.bg-microsoft{background-color:#2672ec}

.bg-odnoklassniki{background-color:#f4731c}

.bg-openid{background-color:#f7931e}

.bg-pinterest{background-color:#cb2027}

.bg-reddit{background-color:#ff4500}

.bg-soundcloud{background-color:#f50}

.bg-tumblr{background-color:#2c4762}

.bg-twitter{background-color:#55acee}

.bg-vimeo{background-color:#1ab7ea}

.bg-vk{background-color:#587ea3}

.bg-yahoo{background-color:#720e9e}

.divider{display:block;text-align:center;overflow:hidden;white-space:nowrap;margin:1rem 0}

.divider .divider-text{position:relative;display:inline-block;font-size:.9375rem;padding:0 1rem}

.divider .divider-text i,.divider .divider-text svg{height:1rem;width:1rem;font-size:1rem}

.divider .divider-text:before,.divider .divider-text:after{content:"";position:absolute;top:50%;width:9999px;border-top:1px solid #ebe9f1}

[dir="ltr"] .divider .divider-text:before{right:100%}

[dir="rtl"] .divider .divider-text:before{left:100%}

[dir="ltr"] .divider .divider-text:after{left:100%}

[dir="rtl"] .divider .divider-text:after{right:100%}

[dir="ltr"] .divider.divider-left .divider-text{float:left;padding-left:0}

[dir="rtl"] .divider.divider-left .divider-text{float:right;padding-right:0}

.divider.divider-left .divider-text:before{display:none}

[dir="ltr"] .divider.divider-left-center .divider-text{left:-25%}

[dir="rtl"] .divider.divider-left-center .divider-text{right:-25%}

[dir="ltr"] .divider.divider-right .divider-text{float:right;padding-right:0}

[dir="rtl"] .divider.divider-right .divider-text{float:left;padding-left:0}

.divider.divider-right .divider-text:after{display:none}

[dir="ltr"] .divider.divider-right-center .divider-text{right:-25%}

[dir="rtl"] .divider.divider-right-center .divider-text{left:-25%}

.divider.divider-dotted .divider-text:before,.divider.divider-dotted .divider-text:after{border-style:dotted;border-width:1px;border-top-width:0;border-color:#ebe9f1}

.divider.divider-dashed .divider-text:before,.divider.divider-dashed .divider-text:after{border-style:dashed;border-width:1px;border-top-width:0;border-color:#ebe9f1}

@media screen and (min-width: 0px){
head{font-family:"xs 0px"}

body:after{content:"xs - min-width: 0px"}}

@media screen and (min-width: 544px){
head{font-family:"sm 544px"}

body:after{content:"sm - min-width: 544px"}}

@media screen and (min-width: 768px){
head{font-family:"md 768px"}

body:after{content:"md - min-width: 768px"}}

@media screen and (min-width: 992px){
head{font-family:"lg 992px"}

body:after{content:"lg - min-width: 992px"}}

@media screen and (min-width: 1200px){
head{font-family:"xl 1200px"}

body:after{content:"xl - min-width: 1200px"}}

head{clear:both}

head title{font-family:"xs 0px, sm 544px, md 768px, lg 992px, xl 1200px"}

body:after{display:none}

*[data-usn-if]{display:none}

.select2-container{width:100% !important;margin:0;display:inline-block;position:relative;vertical-align:middle;box-sizing:border-box}

.select2-container--classic:focus,.select2-container--default:focus{outline:none}

.select2-container--classic .select2-selection__choice,.select2-container--default .select2-selection__choice{font-size:.9rem;margin-top:6px !important}

.select2-container--classic .select2-selection--single,.select2-container--default .select2-selection--single{min-height:2.714rem;padding:5px;border:1px solid #d8d6de}

.select2-container--classic .select2-selection--single:focus,.select2-container--default .select2-selection--single:focus{outline:0;border-color:#a94cd8 !important;box-shadow:0 3px 10px 0 rgba(34,41,47,.1) !important}

.select2-container--classic .select2-selection--single .select2-selection__rendered i,.select2-container--classic .select2-selection--single .select2-selection__rendered svg,.select2-container--default .select2-selection--single .select2-selection__rendered i,.select2-container--default .select2-selection--single .select2-selection__rendered svg{font-size:1.15rem;height:1.15rem;width:1.15rem}

[dir="ltr"] .select2-container--classic .select2-selection--single .select2-selection__rendered i,[dir="ltr"] .select2-container--classic .select2-selection--single .select2-selection__rendered svg,[dir="ltr"] .select2-container--default .select2-selection--single .select2-selection__rendered i,[dir="ltr"] .select2-container--default .select2-selection--single .select2-selection__rendered svg{margin-right:.5rem}

[dir="rtl"] .select2-container--classic .select2-selection--single .select2-selection__rendered i,[dir="rtl"] .select2-container--classic .select2-selection--single .select2-selection__rendered svg,[dir="rtl"] .select2-container--default .select2-selection--single .select2-selection__rendered i,[dir="rtl"] .select2-container--default .select2-selection--single .select2-selection__rendered svg{margin-left:.5rem}

.select2-container--classic .select2-selection--single .select2-selection__arrow b,.select2-container--default .select2-selection--single .select2-selection__arrow b{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23d8d6de%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-down%27%3E%3Cpolyline points=%276 9 12 15 18 9%27%3E%3C/polyline%3E%3C/svg%3E");background-size:18px 14px,18px 14px;background-repeat:no-repeat;height:1rem;margin-top:0;border-style:none}

[dir="ltr"] .select2-container--classic .select2-selection--single .select2-selection__arrow b,[dir="ltr"] .select2-container--default .select2-selection--single .select2-selection__arrow b{padding-right:1.5rem;margin-left:0;left:-8px}

[dir="rtl"] .select2-container--classic .select2-selection--single .select2-selection__arrow b,[dir="rtl"] .select2-container--default .select2-selection--single .select2-selection__arrow b{padding-left:1.5rem;margin-right:0;right:-8px}

.select2-container--classic.select2-container--open,.select2-container--default.select2-container--open{box-shadow:0 5px 25px rgba(34,41,47,.1)}

.select2-container--classic.select2-container--open .select2-selection--single,.select2-container--default.select2-container--open .select2-selection--single{border-color:#a94cd8 !important;outline:0}

.select2-container--classic.select2-container--focus,.select2-container--default.select2-container--focus{outline:0}

.select2-container--classic.select2-container--focus .select2-selection--multiple,.select2-container--default.select2-container--focus .select2-selection--multiple{border-color:#a94cd8 !important;outline:0}

.select2-container--classic .select2-dropdown,.select2-container--default .select2-dropdown{border-color:#d8d6de}

.select2-container--classic .select2-search--dropdown,.select2-container--default .select2-search--dropdown{padding:.5rem}

.select2-container--classic .select2-search--dropdown .select2-search__field,.select2-container--default .select2-search--dropdown .select2-search__field{outline:none !important;border-radius:.357rem;border-color:#d8d6de;padding:.438rem 1rem}

.select2-container--classic .select2-selection--multiple,.select2-container--default .select2-selection--multiple{min-height:38px !important;border:1px solid #d8d6de}

.select2-container--classic .select2-selection--multiple:focus,.select2-container--default .select2-selection--multiple:focus{outline:0;border-color:#a94cd8 !important;box-shadow:0 3px 10px 0 rgba(34,41,47,.1) !important}

.select2-container--classic .select2-selection--multiple .select2-selection__choice,.select2-container--default .select2-selection--multiple .select2-selection__choice{background-color:#a94cd8 !important;border-color:#a94cd8 !important;color:#fff;padding:2px 5px}

.select2-container--classic .select2-selection--multiple .select2-selection__rendered,.select2-container--default .select2-selection--multiple .select2-selection__rendered{padding:0 6px}

.select2-container--classic .select2-selection--multiple .select2-selection__rendered li .select2-search__field,.select2-container--default .select2-selection--multiple .select2-selection__rendered li .select2-search__field{margin-top:7px}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove,.select2-container--default .select2-selection--multiple .select2-selection__choice__remove{font-size:0px;display:inline-block;position:relative;line-height:1rem}

[dir="ltr"] .select2-container--classic .select2-selection--multiple .select2-selection__choice__remove,[dir="ltr"] .select2-container--default .select2-selection--multiple .select2-selection__choice__remove{float:right;margin-left:.7rem;margin-right:.5rem}

[dir="rtl"] .select2-container--classic .select2-selection--multiple .select2-selection__choice__remove,[dir="rtl"] .select2-container--default .select2-selection--multiple .select2-selection__choice__remove{float:left;margin-right:.7rem;margin-left:.5rem}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:before,.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:before{content:"";background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23fff%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-x%27%3E%3Cline x1=%2718%27 y1=%276%27 x2=%276%27 y2=%2718%27%3E%3C/line%3E%3Cline x1=%276%27 y1=%276%27 x2=%2718%27 y2=%2718%27%3E%3C/line%3E%3C/svg%3E");background-size:.85rem;height:.85rem;width:.85rem;position:absolute;top:22%}

[dir="ltr"] .select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:before,[dir="ltr"] .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:before{left:-4px}

[dir="rtl"] .select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:before,[dir="rtl"] .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:before{right:-4px}

.select2-container--classic .select2-selection--multiple i,.select2-container--classic .select2-selection--multiple svg,.select2-container--default .select2-selection--multiple i,.select2-container--default .select2-selection--multiple svg{position:relative;top:1px;height:1.15rem;width:1.15rem;font-size:1.15rem}

[dir="ltr"] .select2-container--classic .select2-selection--multiple i,[dir="ltr"] .select2-container--classic .select2-selection--multiple svg,[dir="ltr"] .select2-container--default .select2-selection--multiple i,[dir="ltr"] .select2-container--default .select2-selection--multiple svg{margin-right:.5rem;padding-left:1px}

[dir="rtl"] .select2-container--classic .select2-selection--multiple i,[dir="rtl"] .select2-container--classic .select2-selection--multiple svg,[dir="rtl"] .select2-container--default .select2-selection--multiple i,[dir="rtl"] .select2-container--default .select2-selection--multiple svg{margin-left:.5rem;padding-right:1px}

.select2-container--classic .select2-results__group,.select2-container--default .select2-results__group{padding:6px 9px;font-weight:600}

.select2-container--classic .select2-results__option,.select2-container--default .select2-results__option{padding:.428rem 1rem}

.select2-container--classic .select2-results__option[role=group],.select2-container--default .select2-results__option[role=group]{padding:0}

.select2-container--classic .select2-results__option[aria-selected=true],.select2-container--default .select2-results__option[aria-selected=true]{background-color:#a94cd8 !important;color:#fff !important}

.select2-container--classic .select2-results__option i,.select2-container--classic .select2-results__option svg,.select2-container--default .select2-results__option i,.select2-container--default .select2-results__option svg{height:1.15rem;width:1.15rem;font-size:1.15rem}

[dir="ltr"] .select2-container--classic .select2-results__option i,[dir="ltr"] .select2-container--classic .select2-results__option svg,[dir="ltr"] .select2-container--default .select2-results__option i,[dir="ltr"] .select2-container--default .select2-results__option svg{margin-right:.5rem}

[dir="rtl"] .select2-container--classic .select2-results__option i,[dir="rtl"] .select2-container--classic .select2-results__option svg,[dir="rtl"] .select2-container--default .select2-results__option i,[dir="rtl"] .select2-container--default .select2-results__option svg{margin-left:.5rem}

.select2-container--classic .select2-results__option--highlighted,.select2-container--default .select2-results__option--highlighted{background-color:rgba(169,76,216,.12) !important;color:#a94cd8 !important}

.select2-container--classic .select2-result-repository__avatar img,.select2-container--default .select2-result-repository__avatar img{width:50px}

.select2-container--classic .select-lg,.select2-container--default .select-lg{min-height:2.714rem !important;font-size:1.2rem;margin-bottom:0 !important;padding:.3rem .7rem}

.select2-container--classic .select-lg.select2-selection--single .select2-selection__rendered,.select2-container--default .select-lg.select2-selection--single .select2-selection__rendered{padding-top:-0.1rem}

.select2-container--classic .select-lg.select2-selection--single .select2-selection__arrow,.select2-container--default .select-lg.select2-selection--single .select2-selection__arrow{top:0rem !important}

.select2-container--classic .select-lg.select2-selection--multiple,.select2-container--default .select-lg.select2-selection--multiple{padding:0 .2rem}

.select2-container--classic .select-lg.select2-selection--multiple .select2-selection__rendered,.select2-container--default .select-lg.select2-selection--multiple .select2-selection__rendered{padding-top:0 !important}

.select2-container--classic .select-lg.select2-selection--multiple .select2-selection__rendered li,.select2-container--default .select-lg.select2-selection--multiple .select2-selection__rendered li{font-size:1.2rem}

.select2-container--classic .select-lg.select2-selection--multiple .select2-selection__rendered li .select2-search__field,.select2-container--default .select-lg.select2-selection--multiple .select2-selection__rendered li .select2-search__field{margin-top:10px}

.select2-container--classic .select-lg.select2-selection--multiple .select2-selection__rendered .select2-selection__choice,.select2-container--default .select-lg.select2-selection--multiple .select2-selection__rendered .select2-selection__choice{padding:5px}

.select2-container--classic .select-lg.select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove:before,.select2-container--default .select-lg.select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove:before{top:46%}

.select2-container--classic .select-sm,.select2-container--default .select-sm{min-height:2.142rem !important;padding:0 .2rem;font-size:.75rem;margin-bottom:0 !important;line-height:1.5}

.select2-container--classic .select-sm.select2-selection--single .select2-selection__arrow,.select2-container--default .select-sm.select2-selection--single .select2-selection__arrow{top:-0.3rem !important}

.select2-container--classic .select-sm.select2-selection--multiple,.select2-container--default .select-sm.select2-selection--multiple{line-height:1.3}

.select2-container--classic .select-sm.select2-selection--multiple .select2-selection__rendered,.select2-container--default .select-sm.select2-selection--multiple .select2-selection__rendered{padding:3px}

.select2-container--classic .select-sm.select2-selection--multiple .select2-selection__rendered li,.select2-container--default .select-sm.select2-selection--multiple .select2-selection__rendered li{font-size:.75rem;margin-top:2px}

.select2-container--classic .select-sm.select2-selection--multiple .select2-selection__rendered li .select2-search__field,.select2-container--default .select-sm.select2-selection--multiple .select2-selection__rendered li .select2-search__field{margin-top:4px}

.select2-container--classic .select-sm.select2-selection--multiple .select2-selection__choice,.select2-container--default .select-sm.select2-selection--multiple .select2-selection__choice{padding:0 .2rem}

.select2-container--classic .select-sm.select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove:before,.select2-container--default .select-sm.select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove:before{top:5%}

.select2-container--classic .select-sm.select2-selection--multiple .select2-search--inline .select2-search__field,.select2-container--default .select-sm.select2-selection--multiple .select2-search--inline .select2-search__field{margin-top:0}

.dark-layout .select2-container .select2-selection,.dark-layout .select2-container .select2-search__field,.dark-layout .select2-container .select2-selection__placeholder{background:#283046;border-color:#3b4253;color:#fff}

.dark-layout .select2-container .select2-selection__rendered{color:#fff}

.dark-layout .select2-container .select2-dropdown{background-color:#283046;border-color:#3b4253}

.dark-layout .select2-container .select2-selection--multiple .select2-selection__choice{background:rgba(169,76,216,.12) !important;color:#a94cd8 !important;border:none}

.dark-layout .select2-container .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove{color:#a94cd8 !important}

.dark-layout .select2-container .select2-selection--multiple .select2-selection__choice__remove:before{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23A94CD8%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-x%27%3E%3Cline x1=%2718%27 y1=%276%27 x2=%276%27 y2=%2718%27%3E%3C/line%3E%3Cline x1=%276%27 y1=%276%27 x2=%2718%27 y2=%2718%27%3E%3C/line%3E%3C/svg%3E")}

.dark-layout .select2-container .select2-results__option[aria-disabled=true]{color:#676d7d}

.dark-layout .select2-container .select2-selection__arrow b{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23fff%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-down%27%3E%3Cpolyline points=%276 9 12 15 18 9%27%3E%3C/polyline%3E%3C/svg%3E")}

.dark-layout .select2-container--disabled .select2-selection{opacity:.5}

table.dataTable{width:100% !important}

table.dataTable td,table.dataTable th{padding:.72rem 1.5rem;vertical-align:middle}

[dir="ltr"] table.dataTable thead .sorting,[dir="ltr"] table.dataTable thead .sorting_asc,[dir="ltr"] table.dataTable thead .sorting_desc{padding-right:inherit}

[dir="rtl"] table.dataTable thead .sorting,[dir="rtl"] table.dataTable thead .sorting_asc,[dir="rtl"] table.dataTable thead .sorting_desc{padding-left:inherit}

table.dataTable thead .sorting:before,table.dataTable thead .sorting_asc:before,table.dataTable thead .sorting_desc:before{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%235e5873%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-up%27%3E%3Cpolyline points=%2718 15 12 9 6 15%27%3E%3C/polyline%3E%3C/svg%3E");background-repeat:no-repeat;background-position:center;background-size:12px;color:#6e6b7b;width:14px;height:14px;content:"";top:.5rem}

[dir="ltr"] table.dataTable thead .sorting:before,[dir="ltr"] table.dataTable thead .sorting_asc:before,[dir="ltr"] table.dataTable thead .sorting_desc:before{right:.3rem}

[dir="rtl"] table.dataTable thead .sorting:before,[dir="rtl"] table.dataTable thead .sorting_asc:before,[dir="rtl"] table.dataTable thead .sorting_desc:before{left:.3rem}

table.dataTable thead .sorting:after,table.dataTable thead .sorting_asc:after,table.dataTable thead .sorting_desc:after{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%235e5873%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-down%27%3E%3Cpolyline points=%276 9 12 15 18 9%27%3E%3C/polyline%3E%3C/svg%3E");background-repeat:no-repeat;background-position:center;background-size:12px;color:#6e6b7b;width:14px;height:14px;content:"";top:1.3rem}

[dir="ltr"] table.dataTable thead .sorting:after,[dir="ltr"] table.dataTable thead .sorting_asc:after,[dir="ltr"] table.dataTable thead .sorting_desc:after{right:.3rem}

[dir="rtl"] table.dataTable thead .sorting:after,[dir="rtl"] table.dataTable thead .sorting_asc:after,[dir="rtl"] table.dataTable thead .sorting_desc:after{left:.3rem}

[dir="ltr"] table.dataTable .dt-checkboxes-cell .custom-control{padding-left:0;padding-right:1.25rem}

[dir="rtl"] table.dataTable .dt-checkboxes-cell .custom-control{padding-right:0;padding-left:1.25rem}

div.dataTables_wrapper .dt-button-collection{background-color:#fff}

div.dataTables_wrapper .dt-button-collection>div[role=menu]{box-shadow:0px 5px 16px -8px;min-width:8rem}

[dir="ltr"] div.dataTables_wrapper .dt-button-collection>div[role=menu]{text-align:left}

[dir="rtl"] div.dataTables_wrapper .dt-button-collection>div[role=menu]{text-align:right}

div.dataTables_wrapper .dt-button-collection [class*=buttons-]:focus{outline:0}

table.dataTable.dtr-column>tbody>tr>td.control:before,table.dataTable.dtr-column>tbody>tr>th.control:before{line-height:16px}

div.dataTables_wrapper div.dataTables_filter label,div.dataTables_wrapper div.dataTables_length label{margin-top:.5rem;margin-bottom:.5rem}

div.dataTables_wrapper div.dataTables_filter select,div.dataTables_wrapper div.dataTables_length select{padding:0 .8rem;width:5rem;margin:0 .5rem}

[dir="ltr"] div.dataTables_wrapper div.dataTables_filter select,[dir="ltr"] div.dataTables_wrapper div.dataTables_length select{background-position:calc(100% - 3px) 11px,calc(100% - 20px) 13px,100% 0}

[dir="rtl"] div.dataTables_wrapper div.dataTables_filter select,[dir="rtl"] div.dataTables_wrapper div.dataTables_length select{background-position:calc(100% - (100% - 3px)) 11px,calc(100% - (100% - 20px)) 13px,0% 0}

[dir="ltr"] div.dataTables_wrapper div.dataTables_filter input{margin-left:.75rem}

[dir="rtl"] div.dataTables_wrapper div.dataTables_filter input{margin-right:.75rem}

div.dataTables_wrapper div.dataTables_paginate ul.pagination{margin-top:.5rem;margin-bottom:1.5rem}

div.dataTables_wrapper .dataTables_info{padding-top:1.25rem !important;color:#b9b9c3}

tr.group{background-color:#fafafc}

@media only screen and (max-width: 768px){
div.dataTables_wrapper div.dataTables_paginate ul.pagination{justify-content:center}

div.dataTables_wrapper div.dataTables_info{white-space:inherit}

div.dt-buttons{justify-content:center;margin-top:.5rem}

div.dt-buttons button{margin-bottom:.5rem}}

@media(max-width: 575.98px){
.dtr-bs-modal .modal-body{padding-left:0;padding-right:0;overflow-x:scroll}

.dtr-bs-modal .table.dtr-details{margin:0 auto 1rem}

.dtr-bs-modal .table.dtr-details td{padding-left:0;padding-right:0}

[dir="ltr"] .dtr-bs-modal .table.dtr-details tr td:first-child{padding-left:.75rem}

[dir="rtl"] .dtr-bs-modal .table.dtr-details tr td:first-child{padding-right:.75rem}

div.dataTables_wrapper{overflow:scroll}}

.bootstrap-touchspin.input-group{width:8.4rem;align-items:center}

.bootstrap-touchspin.input-group .form-control{padding:5px;height:auto;border:0;background-color:#f6f6f6;border-radius:.357rem !important;text-align:center;font-weight:500}

.bootstrap-touchspin.input-group .form-control:focus{z-index:1;box-shadow:none}

.bootstrap-touchspin .bootstrap-touchspin-injected{margin:0 !important}

.bootstrap-touchspin .bootstrap-touchspin-injected.input-group-prepend{position:relative}

[dir="ltr"] .bootstrap-touchspin .bootstrap-touchspin-injected.input-group-prepend{left:12px}

[dir="rtl"] .bootstrap-touchspin .bootstrap-touchspin-injected.input-group-prepend{right:12px}

.bootstrap-touchspin .bootstrap-touchspin-injected.input-group-append{position:relative}

[dir="ltr"] .bootstrap-touchspin .bootstrap-touchspin-injected.input-group-append{right:12px}

[dir="rtl"] .bootstrap-touchspin .bootstrap-touchspin-injected.input-group-append{left:12px}

.bootstrap-touchspin .bootstrap-touchspin-injected .bootstrap-touchspin-down,.bootstrap-touchspin .bootstrap-touchspin-injected .bootstrap-touchspin-up{padding:0;min-width:20px;min-height:20px;border-radius:.357rem !important}

.bootstrap-touchspin .bootstrap-touchspin-injected .bootstrap-touchspin-down i,.bootstrap-touchspin .bootstrap-touchspin-injected .bootstrap-touchspin-down svg,.bootstrap-touchspin .bootstrap-touchspin-injected .bootstrap-touchspin-up i,.bootstrap-touchspin .bootstrap-touchspin-injected .bootstrap-touchspin-up svg{height:.8rem;width:.8rem;font-size:.8rem;position:relative;top:-1px}

.bootstrap-touchspin.disabled-touchspin .bootstrap-touchspin-down,.bootstrap-touchspin.disabled-touchspin .bootstrap-touchspin-up{background-color:rgba(34,41,47,.5) !important;cursor:default;opacity:.5}

.bootstrap-touchspin.input-group-lg{width:9.375rem}

.bootstrap-touchspin.input-group-lg .touchspin.form-control{height:auto !important}

.bootstrap-touchspin.input-group-lg .bootstrap-touchspin-down,.bootstrap-touchspin.input-group-lg .bootstrap-touchspin-up{min-width:24px;min-height:24px}

.bootstrap-touchspin.input-group-lg .bootstrap-touchspin-down i,.bootstrap-touchspin.input-group-lg .bootstrap-touchspin-down svg,.bootstrap-touchspin.input-group-lg .bootstrap-touchspin-up i,.bootstrap-touchspin.input-group-lg .bootstrap-touchspin-up svg{height:1rem;width:1rem;font-size:1rem;top:-3px}

.bootstrap-touchspin.input-group-sm{width:6.25rem}

.bootstrap-touchspin.input-group-sm .touchspin.form-control{height:auto !important}

.bootstrap-touchspin.input-group-sm .bootstrap-touchspin-injected .bootstrap-touchspin-down,.bootstrap-touchspin.input-group-sm .bootstrap-touchspin-injected .bootstrap-touchspin-up{min-width:16px;min-height:16px}

.bootstrap-touchspin.input-group-sm .bootstrap-touchspin-injected .bootstrap-touchspin-down i,.bootstrap-touchspin.input-group-sm .bootstrap-touchspin-injected .bootstrap-touchspin-down svg,.bootstrap-touchspin.input-group-sm .bootstrap-touchspin-injected .bootstrap-touchspin-up i,.bootstrap-touchspin.input-group-sm .bootstrap-touchspin-injected .bootstrap-touchspin-up svg{height:.6rem;width:.6rem;font-size:.6rem}

.bootstrap-touchspin.input-group input[type=number]{-moz-appearance:textfield}

.dark-layout .bootstrap-touchspin.input-group .form-control{background-color:#161d31}

.dark-layout .bootstrap-touchspin.disabled-touchspin .bootstrap-touchspin-injected .bootstrap-touchspin-down,.dark-layout .bootstrap-touchspin.disabled-touchspin .bootstrap-touchspin-injected .bootstrap-touchspin-up,.dark-layout .bootstrap-touchspin.disabled-touchspin .bootstrap-touchspin-injected .disabled-max-min{background-color:#b8c2cc !important;opacity:1}

.dark-layout .bootstrap-touchspin .bootstrap-touchspin-injected .disabled-max-min{background-color:#b8c2cc !important;opacity:1}

body.dark-layout{color:#fff;background-color:#161d31}

.dark-layout body{color:#fff;background-color:#161d31}

.dark-layout h1,.dark-layout h2,.dark-layout h3,.dark-layout h4,.dark-layout h5,.dark-layout h6{color:#fff}

.dark-layout .border,.dark-layout .border-top,.dark-layout .border-right,.dark-layout .border-bottom,.dark-layout .border-left{border-color:#3b4253 !important}

.dark-layout a:hover{color:#a94cd8}

.dark-layout label{color:#fff}

.dark-layout .btn span{color:inherit}

.dark-layout hr{border-color:#3b4253}

.dark-layout pre{background-color:#161d31 !important;border:0}

.dark-layout pre code{background-color:inherit;text-shadow:none}

.dark-layout pre code .url{background-color:#161d31}

.dark-layout code{background-color:#161d31}

.dark-layout kbd{background-color:#161d31}

.dark-layout .text-dark{color:#b8c2cc !important}

.dark-layout .text-muted{color:#676d7d !important}

.dark-layout .text-body{color:#fff !important}

.dark-layout .text-body-heading{color:#fff}

.dark-layout .section-label{color:#676d7d}

.dark-layout .shadow{box-shadow:0 4px 24px 0 rgba(0,0,0,.24) !important}

.dark-layout .blockquote-footer{color:#676d7d}

[dir="ltr"] .dark-layout .header-navbar-shadow{background:linear-gradient(180deg, rgba(22, 29, 49, 0.9) 44%, rgba(22, 29, 49, 0.43) 73%, rgba(22, 29, 49, 0))}

[dir="rtl"] .dark-layout .header-navbar-shadow{background:linear-gradient(-180deg, rgba(22, 29, 49, 0.9) 44%, rgba(22, 29, 49, 0.43) 73%, rgba(22, 29, 49, 0))}

.dark-layout .horizontal-layout .header-navbar{background-color:#283046}

.dark-layout .horizontal-layout.navbar-sticky .header-navbar,.dark-layout .horizontal-layout.navbar-static .header-navbar{background-color:#161d31}

.dark-layout .navbar-sticky .header-navbar.navbar-shadow{box-shadow:0 4px 24px 0 rgba(34,41,47,.75)}

.dark-layout .header-navbar{background-color:#283046}

.dark-layout .header-navbar .navbar-container .nav .nav-item .nav-link{color:#fff;background-color:transparent}

.dark-layout .header-navbar .navbar-container .nav .nav-item .nav-link i,.dark-layout .header-navbar .navbar-container .nav .nav-item .nav-link svg{color:#fff}

.dark-layout .header-navbar .navbar-container .nav .nav-item .nav-link.bookmark-star i,.dark-layout .header-navbar .navbar-container .nav .nav-item .nav-link.bookmark-star svg{color:#fff !important}

.dark-layout .header-navbar .navbar-container .nav .nav-item.nav-search .search-input.open{background-color:#283046}

.dark-layout .header-navbar .navbar-container .nav .nav-item.nav-search .search-input.open .input{border-color:#3b4253}

.dark-layout .header-navbar .navbar-container .nav .nav-item.nav-search .search-input.open .input,.dark-layout .header-navbar .navbar-container .nav .nav-item.nav-search .search-input.open .input::placeholder,.dark-layout .header-navbar .navbar-container .nav .nav-item.nav-search .search-input.open .search-input-close{color:#fff}

.dark-layout .header-navbar .navbar-container .nav .nav-item .bookmark-input{background-color:#283046}

.dark-layout .header-navbar .navbar-container .nav .nav-item .bookmark-input .form-control{background-color:#283046}

.dark-layout .header-navbar .navbar-container .nav .nav-item .search-list{background-color:#283046}

.dark-layout .header-navbar .navbar-container .nav .nav-item .search-list li a{color:#fff}

.dark-layout .header-navbar .navbar-container .nav li i.ficon,.dark-layout .header-navbar .navbar-container .nav li svg.ficon{color:#fff}

.dark-layout .header-navbar .navbar-container .nav .dropdown-cart .dropdown-menu.dropdown-menu-media,.dark-layout .header-navbar .navbar-container .nav .dropdown-notification .dropdown-menu.dropdown-menu-media{overflow:hidden}

.dark-layout .header-navbar .navbar-container .nav .dropdown-cart .dropdown-menu.dropdown-menu-media .media,.dark-layout .header-navbar .navbar-container .nav .dropdown-notification .dropdown-menu.dropdown-menu-media .media{border-color:#3b4253}

.dark-layout .header-navbar .navbar-container .nav .dropdown-cart .dropdown-menu.dropdown-menu-media .media .media-meta,.dark-layout .header-navbar .navbar-container .nav .dropdown-notification .dropdown-menu.dropdown-menu-media .media .media-meta{color:#fff}

.dark-layout .header-navbar .navbar-container .nav .dropdown-cart .dropdown-menu.dropdown-menu-media .media:hover,.dark-layout .header-navbar .navbar-container .nav .dropdown-notification .dropdown-menu.dropdown-menu-media .media:hover{background-color:#161d31}

.dark-layout .header-navbar .navbar-container .nav .dropdown-cart .dropdown-menu.dropdown-menu-media .dropdown-menu-header,.dark-layout .header-navbar .navbar-container .nav .dropdown-notification .dropdown-menu.dropdown-menu-media .dropdown-menu-header{border-bottom:1px solid #3b4253}

.dark-layout .header-navbar .navbar-container .nav .dropdown-cart .dropdown-menu.dropdown-menu-media .dropdown-menu-footer,.dark-layout .header-navbar .navbar-container .nav .dropdown-notification .dropdown-menu.dropdown-menu-media .dropdown-menu-footer{background-color:#283046;border-top:1px solid #3b4253}

.dark-layout .header-navbar .navbar-container .nav .dropdown-cart .dropdown-menu.dropdown-menu-media .dropdown-menu-footer .dropdown-item,.dark-layout .header-navbar .navbar-container .nav .dropdown-notification .dropdown-menu.dropdown-menu-media .dropdown-menu-footer .dropdown-item{border-color:#3b4253}

.dark-layout .header-navbar .navbar-container .nav .dropdown-cart .dropdown-menu.dropdown-menu-media .dropdown-menu-footer .dropdown-item:hover,.dark-layout .header-navbar .navbar-container .nav .dropdown-notification .dropdown-menu.dropdown-menu-media .dropdown-menu-footer .dropdown-item:hover{background-color:#283046}

.dark-layout .header-navbar .navbar-container .nav .dropdown-cart.empty-cart:before{background-color:#161d31}

.dark-layout .header-navbar .navbar-container .nav .dropdown-cart li{color:#fff}

.dark-layout .header-navbar .navbar-container .nav .dropdown-cart .cart-item{color:#fff}

.dark-layout .header-navbar .navbar-container .nav .dropdown-cart .dropdown-header span{color:#fff}

.dark-layout .header-navbar .navbar-container .nav .dropdown-cart .media img{background-color:#161d31}

.dark-layout .header-navbar .navbar-container .nav .dropdown-cart .media-heading .cart-item-by{color:#676d7d}

.dark-layout .header-navbar .navbar-container .nav .dropdown-notification .media-body .media-heading{color:#fff}

.dark-layout .header-navbar .navbar-container .nav .dropdown-notification .notification-text{color:#676d7d}

.dark-layout .header-navbar.navbar-static-top{background-color:transparent !important}

.dark-layout .header-navbar[class*=bg-] .navbar-nav .nav-item .nav-link{background-color:inherit}

.dark-layout .blank-page .card.bg-transparent h1,.dark-layout .blank-page .card.bg-transparent h2,.dark-layout .blank-page .card.bg-transparent h3,.dark-layout .blank-page .card.bg-transparent h4,.dark-layout .blank-page .card.bg-transparent h5,.dark-layout .blank-page .card.bg-transparent h6{color:#283046}

.dark-layout .blank-page .card.bg-transparent p{color:#161d31}

.dark-layout .main-menu{background-color:#283046}

[dir="ltr"] .dark-layout .main-menu .shadow-bottom{background:linear-gradient(180deg, #283046 44%, rgba(40, 48, 70, 0.51) 73%, rgba(40, 48, 70, 0))}

[dir="rtl"] .dark-layout .main-menu .shadow-bottom{background:linear-gradient(-180deg, #283046 44%, rgba(40, 48, 70, 0.51) 73%, rgba(40, 48, 70, 0))}

.dark-layout .main-menu:not(.expanded) .navigation .sidebar-group-active>a{background-color:#161d31}

.dark-layout .main-menu.menu-dark .navigation>li.open:not(.menu-item-closing)>a{background-color:#161d31}

.dark-layout .main-menu.menu-dark .navigation>li.sidebar-group-active>a{background-color:#161d31}

.dark-layout .ps__thumb-x,.dark-layout .ps__thumb-y{background-color:#fff}

.dark-layout .ps .ps__rail-x.ps--clicking,.dark-layout .ps .ps__rail-x:focus,.dark-layout .ps .ps__rail-x:hover,.dark-layout .ps .ps__rail-y.ps--clicking,.dark-layout .ps .ps__rail-y:focus,.dark-layout .ps .ps__rail-y:hover{background-color:#3b4253}

.dark-layout .ps__rail-y.ps--clicking .ps__thumb-y,.dark-layout .ps__rail-y:focus>.ps__thumb-y,.dark-layout .ps__rail-y:hover>.ps__thumb-y{background-color:#fff}

.dark-layout .main-menu-content .navigation-main{background-color:#283046}

.dark-layout .main-menu-content .navigation-main .navigation-header{color:#676d7d}

.dark-layout .main-menu-content .navigation-main li a{color:#fff !important}

.dark-layout .main-menu-content .navigation-main li ul .open>a,.dark-layout .main-menu-content .navigation-main li ul .sidebar-group-active>a{background-color:#161d31}

.dark-layout .main-menu-content .navigation-main .nav-item i,.dark-layout .main-menu-content .navigation-main .nav-item svg{color:#fff}

.dark-layout .main-menu-content .navigation-main .nav-item.open>a{background-color:#161d31 !important}

.dark-layout .main-menu-content .navigation-main .nav-item a:after{color:#fff}

.dark-layout .main-menu-content .navigation-main .nav-item .menu-content{background-color:#283046}

.dark-layout .main-menu-content .navigation-main .nav-item .menu-content .active .menu-item{color:#fff}

.dark-layout .main-menu-content .navigation-main .active .menu-title,.dark-layout .main-menu-content .navigation-main .active i,.dark-layout .main-menu-content .navigation-main .active svg{color:#fff}

.dark-layout .main-menu-content .navigation-main .sidebar-group-active .menu-content{background-color:#283046}

.dark-layout .main-menu-content .navigation-main .sidebar-group-active .menu-content .active{z-index:1}

.dark-layout .main-menu-content .navigation-main .sidebar-group-active .menu-content .active a{background-color:transparent}

.dark-layout .alert .alert-heading,.dark-layout .alert p{color:inherit}

.dark-layout .alert .close{text-shadow:none}

.dark-layout .alert.alert-dark .alert-heading,.dark-layout .alert.alert-dark .alert-body,.dark-layout .alert.alert-dark p{color:#b8c2cc}

.dark-layout .alert.alert-dark.alert-dismissible .close{color:#b8c2cc !important}

.dark-layout .content-header-left .breadcrumbs-top .content-header-title{color:#fff;border-color:#3b4253}

.dark-layout .breadcrumb .breadcrumb-item{color:#fff}

.dark-layout .breadcrumb .breadcrumb-item+.breadcrumb-item::before{color:#fff}

.dark-layout .breadcrumb:not([class*=breadcrumb-]) .breadcrumb-item+.breadcrumb-item:before{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23fff%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-right%27%3E%3Cpolyline points=%279 18 15 12 9 6%27%3E%3C/polyline%3E%3C/svg%3E")}

.dark-layout .breadcrumb-chevron.breadcrumb .breadcrumb-item+.breadcrumb-item:before{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23fff%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-right%27%3E%3Cpolyline points=%279 18 15 12 9 6%27%3E%3C/polyline%3E%3C/svg%3E")}

.dark-layout .btn:not([class*=btn-]){color:#fff}

.dark-layout .btn.btn-dark,.dark-layout .btn.btn-outline-dark,.dark-layout .btn.btn-flat-dark{color:#b8c2cc}

.dark-layout .btn.btn-dark{background-color:#161d31 !important}

.dark-layout .btn.btn-outline-dark{border-color:#161d31;color:#b8c2cc}

.dark-layout .btn.btn-outline-dark:hover:not(.disabled):not(:disabled){color:#b8c2cc}

.dark-layout .btn.btn-flat-dark:active,.dark-layout .btn.btn-flat-dark:focus{background:#161d31}

.dark-layout .btn-group-toggle label[class*=btn-outline-]{color:#a94cd8 !important}

.dark-layout .dropdown-menu{background-color:#283046;box-shadow:0 4px 24px 0 rgba(0,0,0,.24)}

.dark-layout .dropdown-menu .dropdown-header{color:#fff}

.dark-layout .dropdown-menu .dropdown-item{color:#fff}

.dark-layout .dropdown-menu .dropdown-item:hover,.dark-layout .dropdown-menu .dropdown-item:focus{background:rgba(169,76,216,.12);color:#a94cd8}

.dark-layout .dropdown-menu .dropdown-item:active,.dark-layout .dropdown-menu .dropdown-item.active{color:#fff;background-color:#a94cd8}

.dark-layout .dropdown-menu .dropdown-item.disabled,.dark-layout .dropdown-menu .dropdown-item:disabled{color:#676d7d}

.dark-layout .dropdown-divider{border-color:#3b4253}

.dark-layout .modal .modal-header,.dark-layout .modal .modal-header[class*=bg-]{background-color:#161d31}

.dark-layout .modal .modal-header .close{text-shadow:none;background-color:#283046 !important;color:#fff;box-shadow:0 3px 8px 0 rgba(11,10,25,.49) !important}

.dark-layout .modal .modal-content,.dark-layout .modal .modal-body,.dark-layout .modal .modal-footer{background-color:#283046;border-color:#3b4253}

.dark-layout .pagination:not([class*=pagination-]) .page-item.active{background-color:#242b3d}

.dark-layout .pagination:not([class*=pagination-]) .page-item.active .page-link{background-color:#a94cd8}

.dark-layout .pagination:not([class*=pagination-]) .page-item.active .page-link:hover{color:#fff}

.dark-layout .pagination:not([class*=pagination-]) .page-item .page-link{background-color:#242b3d;color:#fff}

.dark-layout .pagination:not([class*=pagination-]) .page-item .page-link:hover{color:#a94cd8}

.dark-layout .pagination:not([class*=pagination-]) .page-item.prev-item .page-link:hover,.dark-layout .pagination:not([class*=pagination-]) .page-item.next-item .page-link:hover{color:#fff}

.dark-layout .pagination[class*=pagination-] .page-item:not(.active) .page-link{background-color:#242b3d}

.dark-layout .pagination[class*=pagination-] .page-item:not(.active):not(:hover) .page-link{color:#fff}

.dark-layout .pagination[class*=pagination-] .page-item:not(.active) .page-link:hover{background-color:#242b3d}

.dark-layout .pagination[class*=pagination-] .page-item.active{background-color:#242b3d}

.dark-layout .pagination .page-item.prev-item .page-link:before,.dark-layout .pagination .page-item.prev .page-link:before{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23fff%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-left%27%3E%3Cpolyline points=%2715 18 9 12 15 6%27%3E%3C/polyline%3E%3C/svg%3E")}

.dark-layout .pagination .page-item.prev-item.disabled .page-link:before,.dark-layout .pagination .page-item.prev.disabled .page-link:before{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23676d7d%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-left%27%3E%3Cpolyline points=%2715 18 9 12 15 6%27%3E%3C/polyline%3E%3C/svg%3E")}

.dark-layout .pagination .page-item.next-item .page-link::after,.dark-layout .pagination .page-item.next .page-link::after{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23fff%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-right%27%3E%3Cpolyline points=%279 18 15 12 9 6%27%3E%3C/polyline%3E%3C/svg%3E")}

.dark-layout .pagination .page-item.next-item.disabled .page-link:after,.dark-layout .pagination .page-item.next.disabled .page-link:after{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23676d7d%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-right%27%3E%3Cpolyline points=%279 18 15 12 9 6%27%3E%3C/polyline%3E%3C/svg%3E")}

.dark-layout .pagination .page-item.disabled .page-link{color:#676d7d}

.dark-layout .nav-tabs .nav-item .nav-link,.dark-layout .nav-pills .nav-item .nav-link,.dark-layout .nav-tabs.nav-justified .nav-item .nav-link{color:#fff}

.dark-layout .nav-tabs .nav-item .nav-link.active,.dark-layout .nav-pills .nav-item .nav-link.active,.dark-layout .nav-tabs.nav-justified .nav-item .nav-link.active{background-color:#283046;color:#a94cd8}

.dark-layout .nav-tabs .nav-item .nav-link.disabled,.dark-layout .nav-pills .nav-item .nav-link.disabled,.dark-layout .nav-tabs.nav-justified .nav-item .nav-link.disabled{color:#676d7d}

.dark-layout .nav.wrap-border{border-color:#3b4253}

.dark-layout .nav-pills .nav-item .nav-link.active{color:#fff;background-color:#a94cd8}

.dark-layout .media-bordered .media:not(:first-child){border-color:#3b4253}

.dark-layout .popover[x-placement=top] .arrow:before{border-top-color:#3b4253}

.dark-layout .popover[x-placement=top] .arrow:after{border-top-color:#283046}

[dir="ltr"] .dark-layout .popover[x-placement=left] .arrow:before{border-left-color:#3b4253}

[dir="rtl"] .dark-layout .popover[x-placement=left] .arrow:before{border-right-color:#3b4253}

[dir="ltr"] .dark-layout .popover[x-placement=left] .arrow:after{border-left-color:#283046}

[dir="rtl"] .dark-layout .popover[x-placement=left] .arrow:after{border-right-color:#283046}

[dir="ltr"] .dark-layout .popover[x-placement=right] .arrow:before{border-right-color:#3b4253}

[dir="rtl"] .dark-layout .popover[x-placement=right] .arrow:before{border-left-color:#3b4253}

[dir="ltr"] .dark-layout .popover[x-placement=right] .arrow:after{border-right-color:#283046}

[dir="rtl"] .dark-layout .popover[x-placement=right] .arrow:after{border-left-color:#283046}

.dark-layout .popover .popover-header{color:#fff}

.dark-layout .popover .popover-body{background-color:#283046;color:#fff;border-color:#3b4253 !important}

.dark-layout .toast{background-color:rgba(40,48,70,.85);box-shadow:0 4px 24px 0 rgba(0,0,0,.24);color:#fff}

.dark-layout .toast .toast-header{background-color:#283046;color:#fff}

.dark-layout .toast .toast-header .close{background-color:transparent !important;box-shadow:none !important;text-shadow:none}

.dark-layout .list-group .list-group-item:not([class*=list-group-item-]),.dark-layout .list-group .list-group-item.list-group-item-action{background-color:#283046;border-color:#3b4253;color:#fff}

.dark-layout .list-group .list-group-item:not([class*=list-group-item-]):hover,.dark-layout .list-group .list-group-item:not([class*=list-group-item-]):focus,.dark-layout .list-group .list-group-item.list-group-item-action:hover,.dark-layout .list-group .list-group-item.list-group-item-action:focus{background-color:#161d31}

.dark-layout .list-group .list-group-item:not([class*=list-group-item-]).active,.dark-layout .list-group .list-group-item:not([class*=list-group-item-]):active,.dark-layout .list-group .list-group-item.list-group-item-action.active,.dark-layout .list-group .list-group-item.list-group-item-action:active{background-color:#a94cd8;color:#fff}

.dark-layout .list-group .list-group-item.active p,.dark-layout .list-group .list-group-item.active small{color:#fff}

.dark-layout .list-group .list-group-item.disabled{color:#676d7d}

.dark-layout .list-group.list-group-circle .list-group-item:after{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2724%27 height=%2724%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23fff%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-circle%27%3E%3Ccircle cx=%2712%27 cy=%2712%27 r=%2710%27%3E%3C/circle%3E%3C/svg%3E")}

.dark-layout .spinner-border{border-color:currentColor !important}

[dir="ltr"] .dark-layout .spinner-border{border-right-color:transparent !important}

[dir="rtl"] .dark-layout .spinner-border{border-left-color:transparent !important}

.dark-layout .avatar{background-color:#161d31}

.dark-layout .avatar [class*=avatar-status-]{border-color:#283046}

.dark-layout .avatar-group .avatar img,.dark-layout .avatar-group .avatar .avatar-content{box-shadow:0 0 0 2px #283046,inset 0 0 0 1px rgba(40,48,70,.07)}

.dark-layout .divider .divider-text{color:#fff}

.dark-layout .divider .divider-text::before,.dark-layout .divider .divider-text::after{border-color:#3b4253}

.dark-layout .divider.divider-dark .divider-text::before,.dark-layout .divider.divider-dark .divider-text::after{border-color:#3b4253 !important}

.dark-layout .card{background-color:#283046;box-shadow:0 4px 24px 0 rgba(0,0,0,.24)}

.dark-layout .card .card-footer{border-color:#3b4253}

.dark-layout .card.overlay-img-card .card-img-overlay span,.dark-layout .card.overlay-img-card .card-img-overlay p,.dark-layout .card.overlay-img-card .card-img-overlay .card-body{color:#fff}

[dir="ltr"] .dark-layout .card-developer-meetup .meetup-header .meetup-day{border-right-color:#404656}

[dir="rtl"] .dark-layout .card-developer-meetup .meetup-header .meetup-day{border-left-color:#404656}

.dark-layout .card-profile .profile-image-wrapper .profile-image{background-color:#161d31}

.dark-layout .business-card .business-items .business-item{border-color:#3b4253}

.dark-layout .card-app-design .design-planning-wrapper .design-planning{background-color:#161d31}

[dir="ltr"] .dark-layout .card-revenue-budget .revenue-report-wrapper{border-right-color:#3b4253}

[dir="rtl"] .dark-layout .card-revenue-budget .revenue-report-wrapper{border-left-color:#3b4253}

.dark-layout .card-company-table .avatar{background-color:#161d31}

.dark-layout input.form-control,.dark-layout .custom-file-label,.dark-layout textarea.form-control{background-color:#283046;color:#fff}

.dark-layout input.form-control:not(:focus),.dark-layout .custom-file-label:not(:focus),.dark-layout textarea.form-control:not(:focus){border-color:#404656}

.dark-layout input.form-control::placeholder,.dark-layout .custom-file-label::placeholder,.dark-layout textarea.form-control::placeholder{color:#676d7d}

.dark-layout input.form-control:disabled,.dark-layout input.form-control[readonly=readonly],.dark-layout .custom-file-label:disabled,.dark-layout .custom-file-label[readonly=readonly],.dark-layout textarea.form-control:disabled,.dark-layout textarea.form-control[readonly=readonly]{opacity:.5}

.dark-layout .char-textarea.active{color:#fff !important}

.dark-layout .char-textarea.max-limit{color:#ea5455 !important}

.dark-layout .custom-file-label:after{background-color:#161d31;color:#fff}

[dir="ltr"] .dark-layout .custom-file-label:after{border-left:1px solid #3b4253}

[dir="rtl"] .dark-layout .custom-file-label:after{border-right:1px solid #3b4253}

.dark-layout .custom-control-input:not(:checked)~.custom-control-label:before{background-color:#283046;border-color:#404656}

.dark-layout .custom-control-input:disabled~.custom-control-label:before{background-color:#444b60;border-color:#444b60}

.dark-layout .custom-switch .custom-control-input:not(:checked)~.custom-control-label:before{background-color:#545a6a}

.dark-layout .custom-switch .custom-control-input:disabled~.custom-control-label:before{background-color:#1b2337}

.dark-layout select.form-control,.dark-layout .custom-select{background-color:#283046;color:#fff;border-color:#3b4253}

.dark-layout select.form-control option:checked,.dark-layout .custom-select option:checked{background-color:#283046}

.dark-layout select.form-control:disabled,.dark-layout .custom-select:disabled{opacity:.5}

.dark-layout select.form-control:focus,.dark-layout .custom-select:focus{border-color:#a94cd8}

.dark-layout select.form-control:not([multiple=multiple]){background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23fff%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-down%27%3E%3Cpolyline points=%276 9 12 15 18 9%27%3E%3C/polyline%3E%3C/svg%3E")}

.dark-layout .was-validated .form-control:invalid,.dark-layout .form-control.is-invalid{border-color:#ea5455 !important}

.dark-layout .was-validated .form-control:valid,.dark-layout .form-control.is-valid{border-color:#28c76f !important}

.dark-layout .wizard .steps ul .disabled a,.dark-layout .wizard .steps ul .done a{color:#fff !important}

.dark-layout .wizard .steps ul .disabled a:hover,.dark-layout .wizard .steps ul .done a:hover{color:#fff}

.dark-layout .wizard .steps ul .disabled a .step,.dark-layout .wizard .steps ul .done a .step{background-color:#161d31 !important;color:#fff !important}

.dark-layout .input-group .input-group-text{background-color:#283046;border-color:#3b4253;color:#fff}

.dark-layout .input-group:focus-within .form-control,.dark-layout .input-group:focus-within .input-group-text{border-color:#a94cd8;box-shadow:none}

.dark-layout .form-label-group>input:not(:focus):not(:placeholder-shown)~label,.dark-layout .form-label-group>textarea:not(:focus):not(:placeholder-shown)~label{color:#fff !important}

.dark-layout .counter-value{color:#fff}

.dark-layout .table{background-color:#283046}

.dark-layout .table td,.dark-layout .table th{color:#fff;border-color:#3b4253 !important}

.dark-layout .table .thead tr:not([class*=table-]) th,.dark-layout .table .thead tr:not([class*=table-]) td,.dark-layout .table tbody tr:not([class*=table-]) th,.dark-layout .table tbody tr:not([class*=table-]) td{border-color:#3b4253;color:#fff}

.dark-layout .table .thead tr:not([class*=table-]) th code,.dark-layout .table .thead tr:not([class*=table-]) td code,.dark-layout .table tbody tr:not([class*=table-]) th code,.dark-layout .table tbody tr:not([class*=table-]) td code{background-color:#283046}

.dark-layout .table thead tr th,.dark-layout .table tfoot tr th{border-color:#3b4253;background-color:#343d55;color:#fff}

.dark-layout .table thead.thead-dark th,.dark-layout .table tfoot.thead-dark th{background-color:#fff;color:#161d31 !important}

.dark-layout .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,.dark-layout .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th{background-color:#343d55}

.dark-layout .table.table-dark{background-color:#fff}

.dark-layout .table.table-dark.table-striped tbody tr:nth-of-type(odd){background-color:rgba(22,29,49,.05)}

.dark-layout .table.table-dark.table-striped tbody tr:nth-of-type(odd) td{background-color:inherit}

.dark-layout .table.table-dark td,.dark-layout .table.table-dark th{border-color:#f8f9fa !important;color:#161d31 !important;background-color:#fff}

.dark-layout .table.table-dark td .text-white,.dark-layout .table.table-dark th .text-white{color:#161d31 !important}

.dark-layout .table tbody tr[class*=table-] td,.dark-layout .table tbody tr[class*=table-] th{background-color:unset}

.dark-layout .table tbody tr.table-dark td,.dark-layout .table tbody tr.table-dark th{color:#fff}

.dark-layout .table tbody tr.table-active td,.dark-layout .table tbody tr.table-active th{color:#fff}

.dark-layout .table.table-bordered{border-color:#3b4253}

.dark-layout .table.table-hover tbody tr:hover{background-color:#242b3d}

.dark-layout .table.table-hover tbody tr th,.dark-layout .table.table-hover tbody tr td{background-color:unset}

.dark-layout .table.table-hover-animation tbody tr{background-color:#283046}

.dark-layout .table.table-hover-animation tbody tr:hover{box-shadow:0 4px 24px 0 rgba(0,0,0,.24)}

.dark-layout .table.table-striped tbody tr:nth-of-type(odd){background-color:#242b3d}

.dark-layout .dataTables_wrapper .dt-buttons .buttons-copy,.dark-layout .dataTables_wrapper .dt-buttons .buttons-excel,.dark-layout .dataTables_wrapper .dt-buttons .buttons-pdf,.dark-layout .dataTables_wrapper .dt-buttons .buttons-print,.dark-layout .dataTables_wrapper .dt-buttons .btn-secondary,.dark-layout .dataTables_wrapper .dt-buttons [class*=buttons-]{background-color:#161d31 !important}

.dark-layout .dataTables_wrapper .dt-buttons .buttons-copy:active,.dark-layout .dataTables_wrapper .dt-buttons .buttons-excel:active,.dark-layout .dataTables_wrapper .dt-buttons .buttons-pdf:active,.dark-layout .dataTables_wrapper .dt-buttons .buttons-print:active,.dark-layout .dataTables_wrapper .dt-buttons .btn-secondary:active,.dark-layout .dataTables_wrapper .dt-buttons [class*=buttons-]:active{background-color:#a94cd8 !important;color:#fff}

.dark-layout .dataTables_wrapper .dt-button-collection>div[role=menu]{box-shadow:0 4px 24px 0 rgba(0,0,0,.24)}

.dark-layout .dataTables_wrapper .table.dataTable tr.group td{background-color:#242b3d;color:#fff}

.dark-layout .dataTables_wrapper .table.dataTable thead .sorting:before,.dark-layout .dataTables_wrapper .table.dataTable thead .sorting_asc:before,.dark-layout .dataTables_wrapper .table.dataTable thead .sorting_desc:before{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23fff%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-up%27%3E%3Cpolyline points=%2718 15 12 9 6 15%27%3E%3C/polyline%3E%3C/svg%3E")}

.dark-layout .dataTables_wrapper .table.dataTable thead .sorting:after,.dark-layout .dataTables_wrapper .table.dataTable thead .sorting_asc:after,.dark-layout .dataTables_wrapper .table.dataTable thead .sorting_desc:after{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23fff%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-down%27%3E%3Cpolyline points=%276 9 12 15 18 9%27%3E%3C/polyline%3E%3C/svg%3E")}

.dark-layout .dataTables_wrapper .table.dataTable thead .sorting:before,.dark-layout .dataTables_wrapper .table.dataTable thead .sorting:after{opacity:.5}

.dark-layout .dataTables_wrapper .table.dataTable thead .sorting_asc:before{opacity:1}

.dark-layout .dataTables_wrapper .table.dataTable thead .sorting_asc:after{opacity:.5}

.dark-layout .dataTables_wrapper .table.dataTable thead .sorting_desc:after{opacity:1}

.dark-layout .dataTables_wrapper .table.dataTable thead .sorting_desc:before{opacity:.5}

.dark-layout .ag-grid-btns .filter-btn{background-color:transparent !important}

.dark-layout .aggrid .ag-input-wrapper input{color:#fff}

.dark-layout .aggrid,.dark-layout .ag-header{background-color:#283046;color:#fff;border-color:#3b4253}

.dark-layout .aggrid .ag-row-hover,.dark-layout .aggrid .ag-row-selected,.dark-layout .ag-header .ag-row-hover,.dark-layout .ag-header .ag-row-selected{background-color:#242b3d !important}

.dark-layout .aggrid .ag-icon,.dark-layout .ag-header .ag-icon{color:#404656}

.dark-layout .aggrid .ag-header-cell.ag-column-hover,.dark-layout .ag-header .ag-header-cell.ag-column-hover{background:transparent !important}

.dark-layout .aggrid .ag-header-cell,.dark-layout .aggrid .ag-cell,.dark-layout .aggrid .ag-row,.dark-layout .aggrid .ag-pinned-left-header,.dark-layout .aggrid .ag-pinned-left-cols-container,.dark-layout .aggrid .ag-horizontal-left-spacer,.dark-layout .aggrid .ag-paging-panel,.dark-layout .aggrid .ag-floating-filter-input,.dark-layout .ag-header .ag-header-cell,.dark-layout .ag-header .ag-cell,.dark-layout .ag-header .ag-row,.dark-layout .ag-header .ag-pinned-left-header,.dark-layout .ag-header .ag-pinned-left-cols-container,.dark-layout .ag-header .ag-horizontal-left-spacer,.dark-layout .ag-header .ag-paging-panel,.dark-layout .ag-header .ag-floating-filter-input{border-color:#3b4253 !important;color:#fff}

.dark-layout .aggrid .ag-cell.ag-cell-inline-editing,.dark-layout .ag-header .ag-cell.ag-cell-inline-editing{border-color:#3b4253;background:#161d31;height:auto;padding-top:0}

.dark-layout .aggrid .ag-paging-panel .ag-paging-button,.dark-layout .ag-header .ag-paging-panel .ag-paging-button{background-color:#161d31}

.dark-layout .aggrid .ag-paging-panel .ag-disabled,.dark-layout .ag-header .ag-paging-panel .ag-disabled{background:#283046}

.dark-layout .aggrid .ag-paging-panel span[ref=lbTotal],.dark-layout .ag-header .ag-paging-panel span[ref=lbTotal]{background-color:#242b3d;color:#fff !important}

.dark-layout .aggrid ::-webkit-scrollbar-track,.dark-layout .ag-header ::-webkit-scrollbar-track{background:#161d31}

.dark-layout .aggrid ::-webkit-scrollbar-thumb,.dark-layout .ag-header ::-webkit-scrollbar-thumb{background:#a94cd8}

.dark-layout .getting-started .clockCard p{color:#fff !important}

.dark-layout #user-profile .profile-header .navbar{background-color:#283046}

.dark-layout #user-profile .profile-header .navbar .navbar-toggler{color:#fff;border-color:#3b4253}

.dark-layout .search-bar .form-control{background-color:#283046}

.dark-layout .blog-edit-wrapper .border{border-color:#3b4253 !important}

.dark-layout .kb-search-content-info .kb-search-content .card-img-top{background-color:#3f4860}

.dark-layout .list-group-circle .list-group-item:not([class*=list-group-item-]):hover,.dark-layout .list-group-circle .list-group-item:not([class*=list-group-item-]):focus,.dark-layout .list-group-circle .list-group-item:not([class*=list-group-item-]):active,.dark-layout .list-group-circle .list-group-item-action:hover,.dark-layout .list-group-circle .list-group-item-action:focus,.dark-layout .list-group-circle .list-group-item-action:active{background-color:transparent !important}

.dark-layout .content-area-wrapper{border-color:#3b4253 !important}

.dark-layout .content-area-wrapper .sidebar .sidebar-content{background-color:#283046 !important}

.dark-layout .content-area-wrapper .app-fixed-search{background-color:#283046 !important;border-color:#3b4253 !important}

.dark-layout .content-area-wrapper .content-right{border-color:#3b4253 !important}

.dark-layout .email-application .content-area-wrapper .email-app-list .app-action{border-color:#3b4253;background-color:#283046}

.dark-layout .email-application .content-area-wrapper .email-app-list .app-action .action-right .list-inline-item .dropdown-toggle{color:#fff}

.dark-layout .email-application .content-area-wrapper .email-app-list .email-user-list .media{border-color:#3b4253;background-color:#283046}

.dark-layout .email-application .content-area-wrapper .email-app-list .email-user-list .media:hover{box-shadow:0 3px 10px 0 #283046}

.dark-layout .email-application .content-area-wrapper .email-app-list .email-user-list .media.selected-row-bg{background-color:rgba(169,76,216,.06);border-color:#3b4253}

.dark-layout .email-application .content-area-wrapper .email-app-list .email-user-list .media .user-details p,.dark-layout .email-application .content-area-wrapper .email-app-list .email-user-list .media .user-details .mail-date,.dark-layout .email-application .content-area-wrapper .email-app-list .email-user-list .media .mail-message p,.dark-layout .email-application .content-area-wrapper .email-app-list .email-user-list .media .mail-message .mail-date{color:#676d7d}

.dark-layout .email-application .content-area-wrapper .email-app-list .email-user-list .mail-read{background-color:#242b3d}

.dark-layout .email-application .content-area-wrapper .email-app-details{border-color:#3b4253}

.dark-layout .email-application .content-area-wrapper .email-app-details .email-scroll-area{background-color:#161d31}

.dark-layout .email-application .content-area-wrapper .email-app-details .email-detail-header{background-color:#283046;border-color:#3b4253}

.dark-layout .email-application .content-area-wrapper .email-app-details .email-detail-header .email-header-right .list-inline-item .dropdown-toggle,.dark-layout .email-application .content-area-wrapper .email-app-details .email-detail-header .email-header-right .list-inline-item .action-icon{color:#fff}

.dark-layout .email-application .content-area-wrapper .email-app-details .email-info-dropup .dropdown-toggle::after{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23676d7d%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-down%27%3E%3Cpolyline points=%276 9 12 15 18 9%27%3E%3C/polyline%3E%3C/svg%3E")}

.dark-layout .email-application .content-area-wrapper .email-app-details .mail-message-wrapper,.dark-layout .email-application .content-area-wrapper .email-app-details .email-detail-head{border-color:#3b4253 !important}

.dark-layout .email-application .content-area-wrapper #compose-mail .compose-mail-form-field,.dark-layout .email-application .content-area-wrapper #compose-mail .ql-toolbar,.dark-layout .email-application .content-area-wrapper #compose-mail .ql-container{border-color:#3b4253}

.dark-layout .email-application .content-area-wrapper #compose-mail label{color:#fff}

.dark-layout .email-application .content-area-wrapper #compose-mail .modal-body{border-bottom-left-radius:.358rem;border-bottom-right-radius:.358rem}

.dark-layout .kanban-application .kanban-wrapper .kanban-container .kanban-board .kanban-board-header .kanban-title-board{color:#fff}

.dark-layout .kanban-application .kanban-wrapper .kanban-container .kanban-board .kanban-board-header .kanban-title-board:hover,.dark-layout .kanban-application .kanban-wrapper .kanban-container .kanban-board .kanban-board-header .kanban-title-board:focus{background-color:#283046}

.dark-layout .kanban-application .kanban-wrapper .kanban-container .kanban-board .kanban-item{background-color:#283046}

.dark-layout .kanban-application .kanban-wrapper .kanban-container .kanban-board .kanban-item .item-dropdown i.dropdown-toggle,.dark-layout .kanban-application .kanban-wrapper .kanban-container .kanban-board .kanban-item .item-dropdown svg.dropdown-toggle{stroke:#fff}

.dark-layout .kanban-application .kanban-wrapper .kanban-container .kanban-board .kanban-item .kanban-title-button{color:#fff}

.dark-layout .kanban-application .kanban-wrapper .kanban-container .kanban-board .kanban-item i,.dark-layout .kanban-application .kanban-wrapper .kanban-container .kanban-board .kanban-item svg{stroke:#fff}

.dark-layout .kanban-application .update-item-sidebar .nav-tabs .nav-item .nav-link,.dark-layout .kanban-application .update-item-sidebar .tab-content .tab-pane{background-color:transparent}

.dark-layout .kanban-item.gu-mirror{background-color:#283046}

.dark-layout .todo-application .content-area-wrapper .sidebar .todo-form .todo-item-action{color:#fff}

.dark-layout .todo-application .content-area-wrapper .content-right .todo-task-list-wrapper{background-color:#283046 !important;border-color:#3b4253}

.dark-layout .todo-application .content-area-wrapper .content-right .todo-task-list .todo-item:not(:first-child){border-color:#3b4253}

.dark-layout .todo-application .content-area-wrapper .content-right .todo-task-list .todo-item.completed .todo-title{color:#676d7d}

.dark-layout .todo-application .content-area-wrapper .content-right .todo-task-list .todo-item:hover{box-shadow:0 4px 24px 0 rgba(0,0,0,.24)}

.dark-layout .todo-application .content-area-wrapper .content-right .todo-task-list .todo-item .todo-item-action .todo-item-favorite:not(.text-warning) i,.dark-layout .todo-application .content-area-wrapper .content-right .todo-task-list .todo-item .todo-item-action .todo-item-favorite:not(.text-warning) svg{color:#fff}

.dark-layout .todo-application .todo-item-action .close{background-color:transparent !important}

.dark-layout .todo-item.gu-mirror{background-color:#283046;border-color:#3b4253;box-shadow:0 4px 24px 0 rgba(0,0,0,.24)}

.dark-layout .todo-item.gu-mirror.completed .todo-title{color:#676d7d}

.dark-layout .app-user-view .plan-card{border-color:#a94cd8 !important}

.dark-layout .app-user-edit .form-control.error{border-color:#ea5455 !important}

.dark-layout .footer-fixed .footer{background-color:#283046}

.dark-layout .horizontal-layout.navbar-sticky .horizontal-menu-wrapper .navbar-horizontal.header-navbar.fixed-top{box-shadow:0 4px 24px 0 rgba(34,41,47,.75) !important}

.dark-layout .horizontal-layout .horizontal-menu-wrapper{background:linear-gradient(to bottom, rgba(37, 43, 71, 0.76) 44%, rgba(56, 53, 53, 0.46) 73%, rgba(255, 255, 255, 0) 100%) !important}

.dark-layout .horizontal-layout .horizontal-menu-wrapper .header-navbar{background:#283046 !important}

.dark-layout .horizontal-layout .horizontal-menu-wrapper .header-navbar.navbar-shadow{box-shadow:0 4px 24px 0 rgba(34,41,47,.75)}

.dark-layout .horizontal-layout .horizontal-menu-wrapper .header-navbar.navbar-horizontal ul#main-menu-navigation>li:hover:not(.active)>a{background:#161d31}

.dark-layout .horizontal-layout .horizontal-menu-wrapper .header-navbar.navbar-horizontal .active .nav-link.dropdown-toggle::after{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23fff%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-down%27%3E%3Cpolyline points=%276 9 12 15 18 9%27%3E%3C/polyline%3E%3C/svg%3E")}

.dark-layout .horizontal-layout .horizontal-menu-wrapper .header-navbar.navbar-horizontal .nav-link.dropdown-toggle::after{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23fff%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-down%27%3E%3Cpolyline points=%276 9 12 15 18 9%27%3E%3C/polyline%3E%3C/svg%3E")}

.dark-layout .horizontal-layout .main-menu-content .navbar-nav .dropdown-submenu.show{background-color:#283046 !important;color:#fff}

.dark-layout .horizontal-layout .main-menu-content .navbar-nav .dropdown-menu a:hover{color:#fff !important}

.dark-layout .horizontal-layout .main-menu-content .navbar-nav .dropdown-menu .disabled a{color:#676d7d}

.dark-layout .horizontal-layout .main-menu-content .navbar-nav .dropdown-menu .dropdown-item{color:#fff}

.dark-layout .horizontal-layout .main-menu-content .navbar-nav .dropdown-menu .dropdown-toggle::after{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23fff%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-right%27%3E%3Cpolyline points=%279 18 15 12 9 6%27%3E%3C/polyline%3E%3C/svg%3E")}

.dark-layout .horizontal-layout .main-menu-content .navbar-nav .dropdown-menu .dropdown-toggle:hover::after,.dark-layout .horizontal-layout .main-menu-content .navbar-nav .dropdown-menu .dropdown-toggle:active::after{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23fff%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-right%27%3E%3Cpolyline points=%279 18 15 12 9 6%27%3E%3C/polyline%3E%3C/svg%3E")}

.dark-layout .horizontal-layout .main-menu-content .navbar-nav>li.active>a>i,.dark-layout .horizontal-layout .main-menu-content .navbar-nav>li.active>a>svg{color:#fff !important}

.dark-layout .horizontal-layout .main-menu-content .navbar-nav>li.active .dropdown-menu li.active>a{background:#161d31 !important;color:#fff}

.dark-layout .horizontal-layout .main-menu-content .navbar-nav>li.active .dropdown-menu li.active>a:hover{color:#a94cd8 !important}

.dark-layout .horizontal-layout .main-menu-content .navbar-nav>li.active .dropdown-menu li.open.active>a{color:#fff !important}

.semi-dark-layout .main-menu{background-color:#283046}

[dir="ltr"] .semi-dark-layout .main-menu .shadow-bottom{background:linear-gradient(180deg, #283046 44%, rgba(40, 48, 70, 0.51) 73%, rgba(40, 48, 70, 0))}

[dir="rtl"] .semi-dark-layout .main-menu .shadow-bottom{background:linear-gradient(-180deg, #283046 44%, rgba(40, 48, 70, 0.51) 73%, rgba(40, 48, 70, 0))}

.semi-dark-layout .main-menu:not(.expanded) .navigation .sidebar-group-active a{background-color:#161d31}

.semi-dark-layout .main-menu-content .navigation-main{background-color:#283046}

.semi-dark-layout .main-menu-content .navigation-main .navigation-header{color:#676d7d}

.semi-dark-layout .main-menu-content .navigation-main .nav-item a{color:#fff}

.semi-dark-layout .main-menu-content .navigation-main .nav-item.open a{background-color:#161d31}

.semi-dark-layout .main-menu-content .navigation-main .nav-item a:after,.semi-dark-layout .main-menu-content .navigation-main .nav-item a{color:#fff}

.semi-dark-layout .main-menu-content .navigation-main .nav-item .menu-content{background-color:#283046}

.semi-dark-layout .main-menu-content .navigation-main .nav-item .menu-content .active .menu-item{color:#fff}

.semi-dark-layout .main-menu-content .navigation-main .nav-item .menu-content li:not(.active) a{background-color:#283046}

.semi-dark-layout .main-menu-content .navigation-main .active .menu-title,.semi-dark-layout .main-menu-content .navigation-main .active i{color:#fff}

.semi-dark-layout .main-menu-content .navigation-main .sidebar-group-active a{background:#283046;border-radius:4px}

.semi-dark-layout .main-menu-content .navigation-main .sidebar-group-active .menu-content{background-color:#283046}

.semi-dark-layout .main-menu-content .navigation-main .sidebar-group-active .menu-content .active{z-index:1}

.semi-dark-layout .main-menu-content .navigation-main .sidebar-group-active .menu-content .active a{background-color:transparent}

.bv-no-focus-ring:focus{outline:none}

@media(max-width: 575.98px){
.bv-d-xs-down-none{display:none !important}}

@media(max-width: 767.98px){
.bv-d-sm-down-none{display:none !important}}

@media(max-width: 991.98px){
.bv-d-md-down-none{display:none !important}}

@media(max-width: 1199.98px){
.bv-d-lg-down-none{display:none !important}}

.bv-d-xl-down-none{display:none !important}

.form-control.focus{color:#6e6b7b;background-color:#fff;border-color:#a94cd8;outline:0;box-shadow:0 3px 10px 0 rgba(34,41,47,.1)}

.form-control.focus.is-valid{border-color:#28c76f;box-shadow:0 0 0 .2rem rgba(40,199,111,.25)}

.form-control.focus.is-invalid{border-color:#ea5455;box-shadow:0 0 0 .2rem rgba(234,84,85,.25)}

.b-avatar{display:inline-flex;align-items:center;justify-content:center;vertical-align:middle;flex-shrink:0;width:2.5rem;height:2.5rem;font-size:inherit;font-weight:400;line-height:1;max-width:100%;max-height:auto;text-align:center;overflow:visible;position:relative;transition:color .15s ease-in-out,background-color .15s ease-in-out,box-shadow .15s ease-in-out}

.b-avatar:focus{outline:0}

.b-avatar.btn,.b-avatar[href]{padding:0;border:0}

.b-avatar.btn .b-avatar-img img,.b-avatar[href] .b-avatar-img img{transition:transform .15s ease-in-out}

.b-avatar.btn:not(:disabled):not(.disabled),.b-avatar[href]:not(:disabled):not(.disabled){cursor:pointer}

.b-avatar.btn:not(:disabled):not(.disabled):hover .b-avatar-img img,.b-avatar[href]:not(:disabled):not(.disabled):hover .b-avatar-img img{transform:scale(1.15)}

.b-avatar.disabled,.b-avatar:disabled,.b-avatar[disabled]{opacity:.65;pointer-events:none}

.b-avatar .b-avatar-custom,.b-avatar .b-avatar-text,.b-avatar .b-avatar-img{border-radius:inherit;width:100%;height:100%;overflow:hidden;display:flex;justify-content:center;align-items:center;mask-image:radial-gradient(white, black)}

.b-avatar .b-avatar-text{text-transform:uppercase;white-space:nowrap}

.b-avatar[href]{text-decoration:none}

.b-avatar>.b-icon{width:60%;height:auto;max-width:100%}

.b-avatar .b-avatar-img img{width:100%;height:100%;max-height:auto;border-radius:inherit;object-fit:cover}

.b-avatar .b-avatar-badge{position:absolute;min-height:1.5em;min-width:1.5em;padding:.25em;line-height:1;border-radius:10em;font-size:70%;font-weight:700;z-index:1}

.b-avatar-sm{width:1.5rem;height:1.5rem}

.b-avatar-sm .b-avatar-text{font-size:calc(0.6rem)}

.b-avatar-sm .b-avatar-badge{font-size:calc(0.42rem)}

.b-avatar-lg{width:3.5rem;height:3.5rem}

.b-avatar-lg .b-avatar-text{font-size:calc(1.4rem)}

.b-avatar-lg .b-avatar-badge{font-size:calc(0.98rem)}

.b-avatar-group .b-avatar-group-inner{display:flex;flex-wrap:wrap}

.b-avatar-group .b-avatar{border:1px solid #ebe9f1}

.b-avatar-group a.b-avatar:hover:not(.disabled):not(disabled),.b-avatar-group .btn.b-avatar:hover:not(.disabled):not(disabled){z-index:1}

.b-calendar{display:inline-flex}

.b-calendar .b-calendar-inner{min-width:250px}

.b-calendar .b-calendar-header,.b-calendar .b-calendar-nav{margin-bottom:.25rem}

.b-calendar .b-calendar-nav .btn{padding:.25rem}

.b-calendar output{padding:.25rem;font-size:80%}

.b-calendar output.readonly{background-color:#efefef;opacity:1}

.b-calendar .b-calendar-footer{margin-top:.5rem}

.b-calendar .b-calendar-grid{padding:0;margin:0;overflow:hidden}

.b-calendar .b-calendar-grid .row{flex-wrap:nowrap}

.b-calendar .b-calendar-grid-caption{padding:.25rem}

.b-calendar .b-calendar-grid-body .col[data-date] .btn{width:32px;height:32px;font-size:14px;line-height:1;margin:3px auto;padding:9px 0}

.b-calendar .btn:disabled,.b-calendar .btn.disabled,.b-calendar .btn[aria-disabled=true]{cursor:default;pointer-events:none}

[dir="ltr"] .card-img-left{border-top-left-radius:calc(1.2rem - 1px);border-bottom-left-radius:calc(1.2rem - 1px)}

[dir="rtl"] .card-img-left{border-top-right-radius:calc(1.2rem - 1px);border-bottom-right-radius:calc(1.2rem - 1px)}

[dir="ltr"] .card-img-right{border-top-right-radius:calc(1.2rem - 1px);border-bottom-right-radius:calc(1.2rem - 1px)}

[dir="rtl"] .card-img-right{border-top-left-radius:calc(1.2rem - 1px);border-bottom-left-radius:calc(1.2rem - 1px)}

.dropdown:not(.dropleft) .dropdown-toggle.dropdown-toggle-no-caret::after{display:none !important}

.dropdown.dropleft .dropdown-toggle.dropdown-toggle-no-caret::before{display:none !important}

.dropdown .dropdown-menu:focus{outline:none}

.b-dropdown-form{display:inline-block;padding:.65rem 1.28rem;width:100%;clear:both;font-weight:400}

.b-dropdown-form:focus{outline:1px dotted !important;outline:5px auto -webkit-focus-ring-color !important}

.b-dropdown-form.disabled,.b-dropdown-form:disabled{outline:0 !important;color:#b9b9c3;pointer-events:none}

.b-dropdown-text{display:inline-block;padding:.65rem 1.28rem;margin-bottom:0;width:100%;clear:both;font-weight:lighter}

.custom-checkbox.b-custom-control-lg,.input-group-lg .custom-checkbox{font-size:2rem;line-height:1.25}

[dir="ltr"] .custom-checkbox.b-custom-control-lg,[dir="ltr"] .input-group-lg .custom-checkbox{padding-left:1.875rem}

[dir="rtl"] .custom-checkbox.b-custom-control-lg,[dir="rtl"] .input-group-lg .custom-checkbox{padding-right:1.875rem}

.custom-checkbox.b-custom-control-lg .custom-control-label::before,.input-group-lg .custom-checkbox .custom-control-label::before{top:.625rem;width:1.25rem;height:1.25rem;border-radius:.6rem}

[dir="ltr"] .custom-checkbox.b-custom-control-lg .custom-control-label::before,[dir="ltr"] .input-group-lg .custom-checkbox .custom-control-label::before{left:-1.875rem}

[dir="rtl"] .custom-checkbox.b-custom-control-lg .custom-control-label::before,[dir="rtl"] .input-group-lg .custom-checkbox .custom-control-label::before{right:-1.875rem}

.custom-checkbox.b-custom-control-lg .custom-control-label::after,.input-group-lg .custom-checkbox .custom-control-label::after{top:.625rem;width:1.25rem;height:1.25rem;background-size:50% 50%}

[dir="ltr"] .custom-checkbox.b-custom-control-lg .custom-control-label::after,[dir="ltr"] .input-group-lg .custom-checkbox .custom-control-label::after{left:-1.875rem}

[dir="rtl"] .custom-checkbox.b-custom-control-lg .custom-control-label::after,[dir="rtl"] .input-group-lg .custom-checkbox .custom-control-label::after{right:-1.875rem}

.custom-checkbox.b-custom-control-sm,.input-group-sm .custom-checkbox{font-size:1rem;line-height:1}

[dir="ltr"] .custom-checkbox.b-custom-control-sm,[dir="ltr"] .input-group-sm .custom-checkbox{padding-left:1.3125rem}

[dir="rtl"] .custom-checkbox.b-custom-control-sm,[dir="rtl"] .input-group-sm .custom-checkbox{padding-right:1.3125rem}

.custom-checkbox.b-custom-control-sm .custom-control-label::before,.input-group-sm .custom-checkbox .custom-control-label::before{top:.0625rem;width:.875rem;height:.875rem;border-radius:.25rem}

[dir="ltr"] .custom-checkbox.b-custom-control-sm .custom-control-label::before,[dir="ltr"] .input-group-sm .custom-checkbox .custom-control-label::before{left:-1.3125rem}

[dir="rtl"] .custom-checkbox.b-custom-control-sm .custom-control-label::before,[dir="rtl"] .input-group-sm .custom-checkbox .custom-control-label::before{right:-1.3125rem}

.custom-checkbox.b-custom-control-sm .custom-control-label::after,.input-group-sm .custom-checkbox .custom-control-label::after{top:.0625rem;width:.875rem;height:.875rem;background-size:50% 50%}

[dir="ltr"] .custom-checkbox.b-custom-control-sm .custom-control-label::after,[dir="ltr"] .input-group-sm .custom-checkbox .custom-control-label::after{left:-1.3125rem}

[dir="rtl"] .custom-checkbox.b-custom-control-sm .custom-control-label::after,[dir="rtl"] .input-group-sm .custom-checkbox .custom-control-label::after{right:-1.3125rem}

[dir="ltr"] .custom-switch.b-custom-control-lg,[dir="ltr"] .input-group-lg .custom-switch{padding-left:2.8125rem}

[dir="rtl"] .custom-switch.b-custom-control-lg,[dir="rtl"] .input-group-lg .custom-switch{padding-right:2.8125rem}

.custom-switch.b-custom-control-lg .custom-control-label,.input-group-lg .custom-switch .custom-control-label{font-size:2rem;line-height:1.25}

.custom-switch.b-custom-control-lg .custom-control-label::before,.input-group-lg .custom-switch .custom-control-label::before{top:.625rem;height:1.25rem;width:2.1875rem;border-radius:.625rem}

[dir="ltr"] .custom-switch.b-custom-control-lg .custom-control-label::before,[dir="ltr"] .input-group-lg .custom-switch .custom-control-label::before{left:-2.8125rem}

[dir="rtl"] .custom-switch.b-custom-control-lg .custom-control-label::before,[dir="rtl"] .input-group-lg .custom-switch .custom-control-label::before{right:-2.8125rem}

.custom-switch.b-custom-control-lg .custom-control-label::after,.input-group-lg .custom-switch .custom-control-label::after{top:calc( 0.625rem + 2px );width:calc( 1.25rem - 4px );height:calc( 1.25rem - 4px );border-radius:.625rem;background-size:50% 50%}

[dir="ltr"] .custom-switch.b-custom-control-lg .custom-control-label::after,[dir="ltr"] .input-group-lg .custom-switch .custom-control-label::after{left:calc( -2.8125rem + 2px )}

[dir="rtl"] .custom-switch.b-custom-control-lg .custom-control-label::after,[dir="rtl"] .input-group-lg .custom-switch .custom-control-label::after{right:calc( -2.8125rem + 2px )}

[dir="ltr"] .custom-switch.b-custom-control-lg .custom-control-input:checked~.custom-control-label::after,[dir="ltr"] .input-group-lg .custom-switch .custom-control-input:checked~.custom-control-label::after{transform:translateX(0.9375rem)}

[dir="rtl"] .custom-switch.b-custom-control-lg .custom-control-input:checked~.custom-control-label::after,[dir="rtl"] .input-group-lg .custom-switch .custom-control-input:checked~.custom-control-label::after{transform:translateX(-0.9375rem)}

[dir="ltr"] .custom-switch.b-custom-control-sm,[dir="ltr"] .input-group-sm .custom-switch{padding-left:1.96875rem}

[dir="rtl"] .custom-switch.b-custom-control-sm,[dir="rtl"] .input-group-sm .custom-switch{padding-right:1.96875rem}

.custom-switch.b-custom-control-sm .custom-control-label,.input-group-sm .custom-switch .custom-control-label{font-size:1rem;line-height:1}

.custom-switch.b-custom-control-sm .custom-control-label::before,.input-group-sm .custom-switch .custom-control-label::before{top:.0625rem;width:1.53125rem;height:.875rem;border-radius:.4375rem}

[dir="ltr"] .custom-switch.b-custom-control-sm .custom-control-label::before,[dir="ltr"] .input-group-sm .custom-switch .custom-control-label::before{left:-1.96875rem}

[dir="rtl"] .custom-switch.b-custom-control-sm .custom-control-label::before,[dir="rtl"] .input-group-sm .custom-switch .custom-control-label::before{right:-1.96875rem}

.custom-switch.b-custom-control-sm .custom-control-label::after,.input-group-sm .custom-switch .custom-control-label::after{top:calc( 0.0625rem + 2px );width:calc( 0.875rem - 4px );height:calc( 0.875rem - 4px );border-radius:.4375rem;background-size:50% 50%}

[dir="ltr"] .custom-switch.b-custom-control-sm .custom-control-label::after,[dir="ltr"] .input-group-sm .custom-switch .custom-control-label::after{left:calc( -1.96875rem + 2px )}

[dir="rtl"] .custom-switch.b-custom-control-sm .custom-control-label::after,[dir="rtl"] .input-group-sm .custom-switch .custom-control-label::after{right:calc( -1.96875rem + 2px )}

[dir="ltr"] .custom-switch.b-custom-control-sm .custom-control-input:checked~.custom-control-label::after,[dir="ltr"] .input-group-sm .custom-switch .custom-control-input:checked~.custom-control-label::after{transform:translateX(0.65625rem)}

[dir="rtl"] .custom-switch.b-custom-control-sm .custom-control-input:checked~.custom-control-label::after,[dir="rtl"] .input-group-sm .custom-switch .custom-control-input:checked~.custom-control-label::after{transform:translateX(-0.65625rem)}

[dir="ltr"] .input-group>.input-group-prepend>.btn-group>.btn,[dir="ltr"] .input-group>.input-group-append:not(:last-child)>.btn-group>.btn,[dir="ltr"] .input-group>.input-group-append:last-child>.btn-group:not(:last-child):not(.dropdown-toggle)>.btn{border-top-right-radius:0;border-bottom-right-radius:0}

[dir="rtl"] .input-group>.input-group-prepend>.btn-group>.btn,[dir="rtl"] .input-group>.input-group-append:not(:last-child)>.btn-group>.btn,[dir="rtl"] .input-group>.input-group-append:last-child>.btn-group:not(:last-child):not(.dropdown-toggle)>.btn{border-top-left-radius:0;border-bottom-left-radius:0}

[dir="ltr"] .input-group>.input-group-append>.btn-group>.btn,[dir="ltr"] .input-group>.input-group-prepend:not(:first-child)>.btn-group>.btn,[dir="ltr"] .input-group>.input-group-prepend:first-child>.btn-group:not(:first-child)>.btn{border-top-left-radius:0;border-bottom-left-radius:0}

[dir="rtl"] .input-group>.input-group-append>.btn-group>.btn,[dir="rtl"] .input-group>.input-group-prepend:not(:first-child)>.btn-group>.btn,[dir="rtl"] .input-group>.input-group-prepend:first-child>.btn-group:not(:first-child)>.btn{border-top-right-radius:0;border-bottom-right-radius:0}

.b-form-btn-label-control.form-control{display:flex;align-items:stretch;height:auto;padding:0;background-image:none}

.input-group .b-form-btn-label-control.form-control{padding:0}

[dir=rtl] .b-form-btn-label-control.form-control,.b-form-btn-label-control.form-control[dir=rtl]{flex-direction:row-reverse}

[dir="ltr"] [dir=rtl] .b-form-btn-label-control.form-control>label,[dir="ltr"] .b-form-btn-label-control.form-control[dir=rtl]>label{text-align:right}

[dir="rtl"] [dir=rtl] .b-form-btn-label-control.form-control>label,[dir="rtl"] .b-form-btn-label-control.form-control[dir=rtl]>label{text-align:left}

.b-form-btn-label-control.form-control>.btn{line-height:1;font-size:inherit;box-shadow:none !important;border:0}

.b-form-btn-label-control.form-control>.btn:disabled{pointer-events:none}

.b-form-btn-label-control.form-control.is-valid>.btn{color:#28c76f}

.b-form-btn-label-control.form-control.is-invalid>.btn{color:#ea5455}

.b-form-btn-label-control.form-control>.dropdown-menu{padding:.5rem}

.b-form-btn-label-control.form-control>.form-control{height:auto;min-height:calc(2.714rem - 2px);margin:0;border:0;outline:0;background:transparent;word-break:break-word;font-size:inherit;white-space:normal;cursor:pointer}

[dir="ltr"] .b-form-btn-label-control.form-control>.form-control{padding-left:.25rem}

[dir="rtl"] .b-form-btn-label-control.form-control>.form-control{padding-right:.25rem}

.b-form-btn-label-control.form-control>.form-control.form-control-sm{min-height:calc(2.142rem - 2px)}

.b-form-btn-label-control.form-control>.form-control.form-control-lg{min-height:calc(3.2857rem - 2px)}

.input-group.input-group-sm .b-form-btn-label-control.form-control>.form-control{min-height:calc(2.142rem - 2px);padding-top:.188rem;padding-bottom:.188rem}

.input-group.input-group-lg .b-form-btn-label-control.form-control>.form-control{min-height:calc(3.2857rem - 2px);padding-top:.75rem;padding-bottom:.75rem}

.b-form-btn-label-control.form-control[aria-disabled=true],.b-form-btn-label-control.form-control[aria-readonly=true]{background-color:#efefef;opacity:1}

.b-form-btn-label-control.form-control[aria-disabled=true]{pointer-events:none}

.b-form-btn-label-control.form-control[aria-disabled=true]>label{cursor:default}

.b-form-btn-label-control.btn-group>.dropdown-menu{padding:.5rem}

.custom-file-label{white-space:nowrap;overflow-x:hidden}

.b-custom-control-lg.custom-file,.b-custom-control-lg .custom-file-input,.b-custom-control-lg .custom-file-label,.input-group-lg.custom-file,.input-group-lg .custom-file-input,.input-group-lg .custom-file-label{font-size:1.143rem;height:3.2857rem}

.b-custom-control-lg .custom-file-label,.b-custom-control-lg .custom-file-label:after,.input-group-lg .custom-file-label,.input-group-lg .custom-file-label:after{padding:.75rem 1.143rem;line-height:1.25}

.b-custom-control-lg .custom-file-label,.input-group-lg .custom-file-label{border-radius:.357rem}

.b-custom-control-lg .custom-file-label::after,.input-group-lg .custom-file-label::after{font-size:inherit;height:calc( 1.25em + 1.5rem )}

[dir="ltr"] .b-custom-control-lg .custom-file-label::after,[dir="ltr"] .input-group-lg .custom-file-label::after{border-radius:0 .357rem .357rem 0}

[dir="rtl"] .b-custom-control-lg .custom-file-label::after,[dir="rtl"] .input-group-lg .custom-file-label::after{border-radius:.357rem 0 0 .357rem}

.b-custom-control-sm.custom-file,.b-custom-control-sm .custom-file-input,.b-custom-control-sm .custom-file-label,.input-group-sm.custom-file,.input-group-sm .custom-file-input,.input-group-sm .custom-file-label{font-size:.857rem;height:2.142rem}

.b-custom-control-sm .custom-file-label,.b-custom-control-sm .custom-file-label:after,.input-group-sm .custom-file-label,.input-group-sm .custom-file-label:after{padding:.188rem .857rem;line-height:1}

.b-custom-control-sm .custom-file-label,.input-group-sm .custom-file-label{border-radius:.357rem}

.b-custom-control-sm .custom-file-label::after,.input-group-sm .custom-file-label::after{font-size:inherit;height:calc( 1em + 0.376rem )}

[dir="ltr"] .b-custom-control-sm .custom-file-label::after,[dir="ltr"] .input-group-sm .custom-file-label::after{border-radius:0 .357rem .357rem 0}

[dir="rtl"] .b-custom-control-sm .custom-file-label::after,[dir="rtl"] .input-group-sm .custom-file-label::after{border-radius:.357rem 0 0 .357rem}

[dir="ltr"] .was-validated .form-control:invalid,[dir="ltr"] .was-validated .form-control:valid,[dir="ltr"] .form-control.is-invalid,[dir="ltr"] .form-control.is-valid{background-position:right calc(0.3625em + 0.219rem) center}

[dir="rtl"] .was-validated .form-control:invalid,[dir="rtl"] .was-validated .form-control:valid,[dir="rtl"] .form-control.is-invalid,[dir="rtl"] .form-control.is-valid{background-position:left calc(0.3625em + 0.219rem) center}

input[type=color].form-control{height:2.714rem;padding:.094rem .4285rem}

input[type=color].form-control.form-control-sm,.input-group-sm input[type=color].form-control{height:2.142rem;padding:.094rem .4285rem}

input[type=color].form-control.form-control-lg,.input-group-lg input[type=color].form-control{height:3.2857rem;padding:.094rem .4285rem}

input[type=color].form-control:disabled{background-color:#adb5bd;opacity:.65}

.input-group>.custom-range{position:relative;flex:1 1 auto;width:1%;margin-bottom:0}

[dir="ltr"] .input-group>.custom-range+.form-control,[dir="ltr"] .input-group>.custom-range+.form-control-plaintext,[dir="ltr"] .input-group>.custom-range+.custom-select,[dir="ltr"] .input-group>.custom-range+.custom-range,[dir="ltr"] .input-group>.custom-range+.custom-file{margin-left:-1px}

[dir="rtl"] .input-group>.custom-range+.form-control,[dir="rtl"] .input-group>.custom-range+.form-control-plaintext,[dir="rtl"] .input-group>.custom-range+.custom-select,[dir="rtl"] .input-group>.custom-range+.custom-range,[dir="rtl"] .input-group>.custom-range+.custom-file{margin-right:-1px}

[dir="ltr"] .input-group>.form-control+.custom-range,[dir="ltr"] .input-group>.form-control-plaintext+.custom-range,[dir="ltr"] .input-group>.custom-select+.custom-range,[dir="ltr"] .input-group>.custom-range+.custom-range,[dir="ltr"] .input-group>.custom-file+.custom-range{margin-left:-1px}

[dir="rtl"] .input-group>.form-control+.custom-range,[dir="rtl"] .input-group>.form-control-plaintext+.custom-range,[dir="rtl"] .input-group>.custom-select+.custom-range,[dir="rtl"] .input-group>.custom-range+.custom-range,[dir="rtl"] .input-group>.custom-file+.custom-range{margin-right:-1px}

.input-group>.custom-range:focus{z-index:3}

[dir="ltr"] .input-group>.custom-range:not(:last-child){border-top-right-radius:0;border-bottom-right-radius:0}

[dir="rtl"] .input-group>.custom-range:not(:last-child){border-top-left-radius:0;border-bottom-left-radius:0}

[dir="ltr"] .input-group>.custom-range:not(:first-child){border-top-left-radius:0;border-bottom-left-radius:0}

[dir="rtl"] .input-group>.custom-range:not(:first-child){border-top-right-radius:0;border-bottom-right-radius:0}

.input-group>.custom-range{height:2.714rem;padding:0 1rem;background-color:#fff;background-clip:padding-box;border:1px solid #d8d6de;height:2.714rem;border-radius:.357rem;transition:border-color .15s ease-in-out,box-shadow .15s ease-in-out}

@media(prefers-reduced-motion: reduce){
.input-group>.custom-range{transition:none}}

.input-group>.custom-range:focus{color:#6e6b7b;background-color:#fff;border-color:#a94cd8;outline:0;box-shadow:0 3px 10px 0 rgba(34,41,47,.1)}

.input-group>.custom-range:disabled,.input-group>.custom-range[readonly]{background-color:#efefef}

.input-group-lg>.custom-range{height:3.2857rem;padding:0 1.143rem;border-radius:.357rem}

.input-group-sm>.custom-range{height:2.142rem;padding:0 .857rem;border-radius:.357rem}

.was-validated .input-group .custom-range:valid,.input-group .custom-range.is-valid{border-color:#28c76f}

.was-validated .input-group .custom-range:valid:focus,.input-group .custom-range.is-valid:focus{border-color:#28c76f;box-shadow:0 0 0 .2rem rgba(40,199,111,.25)}

.was-validated .custom-range:valid:focus::-webkit-slider-thumb,.custom-range.is-valid:focus::-webkit-slider-thumb{box-shadow:0 0 0 1px #f6f6f6,0 0 0 .2rem #b2f0cd}

.was-validated .custom-range:valid:focus::-moz-range-thumb,.custom-range.is-valid:focus::-moz-range-thumb{box-shadow:0 0 0 1px #f6f6f6,0 0 0 .2rem #b2f0cd}

.was-validated .custom-range:valid:focus::-ms-thumb,.custom-range.is-valid:focus::-ms-thumb{box-shadow:0 0 0 1px #f6f6f6,0 0 0 .2rem #b2f0cd}

.was-validated .custom-range:valid::-webkit-slider-thumb,.custom-range.is-valid::-webkit-slider-thumb{background-color:#28c76f;background-image:none}

.was-validated .custom-range:valid::-webkit-slider-thumb:active,.custom-range.is-valid::-webkit-slider-thumb:active{background-color:#b2f0cd;background-image:none}

.was-validated .custom-range:valid::-webkit-slider-runnable-track,.custom-range.is-valid::-webkit-slider-runnable-track{background-color:rgba(40,199,111,.35)}

.was-validated .custom-range:valid::-moz-range-thumb,.custom-range.is-valid::-moz-range-thumb{background-color:#28c76f;background-image:none}

.was-validated .custom-range:valid::-moz-range-thumb:active,.custom-range.is-valid::-moz-range-thumb:active{background-color:#b2f0cd;background-image:none}

.was-validated .custom-range:valid::-moz-range-track,.custom-range.is-valid::-moz-range-track{background:rgba(40,199,111,.35)}

.was-validated .custom-range:valid~.valid-feedback,.was-validated .custom-range:valid~.valid-tooltip,.custom-range.is-valid~.valid-feedback,.custom-range.is-valid~.valid-tooltip{display:block}

.was-validated .custom-range:valid::-ms-thumb,.custom-range.is-valid::-ms-thumb{background-color:#28c76f;background-image:none}

.was-validated .custom-range:valid::-ms-thumb:active,.custom-range.is-valid::-ms-thumb:active{background-color:#b2f0cd;background-image:none}

.was-validated .custom-range:valid::-ms-track-lower,.custom-range.is-valid::-ms-track-lower{background:rgba(40,199,111,.35)}

.was-validated .custom-range:valid::-ms-track-upper,.custom-range.is-valid::-ms-track-upper{background:rgba(40,199,111,.35)}

.was-validated .input-group .custom-range:invalid,.input-group .custom-range.is-invalid{border-color:#ea5455}

.was-validated .input-group .custom-range:invalid:focus,.input-group .custom-range.is-invalid:focus{border-color:#ea5455;box-shadow:0 0 0 .2rem rgba(234,84,85,.25)}

.was-validated .custom-range:invalid:focus::-webkit-slider-thumb,.custom-range.is-invalid:focus::-webkit-slider-thumb{box-shadow:0 0 0 1px #f6f6f6,0 0 0 .2rem #fef3f3}

.was-validated .custom-range:invalid:focus::-moz-range-thumb,.custom-range.is-invalid:focus::-moz-range-thumb{box-shadow:0 0 0 1px #f6f6f6,0 0 0 .2rem #fef3f3}

.was-validated .custom-range:invalid:focus::-ms-thumb,.custom-range.is-invalid:focus::-ms-thumb{box-shadow:0 0 0 1px #f6f6f6,0 0 0 .2rem #fef3f3}

.was-validated .custom-range:invalid::-webkit-slider-thumb,.custom-range.is-invalid::-webkit-slider-thumb{background-color:#ea5455;background-image:none}

.was-validated .custom-range:invalid::-webkit-slider-thumb:active,.custom-range.is-invalid::-webkit-slider-thumb:active{background-color:#fef3f3;background-image:none}

.was-validated .custom-range:invalid::-webkit-slider-runnable-track,.custom-range.is-invalid::-webkit-slider-runnable-track{background-color:rgba(234,84,85,.35)}

.was-validated .custom-range:invalid::-moz-range-thumb,.custom-range.is-invalid::-moz-range-thumb{background-color:#ea5455;background-image:none}

.was-validated .custom-range:invalid::-moz-range-thumb:active,.custom-range.is-invalid::-moz-range-thumb:active{background-color:#fef3f3;background-image:none}

.was-validated .custom-range:invalid::-moz-range-track,.custom-range.is-invalid::-moz-range-track{background:rgba(234,84,85,.35)}

.was-validated .custom-range:invalid~.invalid-feedback,.was-validated .custom-range:invalid~.invalid-tooltip,.custom-range.is-invalid~.invalid-feedback,.custom-range.is-invalid~.invalid-tooltip{display:block}

.was-validated .custom-range:invalid::-ms-thumb,.custom-range.is-invalid::-ms-thumb{background-color:#ea5455;background-image:none}

.was-validated .custom-range:invalid::-ms-thumb:active,.custom-range.is-invalid::-ms-thumb:active{background-color:#fef3f3;background-image:none}

.was-validated .custom-range:invalid::-ms-track-lower,.custom-range.is-invalid::-ms-track-lower{background:rgba(234,84,85,.35)}

.was-validated .custom-range:invalid::-ms-track-upper,.custom-range.is-invalid::-ms-track-upper{background:rgba(234,84,85,.35)}

.custom-radio.b-custom-control-lg,.input-group-lg .custom-radio{font-size:2rem;line-height:1.25}

[dir="ltr"] .custom-radio.b-custom-control-lg,[dir="ltr"] .input-group-lg .custom-radio{padding-left:1.875rem}

[dir="rtl"] .custom-radio.b-custom-control-lg,[dir="rtl"] .input-group-lg .custom-radio{padding-right:1.875rem}

.custom-radio.b-custom-control-lg .custom-control-label::before,.input-group-lg .custom-radio .custom-control-label::before{top:.625rem;width:1.25rem;height:1.25rem;border-radius:50%}

[dir="ltr"] .custom-radio.b-custom-control-lg .custom-control-label::before,[dir="ltr"] .input-group-lg .custom-radio .custom-control-label::before{left:-1.875rem}

[dir="rtl"] .custom-radio.b-custom-control-lg .custom-control-label::before,[dir="rtl"] .input-group-lg .custom-radio .custom-control-label::before{right:-1.875rem}

.custom-radio.b-custom-control-lg .custom-control-label::after,.input-group-lg .custom-radio .custom-control-label::after{top:.625rem;width:1.25rem;height:1.25rem;background:no-repeat 50%/50% 50%}

[dir="ltr"] .custom-radio.b-custom-control-lg .custom-control-label::after,[dir="ltr"] .input-group-lg .custom-radio .custom-control-label::after{left:-1.875rem}

[dir="rtl"] .custom-radio.b-custom-control-lg .custom-control-label::after,[dir="rtl"] .input-group-lg .custom-radio .custom-control-label::after{right:-1.875rem}

.custom-radio.b-custom-control-sm,.input-group-sm .custom-radio{font-size:1rem;line-height:1}

[dir="ltr"] .custom-radio.b-custom-control-sm,[dir="ltr"] .input-group-sm .custom-radio{padding-left:1.3125rem}

[dir="rtl"] .custom-radio.b-custom-control-sm,[dir="rtl"] .input-group-sm .custom-radio{padding-right:1.3125rem}

.custom-radio.b-custom-control-sm .custom-control-label::before,.input-group-sm .custom-radio .custom-control-label::before{top:.0625rem;width:.875rem;height:.875rem;border-radius:50%}

[dir="ltr"] .custom-radio.b-custom-control-sm .custom-control-label::before,[dir="ltr"] .input-group-sm .custom-radio .custom-control-label::before{left:-1.3125rem}

[dir="rtl"] .custom-radio.b-custom-control-sm .custom-control-label::before,[dir="rtl"] .input-group-sm .custom-radio .custom-control-label::before{right:-1.3125rem}

.custom-radio.b-custom-control-sm .custom-control-label::after,.input-group-sm .custom-radio .custom-control-label::after{top:.0625rem;width:.875rem;height:.875rem;background:no-repeat 50%/50% 50%}

[dir="ltr"] .custom-radio.b-custom-control-sm .custom-control-label::after,[dir="ltr"] .input-group-sm .custom-radio .custom-control-label::after{left:-1.3125rem}

[dir="rtl"] .custom-radio.b-custom-control-sm .custom-control-label::after,[dir="rtl"] .input-group-sm .custom-radio .custom-control-label::after{right:-1.3125rem}

.b-rating{text-align:center}

.b-rating.d-inline-flex{width:auto}

.b-rating .b-rating-star,.b-rating .b-rating-value{padding:0 .25em}

.b-rating .b-rating-value{min-width:2.5em}

.b-rating .b-rating-star{display:inline-flex;justify-content:center;outline:0}

.b-rating .b-rating-star .b-rating-icon{display:inline-flex;transition:all .15s ease-in-out}

.b-rating.disabled,.b-rating:disabled{background-color:#efefef;color:#b9b9c3}

.b-rating:not(.disabled):not(.readonly) .b-rating-star{cursor:pointer}

.b-rating:not(.disabled):not(.readonly):focus:not(:hover) .b-rating-star.focused .b-rating-icon,.b-rating:not(.disabled):not(.readonly) .b-rating-star:hover .b-rating-icon{transform:scale(1.5)}

.b-rating[dir=rtl] .b-rating-star-half{transform:scale(-1, 1)}

.b-form-spinbutton{text-align:center;overflow:hidden;background-image:none;padding:0}

[dir=rtl] .b-form-spinbutton:not(.flex-column),.b-form-spinbutton[dir=rtl]:not(.flex-column){flex-direction:row-reverse}

.b-form-spinbutton output{font-size:inherit;outline:0;border:0;background-color:transparent;width:auto;margin:0;padding:0 .25rem}

.b-form-spinbutton output>div,.b-form-spinbutton output>bdi{display:block;min-width:2.25em;height:1.45em}

.b-form-spinbutton.flex-column{height:auto;width:auto}

.b-form-spinbutton.flex-column output{margin:0 .25rem;padding:.25rem 0}

.b-form-spinbutton:not(.d-inline-flex):not(.flex-column){output-width:100%}

.b-form-spinbutton.d-inline-flex:not(.flex-column){width:auto}

.b-form-spinbutton .btn{line-height:1;box-shadow:none !important}

.b-form-spinbutton .btn:disabled{pointer-events:none}

.b-form-spinbutton .btn:hover:not(:disabled)>div>.b-icon{transform:scale(1.25)}

.b-form-spinbutton.disabled,.b-form-spinbutton.readonly{background-color:#efefef}

.b-form-spinbutton.disabled{pointer-events:none}

.b-form-tags.focus{color:#6e6b7b;background-color:#fff;border-color:#a94cd8;outline:0;box-shadow:0 3px 10px 0 rgba(34,41,47,.1)}

.b-form-tags.focus.is-valid{border-color:#28c76f;box-shadow:0 0 0 .2rem rgba(40,199,111,.25)}

.b-form-tags.focus.is-invalid{border-color:#ea5455;box-shadow:0 0 0 .2rem rgba(234,84,85,.25)}

.b-form-tags.disabled{background-color:#efefef}

.b-form-tags-list{margin-top:-0.25rem}

.b-form-tags-list .b-form-tags-field,.b-form-tags-list .b-form-tag{margin-top:.25rem}

.b-form-tags-input{color:#6e6b7b}

.b-form-tag{font-size:75%;font-weight:normal;line-height:1.45}

[dir="ltr"] .b-form-tag{margin-right:.25rem}

[dir="rtl"] .b-form-tag{margin-left:.25rem}

.b-form-tag.disabled{opacity:.75}

.b-form-tag>button.b-form-tag-remove{color:inherit;font-size:125%;line-height:1;float:none}

[dir="ltr"] .b-form-tag>button.b-form-tag-remove{margin-left:.25rem}

[dir="rtl"] .b-form-tag>button.b-form-tag-remove{margin-right:.25rem}

.form-control-sm .b-form-tag{line-height:1}

.form-control-lg .b-form-tag{line-height:1.25}

.media-aside{display:flex}

[dir="ltr"] .media-aside{margin-right:1rem}

[dir="rtl"] .media-aside{margin-left:1rem}

[dir="ltr"] .media-aside-right{margin-right:0;margin-left:1rem}

[dir="rtl"] .media-aside-right{margin-left:0;margin-right:1rem}

.modal-backdrop{opacity:.5}

.b-pagination-pills .page-item .page-link{border-radius:50rem !important;line-height:1}

[dir="ltr"] .b-pagination-pills .page-item .page-link{margin-left:.25rem}

[dir="rtl"] .b-pagination-pills .page-item .page-link{margin-right:.25rem}

[dir="ltr"] .b-pagination-pills .page-item:first-child .page-link{margin-left:0}

[dir="rtl"] .b-pagination-pills .page-item:first-child .page-link{margin-right:0}

.popover.b-popover{display:block;opacity:1;outline:0}

.popover.b-popover.fade:not(.show){opacity:0}

.popover.b-popover.show{opacity:1}

.b-popover-primary.popover{background-color:#eedbf7;border-color:#e7cdf4}

.b-popover-primary.bs-popover-top>.arrow::before,.b-popover-primary.bs-popover-auto[x-placement^=top]>.arrow::before{border-top-color:#e7cdf4}

.b-popover-primary.bs-popover-top>.arrow::after,.b-popover-primary.bs-popover-auto[x-placement^=top]>.arrow::after{border-top-color:#eedbf7}

[dir="ltr"] .b-popover-primary.bs-popover-right>.arrow::before,[dir="ltr"] .b-popover-primary.bs-popover-auto[x-placement^=right]>.arrow::before{border-right-color:#e7cdf4}

[dir="rtl"] .b-popover-primary.bs-popover-right>.arrow::before,[dir="rtl"] .b-popover-primary.bs-popover-auto[x-placement^=right]>.arrow::before{border-left-color:#e7cdf4}

[dir="ltr"] .b-popover-primary.bs-popover-right>.arrow::after,[dir="ltr"] .b-popover-primary.bs-popover-auto[x-placement^=right]>.arrow::after{border-right-color:#eedbf7}

[dir="rtl"] .b-popover-primary.bs-popover-right>.arrow::after,[dir="rtl"] .b-popover-primary.bs-popover-auto[x-placement^=right]>.arrow::after{border-left-color:#eedbf7}

.b-popover-primary.bs-popover-bottom>.arrow::before,.b-popover-primary.bs-popover-auto[x-placement^=bottom]>.arrow::before{border-bottom-color:#e7cdf4}

.b-popover-primary.bs-popover-bottom>.arrow::after,.b-popover-primary.bs-popover-auto[x-placement^=bottom]>.arrow::after{border-bottom-color:#e8cef4}

.b-popover-primary.bs-popover-bottom .popover-header::before,.b-popover-primary.bs-popover-auto[x-placement^=bottom] .popover-header::before{border-bottom-color:#e8cef4}

[dir="ltr"] .b-popover-primary.bs-popover-left>.arrow::before,[dir="ltr"] .b-popover-primary.bs-popover-auto[x-placement^=left]>.arrow::before{border-left-color:#e7cdf4}

[dir="rtl"] .b-popover-primary.bs-popover-left>.arrow::before,[dir="rtl"] .b-popover-primary.bs-popover-auto[x-placement^=left]>.arrow::before{border-right-color:#e7cdf4}

[dir="ltr"] .b-popover-primary.bs-popover-left>.arrow::after,[dir="ltr"] .b-popover-primary.bs-popover-auto[x-placement^=left]>.arrow::after{border-left-color:#eedbf7}

[dir="rtl"] .b-popover-primary.bs-popover-left>.arrow::after,[dir="rtl"] .b-popover-primary.bs-popover-auto[x-placement^=left]>.arrow::after{border-right-color:#eedbf7}

.b-popover-primary .popover-header{color:#2a2e30;background-color:#e8cef4;border-bottom-color:#debaf0}

.b-popover-primary .popover-body{color:#683b87}

.b-popover-secondary.popover{background-color:#e6e7e8;border-color:#dcdddf}

.b-popover-secondary.bs-popover-top>.arrow::before,.b-popover-secondary.bs-popover-auto[x-placement^=top]>.arrow::before{border-top-color:#dcdddf}

.b-popover-secondary.bs-popover-top>.arrow::after,.b-popover-secondary.bs-popover-auto[x-placement^=top]>.arrow::after{border-top-color:#e6e7e8}

[dir="ltr"] .b-popover-secondary.bs-popover-right>.arrow::before,[dir="ltr"] .b-popover-secondary.bs-popover-auto[x-placement^=right]>.arrow::before{border-right-color:#dcdddf}

[dir="rtl"] .b-popover-secondary.bs-popover-right>.arrow::before,[dir="rtl"] .b-popover-secondary.bs-popover-auto[x-placement^=right]>.arrow::before{border-left-color:#dcdddf}

[dir="ltr"] .b-popover-secondary.bs-popover-right>.arrow::after,[dir="ltr"] .b-popover-secondary.bs-popover-auto[x-placement^=right]>.arrow::after{border-right-color:#e6e7e8}

[dir="rtl"] .b-popover-secondary.bs-popover-right>.arrow::after,[dir="rtl"] .b-popover-secondary.bs-popover-auto[x-placement^=right]>.arrow::after{border-left-color:#e6e7e8}

.b-popover-secondary.bs-popover-bottom>.arrow::before,.b-popover-secondary.bs-popover-auto[x-placement^=bottom]>.arrow::before{border-bottom-color:#dcdddf}

.b-popover-secondary.bs-popover-bottom>.arrow::after,.b-popover-secondary.bs-popover-auto[x-placement^=bottom]>.arrow::after{border-bottom-color:#dedfe1}

.b-popover-secondary.bs-popover-bottom .popover-header::before,.b-popover-secondary.bs-popover-auto[x-placement^=bottom] .popover-header::before{border-bottom-color:#dedfe1}

[dir="ltr"] .b-popover-secondary.bs-popover-left>.arrow::before,[dir="ltr"] .b-popover-secondary.bs-popover-auto[x-placement^=left]>.arrow::before{border-left-color:#dcdddf}

[dir="rtl"] .b-popover-secondary.bs-popover-left>.arrow::before,[dir="rtl"] .b-popover-secondary.bs-popover-auto[x-placement^=left]>.arrow::before{border-right-color:#dcdddf}

[dir="ltr"] .b-popover-secondary.bs-popover-left>.arrow::after,[dir="ltr"] .b-popover-secondary.bs-popover-auto[x-placement^=left]>.arrow::after{border-left-color:#e6e7e8}

[dir="rtl"] .b-popover-secondary.bs-popover-left>.arrow::after,[dir="rtl"] .b-popover-secondary.bs-popover-auto[x-placement^=left]>.arrow::after{border-right-color:#e6e7e8}

.b-popover-secondary .popover-header{color:#2a2e30;background-color:#dedfe1;border-bottom-color:#d1d3d4}

.b-popover-secondary .popover-body{color:#54595f}

.b-popover-success.popover{background-color:#d4f4e2;border-color:#c3efd7}

.b-popover-success.bs-popover-top>.arrow::before,.b-popover-success.bs-popover-auto[x-placement^=top]>.arrow::before{border-top-color:#c3efd7}

.b-popover-success.bs-popover-top>.arrow::after,.b-popover-success.bs-popover-auto[x-placement^=top]>.arrow::after{border-top-color:#d4f4e2}

[dir="ltr"] .b-popover-success.bs-popover-right>.arrow::before,[dir="ltr"] .b-popover-success.bs-popover-auto[x-placement^=right]>.arrow::before{border-right-color:#c3efd7}

[dir="rtl"] .b-popover-success.bs-popover-right>.arrow::before,[dir="rtl"] .b-popover-success.bs-popover-auto[x-placement^=right]>.arrow::before{border-left-color:#c3efd7}

[dir="ltr"] .b-popover-success.bs-popover-right>.arrow::after,[dir="ltr"] .b-popover-success.bs-popover-auto[x-placement^=right]>.arrow::after{border-right-color:#d4f4e2}

[dir="rtl"] .b-popover-success.bs-popover-right>.arrow::after,[dir="rtl"] .b-popover-success.bs-popover-auto[x-placement^=right]>.arrow::after{border-left-color:#d4f4e2}

.b-popover-success.bs-popover-bottom>.arrow::before,.b-popover-success.bs-popover-auto[x-placement^=bottom]>.arrow::before{border-bottom-color:#c3efd7}

.b-popover-success.bs-popover-bottom>.arrow::after,.b-popover-success.bs-popover-auto[x-placement^=bottom]>.arrow::after{border-bottom-color:#c8f1da}

.b-popover-success.bs-popover-bottom .popover-header::before,.b-popover-success.bs-popover-auto[x-placement^=bottom] .popover-header::before{border-bottom-color:#c8f1da}

[dir="ltr"] .b-popover-success.bs-popover-left>.arrow::before,[dir="ltr"] .b-popover-success.bs-popover-auto[x-placement^=left]>.arrow::before{border-left-color:#c3efd7}

[dir="rtl"] .b-popover-success.bs-popover-left>.arrow::before,[dir="rtl"] .b-popover-success.bs-popover-auto[x-placement^=left]>.arrow::before{border-right-color:#c3efd7}

[dir="ltr"] .b-popover-success.bs-popover-left>.arrow::after,[dir="ltr"] .b-popover-success.bs-popover-auto[x-placement^=left]>.arrow::after{border-left-color:#d4f4e2}

[dir="rtl"] .b-popover-success.bs-popover-left>.arrow::after,[dir="rtl"] .b-popover-success.bs-popover-auto[x-placement^=left]>.arrow::after{border-right-color:#d4f4e2}

.b-popover-success .popover-header{color:#2a2e30;background-color:#c8f1da;border-bottom-color:#b4eccc}

.b-popover-success .popover-body{color:#257b50}

.b-popover-info.popover{background-color:#ccf5fa;border-color:#b8f2f9}

.b-popover-info.bs-popover-top>.arrow::before,.b-popover-info.bs-popover-auto[x-placement^=top]>.arrow::before{border-top-color:#b8f2f9}

.b-popover-info.bs-popover-top>.arrow::after,.b-popover-info.bs-popover-auto[x-placement^=top]>.arrow::after{border-top-color:#ccf5fa}

[dir="ltr"] .b-popover-info.bs-popover-right>.arrow::before,[dir="ltr"] .b-popover-info.bs-popover-auto[x-placement^=right]>.arrow::before{border-right-color:#b8f2f9}

[dir="rtl"] .b-popover-info.bs-popover-right>.arrow::before,[dir="rtl"] .b-popover-info.bs-popover-auto[x-placement^=right]>.arrow::before{border-left-color:#b8f2f9}

[dir="ltr"] .b-popover-info.bs-popover-right>.arrow::after,[dir="ltr"] .b-popover-info.bs-popover-auto[x-placement^=right]>.arrow::after{border-right-color:#ccf5fa}

[dir="rtl"] .b-popover-info.bs-popover-right>.arrow::after,[dir="rtl"] .b-popover-info.bs-popover-auto[x-placement^=right]>.arrow::after{border-left-color:#ccf5fa}

.b-popover-info.bs-popover-bottom>.arrow::before,.b-popover-info.bs-popover-auto[x-placement^=bottom]>.arrow::before{border-bottom-color:#b8f2f9}

.b-popover-info.bs-popover-bottom>.arrow::after,.b-popover-info.bs-popover-auto[x-placement^=bottom]>.arrow::after{border-bottom-color:#bef2f9}

.b-popover-info.bs-popover-bottom .popover-header::before,.b-popover-info.bs-popover-auto[x-placement^=bottom] .popover-header::before{border-bottom-color:#bef2f9}

[dir="ltr"] .b-popover-info.bs-popover-left>.arrow::before,[dir="ltr"] .b-popover-info.bs-popover-auto[x-placement^=left]>.arrow::before{border-left-color:#b8f2f9}

[dir="rtl"] .b-popover-info.bs-popover-left>.arrow::before,[dir="rtl"] .b-popover-info.bs-popover-auto[x-placement^=left]>.arrow::before{border-right-color:#b8f2f9}

[dir="ltr"] .b-popover-info.bs-popover-left>.arrow::after,[dir="ltr"] .b-popover-info.bs-popover-auto[x-placement^=left]>.arrow::after{border-left-color:#ccf5fa}

[dir="rtl"] .b-popover-info.bs-popover-left>.arrow::after,[dir="rtl"] .b-popover-info.bs-popover-auto[x-placement^=left]>.arrow::after{border-right-color:#ccf5fa}

.b-popover-info .popover-header{color:#2a2e30;background-color:#bef2f9;border-bottom-color:#a7eef6}

.b-popover-info .popover-body{color:#107f8f}

.b-popover-warning.popover{background-color:#ffecd9;border-color:#ffe4ca}

.b-popover-warning.bs-popover-top>.arrow::before,.b-popover-warning.bs-popover-auto[x-placement^=top]>.arrow::before{border-top-color:#ffe4ca}

.b-popover-warning.bs-popover-top>.arrow::after,.b-popover-warning.bs-popover-auto[x-placement^=top]>.arrow::after{border-top-color:#ffecd9}

[dir="ltr"] .b-popover-warning.bs-popover-right>.arrow::before,[dir="ltr"] .b-popover-warning.bs-popover-auto[x-placement^=right]>.arrow::before{border-right-color:#ffe4ca}

[dir="rtl"] .b-popover-warning.bs-popover-right>.arrow::before,[dir="rtl"] .b-popover-warning.bs-popover-auto[x-placement^=right]>.arrow::before{border-left-color:#ffe4ca}

[dir="ltr"] .b-popover-warning.bs-popover-right>.arrow::after,[dir="ltr"] .b-popover-warning.bs-popover-auto[x-placement^=right]>.arrow::after{border-right-color:#ffecd9}

[dir="rtl"] .b-popover-warning.bs-popover-right>.arrow::after,[dir="rtl"] .b-popover-warning.bs-popover-auto[x-placement^=right]>.arrow::after{border-left-color:#ffecd9}

.b-popover-warning.bs-popover-bottom>.arrow::before,.b-popover-warning.bs-popover-auto[x-placement^=bottom]>.arrow::before{border-bottom-color:#ffe4ca}

.b-popover-warning.bs-popover-bottom>.arrow::after,.b-popover-warning.bs-popover-auto[x-placement^=bottom]>.arrow::after{border-bottom-color:#ffe4ca}

.b-popover-warning.bs-popover-bottom .popover-header::before,.b-popover-warning.bs-popover-auto[x-placement^=bottom] .popover-header::before{border-bottom-color:#ffe4ca}

[dir="ltr"] .b-popover-warning.bs-popover-left>.arrow::before,[dir="ltr"] .b-popover-warning.bs-popover-auto[x-placement^=left]>.arrow::before{border-left-color:#ffe4ca}

[dir="rtl"] .b-popover-warning.bs-popover-left>.arrow::before,[dir="rtl"] .b-popover-warning.bs-popover-auto[x-placement^=left]>.arrow::before{border-right-color:#ffe4ca}

[dir="ltr"] .b-popover-warning.bs-popover-left>.arrow::after,[dir="ltr"] .b-popover-warning.bs-popover-auto[x-placement^=left]>.arrow::after{border-left-color:#ffecd9}

[dir="rtl"] .b-popover-warning.bs-popover-left>.arrow::after,[dir="rtl"] .b-popover-warning.bs-popover-auto[x-placement^=left]>.arrow::after{border-right-color:#ffecd9}

.b-popover-warning .popover-header{color:#2a2e30;background-color:#ffe4ca;border-bottom-color:#ffd8b0}

.b-popover-warning .popover-body{color:#956639}

.b-popover-danger.popover{background-color:#fbdddd;border-color:#f9cfcf}

.b-popover-danger.bs-popover-top>.arrow::before,.b-popover-danger.bs-popover-auto[x-placement^=top]>.arrow::before{border-top-color:#f9cfcf}

.b-popover-danger.bs-popover-top>.arrow::after,.b-popover-danger.bs-popover-auto[x-placement^=top]>.arrow::after{border-top-color:#fbdddd}

[dir="ltr"] .b-popover-danger.bs-popover-right>.arrow::before,[dir="ltr"] .b-popover-danger.bs-popover-auto[x-placement^=right]>.arrow::before{border-right-color:#f9cfcf}

[dir="rtl"] .b-popover-danger.bs-popover-right>.arrow::before,[dir="rtl"] .b-popover-danger.bs-popover-auto[x-placement^=right]>.arrow::before{border-left-color:#f9cfcf}

[dir="ltr"] .b-popover-danger.bs-popover-right>.arrow::after,[dir="ltr"] .b-popover-danger.bs-popover-auto[x-placement^=right]>.arrow::after{border-right-color:#fbdddd}

[dir="rtl"] .b-popover-danger.bs-popover-right>.arrow::after,[dir="rtl"] .b-popover-danger.bs-popover-auto[x-placement^=right]>.arrow::after{border-left-color:#fbdddd}

.b-popover-danger.bs-popover-bottom>.arrow::before,.b-popover-danger.bs-popover-auto[x-placement^=bottom]>.arrow::before{border-bottom-color:#f9cfcf}

.b-popover-danger.bs-popover-bottom>.arrow::after,.b-popover-danger.bs-popover-auto[x-placement^=bottom]>.arrow::after{border-bottom-color:#f9cfcf}

.b-popover-danger.bs-popover-bottom .popover-header::before,.b-popover-danger.bs-popover-auto[x-placement^=bottom] .popover-header::before{border-bottom-color:#f9cfcf}

[dir="ltr"] .b-popover-danger.bs-popover-left>.arrow::before,[dir="ltr"] .b-popover-danger.bs-popover-auto[x-placement^=left]>.arrow::before{border-left-color:#f9cfcf}

[dir="rtl"] .b-popover-danger.bs-popover-left>.arrow::before,[dir="rtl"] .b-popover-danger.bs-popover-auto[x-placement^=left]>.arrow::before{border-right-color:#f9cfcf}

[dir="ltr"] .b-popover-danger.bs-popover-left>.arrow::after,[dir="ltr"] .b-popover-danger.bs-popover-auto[x-placement^=left]>.arrow::after{border-left-color:#fbdddd}

[dir="rtl"] .b-popover-danger.bs-popover-left>.arrow::after,[dir="rtl"] .b-popover-danger.bs-popover-auto[x-placement^=left]>.arrow::after{border-right-color:#fbdddd}

.b-popover-danger .popover-header{color:#2a2e30;background-color:#f9cfcf;border-bottom-color:#f7b8b8}

.b-popover-danger .popover-body{color:#8a3f43}

.b-popover-light.popover{background-color:#fdfdfd;border-color:#fcfcfc}

.b-popover-light.bs-popover-top>.arrow::before,.b-popover-light.bs-popover-auto[x-placement^=top]>.arrow::before{border-top-color:#fcfcfc}

.b-popover-light.bs-popover-top>.arrow::after,.b-popover-light.bs-popover-auto[x-placement^=top]>.arrow::after{border-top-color:#fdfdfd}

[dir="ltr"] .b-popover-light.bs-popover-right>.arrow::before,[dir="ltr"] .b-popover-light.bs-popover-auto[x-placement^=right]>.arrow::before{border-right-color:#fcfcfc}

[dir="rtl"] .b-popover-light.bs-popover-right>.arrow::before,[dir="rtl"] .b-popover-light.bs-popover-auto[x-placement^=right]>.arrow::before{border-left-color:#fcfcfc}

[dir="ltr"] .b-popover-light.bs-popover-right>.arrow::after,[dir="ltr"] .b-popover-light.bs-popover-auto[x-placement^=right]>.arrow::after{border-right-color:#fdfdfd}

[dir="rtl"] .b-popover-light.bs-popover-right>.arrow::after,[dir="rtl"] .b-popover-light.bs-popover-auto[x-placement^=right]>.arrow::after{border-left-color:#fdfdfd}

.b-popover-light.bs-popover-bottom>.arrow::before,.b-popover-light.bs-popover-auto[x-placement^=bottom]>.arrow::before{border-bottom-color:#fcfcfc}

.b-popover-light.bs-popover-bottom>.arrow::after,.b-popover-light.bs-popover-auto[x-placement^=bottom]>.arrow::after{border-bottom-color:#f5f5f5}

.b-popover-light.bs-popover-bottom .popover-header::before,.b-popover-light.bs-popover-auto[x-placement^=bottom] .popover-header::before{border-bottom-color:#f5f5f5}

[dir="ltr"] .b-popover-light.bs-popover-left>.arrow::before,[dir="ltr"] .b-popover-light.bs-popover-auto[x-placement^=left]>.arrow::before{border-left-color:#fcfcfc}

[dir="rtl"] .b-popover-light.bs-popover-left>.arrow::before,[dir="rtl"] .b-popover-light.bs-popover-auto[x-placement^=left]>.arrow::before{border-right-color:#fcfcfc}

[dir="ltr"] .b-popover-light.bs-popover-left>.arrow::after,[dir="ltr"] .b-popover-light.bs-popover-auto[x-placement^=left]>.arrow::after{border-left-color:#fdfdfd}

[dir="rtl"] .b-popover-light.bs-popover-left>.arrow::after,[dir="rtl"] .b-popover-light.bs-popover-auto[x-placement^=left]>.arrow::after{border-right-color:#fdfdfd}

.b-popover-light .popover-header{color:#2a2e30;background-color:#f5f5f5;border-bottom-color:#e9e9e9}

.b-popover-light .popover-body{color:#909496}

.b-popover-dark.popover{background-color:#d0d2d6;border-color:#bec0c5}

.b-popover-dark.bs-popover-top>.arrow::before,.b-popover-dark.bs-popover-auto[x-placement^=top]>.arrow::before{border-top-color:#bec0c5}

.b-popover-dark.bs-popover-top>.arrow::after,.b-popover-dark.bs-popover-auto[x-placement^=top]>.arrow::after{border-top-color:#d0d2d6}

[dir="ltr"] .b-popover-dark.bs-popover-right>.arrow::before,[dir="ltr"] .b-popover-dark.bs-popover-auto[x-placement^=right]>.arrow::before{border-right-color:#bec0c5}

[dir="rtl"] .b-popover-dark.bs-popover-right>.arrow::before,[dir="rtl"] .b-popover-dark.bs-popover-auto[x-placement^=right]>.arrow::before{border-left-color:#bec0c5}

[dir="ltr"] .b-popover-dark.bs-popover-right>.arrow::after,[dir="ltr"] .b-popover-dark.bs-popover-auto[x-placement^=right]>.arrow::after{border-right-color:#d0d2d6}

[dir="rtl"] .b-popover-dark.bs-popover-right>.arrow::after,[dir="rtl"] .b-popover-dark.bs-popover-auto[x-placement^=right]>.arrow::after{border-left-color:#d0d2d6}

.b-popover-dark.bs-popover-bottom>.arrow::before,.b-popover-dark.bs-popover-auto[x-placement^=bottom]>.arrow::before{border-bottom-color:#bec0c5}

.b-popover-dark.bs-popover-bottom>.arrow::after,.b-popover-dark.bs-popover-auto[x-placement^=bottom]>.arrow::after{border-bottom-color:#c8cacf}

.b-popover-dark.bs-popover-bottom .popover-header::before,.b-popover-dark.bs-popover-auto[x-placement^=bottom] .popover-header::before{border-bottom-color:#c8cacf}

[dir="ltr"] .b-popover-dark.bs-popover-left>.arrow::before,[dir="ltr"] .b-popover-dark.bs-popover-auto[x-placement^=left]>.arrow::before{border-left-color:#bec0c5}

[dir="rtl"] .b-popover-dark.bs-popover-left>.arrow::before,[dir="rtl"] .b-popover-dark.bs-popover-auto[x-placement^=left]>.arrow::before{border-right-color:#bec0c5}

[dir="ltr"] .b-popover-dark.bs-popover-left>.arrow::after,[dir="ltr"] .b-popover-dark.bs-popover-auto[x-placement^=left]>.arrow::after{border-left-color:#d0d2d6}

[dir="rtl"] .b-popover-dark.bs-popover-left>.arrow::after,[dir="rtl"] .b-popover-dark.bs-popover-auto[x-placement^=left]>.arrow::after{border-right-color:#d0d2d6}

.b-popover-dark .popover-header{color:#2a2e30;background-color:#c8cacf;border-bottom-color:#babdc3}

.b-popover-dark .popover-body{color:#1c2330}

.b-sidebar-outer{position:fixed;top:0;left:0;right:0;height:0;overflow:visible;z-index:calc(1030 + 5)}

.b-sidebar-backdrop{position:fixed;top:0;z-index:-1;width:100vw;height:100vh;opacity:.6}

[dir="ltr"] .b-sidebar-backdrop{left:0}

[dir="rtl"] .b-sidebar-backdrop{right:0}

.b-sidebar{display:flex;flex-direction:column;position:fixed;top:0;width:320px;max-width:100%;height:100vh;max-height:100%;margin:0;outline:0;transform:translateX(0)}

.b-sidebar.slide{transition:transform .3s ease-in-out}

@media(prefers-reduced-motion: reduce){
.b-sidebar.slide{transition:none}}

[dir="ltr"] .b-sidebar:not(.b-sidebar-right){left:0;right:auto}

[dir="rtl"] .b-sidebar:not(.b-sidebar-right){right:0;left:auto}

[dir="ltr"] .b-sidebar:not(.b-sidebar-right).slide:not(.show){transform:translateX(-100%)}

[dir="rtl"] .b-sidebar:not(.b-sidebar-right).slide:not(.show){transform:translateX(100%)}

[dir="ltr"] .b-sidebar:not(.b-sidebar-right)>.b-sidebar-header .close{margin-left:auto}

[dir="rtl"] .b-sidebar:not(.b-sidebar-right)>.b-sidebar-header .close{margin-right:auto}

[dir="ltr"] .b-sidebar.b-sidebar-right{left:auto;right:0}

[dir="rtl"] .b-sidebar.b-sidebar-right{right:auto;left:0}

[dir="ltr"] .b-sidebar.b-sidebar-right.slide:not(.show){transform:translateX(100%)}

[dir="rtl"] .b-sidebar.b-sidebar-right.slide:not(.show){transform:translateX(-100%)}

[dir="ltr"] .b-sidebar.b-sidebar-right>.b-sidebar-header .close{margin-right:auto}

[dir="rtl"] .b-sidebar.b-sidebar-right>.b-sidebar-header .close{margin-left:auto}

.b-sidebar>.b-sidebar-header{font-size:1.5rem;padding:.5rem 1rem;display:flex;flex-direction:row;flex-grow:0;align-items:center}

[dir=rtl] .b-sidebar>.b-sidebar-header{flex-direction:row-reverse}

.b-sidebar>.b-sidebar-header .close{float:none;font-size:1.5rem}

.b-sidebar>.b-sidebar-body{flex-grow:1;height:100%;overflow-y:auto}

.b-sidebar>.b-sidebar-footer{flex-grow:0}

.b-skeleton-wrapper{cursor:wait}

.b-skeleton{position:relative;overflow:hidden;background-color:rgba(0,0,0,.12);cursor:wait;mask-image:radial-gradient(white, black)}

.b-skeleton::before{content:" "}

.b-skeleton-text{height:1rem;margin-bottom:1rem;border-radius:.25rem}

.b-skeleton-button{width:75px;padding:.786rem 1.5rem;font-size:1rem;line-height:1;border-radius:.358rem}

.b-skeleton-avatar{width:2.5em;height:2.5em;border-radius:50%}

.b-skeleton-input{height:2.714rem;padding:.438rem 1rem;line-height:1.45;border:#d8d6de solid 1px;border-radius:.357rem}

.b-skeleton-icon-wrapper svg{color:rgba(0,0,0,.12)}

.b-skeleton-img{height:100%;width:100%}

.b-skeleton-animate-wave::after{content:"";position:absolute;top:0;right:0;bottom:0;left:0;z-index:0;animation:b-skeleton-animate-wave 1.75s linear infinite}

[dir="ltr"] .b-skeleton-animate-wave::after{background:linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.4), transparent)}

[dir="rtl"] .b-skeleton-animate-wave::after{background:linear-gradient(-90deg, transparent, rgba(255, 255, 255, 0.4), transparent)}

@media(prefers-reduced-motion: reduce){
.b-skeleton-animate-wave::after{background:none;animation:none}}

@keyframes b-skeleton-animate-wave{
from{transform:translateX(-100%)}

to{transform:translateX(100%)}}

.b-skeleton-animate-fade{animation:b-skeleton-animate-fade .875s ease-in-out alternate infinite}

@media(prefers-reduced-motion: reduce){
.b-skeleton-animate-fade{animation:none}}

@keyframes b-skeleton-animate-fade{
0%{opacity:1}

100%{opacity:.4}}

.b-skeleton-animate-throb{animation:b-skeleton-animate-throb .875s ease-in alternate infinite}

@media(prefers-reduced-motion: reduce){
.b-skeleton-animate-throb{animation:none}}

@keyframes b-skeleton-animate-throb{
0%{transform:scale(1)}

100%{transform:scale(0.975)}}

.table.b-table.b-table-fixed{table-layout:fixed}

.table.b-table.b-table-no-border-collapse{border-collapse:separate;border-spacing:0}

.table.b-table[aria-busy=true]{opacity:.55}

.table.b-table>tbody>tr.b-table-details>td{border-top:none !important}

.table.b-table>caption{caption-side:bottom}

.table.b-table.b-table-caption-top>caption{caption-side:top !important}

.table.b-table>tbody>.table-active,.table.b-table>tbody>.table-active>th,.table.b-table>tbody>.table-active>td{background-color:#f6f6f9}

.table.b-table.table-hover>tbody>tr.table-active:hover td,.table.b-table.table-hover>tbody>tr.table-active:hover th{color:#6e6b7b;background-image:linear-gradient(#f6f6f9, #f6f6f9);background-repeat:no-repeat}

.table.b-table>tbody>.bg-active,.table.b-table>tbody>.bg-active>th,.table.b-table>tbody>.bg-active>td{background-color:rgba(255,255,255,.075) !important}

.table.b-table.table-hover.table-dark>tbody>tr.bg-active:hover td,.table.b-table.table-hover.table-dark>tbody>tr.bg-active:hover th{color:#fff;background-image:linear-gradient(rgba(255, 255, 255, 0.075), rgba(255, 255, 255, 0.075));background-repeat:no-repeat}

.b-table-sticky-header,.table-responsive,[class*=table-responsive-]{margin-bottom:1rem}

.b-table-sticky-header>.table,.table-responsive>.table,[class*=table-responsive-]>.table{margin-bottom:0}

.b-table-sticky-header{overflow-y:auto;max-height:300px}

@media print{
.b-table-sticky-header{overflow-y:visible !important;max-height:none !important}}

@supports(position: sticky){
.b-table-sticky-header>.table.b-table>thead>tr>th{position:sticky;top:0;z-index:2}

.b-table-sticky-header>.table.b-table>thead>tr>.b-table-sticky-column,.b-table-sticky-header>.table.b-table>tbody>tr>.b-table-sticky-column,.b-table-sticky-header>.table.b-table>tfoot>tr>.b-table-sticky-column,.table-responsive>.table.b-table>thead>tr>.b-table-sticky-column,.table-responsive>.table.b-table>tbody>tr>.b-table-sticky-column,.table-responsive>.table.b-table>tfoot>tr>.b-table-sticky-column,[class*=table-responsive-]>.table.b-table>thead>tr>.b-table-sticky-column,[class*=table-responsive-]>.table.b-table>tbody>tr>.b-table-sticky-column,[class*=table-responsive-]>.table.b-table>tfoot>tr>.b-table-sticky-column{position:sticky}

[dir="ltr"] .b-table-sticky-header>.table.b-table>thead>tr>.b-table-sticky-column,[dir="ltr"] .b-table-sticky-header>.table.b-table>tbody>tr>.b-table-sticky-column,[dir="ltr"] .b-table-sticky-header>.table.b-table>tfoot>tr>.b-table-sticky-column,[dir="ltr"] .table-responsive>.table.b-table>thead>tr>.b-table-sticky-column,[dir="ltr"] .table-responsive>.table.b-table>tbody>tr>.b-table-sticky-column,[dir="ltr"] .table-responsive>.table.b-table>tfoot>tr>.b-table-sticky-column,[dir="ltr"] [class*=table-responsive-]>.table.b-table>thead>tr>.b-table-sticky-column,[dir="ltr"] [class*=table-responsive-]>.table.b-table>tbody>tr>.b-table-sticky-column,[dir="ltr"] [class*=table-responsive-]>.table.b-table>tfoot>tr>.b-table-sticky-column{left:0}

[dir="rtl"] .b-table-sticky-header>.table.b-table>thead>tr>.b-table-sticky-column,[dir="rtl"] .b-table-sticky-header>.table.b-table>tbody>tr>.b-table-sticky-column,[dir="rtl"] .b-table-sticky-header>.table.b-table>tfoot>tr>.b-table-sticky-column,[dir="rtl"] .table-responsive>.table.b-table>thead>tr>.b-table-sticky-column,[dir="rtl"] .table-responsive>.table.b-table>tbody>tr>.b-table-sticky-column,[dir="rtl"] .table-responsive>.table.b-table>tfoot>tr>.b-table-sticky-column,[dir="rtl"] [class*=table-responsive-]>.table.b-table>thead>tr>.b-table-sticky-column,[dir="rtl"] [class*=table-responsive-]>.table.b-table>tbody>tr>.b-table-sticky-column,[dir="rtl"] [class*=table-responsive-]>.table.b-table>tfoot>tr>.b-table-sticky-column{right:0}

.b-table-sticky-header>.table.b-table>thead>tr>.b-table-sticky-column,.table-responsive>.table.b-table>thead>tr>.b-table-sticky-column,[class*=table-responsive-]>.table.b-table>thead>tr>.b-table-sticky-column{z-index:5}

.b-table-sticky-header>.table.b-table>tbody>tr>.b-table-sticky-column,.b-table-sticky-header>.table.b-table>tfoot>tr>.b-table-sticky-column,.table-responsive>.table.b-table>tbody>tr>.b-table-sticky-column,.table-responsive>.table.b-table>tfoot>tr>.b-table-sticky-column,[class*=table-responsive-]>.table.b-table>tbody>tr>.b-table-sticky-column,[class*=table-responsive-]>.table.b-table>tfoot>tr>.b-table-sticky-column{z-index:2}

.table.b-table>thead>tr>.table-b-table-default,.table.b-table>tbody>tr>.table-b-table-default,.table.b-table>tfoot>tr>.table-b-table-default{color:#6e6b7b;background-color:#f6f6f6}

.table.b-table.table-dark>thead>tr>.bg-b-table-default,.table.b-table.table-dark>tbody>tr>.bg-b-table-default,.table.b-table.table-dark>tfoot>tr>.bg-b-table-default{color:#fff;background-color:#161d31}

.table.b-table.table-striped>tbody>tr:nth-of-type(odd)>.table-b-table-default{background-image:linear-gradient(#fafafc, #fafafc);background-repeat:no-repeat}

.table.b-table.table-striped.table-dark>tbody>tr:nth-of-type(odd)>.bg-b-table-default{background-image:linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05));background-repeat:no-repeat}

.table.b-table.table-hover>tbody>tr:hover>.table-b-table-default{color:#6e6b7b;background-image:linear-gradient(#f6f6f9, #f6f6f9);background-repeat:no-repeat}

.table.b-table.table-hover.table-dark>tbody>tr:hover>.bg-b-table-default{color:#fff;background-image:linear-gradient(rgba(255, 255, 255, 0.075), rgba(255, 255, 255, 0.075));background-repeat:no-repeat}}

.table.b-table>thead>tr>[aria-sort],.table.b-table>tfoot>tr>[aria-sort]{cursor:pointer;background-image:none;background-repeat:no-repeat;background-size:.65em 1em}

[dir="ltr"] .table.b-table>thead>tr>[aria-sort]:not(.b-table-sort-icon-left),[dir="ltr"] .table.b-table>tfoot>tr>[aria-sort]:not(.b-table-sort-icon-left){background-position:right calc(0.72rem / 2) center;padding-right:calc(0.72rem + 0.65em)}

[dir="rtl"] .table.b-table>thead>tr>[aria-sort]:not(.b-table-sort-icon-left),[dir="rtl"] .table.b-table>tfoot>tr>[aria-sort]:not(.b-table-sort-icon-left){background-position:left calc(0.72rem / 2) center;padding-left:calc(0.72rem + 0.65em)}

[dir="ltr"] .table.b-table>thead>tr>[aria-sort].b-table-sort-icon-left,[dir="ltr"] .table.b-table>tfoot>tr>[aria-sort].b-table-sort-icon-left{background-position:left calc(0.72rem / 2) center;padding-left:calc(0.72rem + 0.65em)}

[dir="rtl"] .table.b-table>thead>tr>[aria-sort].b-table-sort-icon-left,[dir="rtl"] .table.b-table>tfoot>tr>[aria-sort].b-table-sort-icon-left{background-position:right calc(0.72rem / 2) center;padding-right:calc(0.72rem + 0.65em)}

.table.b-table>thead>tr>[aria-sort=none],.table.b-table>tfoot>tr>[aria-sort=none]{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 xmlns:xlink=%27http://www.w3.org/1999/xlink%27 width=%2714px%27 height=%2726px%27 viewBox=%270 0 14 26%27 version=%271.1%27%3E%3Ctitle%3Earrows%3C/title%3E%3Cg id=%27Page-1%27 stroke=%27none%27 stroke-width=%271%27 fill=%27none%27 fill-rule=%27evenodd%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27%3E%3Cg id=%27Artboard%27 transform=%27translate%28-257.000000, -561.000000%29%27 stroke=%27%23C6C4D0%27 stroke-width=%272%27%3E%3Cg id=%27arrows%27 transform=%27translate%28258.000000, 562.000000%29%27%3E%3Cg id=%27chevron-down%27 transform=%27translate%280.000000, 18.000000%29%27%3E%3Cpolyline id=%27Path%27 points=%270 0 6 6 12 0%27/%3E%3C/g%3E%3Cg id=%27chevron-up%27%3E%3Cpolyline id=%27Path%27 points=%2712 6 6 0 0 6%27/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E")}

.table.b-table>thead>tr>[aria-sort=ascending],.table.b-table>tfoot>tr>[aria-sort=ascending]{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 xmlns:xlink=%27http://www.w3.org/1999/xlink%27 width=%2714px%27 height=%2726px%27 viewBox=%270 0 14 26%27 version=%271.1%27%3E%3Ctitle%3Eactive-up%3C/title%3E%3Cg id=%27Page-1%27 stroke=%27none%27 stroke-width=%271%27 fill=%27none%27 fill-rule=%27evenodd%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27%3E%3Cg id=%27Artboard%27 transform=%27translate%28-197.000000, -561.000000%29%27 stroke-width=%272%27%3E%3Cg id=%27active-up%27 transform=%27translate%28198.000000, 562.000000%29%27%3E%3Cg id=%27chevron-down%27 transform=%27translate%280.000000, 18.000000%29%27 stroke=%27%23C6C4D0%27%3E%3Cpolyline id=%27Path%27 points=%270 0 6 6 12 0%27/%3E%3C/g%3E%3Cg id=%27chevron-up%27 stroke=%27%235E5873%27%3E%3Cpolyline id=%27Path%27 points=%2712 6 6 0 0 6%27/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E")}

.table.b-table>thead>tr>[aria-sort=descending],.table.b-table>tfoot>tr>[aria-sort=descending]{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 xmlns:xlink=%27http://www.w3.org/1999/xlink%27 width=%2714px%27 height=%2726px%27 viewBox=%270 0 14 26%27 version=%271.1%27%3E%3Ctitle%3Eactive-down%3C/title%3E%3Cg id=%27Page-1%27 stroke=%27none%27 stroke-width=%271%27 fill=%27none%27 fill-rule=%27evenodd%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27%3E%3Cg id=%27Artboard%27 transform=%27translate%28-227.000000, -561.000000%29%27 stroke-width=%272%27%3E%3Cg id=%27active-down%27 transform=%27translate%28228.000000, 562.000000%29%27%3E%3Cg id=%27chevron-down%27 transform=%27translate%280.000000, 18.000000%29%27 stroke=%27%235E5873%27%3E%3Cpolyline id=%27Path%27 points=%270 0 6 6 12 0%27/%3E%3C/g%3E%3Cg id=%27chevron-up%27 stroke=%27%23C6C4D0%27%3E%3Cpolyline id=%27Path%27 points=%2712 6 6 0 0 6%27/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E")}

.table.b-table.table-dark>thead>tr>[aria-sort=none],.table.b-table.table-dark>tfoot>tr>[aria-sort=none],.table.b-table>.thead-dark>tr>[aria-sort=none]{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 xmlns:xlink=%27http://www.w3.org/1999/xlink%27 width=%2714px%27 height=%2726px%27 viewBox=%270 0 14 26%27 version=%271.1%27%3E%3Ctitle%3Earrow-dark%3C/title%3E%3Cg id=%27Page-1%27 stroke=%27none%27 stroke-width=%271%27 fill=%27none%27 fill-rule=%27evenodd%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27%3E%3Cg id=%27Artboard%27 transform=%27translate%28-259.000000, -621.000000%29%27 stroke=%27%23747A88%27 stroke-width=%272%27%3E%3Cg id=%27arrow-dark%27 transform=%27translate%28260.000000, 622.000000%29%27%3E%3Cg id=%27chevron-down%27 transform=%27translate%280.000000, 18.000000%29%27%3E%3Cpolyline id=%27Path%27 points=%270 0 6 6 12 0%27/%3E%3C/g%3E%3Cg id=%27chevron-up%27%3E%3Cpolyline id=%27Path%27 points=%2712 6 6 0 0 6%27/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E")}

.table.b-table.table-dark>thead>tr>[aria-sort=ascending],.table.b-table.table-dark>tfoot>tr>[aria-sort=ascending],.table.b-table>.thead-dark>tr>[aria-sort=ascending]{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 xmlns:xlink=%27http://www.w3.org/1999/xlink%27 width=%2714px%27 height=%2726px%27 viewBox=%270 0 14 26%27 version=%271.1%27%3E%3Ctitle%3Eactive-up-dark%3C/title%3E%3Cg id=%27Page-1%27 stroke=%27none%27 stroke-width=%271%27 fill=%27none%27 fill-rule=%27evenodd%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27%3E%3Cg id=%27Artboard%27 transform=%27translate%28-199.000000, -621.000000%29%27 stroke-width=%272%27%3E%3Cg id=%27active-up-dark%27 transform=%27translate%28200.000000, 622.000000%29%27%3E%3Cg id=%27chevron-down%27 transform=%27translate%280.000000, 18.000000%29%27 stroke=%27%23747A88%27%3E%3Cpolyline id=%27Path%27 points=%270 0 6 6 12 0%27/%3E%3C/g%3E%3Cg id=%27chevron-up%27 stroke=%27%23B4B7BC%27%3E%3Cpolyline id=%27Path%27 points=%2712 6 6 0 0 6%27/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E")}

.table.b-table.table-dark>thead>tr>[aria-sort=descending],.table.b-table.table-dark>tfoot>tr>[aria-sort=descending],.table.b-table>.thead-dark>tr>[aria-sort=descending]{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 xmlns:xlink=%27http://www.w3.org/1999/xlink%27 width=%2714px%27 height=%2726px%27 viewBox=%270 0 14 26%27 version=%271.1%27%3E%3Ctitle%3Eactive-dow-dark%3C/title%3E%3Cg id=%27Page-1%27 stroke=%27none%27 stroke-width=%271%27 fill=%27none%27 fill-rule=%27evenodd%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27%3E%3Cg id=%27Artboard%27 transform=%27translate%28-229.000000, -621.000000%29%27 stroke-width=%272%27%3E%3Cg id=%27active-dow-dark%27 transform=%27translate%28230.000000, 622.000000%29%27%3E%3Cg id=%27chevron-down%27 transform=%27translate%280.000000, 18.000000%29%27 stroke=%27%23B4B7BC%27%3E%3Cpolyline id=%27Path%27 points=%270 0 6 6 12 0%27/%3E%3C/g%3E%3Cg id=%27chevron-up%27 stroke=%27%23747A88%27%3E%3Cpolyline id=%27Path%27 points=%2712 6 6 0 0 6%27/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E")}

.table.b-table>thead>tr>.table-dark[aria-sort=none],.table.b-table>tfoot>tr>.table-dark[aria-sort=none]{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 xmlns:xlink=%27http://www.w3.org/1999/xlink%27 width=%2714px%27 height=%2726px%27 viewBox=%270 0 14 26%27 version=%271.1%27%3E%3Ctitle%3Earrow-dark%3C/title%3E%3Cg id=%27Page-1%27 stroke=%27none%27 stroke-width=%271%27 fill=%27none%27 fill-rule=%27evenodd%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27%3E%3Cg id=%27Artboard%27 transform=%27translate%28-259.000000, -621.000000%29%27 stroke=%27%23747A88%27 stroke-width=%272%27%3E%3Cg id=%27arrow-dark%27 transform=%27translate%28260.000000, 622.000000%29%27%3E%3Cg id=%27chevron-down%27 transform=%27translate%280.000000, 18.000000%29%27%3E%3Cpolyline id=%27Path%27 points=%270 0 6 6 12 0%27/%3E%3C/g%3E%3Cg id=%27chevron-up%27%3E%3Cpolyline id=%27Path%27 points=%2712 6 6 0 0 6%27/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E")}

.table.b-table>thead>tr>.table-dark[aria-sort=ascending],.table.b-table>tfoot>tr>.table-dark[aria-sort=ascending]{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 xmlns:xlink=%27http://www.w3.org/1999/xlink%27 width=%2714px%27 height=%2726px%27 viewBox=%270 0 14 26%27 version=%271.1%27%3E%3Ctitle%3Eactive-up-dark%3C/title%3E%3Cg id=%27Page-1%27 stroke=%27none%27 stroke-width=%271%27 fill=%27none%27 fill-rule=%27evenodd%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27%3E%3Cg id=%27Artboard%27 transform=%27translate%28-199.000000, -621.000000%29%27 stroke-width=%272%27%3E%3Cg id=%27active-up-dark%27 transform=%27translate%28200.000000, 622.000000%29%27%3E%3Cg id=%27chevron-down%27 transform=%27translate%280.000000, 18.000000%29%27 stroke=%27%23747A88%27%3E%3Cpolyline id=%27Path%27 points=%270 0 6 6 12 0%27/%3E%3C/g%3E%3Cg id=%27chevron-up%27 stroke=%27%23B4B7BC%27%3E%3Cpolyline id=%27Path%27 points=%2712 6 6 0 0 6%27/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E")}

.table.b-table>thead>tr>.table-dark[aria-sort=descending],.table.b-table>tfoot>tr>.table-dark[aria-sort=descending]{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 xmlns:xlink=%27http://www.w3.org/1999/xlink%27 width=%2714px%27 height=%2726px%27 viewBox=%270 0 14 26%27 version=%271.1%27%3E%3Ctitle%3Eactive-dow-dark%3C/title%3E%3Cg id=%27Page-1%27 stroke=%27none%27 stroke-width=%271%27 fill=%27none%27 fill-rule=%27evenodd%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27%3E%3Cg id=%27Artboard%27 transform=%27translate%28-229.000000, -621.000000%29%27 stroke-width=%272%27%3E%3Cg id=%27active-dow-dark%27 transform=%27translate%28230.000000, 622.000000%29%27%3E%3Cg id=%27chevron-down%27 transform=%27translate%280.000000, 18.000000%29%27 stroke=%27%23B4B7BC%27%3E%3Cpolyline id=%27Path%27 points=%270 0 6 6 12 0%27/%3E%3C/g%3E%3Cg id=%27chevron-up%27 stroke=%27%23747A88%27%3E%3Cpolyline id=%27Path%27 points=%2712 6 6 0 0 6%27/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E")}

[dir="ltr"] .table.b-table.table-sm>thead>tr>[aria-sort]:not(.b-table-sort-icon-left),[dir="ltr"] .table.b-table.table-sm>tfoot>tr>[aria-sort]:not(.b-table-sort-icon-left){background-position:right calc(0.3rem / 2) center;padding-right:calc(0.3rem + 0.65em)}

[dir="rtl"] .table.b-table.table-sm>thead>tr>[aria-sort]:not(.b-table-sort-icon-left),[dir="rtl"] .table.b-table.table-sm>tfoot>tr>[aria-sort]:not(.b-table-sort-icon-left){background-position:left calc(0.3rem / 2) center;padding-left:calc(0.3rem + 0.65em)}

[dir="ltr"] .table.b-table.table-sm>thead>tr>[aria-sort].b-table-sort-icon-left,[dir="ltr"] .table.b-table.table-sm>tfoot>tr>[aria-sort].b-table-sort-icon-left{background-position:left calc(0.3rem / 2) center;padding-left:calc(0.3rem + 0.65em)}

[dir="rtl"] .table.b-table.table-sm>thead>tr>[aria-sort].b-table-sort-icon-left,[dir="rtl"] .table.b-table.table-sm>tfoot>tr>[aria-sort].b-table-sort-icon-left{background-position:right calc(0.3rem / 2) center;padding-right:calc(0.3rem + 0.65em)}

.table.b-table.b-table-selectable:not(.b-table-selectable-no-click)>tbody>tr{cursor:pointer}

.table.b-table.b-table-selectable:not(.b-table-selectable-no-click).b-table-selecting.b-table-select-range>tbody>tr{user-select:none}

@media(max-width: 575.98px){
.table.b-table.b-table-stacked-sm{display:block;width:100%}

.table.b-table.b-table-stacked-sm>caption,.table.b-table.b-table-stacked-sm>tbody,.table.b-table.b-table-stacked-sm>tbody>tr,.table.b-table.b-table-stacked-sm>tbody>tr>td,.table.b-table.b-table-stacked-sm>tbody>tr>th{display:block}

.table.b-table.b-table-stacked-sm>thead,.table.b-table.b-table-stacked-sm>tfoot{display:none}

.table.b-table.b-table-stacked-sm>thead>tr.b-table-top-row,.table.b-table.b-table-stacked-sm>thead>tr.b-table-bottom-row,.table.b-table.b-table-stacked-sm>tfoot>tr.b-table-top-row,.table.b-table.b-table-stacked-sm>tfoot>tr.b-table-bottom-row{display:none}

.table.b-table.b-table-stacked-sm>caption{caption-side:top !important}

.table.b-table.b-table-stacked-sm>tbody>tr>[data-label]::before{content:attr(data-label);width:40%;overflow-wrap:break-word;font-weight:bold;font-style:normal;margin:0}

[dir="ltr"] .table.b-table.b-table-stacked-sm>tbody>tr>[data-label]::before{float:left;text-align:right;padding:0 calc(1rem / 2) 0 0}

[dir="rtl"] .table.b-table.b-table-stacked-sm>tbody>tr>[data-label]::before{float:right;text-align:left;padding:0 0 0 calc(1rem / 2)}

.table.b-table.b-table-stacked-sm>tbody>tr>[data-label]::after{display:block;clear:both;content:""}

.table.b-table.b-table-stacked-sm>tbody>tr>[data-label]>div{display:inline-block;width:calc(100% - 40%);margin:0}

[dir="ltr"] .table.b-table.b-table-stacked-sm>tbody>tr>[data-label]>div{padding:0 0 0 calc(1rem / 2)}

[dir="rtl"] .table.b-table.b-table-stacked-sm>tbody>tr>[data-label]>div{padding:0 calc(1rem / 2) 0 0}

.table.b-table.b-table-stacked-sm>tbody>tr.top-row,.table.b-table.b-table-stacked-sm>tbody>tr.bottom-row{display:none}

.table.b-table.b-table-stacked-sm>tbody>tr>:first-child{border-top-width:3px}

.table.b-table.b-table-stacked-sm>tbody>tr>[rowspan]+td,.table.b-table.b-table-stacked-sm>tbody>tr>[rowspan]+th{border-top-width:3px}}

@media(max-width: 767.98px){
.table.b-table.b-table-stacked-md{display:block;width:100%}

.table.b-table.b-table-stacked-md>caption,.table.b-table.b-table-stacked-md>tbody,.table.b-table.b-table-stacked-md>tbody>tr,.table.b-table.b-table-stacked-md>tbody>tr>td,.table.b-table.b-table-stacked-md>tbody>tr>th{display:block}

.table.b-table.b-table-stacked-md>thead,.table.b-table.b-table-stacked-md>tfoot{display:none}

.table.b-table.b-table-stacked-md>thead>tr.b-table-top-row,.table.b-table.b-table-stacked-md>thead>tr.b-table-bottom-row,.table.b-table.b-table-stacked-md>tfoot>tr.b-table-top-row,.table.b-table.b-table-stacked-md>tfoot>tr.b-table-bottom-row{display:none}

.table.b-table.b-table-stacked-md>caption{caption-side:top !important}

.table.b-table.b-table-stacked-md>tbody>tr>[data-label]::before{content:attr(data-label);width:40%;overflow-wrap:break-word;font-weight:bold;font-style:normal;margin:0}

[dir="ltr"] .table.b-table.b-table-stacked-md>tbody>tr>[data-label]::before{float:left;text-align:right;padding:0 calc(1rem / 2) 0 0}

[dir="rtl"] .table.b-table.b-table-stacked-md>tbody>tr>[data-label]::before{float:right;text-align:left;padding:0 0 0 calc(1rem / 2)}

.table.b-table.b-table-stacked-md>tbody>tr>[data-label]::after{display:block;clear:both;content:""}

.table.b-table.b-table-stacked-md>tbody>tr>[data-label]>div{display:inline-block;width:calc(100% - 40%);margin:0}

[dir="ltr"] .table.b-table.b-table-stacked-md>tbody>tr>[data-label]>div{padding:0 0 0 calc(1rem / 2)}

[dir="rtl"] .table.b-table.b-table-stacked-md>tbody>tr>[data-label]>div{padding:0 calc(1rem / 2) 0 0}

.table.b-table.b-table-stacked-md>tbody>tr.top-row,.table.b-table.b-table-stacked-md>tbody>tr.bottom-row{display:none}

.table.b-table.b-table-stacked-md>tbody>tr>:first-child{border-top-width:3px}

.table.b-table.b-table-stacked-md>tbody>tr>[rowspan]+td,.table.b-table.b-table-stacked-md>tbody>tr>[rowspan]+th{border-top-width:3px}}

@media(max-width: 991.98px){
.table.b-table.b-table-stacked-lg{display:block;width:100%}

.table.b-table.b-table-stacked-lg>caption,.table.b-table.b-table-stacked-lg>tbody,.table.b-table.b-table-stacked-lg>tbody>tr,.table.b-table.b-table-stacked-lg>tbody>tr>td,.table.b-table.b-table-stacked-lg>tbody>tr>th{display:block}

.table.b-table.b-table-stacked-lg>thead,.table.b-table.b-table-stacked-lg>tfoot{display:none}

.table.b-table.b-table-stacked-lg>thead>tr.b-table-top-row,.table.b-table.b-table-stacked-lg>thead>tr.b-table-bottom-row,.table.b-table.b-table-stacked-lg>tfoot>tr.b-table-top-row,.table.b-table.b-table-stacked-lg>tfoot>tr.b-table-bottom-row{display:none}

.table.b-table.b-table-stacked-lg>caption{caption-side:top !important}

.table.b-table.b-table-stacked-lg>tbody>tr>[data-label]::before{content:attr(data-label);width:40%;overflow-wrap:break-word;font-weight:bold;font-style:normal;margin:0}

[dir="ltr"] .table.b-table.b-table-stacked-lg>tbody>tr>[data-label]::before{float:left;text-align:right;padding:0 calc(1rem / 2) 0 0}

[dir="rtl"] .table.b-table.b-table-stacked-lg>tbody>tr>[data-label]::before{float:right;text-align:left;padding:0 0 0 calc(1rem / 2)}

.table.b-table.b-table-stacked-lg>tbody>tr>[data-label]::after{display:block;clear:both;content:""}

.table.b-table.b-table-stacked-lg>tbody>tr>[data-label]>div{display:inline-block;width:calc(100% - 40%);margin:0}

[dir="ltr"] .table.b-table.b-table-stacked-lg>tbody>tr>[data-label]>div{padding:0 0 0 calc(1rem / 2)}

[dir="rtl"] .table.b-table.b-table-stacked-lg>tbody>tr>[data-label]>div{padding:0 calc(1rem / 2) 0 0}

.table.b-table.b-table-stacked-lg>tbody>tr.top-row,.table.b-table.b-table-stacked-lg>tbody>tr.bottom-row{display:none}

.table.b-table.b-table-stacked-lg>tbody>tr>:first-child{border-top-width:3px}

.table.b-table.b-table-stacked-lg>tbody>tr>[rowspan]+td,.table.b-table.b-table-stacked-lg>tbody>tr>[rowspan]+th{border-top-width:3px}}

@media(max-width: 1199.98px){
.table.b-table.b-table-stacked-xl{display:block;width:100%}

.table.b-table.b-table-stacked-xl>caption,.table.b-table.b-table-stacked-xl>tbody,.table.b-table.b-table-stacked-xl>tbody>tr,.table.b-table.b-table-stacked-xl>tbody>tr>td,.table.b-table.b-table-stacked-xl>tbody>tr>th{display:block}

.table.b-table.b-table-stacked-xl>thead,.table.b-table.b-table-stacked-xl>tfoot{display:none}

.table.b-table.b-table-stacked-xl>thead>tr.b-table-top-row,.table.b-table.b-table-stacked-xl>thead>tr.b-table-bottom-row,.table.b-table.b-table-stacked-xl>tfoot>tr.b-table-top-row,.table.b-table.b-table-stacked-xl>tfoot>tr.b-table-bottom-row{display:none}

.table.b-table.b-table-stacked-xl>caption{caption-side:top !important}

.table.b-table.b-table-stacked-xl>tbody>tr>[data-label]::before{content:attr(data-label);width:40%;overflow-wrap:break-word;font-weight:bold;font-style:normal;margin:0}

[dir="ltr"] .table.b-table.b-table-stacked-xl>tbody>tr>[data-label]::before{float:left;text-align:right;padding:0 calc(1rem / 2) 0 0}

[dir="rtl"] .table.b-table.b-table-stacked-xl>tbody>tr>[data-label]::before{float:right;text-align:left;padding:0 0 0 calc(1rem / 2)}

.table.b-table.b-table-stacked-xl>tbody>tr>[data-label]::after{display:block;clear:both;content:""}

.table.b-table.b-table-stacked-xl>tbody>tr>[data-label]>div{display:inline-block;width:calc(100% - 40%);margin:0}

[dir="ltr"] .table.b-table.b-table-stacked-xl>tbody>tr>[data-label]>div{padding:0 0 0 calc(1rem / 2)}

[dir="rtl"] .table.b-table.b-table-stacked-xl>tbody>tr>[data-label]>div{padding:0 calc(1rem / 2) 0 0}

.table.b-table.b-table-stacked-xl>tbody>tr.top-row,.table.b-table.b-table-stacked-xl>tbody>tr.bottom-row{display:none}

.table.b-table.b-table-stacked-xl>tbody>tr>:first-child{border-top-width:3px}

.table.b-table.b-table-stacked-xl>tbody>tr>[rowspan]+td,.table.b-table.b-table-stacked-xl>tbody>tr>[rowspan]+th{border-top-width:3px}}

.table.b-table.b-table-stacked{display:block;width:100%}

.table.b-table.b-table-stacked>caption,.table.b-table.b-table-stacked>tbody,.table.b-table.b-table-stacked>tbody>tr,.table.b-table.b-table-stacked>tbody>tr>td,.table.b-table.b-table-stacked>tbody>tr>th{display:block}

.table.b-table.b-table-stacked>thead,.table.b-table.b-table-stacked>tfoot{display:none}

.table.b-table.b-table-stacked>thead>tr.b-table-top-row,.table.b-table.b-table-stacked>thead>tr.b-table-bottom-row,.table.b-table.b-table-stacked>tfoot>tr.b-table-top-row,.table.b-table.b-table-stacked>tfoot>tr.b-table-bottom-row{display:none}

.table.b-table.b-table-stacked>caption{caption-side:top !important}

.table.b-table.b-table-stacked>tbody>tr>[data-label]::before{content:attr(data-label);width:40%;overflow-wrap:break-word;font-weight:bold;font-style:normal;margin:0}

[dir="ltr"] .table.b-table.b-table-stacked>tbody>tr>[data-label]::before{float:left;text-align:right;padding:0 calc(1rem / 2) 0 0}

[dir="rtl"] .table.b-table.b-table-stacked>tbody>tr>[data-label]::before{float:right;text-align:left;padding:0 0 0 calc(1rem / 2)}

.table.b-table.b-table-stacked>tbody>tr>[data-label]::after{display:block;clear:both;content:""}

.table.b-table.b-table-stacked>tbody>tr>[data-label]>div{display:inline-block;width:calc(100% - 40%);margin:0}

[dir="ltr"] .table.b-table.b-table-stacked>tbody>tr>[data-label]>div{padding:0 0 0 calc(1rem / 2)}

[dir="rtl"] .table.b-table.b-table-stacked>tbody>tr>[data-label]>div{padding:0 calc(1rem / 2) 0 0}

.table.b-table.b-table-stacked>tbody>tr.top-row,.table.b-table.b-table-stacked>tbody>tr.bottom-row{display:none}

.table.b-table.b-table-stacked>tbody>tr>:first-child{border-top-width:3px}

.table.b-table.b-table-stacked>tbody>tr>[rowspan]+td,.table.b-table.b-table-stacked>tbody>tr>[rowspan]+th{border-top-width:3px}

.b-time{min-width:150px}

.b-time[aria-disabled=true] output,.b-time[aria-readonly=true] output,.b-time output.disabled{background-color:#efefef;opacity:1}

.b-time[aria-disabled=true] output{pointer-events:none}

[dir=rtl] .b-time>.d-flex:not(.flex-column){flex-direction:row-reverse}

.b-time .b-time-header{margin-bottom:.5rem}

.b-time .b-time-header output{padding:.25rem;font-size:80%}

.b-time .b-time-footer{margin-top:.5rem}

[dir="ltr"] .b-time .b-time-ampm{margin-left:.5rem}

[dir="rtl"] .b-time .b-time-ampm{margin-right:.5rem}

.b-toast{display:block;position:relative;max-width:380px;backface-visibility:hidden;background-clip:padding-box;z-index:1;border-radius:.286rem}

.b-toast .toast{background-color:rgba(255,255,255,.85)}

.b-toast:not(:last-child){margin-bottom:1.14rem}

.b-toast.b-toast-solid .toast{background-color:#fff}

.b-toast .toast{opacity:1}

.b-toast .toast.fade:not(.show){opacity:0}

.b-toast .toast .toast-body{display:block}

.b-toast-primary .toast{background-color:rgba(248,240,252,.85);border-color:rgba(231,205,244,.85);color:#683b87}

.b-toast-primary .toast .toast-header{color:#683b87;background-color:rgba(238,219,247,.85);border-bottom-color:rgba(231,205,244,.85)}

.b-toast-primary.b-toast-solid .toast{background-color:#f8f0fc}

.b-toast-secondary .toast{background-color:rgba(243,244,244,.85);border-color:rgba(220,221,223,.85);color:#54595f}

.b-toast-secondary .toast .toast-header{color:#54595f;background-color:rgba(230,231,232,.85);border-bottom-color:rgba(220,221,223,.85)}

.b-toast-secondary.b-toast-solid .toast{background-color:#f3f4f4}

.b-toast-success .toast{background-color:rgba(232,249,240,.85);border-color:rgba(195,239,215,.85);color:#257b50}

.b-toast-success .toast .toast-header{color:#257b50;background-color:rgba(212,244,226,.85);border-bottom-color:rgba(195,239,215,.85)}

.b-toast-success.b-toast-solid .toast{background-color:#e8f9f0}

.b-toast-info .toast{background-color:rgba(227,250,252,.85);border-color:rgba(184,242,249,.85);color:#107f8f}

.b-toast-info .toast .toast-header{color:#107f8f;background-color:rgba(204,245,250,.85);border-bottom-color:rgba(184,242,249,.85)}

.b-toast-info.b-toast-solid .toast{background-color:#e3fafc}

.b-toast-warning .toast{background-color:rgba(255,249,243,.85);border-color:rgba(255,228,202,.85);color:#956639}

.b-toast-warning .toast .toast-header{color:#956639;background-color:rgba(255,236,217,.85);border-bottom-color:rgba(255,228,202,.85)}

.b-toast-warning.b-toast-solid .toast{background-color:#fff9f3}

.b-toast-danger .toast{background-color:rgba(254,244,244,.85);border-color:rgba(249,207,207,.85);color:#8a3f43}

.b-toast-danger .toast .toast-header{color:#8a3f43;background-color:rgba(251,221,221,.85);border-bottom-color:rgba(249,207,207,.85)}

.b-toast-danger.b-toast-solid .toast{background-color:#fef4f4}

.b-toast-light .toast{background-color:rgba(255,255,255,.85);border-color:rgba(252,252,252,.85);color:#909496}

.b-toast-light .toast .toast-header{color:#909496;background-color:rgba(253,253,253,.85);border-bottom-color:rgba(252,252,252,.85)}

.b-toast-light.b-toast-solid .toast{background-color:#fff}

.b-toast-dark .toast{background-color:rgba(222,223,226,.85);border-color:rgba(190,192,197,.85);color:#1c2330}

.b-toast-dark .toast .toast-header{color:#1c2330;background-color:rgba(208,210,214,.85);border-bottom-color:rgba(190,192,197,.85)}

.b-toast-dark.b-toast-solid .toast{background-color:#dedfe2}

.b-toaster{z-index:1100}

.b-toaster .b-toaster-slot{position:relative;display:block}

.b-toaster .b-toaster-slot:empty{display:none !important}

.b-toaster.b-toaster-top-right,.b-toaster.b-toaster-top-left,.b-toaster.b-toaster-top-center,.b-toaster.b-toaster-top-full,.b-toaster.b-toaster-bottom-right,.b-toaster.b-toaster-bottom-left,.b-toaster.b-toaster-bottom-center,.b-toaster.b-toaster-bottom-full{position:fixed;left:.5rem;right:.5rem;margin:0;padding:0;height:0;overflow:visible}

.b-toaster.b-toaster-top-right .b-toaster-slot,.b-toaster.b-toaster-top-left .b-toaster-slot,.b-toaster.b-toaster-top-center .b-toaster-slot,.b-toaster.b-toaster-top-full .b-toaster-slot,.b-toaster.b-toaster-bottom-right .b-toaster-slot,.b-toaster.b-toaster-bottom-left .b-toaster-slot,.b-toaster.b-toaster-bottom-center .b-toaster-slot,.b-toaster.b-toaster-bottom-full .b-toaster-slot{position:absolute;max-width:380px;width:100%;left:0;right:0;padding:0;margin:0}

.b-toaster.b-toaster-top-full .b-toaster-slot,.b-toaster.b-toaster-bottom-full .b-toaster-slot{width:100%;max-width:100%}

.b-toaster.b-toaster-top-full .b-toaster-slot .b-toast,.b-toaster.b-toaster-top-full .b-toaster-slot .toast,.b-toaster.b-toaster-bottom-full .b-toaster-slot .b-toast,.b-toaster.b-toaster-bottom-full .b-toaster-slot .toast{width:100%;max-width:100%}

.b-toaster.b-toaster-top-right,.b-toaster.b-toaster-top-left,.b-toaster.b-toaster-top-center,.b-toaster.b-toaster-top-full{top:0}

.b-toaster.b-toaster-top-right .b-toaster-slot,.b-toaster.b-toaster-top-left .b-toaster-slot,.b-toaster.b-toaster-top-center .b-toaster-slot,.b-toaster.b-toaster-top-full .b-toaster-slot{top:.5rem}

.b-toaster.b-toaster-bottom-right,.b-toaster.b-toaster-bottom-left,.b-toaster.b-toaster-bottom-center,.b-toaster.b-toaster-bottom-full{bottom:0}

.b-toaster.b-toaster-bottom-right .b-toaster-slot,.b-toaster.b-toaster-bottom-left .b-toaster-slot,.b-toaster.b-toaster-bottom-center .b-toaster-slot,.b-toaster.b-toaster-bottom-full .b-toaster-slot{bottom:.5rem}

[dir="ltr"] .b-toaster.b-toaster-top-right .b-toaster-slot,[dir="ltr"] .b-toaster.b-toaster-bottom-right .b-toaster-slot,[dir="ltr"] .b-toaster.b-toaster-top-center .b-toaster-slot,[dir="ltr"] .b-toaster.b-toaster-bottom-center .b-toaster-slot{margin-left:auto}

[dir="rtl"] .b-toaster.b-toaster-top-right .b-toaster-slot,[dir="rtl"] .b-toaster.b-toaster-bottom-right .b-toaster-slot,[dir="rtl"] .b-toaster.b-toaster-top-center .b-toaster-slot,[dir="rtl"] .b-toaster.b-toaster-bottom-center .b-toaster-slot{margin-right:auto}

[dir="ltr"] .b-toaster.b-toaster-top-left .b-toaster-slot,[dir="ltr"] .b-toaster.b-toaster-bottom-left .b-toaster-slot,[dir="ltr"] .b-toaster.b-toaster-top-center .b-toaster-slot,[dir="ltr"] .b-toaster.b-toaster-bottom-center .b-toaster-slot{margin-right:auto}

[dir="rtl"] .b-toaster.b-toaster-top-left .b-toaster-slot,[dir="rtl"] .b-toaster.b-toaster-bottom-left .b-toaster-slot,[dir="rtl"] .b-toaster.b-toaster-top-center .b-toaster-slot,[dir="rtl"] .b-toaster.b-toaster-bottom-center .b-toaster-slot{margin-left:auto}

.b-toaster.b-toaster-top-right .b-toast.b-toaster-enter-active,.b-toaster.b-toaster-top-right .b-toast.b-toaster-leave-active,.b-toaster.b-toaster-top-right .b-toast.b-toaster-move,.b-toaster.b-toaster-top-left .b-toast.b-toaster-enter-active,.b-toaster.b-toaster-top-left .b-toast.b-toaster-leave-active,.b-toaster.b-toaster-top-left .b-toast.b-toaster-move,.b-toaster.b-toaster-bottom-right .b-toast.b-toaster-enter-active,.b-toaster.b-toaster-bottom-right .b-toast.b-toaster-leave-active,.b-toaster.b-toaster-bottom-right .b-toast.b-toaster-move,.b-toaster.b-toaster-bottom-left .b-toast.b-toaster-enter-active,.b-toaster.b-toaster-bottom-left .b-toast.b-toaster-leave-active,.b-toaster.b-toaster-bottom-left .b-toast.b-toaster-move{transition:transform .175s}

.b-toaster.b-toaster-top-right .b-toast.b-toaster-enter-to .toast.fade,.b-toaster.b-toaster-top-right .b-toast.b-toaster-enter-active .toast.fade,.b-toaster.b-toaster-top-left .b-toast.b-toaster-enter-to .toast.fade,.b-toaster.b-toaster-top-left .b-toast.b-toaster-enter-active .toast.fade,.b-toaster.b-toaster-bottom-right .b-toast.b-toaster-enter-to .toast.fade,.b-toaster.b-toaster-bottom-right .b-toast.b-toaster-enter-active .toast.fade,.b-toaster.b-toaster-bottom-left .b-toast.b-toaster-enter-to .toast.fade,.b-toaster.b-toaster-bottom-left .b-toast.b-toaster-enter-active .toast.fade{transition-delay:.175s}

.b-toaster.b-toaster-top-right .b-toast.b-toaster-leave-active,.b-toaster.b-toaster-top-left .b-toast.b-toaster-leave-active,.b-toaster.b-toaster-bottom-right .b-toast.b-toaster-leave-active,.b-toaster.b-toaster-bottom-left .b-toast.b-toaster-leave-active{position:absolute;transition-delay:.175s}

.b-toaster.b-toaster-top-right .b-toast.b-toaster-leave-active .toast.fade,.b-toaster.b-toaster-top-left .b-toast.b-toaster-leave-active .toast.fade,.b-toaster.b-toaster-bottom-right .b-toast.b-toaster-leave-active .toast.fade,.b-toaster.b-toaster-bottom-left .b-toast.b-toaster-leave-active .toast.fade{transition-delay:0s}

.tooltip.b-tooltip{display:block;opacity:1;outline:0}

.tooltip.b-tooltip.fade:not(.show){opacity:0}

.tooltip.b-tooltip.show{opacity:1}

.tooltip.b-tooltip.noninteractive{pointer-events:none}

.tooltip.b-tooltip .arrow{margin:0 .358rem}

.tooltip.b-tooltip.bs-tooltip-right .arrow,.tooltip.b-tooltip.bs-tooltip-auto[x-placement^=right] .arrow,.tooltip.b-tooltip.bs-tooltip-left .arrow,.tooltip.b-tooltip.bs-tooltip-auto[x-placement^=left] .arrow{margin:.358rem 0}

.tooltip.b-tooltip-primary.bs-tooltip-top .arrow::before,.tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=top] .arrow::before{border-top-color:#a94cd8}

[dir="ltr"] .tooltip.b-tooltip-primary.bs-tooltip-right .arrow::before,[dir="ltr"] .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=right] .arrow::before{border-right-color:#a94cd8}

[dir="rtl"] .tooltip.b-tooltip-primary.bs-tooltip-right .arrow::before,[dir="rtl"] .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=right] .arrow::before{border-left-color:#a94cd8}

.tooltip.b-tooltip-primary.bs-tooltip-bottom .arrow::before,.tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=bottom] .arrow::before{border-bottom-color:#a94cd8}

[dir="ltr"] .tooltip.b-tooltip-primary.bs-tooltip-left .arrow::before,[dir="ltr"] .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=left] .arrow::before{border-left-color:#a94cd8}

[dir="rtl"] .tooltip.b-tooltip-primary.bs-tooltip-left .arrow::before,[dir="rtl"] .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=left] .arrow::before{border-right-color:#a94cd8}

.tooltip.b-tooltip-primary .tooltip-inner{color:#fff;background-color:#a94cd8}

.tooltip.b-tooltip-secondary.bs-tooltip-top .arrow::before,.tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=top] .arrow::before{border-top-color:#82868b}

[dir="ltr"] .tooltip.b-tooltip-secondary.bs-tooltip-right .arrow::before,[dir="ltr"] .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=right] .arrow::before{border-right-color:#82868b}

[dir="rtl"] .tooltip.b-tooltip-secondary.bs-tooltip-right .arrow::before,[dir="rtl"] .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=right] .arrow::before{border-left-color:#82868b}

.tooltip.b-tooltip-secondary.bs-tooltip-bottom .arrow::before,.tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=bottom] .arrow::before{border-bottom-color:#82868b}

[dir="ltr"] .tooltip.b-tooltip-secondary.bs-tooltip-left .arrow::before,[dir="ltr"] .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=left] .arrow::before{border-left-color:#82868b}

[dir="rtl"] .tooltip.b-tooltip-secondary.bs-tooltip-left .arrow::before,[dir="rtl"] .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=left] .arrow::before{border-right-color:#82868b}

.tooltip.b-tooltip-secondary .tooltip-inner{color:#fff;background-color:#82868b}

.tooltip.b-tooltip-success.bs-tooltip-top .arrow::before,.tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=top] .arrow::before{border-top-color:#28c76f}

[dir="ltr"] .tooltip.b-tooltip-success.bs-tooltip-right .arrow::before,[dir="ltr"] .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=right] .arrow::before{border-right-color:#28c76f}

[dir="rtl"] .tooltip.b-tooltip-success.bs-tooltip-right .arrow::before,[dir="rtl"] .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=right] .arrow::before{border-left-color:#28c76f}

.tooltip.b-tooltip-success.bs-tooltip-bottom .arrow::before,.tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=bottom] .arrow::before{border-bottom-color:#28c76f}

[dir="ltr"] .tooltip.b-tooltip-success.bs-tooltip-left .arrow::before,[dir="ltr"] .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=left] .arrow::before{border-left-color:#28c76f}

[dir="rtl"] .tooltip.b-tooltip-success.bs-tooltip-left .arrow::before,[dir="rtl"] .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=left] .arrow::before{border-right-color:#28c76f}

.tooltip.b-tooltip-success .tooltip-inner{color:#fff;background-color:#28c76f}

.tooltip.b-tooltip-info.bs-tooltip-top .arrow::before,.tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=top] .arrow::before{border-top-color:#00cfe8}

[dir="ltr"] .tooltip.b-tooltip-info.bs-tooltip-right .arrow::before,[dir="ltr"] .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=right] .arrow::before{border-right-color:#00cfe8}

[dir="rtl"] .tooltip.b-tooltip-info.bs-tooltip-right .arrow::before,[dir="rtl"] .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=right] .arrow::before{border-left-color:#00cfe8}

.tooltip.b-tooltip-info.bs-tooltip-bottom .arrow::before,.tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=bottom] .arrow::before{border-bottom-color:#00cfe8}

[dir="ltr"] .tooltip.b-tooltip-info.bs-tooltip-left .arrow::before,[dir="ltr"] .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=left] .arrow::before{border-left-color:#00cfe8}

[dir="rtl"] .tooltip.b-tooltip-info.bs-tooltip-left .arrow::before,[dir="rtl"] .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=left] .arrow::before{border-right-color:#00cfe8}

.tooltip.b-tooltip-info .tooltip-inner{color:#fff;background-color:#00cfe8}

.tooltip.b-tooltip-warning.bs-tooltip-top .arrow::before,.tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=top] .arrow::before{border-top-color:#ff9f43}

[dir="ltr"] .tooltip.b-tooltip-warning.bs-tooltip-right .arrow::before,[dir="ltr"] .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=right] .arrow::before{border-right-color:#ff9f43}

[dir="rtl"] .tooltip.b-tooltip-warning.bs-tooltip-right .arrow::before,[dir="rtl"] .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=right] .arrow::before{border-left-color:#ff9f43}

.tooltip.b-tooltip-warning.bs-tooltip-bottom .arrow::before,.tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=bottom] .arrow::before{border-bottom-color:#ff9f43}

[dir="ltr"] .tooltip.b-tooltip-warning.bs-tooltip-left .arrow::before,[dir="ltr"] .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=left] .arrow::before{border-left-color:#ff9f43}

[dir="rtl"] .tooltip.b-tooltip-warning.bs-tooltip-left .arrow::before,[dir="rtl"] .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=left] .arrow::before{border-right-color:#ff9f43}

.tooltip.b-tooltip-warning .tooltip-inner{color:#2a2e30;background-color:#ff9f43}

.tooltip.b-tooltip-danger.bs-tooltip-top .arrow::before,.tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=top] .arrow::before{border-top-color:#ea5455}

[dir="ltr"] .tooltip.b-tooltip-danger.bs-tooltip-right .arrow::before,[dir="ltr"] .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=right] .arrow::before{border-right-color:#ea5455}

[dir="rtl"] .tooltip.b-tooltip-danger.bs-tooltip-right .arrow::before,[dir="rtl"] .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=right] .arrow::before{border-left-color:#ea5455}

.tooltip.b-tooltip-danger.bs-tooltip-bottom .arrow::before,.tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=bottom] .arrow::before{border-bottom-color:#ea5455}

[dir="ltr"] .tooltip.b-tooltip-danger.bs-tooltip-left .arrow::before,[dir="ltr"] .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=left] .arrow::before{border-left-color:#ea5455}

[dir="rtl"] .tooltip.b-tooltip-danger.bs-tooltip-left .arrow::before,[dir="rtl"] .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=left] .arrow::before{border-right-color:#ea5455}

.tooltip.b-tooltip-danger .tooltip-inner{color:#fff;background-color:#ea5455}

.tooltip.b-tooltip-light.bs-tooltip-top .arrow::before,.tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=top] .arrow::before{border-top-color:#f6f6f6}

[dir="ltr"] .tooltip.b-tooltip-light.bs-tooltip-right .arrow::before,[dir="ltr"] .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=right] .arrow::before{border-right-color:#f6f6f6}

[dir="rtl"] .tooltip.b-tooltip-light.bs-tooltip-right .arrow::before,[dir="rtl"] .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=right] .arrow::before{border-left-color:#f6f6f6}

.tooltip.b-tooltip-light.bs-tooltip-bottom .arrow::before,.tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=bottom] .arrow::before{border-bottom-color:#f6f6f6}

[dir="ltr"] .tooltip.b-tooltip-light.bs-tooltip-left .arrow::before,[dir="ltr"] .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=left] .arrow::before{border-left-color:#f6f6f6}

[dir="rtl"] .tooltip.b-tooltip-light.bs-tooltip-left .arrow::before,[dir="rtl"] .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=left] .arrow::before{border-right-color:#f6f6f6}

.tooltip.b-tooltip-light .tooltip-inner{color:#2a2e30;background-color:#f6f6f6}

.tooltip.b-tooltip-dark.bs-tooltip-top .arrow::before,.tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=top] .arrow::before{border-top-color:#161d31}

[dir="ltr"] .tooltip.b-tooltip-dark.bs-tooltip-right .arrow::before,[dir="ltr"] .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=right] .arrow::before{border-right-color:#161d31}

[dir="rtl"] .tooltip.b-tooltip-dark.bs-tooltip-right .arrow::before,[dir="rtl"] .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=right] .arrow::before{border-left-color:#161d31}

.tooltip.b-tooltip-dark.bs-tooltip-bottom .arrow::before,.tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=bottom] .arrow::before{border-bottom-color:#161d31}

[dir="ltr"] .tooltip.b-tooltip-dark.bs-tooltip-left .arrow::before,[dir="ltr"] .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=left] .arrow::before{border-left-color:#161d31}

[dir="rtl"] .tooltip.b-tooltip-dark.bs-tooltip-left .arrow::before,[dir="rtl"] .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=left] .arrow::before{border-right-color:#161d31}

.tooltip.b-tooltip-dark .tooltip-inner{color:#fff;background-color:#161d31}

.b-icon.bi{display:inline-block;overflow:visible;vertical-align:-0.15em}

.b-icon.b-icon-animation-cylon,.b-icon.b-iconstack .b-icon-animation-cylon>g{transform-origin:center;animation:.75s infinite ease-in-out alternate b-icon-animation-cylon}

@media(prefers-reduced-motion: reduce){
.b-icon.b-icon-animation-cylon,.b-icon.b-iconstack .b-icon-animation-cylon>g{animation:none}}

.b-icon.b-icon-animation-cylon-vertical,.b-icon.b-iconstack .b-icon-animation-cylon-vertical>g{transform-origin:center;animation:.75s infinite ease-in-out alternate b-icon-animation-cylon-vertical}

@media(prefers-reduced-motion: reduce){
.b-icon.b-icon-animation-cylon-vertical,.b-icon.b-iconstack .b-icon-animation-cylon-vertical>g{animation:none}}

.b-icon.b-icon-animation-fade,.b-icon.b-iconstack .b-icon-animation-fade>g{transform-origin:center;animation:.75s infinite ease-in-out alternate b-icon-animation-fade}

@media(prefers-reduced-motion: reduce){
.b-icon.b-icon-animation-fade,.b-icon.b-iconstack .b-icon-animation-fade>g{animation:none}}

.b-icon.b-icon-animation-spin,.b-icon.b-iconstack .b-icon-animation-spin>g{transform-origin:center;animation:2s infinite linear normal b-icon-animation-spin}

@media(prefers-reduced-motion: reduce){
.b-icon.b-icon-animation-spin,.b-icon.b-iconstack .b-icon-animation-spin>g{animation:none}}

.b-icon.b-icon-animation-spin-reverse,.b-icon.b-iconstack .b-icon-animation-spin-reverse>g{transform-origin:center;animation:2s infinite linear reverse b-icon-animation-spin}

@media(prefers-reduced-motion: reduce){
.b-icon.b-icon-animation-spin-reverse,.b-icon.b-iconstack .b-icon-animation-spin-reverse>g{animation:none}}

.b-icon.b-icon-animation-spin-pulse,.b-icon.b-iconstack .b-icon-animation-spin-pulse>g{transform-origin:center;animation:1s infinite steps(8) normal b-icon-animation-spin}

@media(prefers-reduced-motion: reduce){
.b-icon.b-icon-animation-spin-pulse,.b-icon.b-iconstack .b-icon-animation-spin-pulse>g{animation:none}}

.b-icon.b-icon-animation-spin-reverse-pulse,.b-icon.b-iconstack .b-icon-animation-spin-reverse-pulse>g{transform-origin:center;animation:1s infinite steps(8) reverse b-icon-animation-spin}

@media(prefers-reduced-motion: reduce){
.b-icon.b-icon-animation-spin-reverse-pulse,.b-icon.b-iconstack .b-icon-animation-spin-reverse-pulse>g{animation:none}}

.b-icon.b-icon-animation-throb,.b-icon.b-iconstack .b-icon-animation-throb>g{transform-origin:center;animation:.75s infinite ease-in-out alternate b-icon-animation-throb}

@media(prefers-reduced-motion: reduce){
.b-icon.b-icon-animation-throb,.b-icon.b-iconstack .b-icon-animation-throb>g{animation:none}}

@keyframes b-icon-animation-cylon{
0%{transform:translateX(-25%)}

100%{transform:translateX(25%)}}

@keyframes b-icon-animation-cylon-vertical{
0%{transform:translateY(25%)}

100%{transform:translateY(-25%)}}

@keyframes b-icon-animation-fade{
0%{opacity:.1}

100%{opacity:1}}

@keyframes b-icon-animation-spin{
0%{transform:rotate(0deg)}

100%{transform:rotate(359deg)}}

@keyframes b-icon-animation-throb{
0%{opacity:.5;transform:scale(0.5)}

100%{opacity:1;transform:scale(1)}}

.btn .b-icon.bi,.nav-link .b-icon.bi,.dropdown-toggle .b-icon.bi,.dropdown-item .b-icon.bi,.input-group-text .b-icon.bi{font-size:125%;vertical-align:text-bottom}

@keyframes tab-panel-slide-in{
0%{opacity:0;transform:translateX(100%)}

100%{opacity:1;transform:translateX(0%)}}

@keyframes tab-panel-slide-up{
0%{opacity:0;transform:translateY(100%)}

100%{opacity:1;transform:translateY(0%)}}

@keyframes rubberBand{
from{transform:scale3d(1, 1, 1)}

30%{transform:scale3d(1.25, 0.75, 1)}

50%{transform:scale3d(1.15, 0.85, 1)}

75%{transform:scale3d(1.05, 0.95, 1)}

to{transform:scale3d(1, 1, 1)}}

body{overflow-x:hidden}

.nav-item.has-sub .nav-item.has-sub li svg{display:none}

[dir="ltr"] .header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .badge.badge-up,[dir="ltr"] .header-navbar .navbar-container ul.navbar-nav li.dropdown-notification .badge.badge-up{right:-9px}

[dir="rtl"] .header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .badge.badge-up,[dir="rtl"] .header-navbar .navbar-container ul.navbar-nav li.dropdown-notification .badge.badge-up{left:-9px}

.horizontal-menu .horizontal-menu-wrapper .navbar-horizontal ul.dropdown-menu::-webkit-scrollbar{width:5px;height:5px}

.horizontal-menu .horizontal-menu-wrapper .navbar-horizontal ul.dropdown-menu::-webkit-scrollbar-thumb{background:#adb5bd;border-radius:20px}

.horizontal-menu .horizontal-menu-wrapper .navbar-horizontal ul.dropdown-menu::-webkit-scrollbar-track{background:#dae1e7;border-radius:30px}

.dark-layout .horizontal-layout.navbar-sticky .navbar.header-navbar{box-shadow:none}

body.dark-layout .b-avatar.badge-secondary{background-color:#161d31}

body.dark-layout .b-avatar .b-avatar-text span{color:#fff}

body.dark-layout .b-avatar .avatar-icon{color:#fff}

body.dark-layout .b-avatar.avatar-border-2 img{border-color:#3b4253}

body.dark-layout .b-avatar-group .b-avatar-group-inner .b-avatar{box-shadow:0 0 0 2px #283046,inset 0 0 0 1px rgba(40,48,70,.07)}

body.dark-layout .badge{color:#fff;white-space:break-spaces}

body.dark-layout .header-navbar.navbar-light .navbar-brand{color:#fff}

body.dark-layout .b-aspect{border-color:#3b4253}

body.dark-layout .popover.b-popover.bs-popover-top .arrow::before,body.dark-layout .popover.b-popover.bs-popover-auto[x-placement^=top] .arrow::before{border-top-color:#3b4253}

body.dark-layout .popover.b-popover.bs-popover-top .arrow::after,body.dark-layout .popover.b-popover.bs-popover-auto[x-placement^=top] .arrow::after{border-top-color:#283046}

[dir="ltr"] body.dark-layout .popover.b-popover.bs-popover-left .arrow::before,[dir="ltr"] body.dark-layout .popover.b-popover.bs-popover-auto[x-placement^=left] .arrow::before{border-left-color:#3b4253}

[dir="rtl"] body.dark-layout .popover.b-popover.bs-popover-left .arrow::before,[dir="rtl"] body.dark-layout .popover.b-popover.bs-popover-auto[x-placement^=left] .arrow::before{border-right-color:#3b4253}

[dir="ltr"] body.dark-layout .popover.b-popover.bs-popover-left .arrow::after,[dir="ltr"] body.dark-layout .popover.b-popover.bs-popover-auto[x-placement^=left] .arrow::after{border-left-color:#283046}

[dir="rtl"] body.dark-layout .popover.b-popover.bs-popover-left .arrow::after,[dir="rtl"] body.dark-layout .popover.b-popover.bs-popover-auto[x-placement^=left] .arrow::after{border-right-color:#283046}

[dir="ltr"] body.dark-layout .popover.b-popover.bs-popover-right .arrow::before,[dir="ltr"] body.dark-layout .popover.b-popover.bs-popover-auto[x-placement^=right] .arrow::before{border-right-color:#3b4253}

[dir="rtl"] body.dark-layout .popover.b-popover.bs-popover-right .arrow::before,[dir="rtl"] body.dark-layout .popover.b-popover.bs-popover-auto[x-placement^=right] .arrow::before{border-left-color:#3b4253}

[dir="ltr"] body.dark-layout .popover.b-popover.bs-popover-right .arrow::after,[dir="ltr"] body.dark-layout .popover.b-popover.bs-popover-auto[x-placement^=right] .arrow::after{border-right-color:#283046}

[dir="rtl"] body.dark-layout .popover.b-popover.bs-popover-right .arrow::after,[dir="rtl"] body.dark-layout .popover.b-popover.bs-popover-auto[x-placement^=right] .arrow::after{border-left-color:#283046}

body.dark-layout .custom-range{background-color:#283046;border-color:#3b4253}

body.dark-layout .custom-range:focus{background-color:#283046;border-color:#a94cd8}

body.dark-layout .modal-content .modal-header .close{color:#fff}

body.dark-layout .modal-content .modal-body{color:#fff}

body.dark-layout .custom-switch.switch-lg .custom-control-label::before,body.dark-layout .custom-switch.switch-md .custom-control-label::before{background-color:#161d31}

body.dark-layout .pagination .page-item.disabled .page-link{color:#676d7d !important}

body.dark-layout .b-toast .toast{background-color:rgba(22,29,49,.85);color:#fff}

body.dark-layout .b-time .b-time-header .form-control{background-color:#283046}

body.dark-layout .b-time[aria-disabled=true] output,body.dark-layout .b-time[aria-readonly=true] output,body.dark-layout .b-time output.disabled{background-color:#283046}

body.dark-layout .b-form-spinbutton.form-control{background-color:#283046;border-color:#404656;color:#fff}

body.dark-layout .b-form-spinbutton.form-control svg{color:#fff}

body.dark-layout .b-rating.form-control{background-color:#283046;border-color:#404656;color:#fff}

body.dark-layout .b-rating.disabled,body.dark-layout .b-rating:disabled{background-color:#24233a}

body.dark-layout .b-form-datepicker.form-control,body.dark-layout .b-form-timepicker.form-control{background-color:#283046;border-color:#404656}

body.dark-layout .b-form-datepicker.is-valid,body.dark-layout .b-form-timepicker.is-valid{border-color:#28c76f}

body.dark-layout .b-form-datepicker.is-invalid,body.dark-layout .b-form-timepicker.is-invalid{border-color:#ea5455}

body.dark-layout .b-time-header output{border-color:#3b4253}

body.dark-layout .b-time-header output bdi{color:#fff}

body.dark-layout .b-form-tags.form-control{background-color:#283046;border-color:#404656;color:#fff;box-shadow:none}

body.dark-layout .b-form-tags.form-control .b-form-tags-list .b-form-tag .b-form-tag-remove{background-color:transparent !important;box-shadow:none !important}

body.dark-layout .b-form-tags-input{color:#fff}

body.dark-layout .was-validated .custom-control-input:invalid~.custom-control-label::before,body.dark-layout .custom-control-input.is-invalid~.custom-control-label::before{border-color:#ea5455 !important}

body.dark-layout .was-validated .custom-control-input:valid~.custom-control-label::before,body.dark-layout .custom-control-input.is-valid~.custom-control-label::before{border-color:#28c76f !important}

body.dark-layout .was-validated .custom-select:valid,body.dark-layout .custom-select.is-valid{border-color:#28c76f}

body.dark-layout .was-validated .custom-select:invalid,body.dark-layout .custom-select.is-invalid{border-color:#ea5455}

body.dark-layout pre{color:#fff}

body.dark-layout .b-sidebar.bg-white{background-color:#283046 !important}

body.dark-layout .b-sidebar .b-sidebar-body .content-sidebar-header{background-color:#161d31}

body.dark-layout .horizontal-menu .horizontal-menu-wrapper .navbar-horizontal ul.dropdown-menu::-webkit-scrollbar-thumb{background:#161d31}

body.dark-layout .horizontal-menu .horizontal-menu-wrapper .navbar-horizontal ul.dropdown-menu::-webkit-scrollbar-track{background:#3b4253}

.form-group.is-invalid .v-select .vs__dropdown-toggle{border-color:#ea5455}

.form-group.is-invalid .flatpickr-input{border-color:#ea5455}

.tooltip{top:0}

[dir=rtl] body .ps__rail-y{right:auto !important;left:1px !important}

[dir=rtl] .flatpickr-prev-month svg,[dir=rtl] .flatpickr-next-month svg{transform:rotate(180deg)}

[dir=rtl] .faq-navigation .pricing-trial-img,[dir=rtl] .faq-navigation img,[dir=rtl] .pricing-free-trial .pricing-trial-img,[dir=rtl] .pricing-free-trial img{transform:scaleX(-1)}

[dir=rtl] .b-aspect .b-aspect-sizer{flex-grow:0 !important}

[dir=rtl] .carousel-control-prev-icon,[dir=rtl] .carousel-control-next-icon{transform:rotate(180deg)}

[dir=rtl] .swiper-container-cube.swiper-container-rtl .swiper-slide{transform-origin:100% 0 !important}

[dir=rtl] .swiper-container-rtl .swiper-button-prev{right:10px !important;left:auto !important}

[dir=rtl] .swiper-container-rtl .swiper-button-next{left:10px !important;right:auto !important}

[dir=rtl] .dropdown .btn-block+.dropdown-menu,[dir=rtl] .dropdown .btn-block+.dropdown-toggle-split+.dropdown-menu{left:0 !important}

[dir=rtl] .pagination .page-item.next-item .page-link svg,[dir=rtl] .pagination .page-item .page-link svg{transform:rotate(180deg)}

[dir=rtl] .popover{right:auto !important}

[dir=rtl] .progress-bar-animated{animation:progress-bar-stripes-rtl 40s linear infinite}

[dir=rtl] .b-time .b-time-ampm{margin-left:.5rem;margin-right:0}

[dir=rtl] .b-form-timepicker .dropdown-menu{right:100%}

[dir=rtl] .b-form-timepicker .dropdown-menu .b-time .b-time-ampm{margin-right:.5rem;margin-left:0}

[dir=rtl] .input-group .input-group-append .b-form-timepicker .dropdown-menu{left:100% !important;right:auto}

[dir=rtl] textarea{overflow-x:hidden}

[dir=rtl] .fc .fc-toolbar .fc-button-group .fc-dayGridMonth-button:first-child{border-top-left-radius:.357rem !important;border-bottom-left-radius:.357rem !important;border-top-right-radius:0 !important;border-bottom-right-radius:0 !important}

[dir=rtl] .fc .fc-toolbar .fc-button-group .fc-listMonth-button:last-child{border-top-right-radius:.357rem !important;border-bottom-right-radius:.357rem !important;border-top-left-radius:0 !important;border-bottom-left-radius:0 !important}

.fade-enter-active,.fade-leave-active{transition:opacity .28s ease-in-out}

.fade-enter,.fade-leave-to{opacity:0}

.slide-fade-enter-active,.slide-fade-leave-active{transition:opacity .35s,transform .4s}

.slide-fade-enter{opacity:0}

[dir="ltr"] .slide-fade-enter{transform:translateX(-30%)}

[dir="rtl"] .slide-fade-enter{transform:translateX(30%)}

.slide-fade-leave-to{opacity:0}

[dir="ltr"] .slide-fade-leave-to{transform:translateX(30%)}

[dir="rtl"] .slide-fade-leave-to{transform:translateX(-30%)}

.fade-bottom-enter-active,.fade-bottom-leave-active{transition:opacity .3s,transform .35s}

.fade-bottom-enter{opacity:0;transform:translateY(-8%)}

.fade-bottom-leave-to{opacity:0;transform:translateY(8%)}

.fade-bottom-2x-enter-active,.fade-bottom-2x-leave-active{transition:opacity .2s,transform .25s}

.fade-bottom-2x-enter{opacity:0;transform:translateY(-4%)}

.fade-bottom-2x-leave-to{opacity:0;transform:translateY(4%)}

.fade-top-enter-active,.fade-top-leave-active{transition:opacity .3s,transform .35s}

.fade-top-enter{opacity:0;transform:translateY(8%)}

.fade-top-leave-to{opacity:0;transform:translateY(-8%)}

.fade-top-2x-enter-active,.fade-top-2x-leave-active{transition:opacity .2s,transform .25s}

.fade-top-2x-enter{opacity:0;transform:translateY(4%)}

.fade-top-2x-leave-to{opacity:0;transform:translateY(-4%)}

.list-leave-active{position:absolute}

.list-enter,.list-leave-to{opacity:0}

[dir="ltr"] .list-enter,[dir="ltr"] .list-leave-to{transform:translateX(30px)}

[dir="rtl"] .list-enter,[dir="rtl"] .list-leave-to{transform:translateX(-30px)}

.list-enter-up-leave-active{transition:none !important}

.list-enter-up-enter{opacity:0;transform:translateY(30px)}

.alert{transition:all .25s ease}

.b-avatar{font-size:.857rem;font-weight:600;color:#fff}

.b-avatar.badge-minimal .b-avatar-badge{min-height:11px;min-width:11px;border:1px solid #fff}

.b-avatar.avatar-border-2 img{border:2px solid #fff}

.b-avatar.btn:not(:disabled):not(.disabled):hover .b-avatar-img img,.b-avatar[href]:not(:disabled):not(.disabled):hover .b-avatar-img img{transform:scale(1)}

.b-avatar-group .b-avatar-group-inner .b-avatar{transition:all .25s ease 0s;border-width:0;box-shadow:0 0 0 2px #fff,inset 0 0 0 1px rgba(34,41,47,.07);cursor:pointer}

.b-avatar-group .b-avatar-group-inner .b-avatar.pull-up:hover{transition:all .25s ease 0s;transform:translateY(-4px) scale(1.07)}

.b-avatar.badge-light-white{background-color:rgba(255,255,255,.12);color:#fff}

.b-avatar.badge-light-black{background-color:rgba(0,0,0,.12);color:#000}

.b-avatar.badge-light-dark{background-color:rgba(22,29,49,.12);color:#161d31}

.b-avatar.badge-light-light{background-color:rgba(246,246,246,.12);color:#f6f6f6}

.b-avatar.badge-light-primary{background-color:rgba(169,76,216,.12);color:#a94cd8}

.b-avatar.badge-light-secondary{background-color:rgba(130,134,139,.12);color:#82868b}

.b-avatar.badge-light-success{background-color:rgba(40,199,111,.12);color:#28c76f}

.b-avatar.badge-light-info{background-color:rgba(0,207,232,.12);color:#00cfe8}

.b-avatar.badge-light-warning{background-color:rgba(255,159,67,.12);color:#ff9f43}

.b-avatar.badge-light-danger{background-color:rgba(234,84,85,.12);color:#ea5455}

.btn-group-toggle :not([class*=btn-outline-]).active,.btn-group-toggle :not([class*=btn-outline-]):active,.btn-group-toggle :not([class*=btn-outline-]).active,.btn-group-toggle :not([class*=btn-outline-]):active{box-shadow:none}

a.badge-white:focus,a.badge-white.focus{box-shadow:none}

a.badge-black:focus,a.badge-black.focus{box-shadow:none}

a.badge-dark:focus,a.badge-dark.focus{box-shadow:none}

a.badge-light:focus,a.badge-light.focus{box-shadow:none}

a.badge-primary:focus,a.badge-primary.focus{box-shadow:none}

a.badge-secondary:focus,a.badge-secondary.focus{box-shadow:none}

a.badge-success:focus,a.badge-success.focus{box-shadow:none}

a.badge-info:focus,a.badge-info.focus{box-shadow:none}

a.badge-warning:focus,a.badge-warning.focus{box-shadow:none}

a.badge-danger:focus,a.badge-danger.focus{box-shadow:none}

.b-form-datepicker label,.b-form-timepicker label{line-height:200%}

.b-form-datepicker .btn.h-auto,.b-form-timepicker .btn.h-auto{padding:.375rem .75rem !important}

.b-form-datepicker .btn svg,.b-form-timepicker .btn svg{margin:0 !important}

.b-form-datepicker .form-control,.b-form-timepicker .form-control{min-height:auto !important}

.b-form-datepicker.form-control-sm .form-control-sm,.b-form-timepicker.form-control-sm .form-control-sm{line-height:1.65}

.dropdown.b-dropdown{border-radius:.357rem}

.b-form-file .custom-file-label{height:100% !important}

.b-form-file.b-custom-control-sm .custom-file-label,.b-form-file.b-custom-control-sm .custom-file-label::after{line-height:22px}

.b-form-file.b-custom-control-sm .custom-file-label::after{height:28px}

.b-form-file.b-custom-control-lg .custom-file-label,.b-form-file.b-custom-control-lg .custom-file-label::after{line-height:23px}

.b-form-file.b-custom-control-lg .custom-file-label::after{height:44px}

[dir="ltr"] .modal .modal-footer>.btn{margin-left:.75rem}

[dir="rtl"] .modal .modal-footer>.btn{margin-right:.75rem}

.b-nav-dropdown .dropdown-toggle::after{background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%236e6b7b%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-down%27%3E%3Cpolyline points=%276 9 12 15 18 9%27%3E%3C/polyline%3E%3C/svg%3E") !important}

.page-item.prev-item .page-link,.page-item.next-item .page-link{padding:.5rem 0}

.page-item.prev-item .page-link:hover,.page-item.next-item .page-link:hover{color:#fff}

.pagination.b-pagination-pills .page-item.active{background-color:transparent}

.page-item.prev-item .page-link:before{display:none}

[dir="ltr"] .page-item.prev-item .page-link svg{transform:translateX(-1px)}

[dir="rtl"] .page-item.prev-item .page-link svg{transform:translateX(1px)}

.page-item.next-item .page-link:after{display:none}

[dir="ltr"] .page-item.next-item .page-link svg{transform:translateX(1px)}

[dir="rtl"] .page-item.next-item .page-link svg{transform:translateX(-1px)}

.popover.b-popover.bs-popover-top .arrow::before,.popover.b-popover.bs-popover-auto[x-placement^=top] .arrow::before{border-top-color:#ebe9f1}

.popover.b-popover.bs-popover-top .arrow::after,.popover.b-popover.bs-popover-auto[x-placement^=top] .arrow::after{border-top-color:#fff}

[dir="ltr"] .popover.b-popover.bs-popover-left .arrow::before,[dir="ltr"] .popover.b-popover.bs-popover-auto[x-placement^=left] .arrow::before{border-left-color:#ebe9f1}

[dir="rtl"] .popover.b-popover.bs-popover-left .arrow::before,[dir="rtl"] .popover.b-popover.bs-popover-auto[x-placement^=left] .arrow::before{border-right-color:#ebe9f1}

[dir="ltr"] .popover.b-popover.bs-popover-left .arrow::after,[dir="ltr"] .popover.b-popover.bs-popover-auto[x-placement^=left] .arrow::after{border-left-color:#fff}

[dir="rtl"] .popover.b-popover.bs-popover-left .arrow::after,[dir="rtl"] .popover.b-popover.bs-popover-auto[x-placement^=left] .arrow::after{border-right-color:#fff}

[dir="ltr"] .popover.b-popover.bs-popover-right .arrow::before,[dir="ltr"] .popover.b-popover.bs-popover-auto[x-placement^=right] .arrow::before{border-right-color:#ebe9f1}

[dir="rtl"] .popover.b-popover.bs-popover-right .arrow::before,[dir="rtl"] .popover.b-popover.bs-popover-auto[x-placement^=right] .arrow::before{border-left-color:#ebe9f1}

[dir="ltr"] .popover.b-popover.bs-popover-right .arrow::after,[dir="ltr"] .popover.b-popover.bs-popover-auto[x-placement^=right] .arrow::after{border-right-color:#fff}

[dir="rtl"] .popover.b-popover.bs-popover-right .arrow::after,[dir="rtl"] .popover.b-popover.bs-popover-auto[x-placement^=right] .arrow::after{border-left-color:#fff}

.popover.b-popover.b-popover-white{background-color:#fff;border-color:#ebe9f1;z-index:9999;position:relative}

.popover.b-popover.b-popover-white .popover-header{background-color:#fff;border-color:#fff;color:#fff}

.popover.b-popover.b-popover-white.bs-popover-bottom .arrow::before,.popover.b-popover.b-popover-white.bs-popover-auto[x-placement^=bottom] .arrow::before{border-bottom-color:#fff}

.popover.b-popover.b-popover-white.bs-popover-bottom .arrow::after,.popover.b-popover.b-popover-white.bs-popover-auto[x-placement^=bottom] .arrow::after{border-bottom-color:#fff}

.popover.b-popover.b-popover-black{background-color:#fff;border-color:#ebe9f1;z-index:9999;position:relative}

.popover.b-popover.b-popover-black .popover-header{background-color:#000;border-color:#000;color:#fff}

.popover.b-popover.b-popover-black.bs-popover-bottom .arrow::before,.popover.b-popover.b-popover-black.bs-popover-auto[x-placement^=bottom] .arrow::before{border-bottom-color:#000}

.popover.b-popover.b-popover-black.bs-popover-bottom .arrow::after,.popover.b-popover.b-popover-black.bs-popover-auto[x-placement^=bottom] .arrow::after{border-bottom-color:#000}

.popover.b-popover.b-popover-dark{background-color:#fff;border-color:#ebe9f1;z-index:9999;position:relative}

.popover.b-popover.b-popover-dark .popover-header{background-color:#161d31;border-color:#161d31;color:#fff}

.popover.b-popover.b-popover-dark.bs-popover-bottom .arrow::before,.popover.b-popover.b-popover-dark.bs-popover-auto[x-placement^=bottom] .arrow::before{border-bottom-color:#161d31}

.popover.b-popover.b-popover-dark.bs-popover-bottom .arrow::after,.popover.b-popover.b-popover-dark.bs-popover-auto[x-placement^=bottom] .arrow::after{border-bottom-color:#161d31}

.popover.b-popover.b-popover-light{background-color:#fff;border-color:#ebe9f1;z-index:9999;position:relative}

.popover.b-popover.b-popover-light .popover-header{background-color:#f6f6f6;border-color:#f6f6f6;color:#fff}

.popover.b-popover.b-popover-light.bs-popover-bottom .arrow::before,.popover.b-popover.b-popover-light.bs-popover-auto[x-placement^=bottom] .arrow::before{border-bottom-color:#f6f6f6}

.popover.b-popover.b-popover-light.bs-popover-bottom .arrow::after,.popover.b-popover.b-popover-light.bs-popover-auto[x-placement^=bottom] .arrow::after{border-bottom-color:#f6f6f6}

.popover.b-popover.b-popover-primary{background-color:#fff;border-color:#ebe9f1;z-index:9999;position:relative}

.popover.b-popover.b-popover-primary .popover-header{background-color:#a94cd8;border-color:#a94cd8;color:#fff}

.popover.b-popover.b-popover-primary.bs-popover-bottom .arrow::before,.popover.b-popover.b-popover-primary.bs-popover-auto[x-placement^=bottom] .arrow::before{border-bottom-color:#a94cd8}

.popover.b-popover.b-popover-primary.bs-popover-bottom .arrow::after,.popover.b-popover.b-popover-primary.bs-popover-auto[x-placement^=bottom] .arrow::after{border-bottom-color:#a94cd8}

.popover.b-popover.b-popover-secondary{background-color:#fff;border-color:#ebe9f1;z-index:9999;position:relative}

.popover.b-popover.b-popover-secondary .popover-header{background-color:#82868b;border-color:#82868b;color:#fff}

.popover.b-popover.b-popover-secondary.bs-popover-bottom .arrow::before,.popover.b-popover.b-popover-secondary.bs-popover-auto[x-placement^=bottom] .arrow::before{border-bottom-color:#82868b}

.popover.b-popover.b-popover-secondary.bs-popover-bottom .arrow::after,.popover.b-popover.b-popover-secondary.bs-popover-auto[x-placement^=bottom] .arrow::after{border-bottom-color:#82868b}

.popover.b-popover.b-popover-success{background-color:#fff;border-color:#ebe9f1;z-index:9999;position:relative}

.popover.b-popover.b-popover-success .popover-header{background-color:#28c76f;border-color:#28c76f;color:#fff}

.popover.b-popover.b-popover-success.bs-popover-bottom .arrow::before,.popover.b-popover.b-popover-success.bs-popover-auto[x-placement^=bottom] .arrow::before{border-bottom-color:#28c76f}

.popover.b-popover.b-popover-success.bs-popover-bottom .arrow::after,.popover.b-popover.b-popover-success.bs-popover-auto[x-placement^=bottom] .arrow::after{border-bottom-color:#28c76f}

.popover.b-popover.b-popover-info{background-color:#fff;border-color:#ebe9f1;z-index:9999;position:relative}

.popover.b-popover.b-popover-info .popover-header{background-color:#00cfe8;border-color:#00cfe8;color:#fff}

.popover.b-popover.b-popover-info.bs-popover-bottom .arrow::before,.popover.b-popover.b-popover-info.bs-popover-auto[x-placement^=bottom] .arrow::before{border-bottom-color:#00cfe8}

.popover.b-popover.b-popover-info.bs-popover-bottom .arrow::after,.popover.b-popover.b-popover-info.bs-popover-auto[x-placement^=bottom] .arrow::after{border-bottom-color:#00cfe8}

.popover.b-popover.b-popover-warning{background-color:#fff;border-color:#ebe9f1;z-index:9999;position:relative}

.popover.b-popover.b-popover-warning .popover-header{background-color:#ff9f43;border-color:#ff9f43;color:#fff}

.popover.b-popover.b-popover-warning.bs-popover-bottom .arrow::before,.popover.b-popover.b-popover-warning.bs-popover-auto[x-placement^=bottom] .arrow::before{border-bottom-color:#ff9f43}

.popover.b-popover.b-popover-warning.bs-popover-bottom .arrow::after,.popover.b-popover.b-popover-warning.bs-popover-auto[x-placement^=bottom] .arrow::after{border-bottom-color:#ff9f43}

.popover.b-popover.b-popover-danger{background-color:#fff;border-color:#ebe9f1;z-index:9999;position:relative}

.popover.b-popover.b-popover-danger .popover-header{background-color:#ea5455;border-color:#ea5455;color:#fff}

.popover.b-popover.b-popover-danger.bs-popover-bottom .arrow::before,.popover.b-popover.b-popover-danger.bs-popover-auto[x-placement^=bottom] .arrow::before{border-bottom-color:#ea5455}

.popover.b-popover.b-popover-danger.bs-popover-bottom .arrow::after,.popover.b-popover.b-popover-danger.bs-popover-auto[x-placement^=bottom] .arrow::after{border-bottom-color:#ea5455}

output.b-rating.form-control:focus{box-shadow:none}

.b-sidebar.sidebar-lg{width:30rem}

@media(max-width: 575.98px){
.b-sidebar.sidebar-lg{width:20rem}}

.b-sidebar .b-sidebar-header .close{outline:none}

.b-sidebar .b-sidebar-body .content-sidebar-header{background-color:#f6f6f6}

.b-sidebar-backdrop{background-color:rgba(34,41,47,.75) !important}

.b-form-spinbutton.form-control{padding:0}

.b-form-spinbutton.form-control:focus,.b-form-spinbutton.form-control.focus{border-color:#a94cd8;box-shadow:none}

[dir="ltr"] .b-form-spinbutton.form-control.is-valid,[dir="ltr"] .b-form-spinbutton.form-control.is-invalid{padding-right:calc(1.5em + 0.75rem)}

[dir="rtl"] .b-form-spinbutton.form-control.is-valid,[dir="rtl"] .b-form-spinbutton.form-control.is-invalid{padding-left:calc(1.5em + 0.75rem)}

.b-form-spinbutton.form-control .btn-sm,.b-form-spinbutton.form-control .btn-group-sm>.btn{padding:.25rem .5rem}

.b-form-spinbutton.form-control-sm output>bdi{height:.9rem}

.custom-switch:not(.custom-control-inline) .custom-control-label{margin-bottom:.5rem}

.table.b-table.b-table-selectable tr{outline:none}

.table.b-table>tbody .b-table-row-selected.table-active td{background-color:#f6f6f9}

.table.b-table th{outline:none}

.b-form-tag{background-color:#a94cd8}

[dir="ltr"] .b-form-tag{margin-right:.8rem}

[dir="rtl"] .b-form-tag{margin-left:.8rem}

.b-form-tag .close{opacity:1;text-shadow:none;vertical-align:middle;display:flex;align-self:center}

.b-time-header output>bdi{line-height:1.85}

.b-time .bi-circle-fill{height:10px;width:10px;fill:#82868b}

.b-time .bi-chevron-up{height:10px;width:10px}

.b-time .b-form-spinbutton+.flex-column:not(.b-time-ampm){margin-left:.4rem;margin-right:.4rem}

.b-time .b-form-spinbutton output.border-bottom.border-top{border:none !important}

.b-time .b-form-spinbutton .btn:hover:not(:disabled)>div>.b-icon{transform:scale(1)}

.b-toast-white .toast-header{background-color:#fff !important;color:#fff !important}

.b-toast-white .toast-header .close{color:#fff;text-shadow:none}

.b-toast-black .toast-header{background-color:#000 !important;color:#fff !important}

.b-toast-black .toast-header .close{color:#fff;text-shadow:none}

.b-toast-dark .toast-header{background-color:#161d31 !important;color:#fff !important}

.b-toast-dark .toast-header .close{color:#fff;text-shadow:none}

.b-toast-light .toast-header{background-color:#f6f6f6 !important;color:#fff !important}

.b-toast-light .toast-header .close{color:#fff;text-shadow:none}

.b-toast-primary .toast-header{background-color:#a94cd8 !important;color:#fff !important}

.b-toast-primary .toast-header .close{color:#fff;text-shadow:none}

.b-toast-secondary .toast-header{background-color:#82868b !important;color:#fff !important}

.b-toast-secondary .toast-header .close{color:#fff;text-shadow:none}

.b-toast-success .toast-header{background-color:#28c76f !important;color:#fff !important}

.b-toast-success .toast-header .close{color:#fff;text-shadow:none}

.b-toast-info .toast-header{background-color:#00cfe8 !important;color:#fff !important}

.b-toast-info .toast-header .close{color:#fff;text-shadow:none}

.b-toast-warning .toast-header{background-color:#ff9f43 !important;color:#fff !important}

.b-toast-warning .toast-header .close{color:#fff;text-shadow:none}

.b-toast-danger .toast-header{background-color:#ea5455 !important;color:#fff !important}

.b-toast-danger .toast-header .close{color:#fff;text-shadow:none}

.b-toast.b-toast-solid .toast{background-color:#fff;color:#6e6b7b}

.toast-header .close{margin-bottom:2px !important}

.tooltip.b-tooltip-warning .tooltip-inner{color:#fff}

.custom-control-input.is-valid:not(:disabled):active~.custom-control-label::before{background-color:#28c76f;border-color:#28c76f}

.custom-control-input.is-invalid:not(:disabled):active~.custom-control-label::before{background-color:#ea5455;border-color:#ea5455}

.input-group-prepend .input-group-text .form-check,.input-group-append .input-group-text .form-check{line-height:0}

.custom-file-label{height:calc(1.65em + 1.4rem + 1px)}

body{font-family:Montserrat}

range-slider{margin:0 !important}

range-slider:before{border-radius:4px !important;height:6px;background:-webkit-gradient(linear, left top, left bottom, from(#A94CD8), to(#A94CD8)) 0/var(--value-percent, 0) 100% no-repeat #eeedfd}

[dir="ltr"] range-slider:before{background:linear-gradient(#A94CD8, #A94CD8) 0/var(--value-percent, 0) 100% no-repeat #eeedfd}

[dir="rtl"] range-slider:before{background:linear-gradient(#A94CD8, #A94CD8) 100%/var(--value-percent, 0) 100% no-repeat #eeedfd}

range-slider .thumb{background:#82868b !important;box-shadow:none}

range-slider .observer{width:100%}

range-slider .form-error{position:absolute}

range-slider .wrap{background:#ffc107;animation-name:expansion;animation-duration:1s;animation-iteration-count:1}

.link-desc{display:none}

.unselectable{-webkit-touch-callout:none;-webkit-user-select:none;-khtml-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}

.loader:before{background:#283046 !important;border-radius:12px !important}
